import React, { Component } from "react";
import ReactDOM from "react-dom";
import { API_URL, opht_url } from "../../config";
import swal from "sweetalert";
import "./Vieweyetest.css";
import Eyeresult from "./Eyetestresult";
import Pre from "../Prescription/Prescription";
import Prel from "../Prescription/PrescriptionL";
import ReactHtmlParser from "react-html-parser";
import Orderp from "../Order/Orderprocessing";
import Select from "react-select";
import {
  options,
  options2,
  options3,
  options4,
  options5,
} from "./EyetestOptions";
import { event } from "jquery";
import * as FileSaver from "file-saver";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
class Vieweyetest extends Component {
  constructor(props) {
    super(props);
    console.log(this.props.det);
    this.state = {
      emailID: "",
      pwd: "",
      loginIP: "",
      loginBrowser: "",
      deviceType: "",
      userLocation: "",
      logIsp: "",
      ii: 0,
      actions: [],
      testno: "",
      eyetest: "",
      currentImage: "",
      currentImagePath2: "",
      currentImagePath3: "",
      currentImagePath4: "",
      pLogo: "",
      docarr: [],
      presf: [{}, {}],
      utype: localStorage.getItem("type"),
      ophtAIStatus: {},
      testreport: null,
      isExternal: localStorage.getItem("isExternal"),
      images: {}, // Store fetched image paths
      loading: false,
      isloading: false,
      isloading2: false,
    };
    this.contentRef = React.createRef();
  }

  fetchImages = async () => {
    this.setState({ loading: true });
    let eyetestid = this.props.det.testid;
    var token = localStorage.getItem("token");
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({
        eyeTestID: eyetestid,
      }),
    };
    let logourl = API_URL + "/eyetests/searcheyetestimagenewbyid";
    fetch(logourl, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        console.log(json);
        this.setState({ loading: false });
        this.setState(
          {
            images: json,
          },
          () => {
            console.log(this.state.images.eyeTestImagePath2, "symreport2");
          }
        );
      })
      .catch((error) => {
        console.error("Error fetching the report:", error);
      });
  };

  renderImage(imagePath, key) {
    const defaultImage =
      "../../../app-assets/images/portrait/small/avatar-s-10.png";
    const imageUrl = imagePath ? `${API_URL}/${imagePath}` : defaultImage;
    console.log(imageUrl, "imageUrl");
    return (
      <div className="media mb-2 pl-5" key={key}>
        <a target="_blank" href={imageUrl}>
          <img
            src={imageUrl}
            alt="users avatar"
            className="user-avatar users-avatar-shadow rounded mr-2 my-25 cursor-pointer"
            height="300"
            width="340"
          />
        </a>
      </div>
    );
  }
  uploadImgCommon(event, imageType) {
    console.log(this.state.eyetest);
    this.setState({ isloading: true });
    // Check if any file is selected
    if (event.target.files.length > 0) {
      const uploadedFile = event.target.files[0];

      // Check if the file size is greater than 0KB
      if (uploadedFile.size > 0) {
        // Check if the file type is an image
        if (uploadedFile.type.startsWith("image/")) {
          switch (imageType) {
            case "1":
              this.setState({
                currentImage: URL.createObjectURL(uploadedFile),
              });
              break;
            case "2":
              this.setState({
                currentImagePath2: URL.createObjectURL(uploadedFile),
              });
              break;
            case "3":
              this.setState({
                currentImagePath3: URL.createObjectURL(uploadedFile),
              });
              break;
            case "4":
              this.setState({
                currentImagePath4: URL.createObjectURL(uploadedFile),
              });
              break;
            default:
          }

          const formData = new FormData();
          formData.append("EyeTestId", this.props.det.testid);
          formData.append("EyeTestImage", uploadedFile);
          let logourl = API_URL + `/eyetests/image/upload${imageType}`;
          const requestOptions = {
            method: "POST",
            headers: {
              authorization: localStorage.getItem("token"),
            },
            body: formData,
          };

          fetch(logourl, requestOptions).then((json) => {
            swal({
              title: "Done",
              text: "L'image a été téléchargée avec succès. Elle sera visible dans quelques minutes.",
              icon: "success",
            });
            this.setState({ isloading: false });
            this.fetchImages();
          });
        } else {
          // Not an image file
          swal({
            title: "Error",
            text: "Veuillez sélectionner un fichier image valide.",
            icon: "warning",
          });
        }
      } else {
        // 0KB file
        swal({
          title: "Error",
          text: "Le fichier sélectionné est vide (0KB). Veuillez sélectionner un fichier valide.",
          icon: "warning",
        });
      }
    }
  }
  getOphtAIStatus() {
    let opthAIStatusUrl = API_URL + opht_url + this.props.det.testid;
    const opthAIStatusRequestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
    fetch(opthAIStatusUrl, opthAIStatusRequestOptions)
      .then((res) => res.json())
      .then((json) => {
        console.log(json);
        this.setState({
          ophtAIStatus: json,
        });
      });
  }
  openOphtAIReport() {
    var ophtAIStatus = this.state.ophtAIStatus;
    console.log(ophtAIStatus);
    if (
      typeof ophtAIStatus !== "undefined" &&
      ophtAIStatus != null &&
      ophtAIStatus.info_Status != null &&
      ophtAIStatus.info_Status.toUpperCase() == "REPORT GENERATED" &&
      ophtAIStatus.info_ReportDocumentPath != null
    ) {
      var reportUrl = API_URL + "/" + ophtAIStatus.info_ReportDocumentPath;
      console.log(reportUrl, "OphtAI");
      window.open(reportUrl);
    }
  }
  assign() {
    let arr1 = [this.props.det.testid];
    console.log(arr1);
    let moveBack = false;
    swal({
      title: window.$language.Areyousure + "?",
      text: window.$language.Areyousure + "?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        let logourl = API_URL + "/eyetests/assign";
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            authorization: localStorage.getItem("token"),
          },
          body: JSON.stringify({
            doctorEmail: document.getElementById("emails").value,
            tests: arr1,
          }),
        };
        fetch(logourl, requestOptions)
          .then((res) => res.json())
          .then((json) => {
            if (json == true) {
              swal({
                title: "Done!",
                text: window.$language.AssignedSuccessfully,
                icon: "success",
              });
              //this.componentDidMount();
              document.getElementById("close7896").click();
              this.props.refresh();
              this.eyeresult();
              //   for (let i = 0; i < leng; i++) {
              //     document.getElementsByName("deletecheck")[i].checked = false;
              //   }
            } else {
              swal({
                title: "OOPS!",
                text: window.$language.Somethingwentwrong,
                icon: "warning",
              });
            }
            //this.setState({opcarr:json})
          })
          .catch((e) => {
            swal({
              title: "OOPS!",
              text: window.$language.Somethingwentwrong,
              icon: "warning",
            });

            console.log(e);
          });
      }
      console.log("before", moveBack);
    });
    console.log("after", moveBack);
    // moveBack ? this.eyeresult(""):""
  }
  componentDidMount() {
    this.getdocinfo("", "");
    if (!localStorage.getItem("type") == "PATIENT") {
      this.getdocinfo("", "");
    }
    console.log(this.props);
    console.log(this.props.det.testid);
    this.setState({ isExternal: localStorage.getItem("isExternal") });
    this.getOphtAIStatus();
    this.getsymreport();
    if (this.props.det.symptoms != null) {
      let symp = this.props.det.symptoms.split(",");
      this.checkUncheck("Symptoms", symp);
    }
    if (this.props.det.infoResultExamine != null) {
      let diag = this.props.det.infoResultExamine.split(",");
      this.checkUncheck("infoResultExamine", diag);
    }
    if (this.props.det.infoImagesVues == "0") {
      $("#Radioimage0").prop("checked", true);
    } else if (this.props.det.infoImagesVues == "1") {
      $("#Radioimage1").prop("checked", true);
    } else if (this.props.det.infoImagesVues == "2") {
      $("#Radioimage2").prop("checked", true);
    } else if (this.props.det.infoImagesVues == "3") {
      $("#Radioimage3").prop("checked", true);
    } else if (this.props.det.infoImagesVues == "N/A") {
      $("#Radioimage4").prop("checked", true);
    } else {
      $("#Radioimage0").prop("checked", false);
      $("#Radioimage1").prop("checked", false);
      $("#Radioimage2").prop("checked", false);
      $("#Radioimage3").prop("checked", false);
      $("#Radioimage4").prop("checked", false);
    }

    if (this.props.det.dignosedwith != null) {
      let diag = this.props.det.dignosedwith.split(",");
      this.checkUncheck("diagnosed", diag);
    }

    if (this.props.det.healthConditions) {
      let health = this.props.det.healthConditions.split(",");
      this.checkUncheck("healthconditions", health);
    }

    let presf = this.props.det.testDetails.filter(function (t) {
      return t.testType == "Reco opticien";
    });
    this.setState({ presf: presf });

    let arr = [];
    for (let i = 0; i < this.props.det.testDetails.length; i++) {
      // if(this.props.det.testDetails[i].whichEye=="left"){
      //   arr.push(this.props.det.testDetails)
      // }
      let arr1 = [];
      if (i % 2 == 1) {
        arr.push(arr1);
        //arr1.push(this.props.det.testDetails[i])
      }
      if (i % 2 == 0) {
      }
    }
    console.log(this.state.testreport, "reportpath");
  }
  checkUncheck(elementName, allValues) {
    let arrValues = document.getElementsByName(elementName);
    for (let i = 0; i < arrValues.length; i++) {
      arrValues[i].checked = allValues.indexOf(arrValues[i].value) > -1;
    }
  }
  geteyet = () => {
    //console.log("this is props", this.props);
    var token = localStorage.getItem("token");
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({
        status: "",
        PatientEmail: "",
        search: "",
        // "Assign": this.props.a,
      }),
    };
    let logourl = API_URL + "/eyetests/search";
    fetch(logourl, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        console.log(json);
        this.setState({
          eyetest: json,
        });
        //alert("Done");
      });
  };
  submit() {}
  backcom() {
    let logourl = API_URL + "/eyetests/UpdateOpticianPOSComment";
    const requestOptions = {
      method: "POST",
      headers: {
        accept: "text/plain",
        "Content-Type": "application/json",
        authorization: localStorage.getItem("token"),
      },
      body: JSON.stringify({
        eyeTestID: this.props.det.testid,
        opticianComment: document.getElementById("infooptcomm").value,
        posComment: document.getElementById("infoposcomm").value,
      }),
    };
    fetch(logourl, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        console.log(json);
        if (json == true) {
          swal({
            title: "Done!",
            text: "Done",
            icon: "success",
          });
          this.props.refresh();
        } else {
          swal({
            title: "OOPS!",
            text: window.$language.Somethingwentwrong,
            icon: "warning",
          });
        }
        //this.setState({opcarr:json})
      })
      .catch((e) => {
        swal({
          title: "OOPS!",
          text: window.$language.Somethingwentwrong,
          icon: "warning",
        });

        console.log(e);
      });
    // }
    //});
  }
  appr(status) {
    this.setState({ isLoading: true }); // Disable the button

    let logourl = API_URL + "/eyetests/approve";
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("token"),
      },
      body: JSON.stringify({
        testno: this.props.det.testid,
        status: status,
        commentsForPrescription: document.getElementById("comp").value,
        comments: document.getElementById("comb").value,
        TestDetails: [
          {
            TestType: "Prescription finale",
            whichEye: "right",
            sph: document.getElementById("frsh").value,
            cyl: document.getElementById("frcy").value,
            axe: document.getElementById("frax").value,
            add: document.getElementById("frad").value,
            pd: document.getElementById("frpd").value,
            OrderNo: "9",
          },
          {
            TestType: "Prescription finale",
            whichEye: "left",
            sph: document.getElementById("flsh").value,
            cyl: document.getElementById("flcy").value,
            axe: document.getElementById("flax").value,
            add: document.getElementById("flad").value,
            pd: document.getElementById("flpd").value,
            OrderNo: "10",
          },
        ],
      }),
    };

    fetch(logourl, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        console.log(json);
        if (json === true) {
          swal({
            title: "Done!",
            text: "Le test de vue a été approuvé.",
            icon: "success",
          });
          this.componentDidMount();
          this.props.refresh();
          this.eyeresult();
        } else {
          swal({
            title: "OOPS!",
            text: window.$language.Somethingwentwrong,
            icon: "warning",
          });
        }
      })
      .catch((e) => {
        swal({
          title: "OOPS!",
          text: window.$language.Somethingwentwrong,
          icon: "warning",
        });
        console.log(e);
      })
      .finally(() => {
        this.setState({ isLoading: false }); // Re-enable the button after request completion
      });
  }
  dappr(status) {
    if (document.getElementById("comb").value == "") {
      swal({
        title: "OOPS!",
        text: "Merci d'indiquer la raison du refus de ce test dans la case commentaire ci-dessous.",
        icon: "warning",
      });
      return;
    }

    swal({
      title: window.$language.Areyousure + "?",
      text: "Êtes-vous sûr de ne pas vouloir approuver ce test ?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        //alert(this.props.row.testno);
        let logourl = API_URL + "/eyetests/approve";
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            authorization: localStorage.getItem("token"),
          },
          body: JSON.stringify({
            testno: this.props.det.testid,
            status: status,
            commentsForPrescription: document.getElementById("comp").value,
            comments: document.getElementById("comb").value,
            TestDetails: [
              {
                TestType: "Prescription finale",
                whichEye: "right",
                sph: document.getElementById("frsh").value,
                cyl: document.getElementById("frcy").value,
                axe: document.getElementById("frax").value,
                add: document.getElementById("frad").value,
                pd: document.getElementById("frpd").value,
                //"phigh": document.getElementById("frph").value,
                OrderNo: "9",
              },
              {
                TestType: "Prescription finale",
                whichEye: "left",
                sph: document.getElementById("flsh").value,
                cyl: document.getElementById("flcy").value,
                axe: document.getElementById("flax").value,
                add: document.getElementById("flad").value,
                pd: document.getElementById("flpd").value,
                // "phigh": document.getElementById("flph").value,
                OrderNo: "10",
              },
            ],
          }),
        };
        fetch(logourl, requestOptions)
          .then((res) => res.json())
          .then((json) => {
            console.log(json);
            if (json == true) {
              swal({
                title: "Done!",
                text: "Le test de vue a été rejeté.",
                icon: "warning",
              });
              this.componentDidMount();
              this.props.refresh();
              this.eyeresult();
            } else {
              swal({
                title: "OOPS!",
                text: window.$language.Somethingwentwrong,
                icon: "warning",
              });
            }
            //this.setState({opcarr:json})
          })
          .catch((e) => {
            swal({
              title: "OOPS!",
              text: window.$language.Somethingwentwrong,
              icon: "warning",
            });

            console.log(e);
          });
      }
    });
  }
  getdocinfo(email, mob) {
    console.log("Doctor", email);
    let logourl = API_URL + "/doctor/search";
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("token"),
      },
      body: JSON.stringify({
        email: email,
        mobile: mob,
      }),
    };
    fetch(logourl, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        const filteredDoctors = json.filter(
          (doctor) => !doctor.isExternalDoctor
        );
        this.setState({ docarr: filteredDoctors });
        console.log(json);
      })

      .catch((e) => {
        console.log(e);
      });
  }
  ass() {
    swal({
      title: "Are you sure?",
      text: "Are you sure to Assign?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        let logourl = API_URL + "/eyetests/assign";
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            authorization: localStorage.getItem("token"),
          },
          body: JSON.stringify({
            DoctorEmail: document.getElementById("docemail").value,
            Tests: [this.props.row.testno],
          }),
        };
        fetch(logourl, requestOptions)
          .then((res) => res.json())
          .then((json) => {
            if (json == true) {
              swal({
                title: "Done!",
                text: "Assigned Successfully",
                icon: "success",
              });
              this.props.refresh();
            } else {
              swal({
                title: "OOPS!",
                text: "Something went wong",
                icon: "warning",
              });
            }
            //this.setState({opcarr:json})
          })
          .catch((e) => {
            swal({
              title: "OOPS!",
              text: "Something went wong",
              icon: "warning",
            });

            console.log(e);
          });
      }
    });
  }
  pres() {
    console.log("hi");
    ReactDOM.render(
      <Pre row={this.props.det} />,
      document.getElementById("c11")
    );
  }
  presl() {
    console.log("hi");
    ReactDOM.render(
      <Prel row={this.props.det} />,
      document.getElementById("c11")
    );
  }
  eyeresult(det) {
    console.log("hi");
    // ReactDOM.render(<Eyeresult status={det.status} />, document.getElementById('c11'));
    this.props.showEyetest("");
  }
  orderp(det) {
    console.log("order");
    ReactDOM.render(
      <Orderp
        det={this.props.det}
        //patientemail={this.props.det.email}
      />,
      document.getElementById("c11")
    );
  }
  // onMenuOpen() {
  //   setTimeout(() => {
  //     const selectedEl = document.getElementsByClassName(
  //       "eyetestdetails__option--is-selected"
  //     )[0];
  //     if (selectedEl) {
  //       selectedEl.scrollIntoView({
  //         behavior: "smooth",
  //         block: "nearest",
  //         inline: "start",
  //       });
  //     }
  //   }, 5);
  // }
  formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const monthIndex = date.getMonth() + 1;
    const year = date.getFullYear();

    const months = [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ];
    const monthAbbreviation = months[monthIndex - 1];

    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = monthIndex < 10 ? `0${monthIndex}` : monthIndex;

    return `${formattedDay}/${formattedMonth}/${year}`;
  }
  // Function to download PDF
  getsymreport = () => {
    let eyetestid = this.props.det.testid;
    var token = localStorage.getItem("token");

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    };

    let logourl = API_URL + "/eyetests/OpthAIdetails?eyetestid=" + eyetestid;

    fetch(logourl, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        console.log(json);

        this.setState(
          {
            testreport: json,
          },
          () => {
            console.log(this.state.testreport.opthAIReportPath, "symreport1");
          }
        );
      })
      .catch((error) => {
        console.error("Error fetching the report:", error);
      });
  };

  // Fix: Using an arrow function to avoid 'this' context issues
  downloadPDF = () => {
    var symAIStatus = this.state.testreport?.opthAIReportPath;

    if (!symAIStatus) {
      console.error("No report path available.");
      return;
    }

    var SymreportUrl = `${API_URL}/${symAIStatus}`;
    console.log(SymreportUrl, "Symreport2");

    if (SymreportUrl) {
      window.open(SymreportUrl, "_blank");
    } else {
      console.error("Invalid report URL.");
    }
  };

  render() {
    const { images, loading, isloading } = this.state;
    return (
      <div class="app-content content">
        <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
        <div class="content-wrapper">
          <div class="content-header row"></div>
          <div class="content-body px-3">
            <section class="invoice-preview-wrapper">
              <div class="row invoice-preview">
                <div class="col-xl-9 col-md-8 col-12">
                  <div class="card invoice-preview-card p-2">
                    <div class="card-body invoice-padding pb-0">
                      <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">
                        <div>
                          {this.state.utype == "PATIENT" &&
                          this.props.det.needPrescription == false ? (
                            <p class="card-text mb-0">
                              Il n'y a pas eu de demande de prescription pour ce
                              test de vue. Pour plus de détails, nous vous
                              prions de contacter votre opticien*ne.
                            </p>
                          ) : null}
                        </div>
                        <div class="mt-md-0 mt-2">
                          <div class="invoice-date-wrapper">
                            <p class="invoice-date-title">
                              Date :-
                              {this.formatDate(this.props.det.createdDate)}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr class="invoice-spacing" />
                    <div class="form-row mb-1">
                      {this.state.utype == "ADMIN" ? (
                        <>
                          {this.props.det.assignedToDoc == true ? (
                            <>
                              <div class="col-lg-3">
                                <label>Attribué par</label>
                                <input
                                  class="form-control"
                                  value={this.props.det.infoTestAssignedBy}
                                  readOnly
                                />
                              </div>
                              <div class="col-lg-3">
                                <label>Date de l’attribution</label>
                                <input
                                  class="form-control"
                                  value={(() => {
                                    if (
                                      this.props.det.infoTestAssignedDate !=
                                      null
                                    ) {
                                      const dateString =
                                        this.props.det.infoTestAssignedDate.split(
                                          "T"
                                        )[0];
                                      const [year, month, day] =
                                        dateString.split("-");
                                      return `${day}/${month}/${year}`;
                                    }
                                  })()}
                                  readOnly
                                />
                              </div>
                            </>
                          ) : null}
                        </>
                      ) : null}
                      <div class="col-lg-3">
                        <label>CODE :</label>
                        <input
                          class="form-control"
                          value={this.props.det.testUniqueCode}
                          readOnly
                        />
                      </div>
                      {this.state.utype !== "EYEDOCTOR" ? (
                        <>
                          <div class="col-lg-3">
                            <label>{window.$language.Optician} :</label>
                            <input
                              class="form-control"
                              value={this.props.det.opticianName}
                              readOnly
                            />
                          </div>
                          <div class="col-lg-3">
                            <label>{window.$language.POS} :</label>
                            <input
                              class="form-control"
                              value={this.props.det.posName}
                              readOnly
                            />
                          </div>
                        </>
                      ) : null}
                      <div class="col-lg-3">
                        <div className="form-group align-baseline flex-row">
                          <label className="form-label" for="login-email">
                            {window.$language.PatientEmail} :
                          </label>

                          <input
                            class="form-control"
                            value={this.props.det.patientEmail}
                            readOnly
                          />
                        </div>
                      </div>
                      <div class="col-lg-3">
                        <label> {window.$language.FirstName} :</label>
                        <input
                          class="form-control"
                          value={this.props.det.patientFirstName}
                          readOnly
                        />
                      </div>
                      <div class="col-lg-3">
                        <label>{window.$language.LastName} :</label>
                        <input
                          class="form-control"
                          value={this.props.det.patientLastName}
                          readOnly
                        />
                      </div>
                      <div class="col-lg-3">
                        <label>{window.$language.DateofBirth} :</label>
                        <input
                          type="text"
                          class="form-control birthdate-picker"
                          value={this.formatDate(this.props.det.patientDOB)}
                          readOnly
                        />
                      </div>
                      <div class="col-lg-3">
                        <label>Age :</label>
                        <input
                          type="text"
                          class="form-control birthdate-picker"
                          value={this.props.det.age}
                          readOnly
                        />
                      </div>
                      {this.props.det.lastTimeWentToEyeDoc == "" ||
                      this.props.det.lastTimeWentToEyeDoc == null ? (
                        <div class="col-lg-4">
                          <div className="form-group align-baseline flex-row">
                            <label className="form-label" for="login-email">
                              {
                                window.$language
                                  .whenIsTheLastTimeYouWentToTheEyeDoctor
                              }{" "}
                              :
                            </label>
                            <input
                              type="text"
                              class="form-control birthdate-picker"
                              defaultValue="Jamais"
                              readOnly
                            />
                          </div>
                        </div>
                      ) : null}
                      {this.props.det.lastTimeWentToEyeDoc !== "" &&
                      this.props.det.lastTimeWentToEyeDoc !== null ? (
                        <div class="col-lg-4">
                          <div className="form-group align-baseline flex-row">
                            <label className="form-label" for="login-email">
                              {
                                window.$language
                                  .whenIsTheLastTimeYouWentToTheEyeDoctor
                              }{" "}
                              :
                            </label>
                            <input
                              type="text"
                              class="form-control birthdate-picker"
                              defaultValue={this.formatDate(
                                this.props.det.lastTimeWentToEyeDoc
                              )}
                              readOnly
                            />
                          </div>
                        </div>
                      ) : null}
                      <div class="col-lg-4">
                        <div className="form-group align-baseline flex-row">
                          <label className="form-label" for="login-email">
                            {window.$language.Doyouwearglasses} :
                          </label>
                          <input
                            class="form-control"
                            value={
                              this.props.det.infoDoYouWearGlasses == true
                                ? window.$language.No
                                : window.$language.Yes
                            }
                            readOnly
                          />
                        </div>
                      </div>

                      <div class="col-lg-4">
                        <div className="form-group align-baseline flex-row">
                          <label className="form-label" for="login-email">
                            {window.$language.TypeofVision} :
                          </label>
                          <input
                            class="form-control"
                            value={this.props.det.infoTypeOfVision}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                    <div class="form-row mb-1">
                      <div class="col-lg-4">
                        <div class="form-group">
                          <label class="d-block pr-1 ml-1">
                            {
                              window.$language
                                .Areyoucurrentlyexperiencinganyofthefollowing
                            }{" "}
                            :
                          </label>
                          <div class=" custom-control custom-checkbox ml-1">
                            <input
                              type="checkbox"
                              disabled
                              id="frequentflashes"
                              name="Symptoms"
                              value="FrequentFlashes"
                              class="custom-control-input"
                              required
                            />
                            <label
                              class="custom-control-label"
                              for="frequentflashes"
                            >
                              {window.$language.FrequentFlashes}
                            </label>
                          </div>
                          <div class="custom-control custom-checkbox ml-1">
                            <input
                              type="checkbox"
                              disabled
                              id="validationRadio4"
                              name="Symptoms"
                              value="FrequentFloaters"
                              class="custom-control-input"
                              required
                            />
                            <label
                              class="custom-control-label"
                              for="validationRadio4"
                            >
                              {window.$language.FrequentFloaters}
                            </label>
                          </div>
                          <div class="custom-control custom-checkbox ml-1">
                            <input
                              type="checkbox"
                              disabled
                              id="validationRadio5"
                              name="Symptoms"
                              value="Eye redness / pain"
                              class="custom-control-input"
                              required
                            />
                            <label
                              class="custom-control-label"
                              for="validationRadio5"
                            >
                              {window.$language.EyerednessPain}
                            </label>
                          </div>
                          <div class="custom-control custom-checkbox ml-1">
                            <input
                              type="checkbox"
                              disabled
                              id="validationRadio6"
                              name="Symptoms"
                              value="Severe Headhaches"
                              class="custom-control-input"
                              required
                            />
                            <label
                              class="custom-control-label"
                              for="validationRadio6"
                            >
                              {window.$language.SevereHeadhaches}
                            </label>
                          </div>
                          <div class="custom-control custom-checkbox ml-1">
                            <input
                              type="checkbox"
                              disabled
                              id="validationRadio7"
                              name="Symptoms"
                              value="Double Vision"
                              class="custom-control-input"
                              required
                            />
                            <label
                              class="custom-control-label"
                              for="validationRadio7"
                            >
                              {window.$language.DoubleVision}{" "}
                            </label>
                          </div>
                          <div class="custom-control custom-checkbox ml-1">
                            <input
                              type="checkbox"
                              disabled
                              id="validationRadio8"
                              name="Symptoms"
                              value="Loss of Vision"
                              class="custom-control-input"
                              required
                            />
                            <label
                              class="custom-control-label"
                              for="validationRadio8"
                            >
                              {window.$language.LossofVision}
                            </label>
                          </div>
                          <div class="custom-control custom-checkbox ml-1">
                            <input
                              type="checkbox"
                              disabled
                              id="validationRadio9"
                              name="Symptoms"
                              value="Dizziness"
                              class="custom-control-input"
                              required
                            />
                            <label
                              class="custom-control-label"
                              for="validationRadio9"
                            >
                              {window.$language.Dizziness}
                            </label>
                          </div>
                          <div class="custom-control custom-checkbox ml-1">
                            <input
                              type="checkbox"
                              disabled
                              id="validationRadio909"
                              name="Symptoms"
                              value="Photosensible"
                              class="custom-control-input"
                              required
                            />
                            <label
                              class="custom-control-label"
                              for="validationRadio909"
                            >
                              Photosensible
                            </label>
                          </div>
                          <div class="custom-control custom-checkbox ml-1">
                            <input
                              type="checkbox"
                              disabled
                              id="validationRadio10"
                              name="Symptoms"
                              value="None of the Above"
                              class="custom-control-input"
                              required
                            />
                            <label
                              class="custom-control-label"
                              for="validationRadio10"
                            >
                              {window.$language.NoneoftheAbove}
                            </label>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="form-group">
                          <label class="d-block ml-1">
                            {
                              window.$language
                                .HaveYouEverBeenDiagnosedWithAnyOfTheFollowingEyeConditions
                            }{" "}
                            :
                          </label>
                          <div class="custom-control custom-checkbox ml-1">
                            <input
                              type="checkbox"
                              disabled
                              id="validationRadio13"
                              name="diagnosed"
                              value="Amblyopya or Lazy eye"
                              class="custom-control-input"
                              required
                            />
                            <label
                              class="custom-control-label"
                              for="validationRadio13"
                            >
                              {window.$language.AmblyopyaOrLazyEye}
                            </label>
                          </div>
                          <div class="custom-control custom-checkbox ml-1">
                            <input
                              type="checkbox"
                              disabled
                              id="validationRadio14"
                              name="diagnosed"
                              value="Strabismus"
                              class="custom-control-input"
                              required
                            />
                            <label
                              class="custom-control-label"
                              for="validationRadio14"
                            >
                              {window.$language.Strabismus}
                            </label>
                          </div>
                          <div class="custom-control custom-checkbox ml-1">
                            <input
                              type="checkbox"
                              disabled
                              id="validationRadio15"
                              name="diagnosed"
                              value="Glaucoma"
                              class="custom-control-input"
                              required
                            />
                            <label
                              class="custom-control-label"
                              for="validationRadio15"
                            >
                              {window.$language.Glaucoma}
                            </label>
                          </div>
                          <div class="custom-control custom-checkbox ml-1">
                            <input
                              type="checkbox"
                              disabled
                              id="validationRadio16"
                              name="diagnosed"
                              value="Cataract"
                              class="custom-control-input"
                              required
                            />
                            <label
                              class="custom-control-label"
                              for="validationRadio16"
                            >
                              Cataracte
                            </label>
                          </div>
                          <div class="custom-control custom-checkbox ml-1">
                            <input
                              type="checkbox"
                              disabled
                              id="validationRadio17"
                              name="diagnosed"
                              value="Macular or Retinal Degeneration"
                              class="custom-control-input"
                              required
                            />
                            <label
                              class="custom-control-label"
                              for="validationRadio17"
                            >
                              {window.$language.MacularOrRetinalDegeneration}
                            </label>
                          </div>
                          <div class="custom-control custom-checkbox ml-1">
                            <input
                              type="checkbox"
                              disabled
                              id="validationRadio18"
                              name="diagnosed"
                              value="Retinal Detachment"
                              class="custom-control-input"
                              required
                            />
                            <label
                              class="custom-control-label"
                              for="validationRadio18"
                            >
                              {window.$language.RetinalDetachement}
                            </label>
                          </div>
                          <div class="custom-control custom-checkbox ml-1">
                            <input
                              type="checkbox"
                              disabled
                              id="validationRadio19"
                              name="diagnosed"
                              value="Corneal Dystrophy"
                              class="custom-control-input"
                              required
                            />
                            <label
                              class="custom-control-label"
                              for="validationRadio19"
                            >
                              {window.$language.CornealDystrophy}
                            </label>
                          </div>
                          <div class="custom-control custom-checkbox ml-1">
                            <input
                              type="checkbox"
                              disabled
                              id="validationRadio20"
                              name="diagnosed"
                              value="None of the above "
                              class="custom-control-input"
                              required
                            />
                            <label
                              class="custom-control-label"
                              for="validationRadio20"
                            >
                              {window.$language.NoneoftheAbove}
                            </label>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="form-group">
                          <label class="d-block ml-1">
                            {
                              window.$language
                                .DoYouSufferFromAnyOfTheFollowingConditions
                            }{" "}
                            :
                          </label>
                          <div class="custom-control custom-checkbox ml-1 ">
                            <input
                              type="checkbox"
                              disabled
                              id="validationRadio23"
                              name="healthconditions"
                              value="Diabetes"
                              class="custom-control-input"
                              required
                            />
                            <label
                              class="custom-control-label"
                              for="validationRadio23"
                            >
                              {window.$language.Diabetes}
                            </label>
                          </div>
                          <div class="custom-control custom-checkbox ml-1">
                            <input
                              type="checkbox"
                              disabled
                              id="validationRadio24"
                              name="healthconditions"
                              value="Hypertension"
                              class="custom-control-input"
                              required
                            />
                            <label
                              class="custom-control-label"
                              for="validationRadio24"
                            >
                              {window.$language.Hypertension}
                            </label>
                          </div>
                          <div class="custom-control custom-checkbox ml-1">
                            <input
                              type="checkbox"
                              disabled
                              id="valid5"
                              name="healthconditions"
                              value="Multiple Sclerosis"
                              class="custom-control-input"
                              required
                            />
                            <label class="custom-control-label" for="valid5">
                              {window.$language.MultipleSclerosis}
                            </label>
                          </div>
                          <div class="custom-control custom-checkbox ml-1">
                            <input
                              type="checkbox"
                              disabled
                              id="valid10"
                              name="healthconditions"
                              value="None of the above"
                              class="custom-control-input"
                              required
                            />
                            <label class="custom-control-label" for="valid10">
                              {window.$language.NoneoftheAbove}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr class="invoice-spacing" />
                    <>
                      {" "}
                      {(this.state.utype == "PATIENT" &&
                        this.props.det.needPrescription == true) ||
                      this.props.det.needPrescription == "Yes" ||
                      this.state.utype !== "PATIENT" ? (
                        <div class="table-responsive mt-2">
                          <table class="table table-bordered">
                            <thead>
                              {this.props.det.isContactLensType == false ? (
                                <tr>
                                  <th class="border border-primary text-center">
                                    {window.$language.TYPEOFTEST}
                                  </th>
                                  <th
                                    class="border border-primary align-middle text-center"
                                    colSpan="5"
                                  >
                                    {window.$language.RIGHTEYE}
                                  </th>
                                  <th
                                    class="border border-primary align-middle text-center"
                                    colSpan="5"
                                  >
                                    {window.$language.LEFTEYE}
                                  </th>
                                </tr>
                              ) : null}
                              {this.props.det.isContactLensType == true ? (
                                <tr>
                                  <th class="border border-primary text-center">
                                    {window.$language.TYPEOFTEST}
                                  </th>
                                  <th
                                    class="border border-primary align-middle text-center"
                                    colSpan="4"
                                  >
                                    {window.$language.RIGHTEYE}
                                  </th>
                                  <th
                                    class="border border-primary align-middle text-center"
                                    colSpan="4"
                                  >
                                    {window.$language.LEFTEYE}
                                  </th>
                                </tr>
                              ) : null}
                              <tr>
                                <th class="px-1 "></th>
                                <th class="px-0 text-center ">SPH</th>
                                <th class="px-0 text-center ">CYL</th>
                                <th class="px-0 text-center ">AXE</th>
                                <th class="px-0 text-center ">ADD</th>
                                {this.props.det.isContactLensType == false ? (
                                  <th class="px-0 text-center ">PD</th>
                                ) : null}
                                {/* {(this.state.utype !== "PATIENT") ?
                                                                <th class="px-0 text-center ">PHIGH</th>
                                                                : null} */}
                                <th class="px-0 text-center ">SPH</th>
                                <th class="px-0 text-center ">CYL</th>
                                <th class="px-0 text-center ">AXE</th>
                                <th class="px-0 text-center ">ADD</th>
                                {this.props.det.isContactLensType == false ? (
                                  <th class="px-0 text-center ">PD</th>
                                ) : null}
                                {/* {(this.state.utype !== "PATIENT") ?
                                                                <th class="px-0 text-center">PHIGH</th>
                                                                : null} */}
                              </tr>
                            </thead>
                            <tbody>
                              {this.props.det.testDetails.map((det, index) => (
                                <>
                                  {(this.state.utype == "PATIENT" &&
                                    det.testType == "Prescription finale") ||
                                  this.state.utype !== "PATIENT" ? (
                                    <>
                                      {index % 2 == 0 ? (
                                        <>
                                          {ReactHtmlParser("<tr>")}

                                          <td class=" py-1 px-1">
                                            {det.testType == "Test au fronto"
                                              ? "Correction portée"
                                              : det.testType == "Auto ref"
                                              ? "Réfraction objective"
                                              : det.testType == "Reco opticien"
                                              ? "Recommandation opticien finale"
                                              : det.testType ==
                                                  "Réfraction subjective" ||
                                                det.testType ==
                                                  "Réfraction subjective (manuelle)"
                                              ? "Réfraction subjective (manuelle)"
                                              : "Prescription finale"}
                                          </td>

                                          <td class="py-0 px-1">
                                            <span class="font-weight-bold text-center">
                                              {det.sph}
                                            </span>
                                          </td>
                                          <td class="py-0 px-1">
                                            <span class="font-weight-bold text-center">
                                              {det.cyl}
                                            </span>
                                          </td>
                                          <td class="py-0 px-1">
                                            <span class="font-weight-bold text-center">
                                              {det.axe}
                                            </span>
                                          </td>
                                          <td class="py-0 px-1">
                                            <span class="font-weight-bold text-center">
                                              {det.add}
                                            </span>
                                          </td>
                                          {this.props.det.isContactLensType ==
                                          false ? (
                                            <td class="py-0 px-1 ">
                                              <span class="font-weight-bold text-center">
                                                {det.pd}
                                              </span>
                                            </td>
                                          ) : null}
                                          {/* {(this.state.utype !== "PATIENT") ?
                                                                                    <td class="py-0 px-1">
                                                                                        <span class="font-weight-bold text-center">{det.phigh}</span>
                                                                                    </td>

                                                                                    : null} */}
                                        </>
                                      ) : null}

                                      {index % 2 == 1 ? (
                                        <>
                                          <td class="py-0 px-1">
                                            <span class="font-weight-bold text-center">
                                              {det.sph}
                                            </span>
                                          </td>
                                          <td class="py-0 px-1">
                                            <span class="font-weight-bold text-center">
                                              {det.cyl}
                                            </span>
                                          </td>
                                          <td class="py-0 px-1">
                                            <span class="font-weight-bold text-center">
                                              {det.axe}
                                            </span>
                                          </td>
                                          <td class="py-0 px-1">
                                            <span class="font-weight-bold text-center">
                                              {det.add}
                                            </span>
                                          </td>
                                          {this.props.det.isContactLensType ==
                                          false ? (
                                            <td class="py-0 px-1 ">
                                              <span class="font-weight-bold text-center">
                                                {det.pd}
                                              </span>
                                            </td>
                                          ) : null}
                                          {/* {(this.state.utype !== "PATIENT") ?
                                                                                    <td class="py-0 px-1">
                                                                                        <span class="font-weight-bold text-center">{det.phigh}</span>
                                                                                    </td>
                                                                                    : null} */}

                                          {ReactHtmlParser("</tr>")}
                                        </>
                                      ) : null}
                                    </>
                                  ) : null}
                                </>
                              ))}

                              {this.state.utype == "EYEDOCTOR" &&
                              this.props.det.status == "New" &&
                              this.props.det.assignedToDoc ? (
                                // <tr>
                                //   <td class="py-1 px-1">Prescription finale</td>
                                //   <td class="py-1 px-1 ">
                                //     <Select
                                //       defaultValue={this.state.presf[0].sph}
                                //       id="frsh"
                                //       //onMenuOpen={onMenuOpen}
                                //       className={"eyetestdetails"}
                                //       classNamePrefix={"eyetestdetails"}
                                //       options={options}
                                //       onChange={(selectedOption) => this.setState({ selectedOption })}
                                //       placeholder="0.00"
                                //     />
                                //   </td>
                                //   <td class="py-1 px-1">
                                //      <Select
                                //       defaultValue={this.state.presf[0].cyl}
                                //       id="frcy"
                                //       //onMenuOpen={onMenuOpen}
                                //       className={"eyetestdetails"}
                                //       classNamePrefix={"eyetestdetails"}
                                //       options={options2}
                                //       onChange={(selectedOption) => this.setState({ selectedOption })}
                                //       placeholder="0.00"
                                //     />{" "}
                                //   </td>
                                //   <td class="py-1 px-1 ">
                                //      <Select
                                //      defaultValue={this.state.presf[0].axe}
                                //       id="frax"
                                //       //onMenuOpen={onMenuOpen}
                                //       className={"eyetestdetails"}
                                //       classNamePrefix={"eyetestdetails"}
                                //       options={options3}
                                //       onChange={(selectedOption) => this.setState({ selectedOption })}
                                //       placeholder="0.00"
                                //     />{" "}
                                //   </td>
                                //   <td class="py-1 px-1 ">
                                //      <Select
                                //      defaultValue={this.state.presf[0].add}
                                //      id="frad"
                                //       //onMenuOpen={onMenuOpen}
                                //       className={"eyetestdetails"}
                                //       classNamePrefix={"eyetestdetails"}
                                //       options={options4}
                                //       onChange={(selectedOption) => this.setState({ selectedOption })}
                                //       placeholder="0.00"
                                //     />{" "}
                                //   </td>
                                //   {this.props.det.isContactLensType == true ? (
                                //     <td class="py-1 px-1 d-none ">
                                //       <Select
                                //      defaultValue="0"
                                //      id="frpd"
                                //       //onMenuOpen={onMenuOpen}
                                //       className={"eyetestdetails"}
                                //       classNamePrefix={"eyetestdetails"}
                                //       options={options5}
                                //       onChange={(selectedOption) => this.setState({ selectedOption })}
                                //       placeholder="0.00"
                                //     />{" "}
                                //     </td>
                                //   ) : null}
                                //   {this.props.det.isContactLensType == false ? (
                                //     <td class="py-1 px-1 ">
                                //       <Select
                                //      defaultValue={this.state.presf[0].pd}
                                //      id="frpd"
                                //       //onMenuOpen={onMenuOpen}
                                //       className={"eyetestdetails"}
                                //       classNamePrefix={"eyetestdetails"}
                                //       options={options5}
                                //       onChange={(selectedOption) => this.setState({ selectedOption })}
                                //       placeholder="0.00"
                                //     />{" "}
                                //     </td>
                                //   ) : null}
                                //   {/* <td class="py-1 px-1 "><select id='frph' class="form-control px-0  " defaultValue={this.state.presf[0].phigh} /></td> */}

                                //   <td class="py-1 px-1 ">
                                //     <Select
                                //      defaultValue={this.state.presf[1].sph}
                                //      id="flsh"
                                //       //onMenuOpen={onMenuOpen}
                                //       className={"eyetestdetails"}
                                //       classNamePrefix={"eyetestdetails"}
                                //       options={options}
                                //       onChange={(selectedOption) => this.setState({ selectedOption })}
                                //       placeholder="0.00"
                                //     />{" "}
                                //   </td>
                                //   <td class="py-1 px-1 ">
                                //     <Select
                                //       defaultValue={this.state.presf[1].cyl}
                                //      id="flcy"
                                //       //onMenuOpen={onMenuOpen}
                                //       className={"eyetestdetails"}
                                //       classNamePrefix={"eyetestdetails"}
                                //       options={options2}
                                //       onChange={(selectedOption) => this.setState({ selectedOption })}
                                //       placeholder="0.00"
                                //     />{" "}
                                //   </td>
                                //   <td class="py-1 px-1 ">
                                //      <Select
                                //       defaultValue={this.state.presf[1].axe}
                                //       id="flax"
                                //       //onMenuOpen={onMenuOpen}
                                //       className={"eyetestdetails"}
                                //       classNamePrefix={"eyetestdetails"}
                                //       options={options3}
                                //       onChange={(selectedOption) => this.setState({ selectedOption })}
                                //       placeholder="0.00"
                                //     />{" "}
                                //   </td>
                                //   <td class="py-1 px-1 ">
                                //      <Select
                                //       defaultValue={this.state.presf[1].add}
                                //       id="flad"
                                //       //onMenuOpen={onMenuOpen}
                                //       className={"eyetestdetails"}
                                //       classNamePrefix={"eyetestdetails"}
                                //       options={options4}
                                //       onChange={(selectedOption) => this.setState({ selectedOption })}
                                //       placeholder="0.00"
                                //     />{" "}
                                //   </td>
                                //   {this.props.det.isContactLensType == true ? (
                                //     <td class="py-1 px-1 d-none ">
                                //       <input
                                //         id="flpd"
                                //         class="form-control px-0 "
                                //         defaultValue="0"
                                //       />
                                //     </td>
                                //   ) : null}
                                //   {this.props.det.isContactLensType == false ? (
                                //     <td class="py-1 px-1 ">
                                //      <Select
                                //       defaultValue={this.state.presf[1].pd}
                                //       id="flpd"
                                //       //onMenuOpen={onMenuOpen}
                                //       className={"eyetestdetails"}
                                //       classNamePrefix={"eyetestdetails"}
                                //       options={options5}
                                //       onChange={(selectedOption) => this.setState({ selectedOption })}
                                //       placeholder="0.00"
                                //     />{" "}
                                //     </td>

                                //   ) : null}
                                //   {/* <td class="py-1 px-1"><input id='flph' class="form-control px-0 text-center" defaultValue={this.state.presf[1].phigh} /></td> */}
                                // </tr>
                                <tr>
                                  <td class="py-1 px-1">Prescription finale</td>
                                  <td class="py-1 px-1 ">
                                    <input
                                      id="frsh"
                                      class="form-control  px-0  "
                                      defaultValue={this.state.presf[0].sph}
                                    />
                                  </td>
                                  <td class="py-1 px-1">
                                    <input
                                      id="frcy"
                                      class="form-control px-0  "
                                      defaultValue={this.state.presf[0].cyl}
                                    />{" "}
                                  </td>
                                  <td class="py-1 px-1 ">
                                    <input
                                      id="frax"
                                      class="form-control px-0  "
                                      defaultValue={this.state.presf[0].axe}
                                    />{" "}
                                  </td>
                                  <td class="py-1 px-1 ">
                                    <input
                                      id="frad"
                                      class="form-control px-0  "
                                      defaultValue={this.state.presf[0].add}
                                    />{" "}
                                  </td>
                                  {this.props.det.isContactLensType == true ? (
                                    <td class="py-1 px-1 d-none ">
                                      <input
                                        id="frpd"
                                        class="form-control px-0  "
                                        defaultValue="0"
                                      />
                                    </td>
                                  ) : null}
                                  {this.props.det.isContactLensType == false ? (
                                    <td class="py-1 px-1 ">
                                      <input
                                        id="frpd"
                                        class="form-control px-0  "
                                        defaultValue={this.state.presf[0].pd}
                                      />{" "}
                                    </td>
                                  ) : null}
                                  {/* <td class="py-1 px-1 "><select id='frph' class="form-control px-0  " defaultValue={this.state.presf[0].phigh} /></td> */}

                                  <td class="py-1 px-1 ">
                                    <input
                                      id="flsh"
                                      class="form-control px-0  "
                                      defaultValue={this.state.presf[1].sph}
                                    />
                                  </td>
                                  <td class="py-1 px-1 ">
                                    <input
                                      id="flcy"
                                      class="form-control px-0 "
                                      defaultValue={this.state.presf[1].cyl}
                                    />{" "}
                                  </td>
                                  <td class="py-1 px-1 ">
                                    <input
                                      id="flax"
                                      class="form-control px-0 "
                                      defaultValue={this.state.presf[1].axe}
                                    />{" "}
                                  </td>
                                  <td class="py-1 px-1 ">
                                    <input
                                      id="flad"
                                      class="form-control px-0 "
                                      defaultValue={this.state.presf[1].add}
                                    />{" "}
                                  </td>
                                  {this.props.det.isContactLensType == true ? (
                                    <td class="py-1 px-1 d-none ">
                                      <input
                                        id="flpd"
                                        class="form-control px-0 "
                                        defaultValue="0"
                                      />
                                    </td>
                                  ) : null}
                                  {this.props.det.isContactLensType == false ? (
                                    <td class="py-1 px-1 ">
                                      <input
                                        id="flpd"
                                        class="form-control px-0 "
                                        defaultValue={this.state.presf[1].pd}
                                      />
                                    </td>
                                  ) : null}
                                  {/* <td class="py-1 px-1"><input id='flph' class="form-control px-0 text-center" defaultValue={this.state.presf[1].phigh} /></td> */}
                                </tr>
                              ) : null}
                              {this.state.utype !== "PATIENT" ? (
                                <>
                                  {this.props.det.isContactLensType == true ? (
                                    <>
                                      <tr>
                                        <td class="p-1">
                                          Acuité vision de près
                                        </td>
                                        <td class="p-1" colSpan="4">
                                          <input
                                            value={
                                              this.props.det
                                                .info_Acuity_Near_Vision_Right_Eye
                                            }
                                            readOnly
                                            class="form-control form-control-sm p-1"
                                          />
                                        </td>

                                        <td class="p-1" colSpan="4">
                                          <input
                                            value={
                                              this.props.det
                                                .info_Acuity_Near_Vision_Left_Eye
                                            }
                                            readOnly
                                            class="form-control form-control-sm p-1"
                                          />
                                        </td>
                                      </tr>
                                      <tr>
                                        <td class="p-1">
                                          Acuité vision de loin
                                        </td>
                                        <td class="p-1" colSpan="4">
                                          <input
                                            value={
                                              this.props.det
                                                .info_Acuity_Distance_Vision_Right_Eye
                                            }
                                            readOnly
                                            class="form-control form-control-sm p-1"
                                          />
                                        </td>

                                        <td class="p-1" colSpan="4">
                                          <input
                                            value={
                                              this.props.det
                                                .info_Acuity_Distance_Vision_Left_Eye
                                            }
                                            readOnly
                                            class="form-control form-control-sm p-1"
                                          />
                                        </td>
                                      </tr>
                                    </>
                                  ) : null}
                                  {this.props.det.isContactLensType == false ? (
                                    <>
                                      <tr>
                                        <td class="p-1">
                                          Acuité vision de près
                                        </td>
                                        <td class="p-1" colSpan="5">
                                          <input
                                            value={
                                              this.props.det
                                                .info_Acuity_Near_Vision_Right_Eye
                                            }
                                            readOnly
                                            class="form-control form-control-sm p-1"
                                          />
                                        </td>

                                        <td class="p-1" colSpan="5">
                                          <input
                                            value={
                                              this.props.det
                                                .info_Acuity_Near_Vision_Left_Eye
                                            }
                                            readOnly
                                            class="form-control form-control-sm p-1"
                                          />
                                        </td>
                                      </tr>
                                      <tr>
                                        <td class="p-1">
                                          Acuité vision de loin
                                        </td>
                                        <td class="p-1" colSpan="5">
                                          <input
                                            value={
                                              this.props.det
                                                .info_Acuity_Distance_Vision_Right_Eye
                                            }
                                            readOnly
                                            class="form-control form-control-sm p-1"
                                          />
                                        </td>

                                        <td class="p-1" colSpan="5">
                                          <input
                                            value={
                                              this.props.det
                                                .info_Acuity_Distance_Vision_Left_Eye
                                            }
                                            readOnly
                                            class="form-control form-control-sm p-1"
                                          />
                                        </td>
                                      </tr>
                                    </>
                                  ) : null}{" "}
                                  {this.props.det.isContactLensType == true ? (
                                    <>
                                      <tr>
                                        <td class="p-1">
                                          Acuité visuelle sans correction de
                                          loin
                                        </td>
                                        <td class="p-1" colSpan="4">
                                          <input
                                            value={
                                              this.props.det
                                                .info_Acuity_visual_sans_correction_Right_Eye
                                            }
                                            readOnly
                                            class="form-control form-control-sm p-1"
                                          />
                                        </td>

                                        <td class="p-1" colSpan="4">
                                          <input
                                            value={
                                              this.props.det
                                                .info_Acuity_visual_sans_correction_Left_Eye
                                            }
                                            readOnly
                                            class="form-control form-control-sm p-1"
                                          />
                                        </td>
                                      </tr>
                                      <tr>
                                        <td class="p-1">
                                          Pression intra oculaire (PIO)
                                        </td>
                                        <td class="p-1" colSpan="4">
                                          <input
                                            value={
                                              this.props.det
                                                .info_Eye_Pressure_Right_Eye
                                            }
                                            readOnly
                                            class="form-control form-control-sm p-1"
                                          />
                                        </td>

                                        <td class="p-1" colSpan="4">
                                          <input
                                            value={
                                              this.props.det
                                                .info_Eye_Pressure_Left_Eye
                                            }
                                            readOnly
                                            class="form-control form-control-sm p-1"
                                          />
                                        </td>
                                      </tr>
                                    </>
                                  ) : null}
                                  {this.props.det.isContactLensType == false ? (
                                    <>
                                      <tr>
                                        <td class="p-1">
                                          Acuité visuelle sans correction de
                                          loin
                                        </td>
                                        <td class="p-1" colSpan="5">
                                          <input
                                            value={
                                              this.props.det
                                                .info_Acuity_visual_sans_correction_Right_Eye
                                            }
                                            readOnly
                                            class="form-control form-control-sm p-1"
                                          />
                                        </td>

                                        <td class="p-1" colSpan="5">
                                          <input
                                            value={
                                              this.props.det
                                                .info_Acuity_visual_sans_correction_Left_Eye
                                            }
                                            readOnly
                                            class="form-control form-control-sm p-1"
                                          />
                                        </td>
                                      </tr>
                                      <tr>
                                        <td class="p-1">
                                          Pression intra oculaire (PIO)
                                        </td>
                                        <td class="p-1" colSpan="5">
                                          <input
                                            value={
                                              this.props.det
                                                .info_Eye_Pressure_Right_Eye
                                            }
                                            readOnly
                                            class="form-control form-control-sm p-1"
                                          />
                                        </td>

                                        <td class="p-1" colSpan="5">
                                          <input
                                            value={
                                              this.props.det
                                                .info_Eye_Pressure_Left_Eye
                                            }
                                            readOnly
                                            class="form-control form-control-sm p-1"
                                          />
                                        </td>
                                      </tr>
                                    </>
                                  ) : null}{" "}
                                </>
                              ) : null}
                            </tbody>
                          </table>
                        </div>
                      ) : null}
                    </>
                    {/* <div class="col-lg-4  ">
                      <label>Test de Lang / # images vues :</label>
                      <input
                        type="text"
                        value={this.props.det.infoImagesVues}
                        class="form-control flatpickr-basic"
                        placeholder=""
                        readOnly
                      />
                    </div> */}
                    {this.state.utype !== "PATIENT" ? (
                      <div class="form-group d-flex flex-wrap align-items-center mt-2">
                        {/* <label class="d-block pr-1 ml-1"></label> */}
                        <h7 class="d-block pr-1">
                          Test de Lang / # images vues :
                        </h7>
                        <div class="custom-control custom-radio ml-1 mr-3">
                          <input
                            type="radio"
                            id="Radioimage0"
                            name="imagesVues"
                            value="0"
                            class="custom-control-input"
                            disabled
                          />
                          <label class="custom-control-label" for="Radioimage0">
                            0
                          </label>
                        </div>
                        <div class="custom-control custom-radio ml-1 mr-3">
                          <input
                            type="radio"
                            id="Radioimage1"
                            name="imagesVues"
                            value="1"
                            class="custom-control-input"
                            disabled
                          />
                          <label class="custom-control-label" for="Radioimage1">
                            1
                          </label>
                        </div>
                        <div class="custom-control custom-radio ml-1 mr-3">
                          <input
                            type="radio"
                            id="Radioimage2"
                            name="imagesVues"
                            value="2"
                            class="custom-control-input"
                            disabled
                          />
                          <label class="custom-control-label" for="Radioimage2">
                            2
                          </label>
                        </div>
                        <div class="custom-control custom-radio ml-1 mr-3">
                          <input
                            type="radio"
                            id="Radioimage3"
                            name="imagesVues"
                            value="3"
                            class="custom-control-input"
                            disabled
                          />
                          <label class="custom-control-label" for="Radioimage3">
                            3
                          </label>
                        </div>
                        <div class="custom-control custom-radio ml-1 mr-3">
                          <input
                            type="radio"
                            id="Radioimage4"
                            name="imagesVues"
                            value="N/A"
                            class="custom-control-input"
                            disabled
                          />
                          <label class="custom-control-label" for="Radioimage4">
                            N/A
                          </label>
                        </div>
                      </div>
                    ) : null}
                    {this.state.utype == "BACKOFFICE" ||
                    this.state.utype == "ADMIN" ||
                    (this.state.utype == "OPTICIAN" &&
                      this.state.isExternal !== null &&
                      this.state.isExternal == "false") ? (
                      <div class="col-lg-4  ">
                        <label> Résultat de l'examen :</label>
                        <input
                          type="text"
                          value={this.props.det.infoResultExamine}
                          class="form-control flatpickr-basic"
                          placeholder=""
                          readOnly
                        />
                      </div>
                    ) : null}
                    {this.state.utype !== "PATIENT" ? (
                      <>
                        <hr class="invoice-spacing mt-1" />
                        <div class="row mt-0">
                          {this.state.utype == "BACKOFFICE" ||
                          this.state.utype == "ADMIN" ||
                          (this.state.utype == "OPTICIAN" &&
                            this.state.isExternal !== null &&
                            this.state.isExternal == "false") ? (
                            <div class="col-lg-4  ">
                              <label>Commentaire interne :</label>
                              <textarea
                                type="text"
                                style={{ height: '150px' }}
                                value={this.props.det.infoCommentaireInterne}
                                class="form-control flatpickr-basic"
                                placeholder=""
                                readOnly
                              />
                            </div>
                          ) : null}
                          {/* {this.state.utype !== "EYEDOCTOR" ? ( */}
                          <div class="col-lg-4  ">
                            <label>Commentaire additionel :</label>
                            <textarea
                              type="text"
                              style={{ height: '150px' }}
                              value={this.props.det.noteOpt}
                              class="form-control flatpickr-basic"
                              placeholder=""
                              readOnly
                            />
                          </div>
                          {/* ) : null} */}
                          {this.props.det.isContactLensType == true ? (
                            <div class="col-lg-4  ">
                              <label>
                                Spécification lentilles (modèle / diamètre /
                                rayon) :
                              </label>
                              <textarea
                                type="text"
                                style={{ height: '150px' }}
                                value={this.props.det.commentForLenses}
                                class="form-control flatpickr-basic"
                                placeholder=""
                                readOnly
                              />
                            </div>
                          ) : null}
                          {this.state.utype == "OPTICIAN" ? (
                            <>
                              <div class="col-lg-4  ">
                                <label>
                                  {
                                    window.$language
                                      .CommentstoBeAddedToThePrescription
                                  }{" "}
                                  :
                                </label>
                                <textarea
                                  type="text"
                                  style={{ height: '150px' }}
                                  value={this.props.det.commentsForPrescription}
                                  class="form-control flatpickr-basic"
                                  placeholder=""
                                  readOnly
                                />
                              </div>
                            </>
                          ) : null}
                          {this.state.utype == "BACKOFFICE" ||
                          this.state.utype == "ADMIN" ||
                          this.state.utype == "EYEDOCTOR" ? (
                            <>
                              {this.state.utype == "BACKOFFICE" ||
                              this.state.utype == "ADMIN" ||
                              this.props.det.status == "Approved" ||
                              this.props.det.status == "Not Approved" ? (
                                <>
                                  <div class="col-lg-4  ">
                                    <label>
                                      {
                                        window.$language
                                          .CommentstoBeAddedToThePrescription
                                      }{" "}
                                      :
                                    </label>
                                    <textarea
                                      type="text"
                                      style={{ height: '150px' }}
                                      value={
                                        this.props.det.commentsForPrescription
                                      }
                                      class="form-control flatpickr-basic"
                                      placeholder=""
                                      readOnly
                                    />
                                  </div>
                                  <div class="col-lg-4">
                                    <label>
                                      {
                                        window.$language
                                          .CommentsforBackofficeTeam
                                      }{" "}
                                      :
                                    </label>
                                    <textarea
                                      type="text"
                                      style={{ height: '150px' }}
                                      value={this.props.det.comment}
                                      class="form-control flatpickr-basic"
                                      placeholder=""
                                      readOnly
                                    />
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div class="col-lg-4  ">
                                    <label>
                                      {
                                        window.$language
                                          .CommentstoBeAddedToThePrescription
                                      }
                                    </label>
                                    <textarea
                                      type="text"
                                      style={{ height: '150px' }}
                                      maxlength="250"
                                      placeholder="250 caractères maximum"
                                      id="comp"
                                      defaultValue={
                                        this.props.det.commentsForPrescription
                                      }
                                      class="form-control flatpickr-basic"
                                    />
                                  </div>
                                  <div class="col-lg-4">
                                    <label>
                                    Commentaire de l'ophalmologue (pour l'opticien)
                                    </label>
                                    <textarea
                                      type="text"
                                      style={{ height: '150px' }}
                                      maxlength="250"
                                      placeholder="250 caractères maximum"
                                      id="comb"
                                      class="form-control flatpickr-basic"
                                    />
                                  </div>
                                </>
                              )}
                            </>
                          ) : null}
                          {this.state.utype == "OPTICIAN" && this.props.det.status == "Not Approved" || this.props.det.status == "Approved" ? (
                           <div class="col-lg-4">
                                    <label>
                                    Commentaire de l'ophalmologue (pour l'opticien)
                                    </label>
                                    <textarea
                                      type="text"
                                      style={{ height: '150px' }}
                                      maxlength="250"
                                      placeholder="250 caractères maximum"
                                      id="comb"
                                      class="form-control flatpickr-basic"
                                      defaultValue={
                                        this.props.det.comment
                                      }
                                      readOnly
                                    />
                                  </div>) : null}
                          {this.props.det.isContactLensType == true ? (
                            <>
                              {this.props.det.eyeTestImagePath1 !== null &&
                              this.props.det.eyeTestImagePath1 !== "" ? (
                                <div class="media col-lg-8 mb-2 pl-1 ">
                                  <a
                                    class="pl-0 pt-1"
                                    target="_blank"
                                    href={
                                      this.props.det.eyeTestImagePath1 ==
                                        undefined ||
                                      this.props.det.eyeTestImagePath1 == ""
                                        ? "../../../app-assets//images/portrait/small/avatar-s-10.png"
                                        : this.state.currentImage == ""
                                        ? API_URL +
                                          "/" +
                                          this.props.det.eyeTestImagePath1
                                        : this.state.currentImage
                                    }
                                  >
                                    Voir l'ancienne ordonnance lentilles
                                  </a>
                                  {this.state.utype == "ADMIN" ? (
                                    <>
                                      {this.props.det.status !== "Approved" ? (
                                        <div class="col-4 d-flex pl-2 mt-1 px-0">
                                          <label
                                            class="btn btn-primary mr-75 mb-0"
                                            for="change-sign1"
                                          >
                                            <span class=" d-sm-block">
                                              Télécharger un nouveau fichier
                                              pour l'ancienne ordonnance
                                              lentilles{" "}
                                            </span>
                                            <input
                                              type="file"
                                              id="change-sign1"
                                              accept="image/png, image/jpeg, image/jpg"
                                              onChange={(event) =>
                                                this.uploadImgCommon(event, "1")
                                              }
                                            />
                                            <span class="d-block d-sm-none">
                                              <i class="fas fa-plus-square"></i>
                                            </span>
                                          </label>
                                        </div>
                                      ) : null}
                                    </>
                                  ) : null}
                                </div>
                              ) : null}
                            </>
                          ) : null}
                        </div>
                      </>
                    ) : null}
                    {this.state.utype == "BACKOFFICE" ||
                    this.state.utype == "ADMIN" ||
                    (this.state.utype == "OPTICIAN" &&
                      this.state.isExternal !== null &&
                      this.state.isExternal == "false") ? (
                      <div class="form-row mb-1  ">
                        <div class="col-lg-4  ">
                          <label>Images rétino :</label>
                          <input
                            type="text"
                            value={
                              this.props.det.isImageRetino == "" ||
                              this.props.det.isImageRetino == null
                                ? ""
                                : this.props.det.isImageRetino == "Yes"
                                ? "Oui"
                                : "Non"
                            }
                            class="form-control flatpickr-basic"
                            placeholder=""
                            readOnly
                          />
                        </div>
                        {this.props.det.isImageRetino == "No" ? (
                          <div class="col-lg-4  ">
                            <label>Si non, veuillez spécifier :</label>
                            <input
                              type="text"
                              value={this.props.det.isImageRetinoSpecification}
                              class="form-control flatpickr-basic"
                              placeholder=""
                              readOnly
                            />
                          </div>
                        ) : null}
                        <div class="col-lg-4  ">
                          <label>Nom de la mutuelle (si connue) :</label>
                          <input
                            type="text"
                            value={this.props.det.infoMutualCompany}
                            class="form-control flatpickr-basic"
                            placeholder=""
                            readOnly
                          />
                        </div>
                      </div>
                    ) : null}
                    <hr class="invoice-spacing" />
                    {/* {this.state.utype !== "PATIENT" ? ( */}
                    {this.props.det.infoEyeTestImgId2 !== 0 ? (
                      <button
                        className="btn btn-primary col-lg-3 mb-3"
                        onClick={this.fetchImages}
                        disabled={loading}
                      >
                        {loading
                          ? "Chargement..."
                          : "Afficher les images fond d'œil"}
                      </button>
                    ) : null}
                    <div class="form-row mb-1">
                      {images.eyeTestImagePath2 && (
                        <>{this.renderImage(images.eyeTestImagePath2, "2")}</>
                      )}
                      <div class="col-4 text-center">
                        {this.state.utype == "BACKOFFICE" ||
                        this.state.utype == "ADMIN"
                          ? this.props.det.status !== "Approved" && (
                              <label
                                class="btn btn-primary mt-2"
                                for="change-sign2"
                                disabled={isloading}
                              >
                                {isloading
                                  ? "Chargement..."
                                  : "Télécharger image fond d’oeil-2"}

                                <input
                                  type="file"
                                  id="change-sign2"
                                  accept="image/png, image/jpeg, image/jpg"
                                  onChange={(event) =>
                                    this.uploadImgCommon(event, "2")
                                  }
                                  hidden
                                />
                              </label>
                            )
                          : null}
                      </div>

                      {images.eyeTestImagePath3 && (
                        <>{this.renderImage(images.eyeTestImagePath3, "3")}</>
                      )}
                      <div class="col-3 text-center">
                        {this.state.utype == "BACKOFFICE" ||
                        this.state.utype == "ADMIN"
                          ? this.props.det.status !== "Approved" && (
                              <label
                                class="btn btn-primary mt-2"
                                disabled={loading}
                              >
                                {isloading
                                  ? "Chargement..."
                                  : "Télécharger image fond d’oeil-3"}
                                <input
                                  type="file"
                                  accept="image/png, image/jpeg, image/jpg"
                                  onChange={(event) =>
                                    this.uploadImgCommon(event, "3")
                                  }
                                  hidden
                                />
                              </label>
                            )
                          : null}
                      </div>

                      {images.eyeTestImagePath4 && (
                        <div class="col-4 text-center mt-2">
                          {this.renderImage(images.eyeTestImagePath4, "4")}
                          {this.state.utype == "BACKOFFICE" ||
                          this.state.utype == "ADMIN"
                            ? this.props.det.status !== "Approved" && (
                                <label class="btn btn-primary mt-2">
                                  Télécharger image fond d’oeil-4
                                  <input
                                    type="file"
                                    accept="image/png, image/jpeg, image/jpg"
                                    onChange={(event) =>
                                      this.uploadImgCommon(event, "4")
                                    }
                                    hidden
                                  />
                                </label>
                              )
                            : null}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className={`col-xl-3 col-md-4 col-12 invoice-actions mt-md-0 mt-2 ${
                    this.state.utype == "EYEDOCTOR" ? "fixedposition" : ""
                  }`}
                >
                  <div class="card mb-1">
                    <div class="card-body">
                      {this.state.utype == "EYEDOCTOR" &&
                      this.props.det.status == "New" ? (
                        <>
                          <button
                            class="btn btn-primary btn-block mb-75"
                            data-toggle=""
                            data-target=""
                            disabled={this.state.isLoading}
                            onClick={() => this.appr("Approved")}
                          >
                            <i class="far fa-thumbs-up mr-50"></i>
                            <span>
                              {this.state.isLoading
                                ? "Traitement..."
                                : window.$language.Approve}
                            </span>
                          </button>
                          <a
                            class="btn btn-outline-danger btn-block mb-75"
                            target="_blank"
                            onClick={() => this.dappr("Not Approved")}
                          >
                            <i class="far fa-thumbs-down mr-50"></i>
                            <span>{window.$language.NotApprove}</span>
                          </a>
                        </>
                      ) : null}
                      {this.props.det.needPrescription == true ? (
                        <>
                          {this.state.utype == "EYEDOCTOR" ||
                          this.state.utype == "BACKOFFICE" ||
                          this.state.utype == "ADMIN" ||
                          this.state.utype == "PATIENT" ? (
                            <>
                              {this.props.det.status == "Approved" ? (
                                <>
                                  {this.props.det.isContactLensType == true ? (
                                    <>
                                      <button
                                        class="btn btn-primary btn-block mb-75"
                                        data-toggle=""
                                        data-target=""
                                        onClick={() => this.presl()}
                                      >
                                        <i class="fas fa-file-medical mr-50"></i>
                                        <span>
                                          {window.$language.Prescription}
                                        </span>
                                      </button>
                                    </>
                                  ) : null}
                                  {this.props.det.isContactLensType == false ? (
                                    <>
                                      <button
                                        class="btn btn-primary btn-block mb-75"
                                        data-toggle=""
                                        data-target=""
                                        onClick={() => this.pres()}
                                      >
                                        <i class="fas fa-file-medical mr-50"></i>
                                        <span>
                                          {window.$language.Prescription}
                                        </span>
                                      </button>
                                    </>
                                  ) : null}
                                </>
                              ) : null}
                            </>
                          ) : null}
                        </>
                      ) : null}

                      <a
                        class="btn btn-secondary btn-block mb-75"
                        target="_blank"
                        onClick={() => this.eyeresult(this.props.det)}
                      >
                        <i class="fas fa-angle-double-left mr-50"></i>
                        <span>{window.$language.Back}</span>
                      </a>

                      {this.state.utype == "BACKOFFICE" ||
                      this.state.utype == "ADMIN" ||
                      this.state.utype == "OPTICIAN" ? (
                        <>
                          {this.state.isExternal == "false" &&
                          this.props.det.isContactLensType == false ? (
                            <a
                              class="btn btn-outline-primary btn-block mb-75"
                              target="_blank"
                              onClick={() => this.orderp(this.props.det)}
                            >
                              <i class="fas fa-clipboard-list mr-50"></i>
                              <span>{window.$language.Orders}</span>
                            </a>
                          ) : null}
                        </>
                      ) : null}
                      {(this.state.utype == "OPTICIAN" &&
                        this.state.isExternal !== null &&
                        this.state.isExternal == "false") ||
                      this.state.utype == "BACKOFFICE" ||
                      this.state.utype == "ADMIN" ? (
                        <>
                          {(this.props.det.status === "New" ||
                            this.props.det.status === "new") && (
                            <a
                              className="btn btn-outline-success btn-block mb-75"
                              type="button" // Changed "reset" to "button" assuming it triggers some action
                              id="assign"
                              data-dismiss="modal"
                              data-toggle="modal"
                              data-target="#modals-slide-in-1"
                            >
                              {/* <i class="fas fa-angle-double-left mr-50"></i> */}
                              <span>{window.$language.Assign}</span>
                            </a>
                          )}
                        </>
                      ) : null}
                    </div>
                  </div>
                  {(this.state.utype == "OPTICIAN" &&
                    this.state.isExternal !== null &&
                    this.state.isExternal == "false") ||
                  this.state.utype == "EYEDOCTOR" ||
                  this.state.utype == "BACKOFFICE" ||
                  this.state.utype == "ADMIN" ? (
                    <div class="card mb-1">
                      <div class="card-body">
                        <h6 className="card-subtitle mb-2 text-muted">
                          {window.$language.TestDetailsSectionHeaderAIAnalysis}
                        </h6>
                        <p class="card-text">
                          Statut :{" "}
                          {typeof this.state.ophtAIStatus !== "undefined" &&
                          this.state.ophtAIStatus != null &&
                          this.state.ophtAIStatus.info_Status != null
                            ? this.state.ophtAIStatus.info_Status.toUpperCase() ==
                              "REPORT GENERATED"
                              ? window.$language
                                  .TestAIAnalysisStatusReportGenerated
                              : this.state.ophtAIStatus.info_Status
                            : window.$language.TestAIAnalysisStatusPending}
                          &nbsp;&nbsp;&nbsp;
                          <a
                            href="#"
                            class="btn btn-primary"
                            onClick={() => this.getOphtAIStatus()}
                          >
                            &#x21bb;
                          </a>
                        </p>
                        <p class="card-text">
                          Résultat :{" "}
                          <b>
                            {this.props.det.opthAIResult == "negative"
                              ? "Négatif"
                              : this.props.det.opthAIResult == "positive"
                              ? "Positif"
                              : ""}
                          </b>
                        </p>
                        {typeof this.state.ophtAIStatus !== "undefined" &&
                        this.state.ophtAIStatus != null &&
                        this.state.ophtAIStatus.info_Status != null &&
                        this.state.ophtAIStatus.info_Status.toUpperCase() ==
                          "REPORT GENERATED" &&
                        this.state.ophtAIStatus.info_ReportDocumentPath !=
                          null ? (
                          <>
                            <a
                              target="_blank"
                              class="btn btn-primary mt-2"
                              onClick={() => this.openOphtAIReport()}
                            >
                              {window.$language.TestAIAnalysisDownloadReport}
                            </a>

                            {this.state.utype !== "OPTICIAN" ? (
                              <a
                                target="_blank"
                                class="btn btn-primary mt-2"
                                onClick={this.downloadPDF}
                              >
                                Télécharger le rapport Sym
                              </a>
                            ) : null}
                          </>
                        ) : null}
                      </div>
                    </div>
                  ) : null}
                  {this.state.utype == "PATIENT" ? (
                    <>
                      {typeof this.state.ophtAIStatus !== "undefined" &&
                      this.state.ophtAIStatus != null &&
                      this.state.ophtAIStatus.info_Status != null &&
                      this.state.ophtAIStatus.info_Status.toUpperCase() ==
                        "REPORT GENERATED" &&
                      this.state.ophtAIStatus.info_ReportDocumentPath !=
                        null ? (
                        <div class="card mb-1">
                          <div class="card-body">
                            <p class="card-text">
                              Résultat :{" "}
                              <b>
                                {this.props.det.opthAIResult == "negative"
                                  ? "Négatif"
                                  : this.props.det.opthAIResult == "positive"
                                  ? "Positif"
                                  : ""}
                              </b>
                            </p>
                            <a
                              target="_blank"
                              class="btn btn-primary mt-2"
                              onClick={this.downloadPDF}
                            >
                              Télécharger le rapport Sym
                            </a>
                          </div>
                        </div>
                      ) : null}
                    </>
                  ) : null}
                  {this.state.utype == "OPTICIAN" ? (
                    <div class="card mb-0">
                      <div class="card-body">
                        <div class="col-lg-12">
                          <div className="form-group align-baseline flex-row">
                            <label className="form-label" for="login-email">
                              Commentaire pour l’opticien :
                            </label>
                            <textarea
                            style={{ height: '150px' }}
                              id="infooptcomm"
                              class="form-control"
                              readOnly
                              value={this.props.det.infoOpticianComment}
                            />
                            {this.props.det.infoOpticianCommentDateTime ==
                            "01 01 0001" ? (
                              ""
                            ) : (
                              <p
                                class="invoice-date-title"
                                type="datetime-local"
                              >
                                Date :-
                                {this.props.det.infoOpticianCommentDateTime}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {this.state.utype == "BACKOFFICE" ||
                  this.state.utype == "ADMIN" ? (
                    <div class="card mb-0">
                      <div class="card-body">
                        <div class="col-lg-12">
                          <div className="form-group align-baseline flex-row">
                            <label className="form-label" for="login-email">
                              Commentaire pour l’opticien :
                            </label>
                            <textarea
                              id="infooptcomm"
                              style={{ height: '150px' }}
                              class="form-control"
                              defaultValue={this.props.det.infoOpticianComment}
                            />
                            {this.props.det.infoOpticianCommentDateTime ==
                            "01 01 0001" ? (
                              ""
                            ) : (
                              <p
                                class="invoice-date-title"
                                type="datetime-local"
                              >
                                Date :-
                                {this.props.det.infoOpticianCommentDateTime}
                              </p>
                            )}
                          </div>
                        </div>
                        <div class="col-lg-12">
                          <div className="form-group align-baseline flex-row">
                            <label className="form-label" for="login-email">
                              Commentaire pour l’equipe backoffice :
                            </label>
                            <textarea
                              id="infoposcomm"
                              class="form-control"
                              defaultValue={this.props.det.infoPOSComment}
                            />
                            {this.props.det.infoPOSCommentDateTime ==
                            "01 01 0001" ? (
                              ""
                            ) : (
                              <p class="invoice-date-title">
                                Date:-{this.props.det.infoPOSCommentDateTime}
                              </p>
                            )}
                          </div>
                        </div>
                        <button
                          class="btn btn-primary btn-block mb-75"
                          onClick={() => this.backcom()}
                        >
                          <span>Envoyer</span>
                        </button>
                      </div>
                    </div>
                  ) : null}
                </div>

                <div class="modal modal-slide-in fade" id="modals-slide-in-1">
                  <div class="modal-dialog sidebar-sm">
                    <form class="add-new-record modal-content pt-0">
                      <button
                        type="button"
                        class="close"
                        id="www4"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        ×
                      </button>
                      <div class="modal-header mb-1">
                        <h5 class="modal-title" id="exampleModalLabel">
                          {window.$language.Doctor}
                        </h5>
                      </div>
                      <div class="modal-body flex-grow-1">
                        <div class="">
                          <form class="dt_adv_search" method="POST">
                            <div class="col-lg-12">
                              <div class="form-group">
                                <label for="languages">
                                  {window.$language.AssignDoctor} :
                                </label>
                                <select id="emails" class="form-control">
                                  <span class="select2-search select2-search--dropdown">
                                    <input
                                      class="select2-search__field"
                                      type="search"
                                      tabindex="0"
                                      autocomplete="off"
                                      autocorrect="off"
                                      autocapitalize="none"
                                      spellcheck="false"
                                      role="searchbox"
                                      aria-autocomplete="list"
                                      aria-controls="select2-y9ao-results"
                                      aria-activedescendant="select2-y9ao-result-pbn3-IN"
                                    />
                                  </span>

                                  {this.state.docarr.map((det) => (
                                    <option>{det.email}</option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </form>
                        </div>
                        <button
                          type="button"
                          class="btn btn-primary data-submit mr-1 m-1"
                          onClick={() => this.assign()}
                        >
                          {window.$language.Assign}
                        </button>
                        <button
                          type="reset"
                          class="btn btn-outline-secondary m-1"
                          id="close7896"
                          data-dismiss="modal"
                        >
                          {window.$language.Cancel}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    );
  }
}
export default Vieweyetest;
