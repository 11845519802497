import React, { Component } from "react";
import ReactDOM from "react-dom";
import Pdf from "react-to-pdf";
import { API_URL } from "../../config";
import Eyeresult from "../EyetestComponent/Eyetestresult";
import ViewEyetest from "../EyetestComponent/Vieweyetest";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import SymLoader from "../SymLoader/SymLoader1";
import $ from "jquery";
import swal from "sweetalert";
import { PDFDocument } from 'pdf-lib';
const ref = React.createRef();

class Prescription extends Component {
  constructor(props) {
    super(props);
    //console.log(this.props.row)
    this.state = {
      emailID: "",
      pwd: "",
      loginIP: "",
      loginBrowser: "",
      deviceType: "",
      userLocation: "",
      logIsp: "",
      ii: 0,
      actions: [],
      utype: localStorage.getItem("type"),
      pLogo: "",
      isLoading:true,
      focal: "",
      testreport: null,
      imagereport: {},
      mon: {
        Jan: "janvier",
        Feb: "février",
        Mar: "mars",
        Apr: "avril",
        May: "mai",
        Jun: "juin",
        Jul: "juillet",
        Aug: "août",
        Sep: "septembre",
        Oct: "octobre",
        Nov: "novembre",
        Dec: "décembre",
      },
      date1: "",
    };
  }
  imagefatch = () => {
    this.setState({ isLoading: true });
    let eyetestid = this.props.row.testid;
    var token = localStorage.getItem("token");
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({
        eyeTestID: eyetestid,
      }),
    };
    let logourl = API_URL + "/eyetests/searcheyetestimagenewbyid";
    fetch(logourl, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        console.log(json);
        this.setState({ isLoading: false });
        this.setState(
          {
            imagereport: json,
          },
          () => {
            console.log(this.state.imagereport.doctorSignature, "symreport2");
          }
        );
      })
      .catch((error) => {
        console.error("Error fetching the report:", error);
      });
  };
  notify1() {
    //alert("hi")
    let logourl = API_URL + "/eyetests/showprescriptiontooptician";
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("token"),
      },
      body: JSON.stringify({
        opticianEmail: this.props.row.opticianEmail,
        eyeTestID: this.props.row.testid,
      }),
    };
    fetch(logourl, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        if (json == true) {
          swal({
            title: "Done!",
            text: window.$language.NotifiedSuccessfully,
            icon: "success",
          });
          $("#notify1").prop("disabled", true);
        } else {
          swal({
            title: "OOPS!",
            text: window.$language.Somethingwentwrong,
            icon: "warning",
          });
        }
        //this.setState({opcarr:json})
      })
      .catch((e) => {
        swal({
          title: "OOPS!",
          text: window.$language.Somethingwentwrong,
          icon: "warning",
        });

        console.log(e);
      });
  }
  componentDidMount() {
    console.log(this.props.row,"propsdata")
    //this.state.date1
    //console.log(this.state.mon.length)
    let arrd = this.props.row.createdDate.split(" ");
   
    //console.log(arrd)
    let finald = "";
    for (var key in this.state.mon) {
      console.log(arrd[1]);
      if (key == arrd[1]) {
        finald = arrd[0] + " " + this.state.mon[key] + " " + arrd[2];
        this.setState({ date1: finald });
        console.log(finald);
      }
    }
    let arr = this.props.row.testDetails.filter(function (t) {
      return t.testType == "Prescription finale";
    });
    var notblank = (element) => element.add == 0;
    this.setState({ focal: arr.some(notblank) });
    this.getreport();
    this.imagefatch();
    // setTimeout(()=>{
    //   this.setState({isLoading:false});
    // },1000)
  }
  _exportPdf = () => {
    alert("done");
    console.log("1");
    html2canvas(document.querySelector("#pdfkoro"), {
      onrendered: function (canvas) {
        document.body.appendChild(canvas);
      },
      allowTaint: true,
    }).then((canvas) => {
      console.log("2");

      // document.body.appendChild(canvas);  // if you want see your screenshot in body.
      const imgData = canvas.toDataURL("image/png");
      console.log(imgData);
      const pdf = new jsPDF("p", "pt", "a4", false);
      pdf.addImage(imgData, "PNG", 0, 0, 600, 0, undefined, false);
      pdf.save(this.props.row.patientName + "-" + this.state.date1 + ".pdf");

      var pdf1 = new jsPDF("p", "pt", "a4");
      pdf1.addHTML($("#ElementYouWantToConvertToPdf")[0], function () {
        pdf.save("Test.pdf");
      });
    });
  };
  submit() {}
  eyeresult() {
    console.log("hi");
    ReactDOM.render(
      // <Eyeresult status="Approved" />
      <Eyeresult status="Approved" />,
      document.getElementById("c11")
    );
    //this.props.showEyetest("");
  }
  b64() {
    var img = new Image();
    img.src = API_URL + "/" + this.props.row.doctorSignature;
    console.log(img);
    return img;
  }
getreport = () => {
      this.setState({isLoading:true});
      let eyetestid = this.props.row.testid;
      var token = localStorage.getItem("token");
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({
          eyeTestID:eyetestid
        }),
      };
      let logourl = API_URL + "/eyetests/prescriptionbytestid";
      fetch(logourl, requestOptions)
        .then((res) => res.json())
        .then((json) => {
          console.log(json);
          this.setState({isLoading:false});
          this.setState(
            {
              testreport: json,
            },
            () => {
          console.log(this.state.testreport.prescriptionFileFullName,"symreport1");
            }
          );
        })
        .catch((error) => {
          console.error("Error fetching the report:", error);
        });
    };
checkit(){
   var sympresStatus = this.state.testreport?.prescriptionFileFullName;
  
      if (!sympresStatus) {
        console.error("No report path available.");
        return;
      }
      var SymreportUrl = `${sympresStatus}`;
      console.log(SymreportUrl, "Symreport2");
  
      if (SymreportUrl) {
        window.open(SymreportUrl, "_blank");
      } else {
        console.error("Invalid report URL.");
      }
}
checkitothers(){
  var sympresStatus = this.state.testreport?.prescriptionFileFullName;
 
     if (!sympresStatus) {
       console.error("No report path available.");
       return;
     }
     var SymreportUrl = `${sympresStatus}`;
     console.log(SymreportUrl, "Symreport2");
 
     if (SymreportUrl) {
       window.open(SymreportUrl, "_blank");
     } else {
       console.error("Invalid report URL.");
     }
}
  render() {
     return (
       <>
         {this.state.isLoading ? (
           <SymLoader />
         ) : (
           <div class="app-content content ">
             <div class="content-overlay"></div>
             <div class="header-navbar-shadow"></div>
             <div class="content-wrapper">
               <div class="content-header row"></div>
               <div class="content-body px-3">
                 <section class="invoice-preview-wrapper">
                   <div class="row invoice-preview">
                     <div
                       class="col-xl-9 col-md-8 col-12"
                       id="pdfkoro"
                       style={{ width: "500px" }}
                       ref={ref}
                     >
                       <div class="card invoice-preview-card p-2">
                         <div class="card-body invoice-padding pb-0">
                           <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">
                             <div>
                               <p>
                                 <b>{this.state.imagereport.doctorName}</b>
                               </p>
                               <p class="card-text mb-25">
                                 Numéro d’ordre:{" "}
                                 {
                                   this.state.imagereport
                                     .infoDoctorIdOfRegistration
                                 }{" "}
                               </p>
                               <p class="card-text mb-25">
                                 RPPS:{" "}
                                 {this.state.imagereport.infoDoctorRppsNumber}
                               </p>
                               <p class="card-text mb-25 mt-1">
                                 {this.state.imagereport.drDesignation}
                               </p>
                             </div>
                             <div class="mt-md-0 mt-2"></div>
                           </div>
                         </div>
                         <div class="row">
                           <div class="col-xl-8 p-0"></div>
                           <div class="col-xl-4 p-0 mt-xl-0">
                             <h6>
                               {this.props.row.acteGratuit == true
                                 ? "Acte gratuit"
                                 : ""}
                             </h6>
                           </div>
                         </div>
                         <div class="card-body pt-0">
                           <div class="row p-0 m-0">
                             <div class="col-xl-8 p-0">
                               <p class="card-text mb-0">
                                 {this.props.row.patientGender == "Male"
                                   ? "Monsieur"
                                   : this.props.row.patientGender == "Female"
                                   ? "Madame"
                                   : " "}
                                 &nbsp;{this.props.row.patientName}
                               </p>
                             </div>
                             <div class="col-xl-4 p-0 mt-xl-0 mt-2">
                               <h6 class="mb-2">
                                 {this.state.imagereport.doctorCity}, le&nbsp;
                                 {this.state.date1}
                               </h6>
                             </div>
                           </div>
                         </div>
                         <h6 class="mb-25" id="uuu">
                           Ordonnance verres et monture (verres&nbsp;
                           {this.state.focal ? "unifocaux)" : "progressifs)"}
                         </h6>
                         <div class="table-responsive">
                           <table class="table table-bordered">
                             <thead>
                               <tr>
                                 <th class="py-1"></th>
                                 <th class="py-1">SPH</th>
                                 <th class="py-1">CYL</th>
                                 <th class="py-1">AXE</th>
                                 <th class="py-1">ADD</th>
 
                                 <th class="py-1">PD</th>
 
                                 <th class="py-1 d-none">PHIGH</th>
                               </tr>
                             </thead>
                             <tbody>
                               {this.props.row.testDetails.map((det) => (
                                 <>
                                   {det.testType == "Prescription finale" ? (
                                     <tr>
                                       <td class="py-1">
                                         {det.whichEye == "left"
                                           ? "Œil gauche"
                                           : "Œil droit"}
                                       </td>
                                       <td class="py-1">
                                         <span
                                           class="font-weight-bold"
                                           id={det.whichEye + "sph"}
                                         >
                                           {det.sph}
                                         </span>
                                       </td>
                                       <td class="py-1">
                                         <span
                                           class="font-weight-bold"
                                           id={det.whichEye + "cyl"}
                                         >
                                           {det.cyl}
                                         </span>
                                       </td>
                                       <td class="py-1">
                                         <span
                                           class="font-weight-bold"
                                           id={det.whichEye + "axe"}
                                         >
                                           {det.axe}
                                         </span>
                                       </td>
                                       <td class="py-1">
                                         <span
                                           class="font-weight-bold"
                                           id={det.whichEye + "add"}
                                         >
                                           {det.add}
                                         </span>
                                       </td>
 
                                       <td class="py-1">
                                         <span
                                           class="font-weight-bold"
                                           id={det.whichEye + "pd"}
                                         >
                                           {det.pd}
                                         </span>
                                       </td>
                                       <td class="py-1 d-none">
                                         <span
                                           class="font-weight-bold"
                                           id={det.whichEye + "phigh"}
                                         >
                                           {det.phigh}
                                         </span>
                                       </td>
                                     </tr>
                                   ) : null}
                                 </>
                               ))}
                             </tbody>
                           </table>
                         </div>
                         <p class="card-text mb-0 mt-1">
                           {this.props.row.commentsForPrescription}
                         </p>
                         <div class="card-body invoice-padding pb-0">
                           <div class="row invoice-sales-total-wrapper">
                             <div class="col-md-12 order-md-1 order-2 mt-md-0 mt-3">
                               <p class="card-text mb-0">
                                 <span class="font-weight-bold">
                                   <h3>
                                     {this.state.imagereport.doctorSignature ===
                                     "" ? (
                                       this.state.imagereport.doctorName
                                     ) : (
                                       <img
                                         src={`${API_URL}/${this.state.imagereport.doctorSignature}`}
                                         alt="Doctor's Signature"
                                         className="signatureClass"
                                       />
                                     )}
                                   </h3>
                                 </span>{" "}
                                 <span class="ml-75"></span>
                               </p>
                             </div>
                             <div class="col-md-12 form-row order-md-1 order-2 mt-md-0 mt-3">
                               <p class="card-text col-lg-8 mb-0">
                                 <span class="font-weight-bold">
                                   <h3>
                                     {this.state.imagereport.rpcsIdImagePath !=
                                     "" ? (
                                       <img
                                         src={`${API_URL}/${this.state.imagereport.rpcsIdImagePath}`}
                                         alt="Doctor's Signature"
                                       />
                                     ) : (
                                       this.state.imagereport
                                         .infoDoctorRppsNumber
                                     )}
                                   </h3>
                                 </span>{" "}
                                 <span class="ml-25"></span>
                               </p>
                               <p class="card-text col-lg-4 mb-0">
                                 <span class="font-weight-bold">
                                   <h3>
                                     {this.state.imagereport.doctorIdImagePath != "" ? (
                                       <img
                                         src={`${API_URL}/${this.state.imagereport.doctorIdImagePath}`}
                                         alt="Doctor's Signature"
                                       />
                                     ) : (
                                       this.state.imagereport
                                         .infoDoctorIdOfRegistration
                                     )}
                                   </h3>
                                 </span>{" "}
                                 <span class="ml-25"></span>
                               </p>
                               <p class="card-text mb-25 pl-4 mt-1">
                                 Cette ordonnance a été validée par le docteur{" "}
                                 <b>{this.state.imagereport.doctorName}</b> via
                                 un acte de téléexpertise réalisé sur la
                                 plateforme e-santé Sym Care Lab. Identifiant du
                                 test: {this.props.row.testUniqueCode}
                               </p>
                             </div>
                           </div>
                         </div>
                       </div>
                     </div>
                     <div class="col-xl-3 col-md-4 col-12 invoice-actions mt-md-0 mt-2">
                       <div class="card px-2 pb-4">
                         <div class="card-body">
                           {this.state.utype == "PATIENT" ? (
                             <>
                               {this.props.row.showPrescriptiontoOptician ==
                               false ? (
                                 <button
                                   class="btn btn-outline-success btn-block   mb-75 float-right"
                                   onClick={() => this.notify1()}
                                   id="notify1"
                                 >
                                   Partager avec l’opticien
                                 </button>
                               ) : null}
                             </>
                           ) : null}
                           {this.state.utype == "PATIENT" ? (
                             <>
                               {this.props.row.showPrescriptiontoOptician ==
                               true ? (
                                 <button
                                   class="btn btn-outline-success btn-block   mb-75 float-right"
                                   disabled
                                 >
                                   Partagé avec l’opticien
                                 </button>
                               ) : null}
                             </>
                           ) : null}
 
                           {this.state.utype == "PATIENT" ? (
                             <button
                               onClick={() => this.checkit()}
                               class="btn btn-outline-secondary btn-block btn-download-invoice mb-75"
                             >
                               {window.$language.Download}
                             </button>
                           ) : null}
                           {this.state.utype !== "PATIENT" ? (
                             <button
                               onClick={() => this.checkitothers()}
                               class="btn btn-outline-secondary btn-block btn-download-invoice mb-75"
                             >
                               {window.$language.Download}
                             </button>
                           ) : null}
                           {this.state.utype == "BACKOFFICE" ||
                           this.state.utype == "ADMIN" ? (
                             <>
                               {this.props.row.isPrescriptionDownload == true ? (
                                 <h5>Ordonnance téléchargée</h5>
                               ) : null}
                             </>
                           ) : null}
 
                           <a
                             class="btn btn-secondary btn-block mb-75"
                             target="_blank"
                             onClick={this.eyeresult}
                           >
                             <i class="fas fa-angle-double-left mr-50"></i>
                             <span>{window.$language.Back}</span>
                           </a>
                         </div>
                       </div>
                     </div>
                   </div>
                 </section>
 
                 <div
                   class="modal modal-slide-in fade"
                   id="send-invoice-sidebar"
                   aria-hidden="true"
                 >
                   <div class="modal-dialog sidebar-lg">
                     <div class="modal-content p-0">
                       <button
                         type="button"
                         class="close"
                         data-dismiss="modal"
                         aria-label="Close"
                       >
                         ×
                       </button>
                       <div class="modal-header mb-1">
                         <h5 class="modal-title">
                           <span class="align-middle">Send Prescription</span>
                         </h5>
                       </div>
                       <div class="modal-body flex-grow-1">
                         <form>
                           <div class="form-group">
                             <label for="invoice-from" class="form-label">
                               From
                             </label>
                             <input
                               type="text"
                               class="form-control"
                               id="invoice-from"
                               value=""
                             />
                           </div>
                           <div class="form-group">
                             <label for="invoice-to" class="form-label">
                               To
                             </label>
                             <input
                               type="text"
                               class="form-control"
                               id="invoice-to"
                               value=""
                             />
                           </div>
                           <div class="form-group">
                             <label for="invoice-subject" class="form-label">
                               Subject
                             </label>
                             <input
                               type="text"
                               class="form-control"
                               id="invoice-subject"
                               value=""
                               placeholder=""
                             />
                           </div>
                           <div class="form-group">
                             <label for="invoice-message" class="form-label">
                               Message
                             </label>
                             <textarea
                               class="form-control"
                               name="invoice-message"
                               id="invoice-message"
                               cols="3"
                               rows="11"
                               placeholder="Message..."
                             ></textarea>
                           </div>
                           <div class="form-group d-flex flex-wrap mt-2">
                             <button
                               type="button"
                               class="btn btn-primary mr-1"
                               data-dismiss="modal"
                             >
                               Send
                             </button>
                             <button
                               type="button"
                               class="btn btn-outline-secondary"
                               data-dismiss="modal"
                             >
                               Cancel
                             </button>
                           </div>
                         </form>
                       </div>
                     </div>
                   </div>
                 </div>
               </div>
             </div>
           </div>
         )}
       </>
     );
   }
}
export default Prescription;
