import React, { useState, useEffect, useMemo, useRef } from "react";
import ReactDOM from "react-dom";
import "./Eyetestresult.css";
import $, { data, event } from "jquery";
//import DataTable from '../datatables';
import { API_URL } from "../../config";
import Vieweyetest from "./Vieweyetest";
import axios from "axios";
import Pre from "../Prescription/Prescription";
import Prel from "../Prescription/PrescriptionL";
import swal from "sweetalert";
import { useForm, Controller } from "react-hook-form";
import html2canvas from "html2canvas";
import SymLoader from "../SymLoader/SymLoader1";
import Patientreg from "../User/patientregistration/pacientreg";
import Select from "react-select";
import * as XLSX from "xlsx";
import {
  options,
  options2,
  options3,
  options4,
  options5,
  options6,
} from "./EyetestOptions";
import { CSVLink } from "react-csv";
import ReactPaginate from "react-paginate";
//import TaskList from "../../Loader";
function Eyetest(props) {
  //  console.log(props.testId)
  const [checked, setChecked] = useState(false);
  let isEyeTest = props.isEyeTest;
  let isPrescrip = props.isPrescrip;
  const [state, setState] = useState({
    emailID: "",
    isContactLens: false,
    assignedToDoc: false,
    pwd: "",
    loginIP: "",
    loginBrowser: "",
    deviceType: "",
    userLocation: "",
    logIsp: "",
    ii: 0,
    eyetesid: [],
    actions: [],
    pLogo: "",
    eyearr: [{ key: {}, values: [] }],
    patientarr: [],
    patientemail: "",
    exparrt: [],
    posarr: [],
    yeararr: [],
    posEmail: "",
    opticianEmail: "",
    docarr: [{ key: {}, values: [] }],
    opcarr: [{ key: {}, values: [] }],
    utype: localStorage.getItem("type"),
    testdet: [{ key: {}, values: [] }],
    testno: "",
    symptoms: "",
    dignosedwith: "",
    healthConditions: "",
    resultExamine: "",
    resultExamine1: "",
    Acte: false,
    Actel: false,
    avdprg: "",
    avdplg: "",
    avdlrg: "",
    avdllg: "",
    avdplgl: "",
    avdlrl: "",
    avdprl: "",
    avdlll: "",
    avscrl: "",
    avscll: "",
    aprl: "",
    apll: "",
    avscrg: "",
    avsclg: "",
    ndp: "",
    ndpl: "",
    commentaireintel: "",
    commentaireinte: "",
    aprg: "",
    aplg: "",
    noteOpt: "",
    noteoptl: "",
    lastTimeDocl: "",
    lastTimeDoc: "",
    comlen: "",
    rsh: "0.00",
    rcy: "0.00",
    rax: "0.00",
    rad: "0.00",
    rpd: "0.00",
    lsh: "0.00",
    lpd: "0.00",
    lad: "0.00",
    lax: "0.00",
    lcy: "0.00",
    arsh: "0.00",
    arcy: "0.00",
    arax: "0.00",
    arad: "0.00",
    arpd: "0.00",
    alsh: "0.00",
    alpd: "0.00",
    alad: "0.00",
    alax: "0.00",
    alcy: "0.00",
    brsh: "0.00",
    brcy: "0.00",
    brax: "0.00",
    brad: "0.00",
    brpd: "0.00",
    blsh: "0.00",
    blpd: "0.00",
    blad: "0.00",
    blax: "0.00",
    blcy: "0.00",
    crsh: "0.00",
    crcy: "0.00",
    crax: "0.00",
    crad: "0.00",
    crpd: "0.00",
    clsh: "0.00",
    clpd: "0.00",
    clad: "0.00",
    clax: "0.00",
    clcy: "0.00",
    rshl: "0.00",
    rcyl: "0.00",
    raxl: "0.00",
    radl: "0.00",
    lshl: "0.00",
    ladl: "0.00",
    laxl: "0.00",
    lcyl: "0.00",
    arshl: "0.00",
    arcyl: "0.00",
    araxl: "0.00",
    aradl: "0.00",
    alshl: "0.00",
    aladl: "0.00",
    alaxl: "0.00",
    alcyl: "0.00",
    brshl: "0.00",
    brcyl: "0.00",
    braxl: "0.00",
    bradl: "0.00",
    blshl: "0.00",
    bladl: "0.00",
    blaxl: "0.00",
    blcyl: "0.00",
    crshl: "0.00",
    crcyl: "0.00",
    craxl: "0.00",
    cradl: "0.00",
    clshl: "0.00",
    cladl: "0.00",
    claxl: "0.00",
    clcyl: "0.00",
    testUniqueCode: "",
    testUniqueCode1: "",
    Image1: "",
    imageValue: null,
    Image2: "",
    imageValue2: null,
    Image3: "",
    imageValue3: null,
    Image4: "",
    imageValue4: null,
    Imagel1: "",
    Imagel2: "",
    Imagel3: "",
    Imagel4: "",
    //isExternal: false,
    isExternal: localStorage.getItem("isExternal"),
    isExternalDoctor: localStorage.getItem("isExternalDoctor"),
  });
  const dateFormatPicker = (date) => {
    let newDate = new Date(date);
    let year = newDate.getFullYear();
    let month = ("0" + (newDate.getMonth() + 1)).slice(-2);
    let day = ("0" + newDate.getDate()).slice(-2);

    return `${year}-${month}-${day}`;
  };
  const [selectedOptions, setSelectedOptions] = useState("");
  const [viewEyeTest, setViewEyeTest] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [isEditType, setIsEditType] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [pageNumber, setPageNumber] = useState(1);
  const [startDate, setStartDate] = useState("");
  const [proceed, setProceed] = useState(true);
  const [loaderIdx, setLoaderIdx] = useState(-1);
  const [endDate, setEndDate] = useState("");
  const {
    register,
    handleSubmit,
    reset,
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({ mode: "onChange" });
  let det = props.det;
  const csvLink = useRef();
  const [patientemail, setPatientEmail] = useState("");
  const [opticianEmail, setopticianEmail] = useState("");
  const [getmonthexport, setmonthexport] = useState("");
  const [posEmail, setPosid] = useState("");
  console.log("this is email", patientemail);
  const [currentItems, setCurrentItems] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [frametype, setFrameType] = useState("");
  const componentToPrint = useRef();
  const [isExternal, setIsExternal] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const handlePrint = () => {
    window.print();
  };
  const handleChange = () => {
    if ($("#imgret").val() === "Yes" || $("#imgret").val() === "") {
      $("#specify").hide();
      $("#labelval").hide();
    } else {
      $("#specify").show();
      $("#labelval").show();
    }
  };
  const handleChange1 = () => {
    if ($("#imgret1").val() === "Yes" || $("#imgret1").val() === "") {
      $("#specify1").hide();
      $("#labelval1").hide();
    } else {
      $("#specify1").show();
      $("#labelval1").show();
    }
  };
  const captureScreenshot = () => {
    const element = document.getElementById("screenshot-target").innerHTML;

    html2canvas(element)
      .then((canvas) => {
        // Convert canvas to base64 image
        const screenshotData = canvas.toDataURL("image/png");

        // Create a link element to download the screenshot
        const link = document.createElement("a");
        link.href = screenshotData;
        link.download = "screenshot.png";
        link.click();
      })
      .catch((error) => {
        console.error("Error capturing screenshot:", error);
      });
  };
  const onChange = (rsh) => {
    setState({ rsh });
  };

  const onMenuOpen = () => {
    setTimeout(() => {
      const selectedEl = document.getElementsByClassName(
        "eyetestdetails__option--is-selected"
      )[0];
      if (selectedEl) {
        selectedEl.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "start",
        });
      }
    }, 5);
  };
  const handlePageClick = (event) => {
    setPageNumber(event.selected + 1);
    setIsLoading(true);
    // const newOffset = (event.selected * itemsPerPage) % state.eyearr.length;
    // console.log(
    //   `User requested page number ${event.selected}, which is offset ${newOffset}`
    // );
    // setItemOffset(newOffset);
  };
  const Language = () => {
    var token = localStorage.getItem("token");

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    };
    let logourl = API_URL + "/languagesOpthai";
    fetch(logourl, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        console.log(json);
        setState((prevState) => {
          return {
            ...prevState,
            languge: [...json],
          };
        });
      });
  };
  /*Image Function Start*/
  const fileUpload = async (id, file, imageNumber) => {
    if (file == null) {
      return "";
    }

    const url = {
      1: "/eyetests/image/upload1",
      2: "/eyetests/image/upload2",
      3: "/eyetests/image/upload3",
      4: "/eyetests/image/upload4",
    };
    const urltoUpload = API_URL + url[imageNumber];
    const formData = new FormData();
    formData.append("EyeTestId", id);
    formData.append("EyeTestImage", file);
    // alert(formData)
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        authorization: localStorage.getItem("token"),
      },
    };
    const result = await axios.post(urltoUpload, formData, config);
    console.log(result);
    return result;
  };
  console.log("abcd", props);
  const onImageChange1 = (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];

      // Check if the file size is greater than 0
      if (img.size > 0) {
        // Check if the file type is an image
        if (img.type.startsWith("image/")) {
          setState((prevState) => {
            return {
              ...prevState,
              imageValue2: img,
              Image2: URL.createObjectURL(img),
            };
          });
        } else {
          // Not an image file
          swal({
            title: "OOPS!",
            text: "Veuillez sélectionner un fichier image valide.",
            icon: "warning",
          });
          return;
        }
      } else {
        // 0KB file
        swal({
          title: "OOPS!",
          text: "Le fichier sélectionné est vide (0KB). Veuillez sélectionner un fichier valide.",
          icon: "warning",
        });
        return;
      }
    }
  };
  const onImageChange2 = (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];

      // Check if the file size is greater than 0
      if (img.size > 0) {
        // Check if the file type is an image
        if (img.type.startsWith("image/")) {
          setState((prevState) => {
            return {
              ...prevState,
              imageValue3: img,
              Image3: URL.createObjectURL(img),
            };
          });
        } else {
          // Not an image file
          swal({
            title: "OOPS!",
            text: "Veuillez sélectionner un fichier image valide.",
            icon: "warning",
          });
          return;
        }
      } else {
        // 0KB file
        swal({
          title: "OOPS!",
          text: "Le fichier sélectionné est vide (0KB). Veuillez sélectionner un fichier valide.",
          icon: "warning",
        });
        return;
      }
    }
  };
  const onImageChange3 = (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];

      // Check if the file size is greater than 0
      if (img.size > 0) {
        // Check if the file type is an image
        if (img.type.startsWith("image/")) {
          setState((prevState) => {
            return {
              ...prevState,
              imageValue4: img,
              Image4: URL.createObjectURL(img),
            };
          });
        } else {
          // Not an image file
          swal({
            title: "OOPS!",
            text: "Veuillez sélectionner un fichier image valide.",
            icon: "warning",
          });
          return;
        }
      } else {
        // 0KB file
        swal({
          title: "OOPS!",
          text: "Le fichier sélectionné est vide (0KB). Veuillez sélectionner un fichier valide.",
          icon: "warning",
        });
        return;
      }
    }
  };
  const onImageChangel = (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];

      // let converted =  blobToBase64(img)
      setState((prevState) => {
        return {
          ...prevState,
          imageValue: img,
          Imagel1: URL.createObjectURL(img),
        };
      });
    }
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];

      // Check if the file size is greater than 0
      if (img.size > 0) {
        setState((prevState) => {
          return {
            ...prevState,
            imageValue: img,
            Imagel1: URL.createObjectURL(img),
          };
        });
      } else {
        // 0KB file
        swal({
          title: "OOPS!",
          text: "Le fichier sélectionné est vide (0KB). Veuillez sélectionner un fichier valide.",
          icon: "warning",
        });
        return;
      }
    }
  };
  const onImageChangel1 = (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];

      // Check if the file size is greater than 0
      if (img.size > 0) {
        // Check if the file type is an image
        if (img.type.startsWith("image/")) {
          setState((prevState) => {
            return {
              ...prevState,
              imageValue2: img,
              Imagel2: URL.createObjectURL(img),
            };
          });
        } else {
          // Not an image file
          swal({
            title: "OOPS!",
            text: "Veuillez sélectionner un fichier image valide.",
            icon: "warning",
          });
          return;
        }
      } else {
        // 0KB file
        swal({
          title: "OOPS!",
          text: "Le fichier sélectionné est vide (0KB). Veuillez sélectionner un fichier valide.",
          icon: "warning",
        });
        return;
      }
    }
  };
  const onImageChangel2 = (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];

      // Check if the file size is greater than 0
      if (img.size > 0) {
        // Check if the file type is an image
        if (img.type.startsWith("image/")) {
          setState((prevState) => {
            return {
              ...prevState,
              imageValue3: img,
              Imagel3: URL.createObjectURL(img),
            };
          });
        } else {
          // Not an image file
          swal({
            title: "OOPS!",
            text: "Veuillez sélectionner un fichier image valide.",
            icon: "warning",
          });
          return;
        }
      } else {
        // 0KB file
        swal({
          title: "OOPS!",
          text: "Le fichier sélectionné est vide (0KB). Veuillez sélectionner un fichier valide.",
          icon: "warning",
        });
        return;
      }
    }
  };
  const onImageChangel3 = (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];

      // Check if the file size is greater than 0
      if (img.size > 0) {
        // Check if the file type is an image
        if (img.type.startsWith("image/")) {
          setState((prevState) => {
            return {
              ...prevState,
              imageValue4: img,
              Imagel4: URL.createObjectURL(img),
            };
          });
        } else {
          // Not an image file
          swal({
            title: "OOPS!",
            text: "Veuillez sélectionner un fichier image valide.",
            icon: "warning",
          });
          return;
        }
      } else {
        // 0KB file
        swal({
          title: "OOPS!",
          text: "Le fichier sélectionné est vide (0KB). Veuillez sélectionner un fichier valide.",
          icon: "warning",
        });
        return;
      }
    }
  };
  /* Image Functions End */
  const check_length = (my_form) => {
    maxLen = 50; // max number of characters allowed
    if (my_form.my_text.value.length >= maxLen) {
      // Alert message if maximum limit is reached.
      // If required Alert can be removed.
      var msg = "You have reached your maximum limit of characters allowed";
      alert(msg);
      // Reached the Maximum length so trim the textarea
      my_form.my_text.value = my_form.my_text.value.substring(0, maxLen);
    } else {
      // Maximum length not reached so update the value of my_text counter
      my_form.text_num.value = maxLen - my_form.my_text.value.length;
    }
  };
  const exportToExcel = async (status, assigned) => {
    var month = "",
      year = "";
    if (props.status == "" || props.status == "Approved") {
      month = $("#monthid").text();
      year = $("#yearhid").text();
      if (month == "Mois") {
        swal({
          title: "OOPS!",
          text: "Le mois ne peut pas être vide pour l'exportation de l'essai oculaire",
          icon: "warning",
        });
        return;
      } else if (year == "Année") {
        swal({
          title: "OOPS!",
          text: "L'année ne peut pas être vide pour l'exportation de tests oculaires",
          icon: "warning",
        });
        return;
      }
    }
    const logourl = `${API_URL}/eyetests/export?status=${status}&AssigUnassigned=${assigned}&month=${getmonthexport}&year=${year}`;
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("token"),
      },
    };

    try {
      const response = await fetch(logourl, requestOptions);
      const json = await response.json();

      const ws = XLSX.utils.json_to_sheet(json);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Eyetest");

      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const url = URL.createObjectURL(data);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "eyetest_data.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error(error);
    }
  };

  const exportToAllExcel = async () => {
    const logourl = `${API_URL}/eyetests/exportalleyetest`;
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("token"),
      },
    };
    try {
      const response = await fetch(logourl, requestOptions);
      const json = await response.json();

      const ws = XLSX.utils.json_to_sheet(json);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Eyetest");

      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const url = URL.createObjectURL(data);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "alleyetest_data.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error(error);
    }
  };
  const deleteEytest = (testid) => {
    swal({
      title: window.$language.Areyousure,
      text: window.$language.AreyousuretoDelete,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        let logourl = API_URL + "/eyetest/delete";
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            authorization: localStorage.getItem("token"),
          },
          body: JSON.stringify({
            eyeTestID: testid,
          }),
        };
        fetch(logourl, requestOptions)
          .then((res) => res.json())
          .then((json) => {
            if (json == true) {
              swal({
                title: "Fait ! ",
                text: window.$language.DeletedSuccessfully,
                icon: "success",
              });
              console.log("Before Component");
              componentMount();
              console.log("After Component");
            } else {
              swal({
                title: "OOPS!",
                text: window.$language.Somethingwentwrong,
                icon: "warning",
              });
            }

            //this.setState({opcarr:json})
          })
          .catch((e) => {
            swal({
              title: "OOPS!",
              text: window.$language.Somethingwentwrong,
              icon: "warning",
            });

            console.log(e);
          });
      }
    });
  };

  const getdocinfo = (email, mob) => {
    let logourl = API_URL + "/doctor/search";
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("token"),
      },
      body: JSON.stringify({
        email: email,
        mobile: mob,
      }),
    };
    fetch(logourl, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        const filteredDoctors = json.filter(
          (doctor) => !doctor.isExternalDoctor
        );
        setState((prevState) => {
          return { ...prevState, docarr: filteredDoctors };
        });
      })
      .catch((e) => {
        swal({
          title: "OOPS!",
          text: window.$language.Somethingwentwrong,
          icon: "warning",
        });

        console.log(e);
      });
  };

  const getposinfo = (email, mob) => {
    let logourl = API_URL + "/pos/search";
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("token"),
      },
      body: JSON.stringify({
        email: email,
        mobile: mob,
      }),
    };
    fetch(logourl, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        setState((prevState) => {
          return {
            ...prevState,
            posarr: [...json],
            posEmail: state.isExternal == "true" ? json[0].email : "",
            // posEmail: json && json[0].email,
          };
        });
        setPosid(state.isExternal == "true" ? json[0].email : "");
        // setPosid(props.posEmail ? props.posEmail : json[0].email);
      })
      .catch((e) => {
        swal({
          title: "OOPS!",
          text: window.$language.Somethingwentwrong,
          icon: "warning",
        });

        console.log(e);
      });
  };

  const getpinfo = (email, mob) => {
    let logourl = API_URL + "/patient/search";
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("token"),
      },
      body: JSON.stringify({
        email: email,
        mobile: mob,
      }),
    };
    fetch(logourl, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        setState((prevState) => {
          return {
            ...prevState,
            patientarr: [...json],
          };
        });
        var patientdetails = $(json).filter(function (i, n) {
          return n.email === props.patientemail;
        });

        props.patientemail != "" && props.patientemail != undefined
          ? $("#patientnametest").text(
              " [" +
                patientdetails[0].name +
                " " +
                patientdetails[0].lastName +
                "]"
            )
          : $("#patientnametest").text("");

        // localStorage.setItem("patientname",patientdetails)
        console.log("this is setting", props.patientemail, json[0].email);
        setPatientEmail(
          props.patientemail ? props.patientemail : json[0].email
        );
      })
      .catch((e) => {
        swal({
          title: "OOPS!",
          text: window.$language.Somethingwentwrong,
          icon: "warning",
        });

        console.log(e);
      });
  };
  const getopinfo = (email, mob) => {
    let logourl = API_URL + "/optician/search";
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("token"),
      },
      body: JSON.stringify({
        email: email,
        mobile: mob,
      }),
    };
    fetch(logourl, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        setState((prevState) => {
          return { ...prevState, opcarr: json };
        });
        // setopticianEmail(
        //   props.opticianEmail ? props.opticianEmail : json[0].email
        // );
      })
      .catch((e) => {
        swal({
          title: "OOPS!",
          text: window.$language.Somethingwentwrong,
          icon: "warning",
        });

        console.log(e);
      });
  };
  const getLocalEyeTest = (search) => {
    setSearchText(search);
    // geteyet(search);
    // console.log(search,"search");
    // if (search == "") {
    //   geteyet();
    // }
    // setState((prevState) => {
    //   return {
    //     ...prevState,
    //     eyearr: state.eyearr.filter((value) => {
    //       console.log(value["patientName"], search);
    //       let matcher = value["patientName"].toLowerCase();
    //       let matcherOptician = value["opticianName"].toLowerCase();
    //       let matcherPatientsMail = value["patientEmail"].toLowerCase();
    //       return (
    //         matcher.startsWith(search) ||
    //         matcherOptician.startsWith(search) ||
    //         matcherPatientsMail.startsWith(search)
    //       );
    //     }),
    //   };
    // });
  };
  const handleReset = () => {
    setStartDate(""); // Reset startDate to an empty string or default value
    setEndDate(""); // Reset endDate to an empty string or default value
    //setIsLoading(false); // Reset isLoading if needed
    setSearchText("");
    setIsLoading(true);
  };
  useEffect(() => {
    if (isEyeTest) geteyetid(isEyeTest);
    if (isPrescrip) Presglas(isPrescrip);
  }, [isEyeTest, isPrescrip]);
  const geteyet = (search = "") => {
    var newtes = "";
    if (props.unass == 1) {
      newtes = "0";
    } else if (props.assnew == 1) {
      newtes = "1";
    }
    var token = localStorage.getItem("token");
    $("#overlay2").css("display", "block");
    setIsLoading(true);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({
        Status: props.status,
        PatientEmail: props.patientemail ? props.patientemail : "",
        search: searchText,
        pageNumber: pageNumber,
        pageSize: itemsPerPage,
        startDate: startDate,
        endDate: endDate,
        assigUnassigned: newtes,
      }),
    };
    //let logourl = API_URL + "/eyetests/search";
    let logourl = API_URL + "/eyetests/exportEyeTest";
    fetch(logourl, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        console.log(json);
        console.log(props);
        console.log(
          Math.ceil(json.totalItems / itemsPerPage),
          "jkdsajkdsajsdajk"
        );
        setPageCount(Math.ceil(json.totalItems / itemsPerPage));
        let currentDetails = json.eyeTestData.filter(
          (val) => val.testId === state.testno
        );

        if (props.status == "" || props.status == "Approved") {
          setState((prevState) => {
            return { ...prevState, yeararr: json.yearlist };
          });
        }

        //setIsLoading(false)
        console.log("currentADetails", currentDetails);
        //alert("load data")
        if (props.unass == 1) {
          let arr1 = [];
          arr1 = json.eyeTestData;
          setState((prevState) => {
            return { ...prevState, eyearr: arr1 };
          });
          setCurrentItems(arr1);
        } else if (props.assnew == 1) {
          let arr1 = [];
          arr1 = json.eyeTestData;
          setState((prevState) => {
            return { ...prevState, eyearr: arr1 };
          });
          console.log("assignedToDoc=", arr1);
          setCurrentItems(arr1);
        } else if (props.apuny == 1) {
          let arr1 = [];
          arr1 = json.eyeTestData.filter(
            (item) => item.notifiedPatient == "No"
          );
          setState((prevState) => {
            return { ...prevState, eyearr: arr1 };
          });
          setCurrentItems(arr1);

          console.log("unnotified=", arr1);
        } else {
          setState((prevState) => {
            return { ...prevState, eyearr: json.eyeTestData };
          });
          setCurrentItems(json.eyeTestData);
        }
        setIsLoading(false);
      });
  };
  const geteyetid = (testno, idx) => {
    var token = localStorage.getItem("token");
    //setIsLoading(true);
    setLoaderIdx(idx);
    setTimeout(() => {
      setLoaderIdx(-1);
    }, 1000);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({
        eyeTestID: testno,
      }),
    };
    let logourl = API_URL + "/eyetests/searcheyetestnewbyid";
    //let logourl = API_URL + "/eyetests/searchbyid";
    fetch(logourl, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        setState((prevState) => {
          return { ...prevState, eyetesid: json[0] };
        });
        setViewEyeTest(json[0]);
        console.log("eyetid=++++++++", json[0]);
      })
      //console.log(eyetesid,"eyetestdata single")
      .catch((e) => {
        swal({
          title: "OOPS!",
          text: window.$language.Somethingwentwrong,
          icon: "warning",
        });
        console.log(e);
      });
    setIsLoading(false);
  };
  const geteyeteditid = (testno) => {
    var token = localStorage.getItem("token");
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({
        eyeTestID: testno,
      }),
    };
    let logourl = API_URL + "/eyetests/searcheyetestnewbyid";
    //let logourl = API_URL + "/eyetests/searchbyid";
    fetch(logourl, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        setFrameType(json[0].commentForLenses);
        setState((prevState) => {
          return { ...prevState, eyetesid: json[0] };
        });

        modify(json[0]);
      })
      //console.log(eyetesid,"eyetestdata single")
      .catch((e) => {
        swal({
          title: "OOPS!",
          text: window.$language.Somethingwentwrong,
          icon: "warning",
        });
        console.log(e);
      });
  };
  const Presglas = (testno) => {
    var token = localStorage.getItem("token");
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({
        eyeTestID: testno,
      }),
    };
    //let logourl = API_URL + "/eyetests/search";
    //let logourl = API_URL + "/eyetests/searchbyid";
    let logourl = API_URL + "/eyetests/searcheyetestnewbyid";
    fetch(logourl, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        setState((prevState) => {
          return { ...prevState, eyetesid: json[0] };
        });

        pres(json[0]);
      })
      //console.log(eyetesid,"eyetestdata single")
      .catch((e) => {
        swal({
          title: "OOPS!",
          text: window.$language.Somethingwentwrong,
          icon: "warning",
        });
        console.log(e);
      });
  };
  const Preslen = (testno) => {
    var token = localStorage.getItem("token");
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({
        eyeTestID: testno,
      }),
    };
    //let logourl = API_URL + "/eyetests/searchbyid";
    let logourl = API_URL + "/eyetests/searcheyetestnewbyid";
    fetch(logourl, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        setState((prevState) => {
          return { ...prevState, eyetesid: json[0] };
        });

        presl(json[0]);
      })
      //console.log(eyetesid,"eyetestdata single")
      .catch((e) => {
        swal({
          title: "OOPS!",
          text: window.$language.Somethingwentwrong,
          icon: "warning",
        });
        console.log(e);
      });
  };
  const gettestname = () => {
    var token = localStorage.getItem("token");

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    };
    let logourl = API_URL + "/testnames";
    fetch(logourl, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        setState((prevState) => {
          return { ...prevState, testdet: json };
        });
      });
  };
  const checkUncheck = (elementName, allValues) => {
    let Symptoms = {
      0: "FrequentFlashes",
      1: "FrequentFloaters",
      2: "Eyerednesspain",
      3: "SevereHeadaches",
      4: "DoubleVision",
      5: "LossofVision",
      6: "Dizziness",
      7: "Photosensible",
      8: "NoneoftheAbove",
    };
    let dignosed = {
      0: "Amblyopya",
      1: "Strabismus",
      2: "Glaucoma",
      3: "Cataract",
      4: "Macular",
      5: "RetinalDetachment",
      6: "CornealDystrophy",
      7: "NoneDiagnosed",
    };
    let healthconditions = {
      0: "Diabetes",
      1: "Hypertension",
      2: "MultipleSclerosis",
      3: "NoneHealthConditions",
    };
    let value = {};
    switch (elementName) {
      case "Symptoms":
        value = Symptoms;
        break;
      case "diagnosed":
        value = dignosed;
        break;
      case "healthconditions":
        value = healthconditions;
        break;
      default:
        break;
    }
    let element = "";
    console.log("this is allvalues" + allValues);
    for (let i = 0; i < allValues.length; i++) {
      if (allValues[i].trim() != "false") {
        console.log(value[i], allValues[i], "This is Here");
        element = document.getElementsByName(value[i])[0];
        element.checked = true;
      }
    }
  };
  const componentMount = () => {
    if (localStorage.getItem("type") != "PATIENT") {
      getdocinfo("", "");
      getposinfo("", "");
      getpinfo("", "");
      getopinfo("", "");
      gettestname();
    }
    if (isEdit) {
      console.log(props, "Props test");
    }

    geteyet();
    //geteyetid();
    //testExport();
    var eyearr = JSON.parse(localStorage.getItem("eye"));

    //console.log(this.state.eyearr);
    $("#selectAll").click(function () {
      $("input[type=checkbox]").prop("checked", $(this).prop("checked"));
    });

    $("input[type=checkbox]").click(function () {
      if (!$(this).prop("checked")) {
        $("#selectAll").prop("checked", false);
      }
    });
  };

  useEffect(() => {
    if (isLoading) {
      reset();
      componentMount();
    }

    // reset();
    // componentMount();
  }, [isLoading]);
  useEffect(() => {
    // Fetch the value from localStorage during component initialization
    const storedIsExternal = localStorage.getItem("isExternal");
    // Update state with the retrieved value
    setIsExternal(storedIsExternal);
  }, []);
  //useMemo(() => testExport(), []);

  // const loadItems = () => {
  //   // alert("load items called")
  //   const endOffset = itemOffset + itemsPerPage;
  //   console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  //   setCurrentItems(state.eyearr.slice(itemOffset, endOffset));
  //   // setPageCount(Math.ceil(state.eyearr.length / itemsPerPage));
  // };

  useEffect(() => {
    console.log("this is called");
    // if(firstTime){
    console.log("inside called");
    // setValue("posid", state.posEmail);
    setValue("patientemail", state.patientemail);
    // }
  }, [state]);
  // useEffect(() => {
  //   loadItems();
  // }, [itemOffset, itemsPerPage, state]);

  const pres = (det) => {
    ReactDOM.render(<Pre row={det} />, document.getElementById("c11"));
  };
  const presl = (det) => {
    ReactDOM.render(<Prel row={det} />, document.getElementById("c11"));
  };
  const app = () => {
    alert("");
  };

  const groupBy = (array, f) => {
    let groups = {};
    array.forEach((o) => {
      let group = JSON.stringify(f(o));
      groups[group] = groups[group] || {};
      groups[group].key = JSON.parse(group);
      groups[group].values = groups[group].values || [];
      groups[group].values.push(o);
    });
    return Object.keys(groups).map(function (group) {
      return groups[group];
    });
  };

  const getValueFromState = (param) => {
    console.log(param);
    const result = typeof param === "object" ? param.value : param;

    return result;
  };

  const submitFormDetails = async (data) => {
    if (state.isExternal == "true" || state.isExternalDoctor == "true") {
      var arax2 = getValueFromState(data.testDetails[2].arax);
      var arcy2 = getValueFromState(data.testDetails[2].arcy);

      var alcy3 = getValueFromState(data.testDetails[3].alcy);
      var alxy3 = getValueFromState(data.testDetails[3].alax);

      var brcy4 = getValueFromState(data.testDetails[4].brcy);
      var brax4 = getValueFromState(data.testDetails[4].brax);

      var blcy5 = getValueFromState(data.testDetails[5].blcy);
      var blax5 = getValueFromState(data.testDetails[5].blax);

      var crcy6 = getValueFromState(data.testDetails[6].crcy);
      var crax6 = getValueFromState(data.testDetails[6].crax);

      var clcy7 = getValueFromState(data.testDetails[7].clcy);
      var clax7 = getValueFromState(data.testDetails[7].clax);

      if (
        (arcy2 != "0.00" && arax2 == "0.00") ||
        (arcy2 == "0.00" && arax2 != "0.00")
      ) {
        swal({
          title: "OOPS!",
          text: "Attention ! Il n'est pas possible de soumettre un test avec une valeur pour CYL sans ajouter une valeur pour AX et vice versa. Merci de vérifier votre test et de compléter les données manquantes.",
          icon: "warning",
        });
        return;
      }

      if (
        (alcy3 != "0.00" && alxy3 == "0.00") ||
        (alcy3 == "0.00" && alxy3 != "0.00")
      ) {
        swal({
          title: "OOPS!",
          text: "Attention ! Il n'est pas possible de soumettre un test avec une valeur pour CYL sans ajouter une valeur pour AX et vice versa. Merci de vérifier votre test et de compléter les données manquantes.",
          icon: "warning",
        });
        return;
      }
      if (
        (brcy4 != "0.00" && brax4 == "0.00") ||
        (brcy4 == "0.00" && brax4 != "0.00")
      ) {
        swal({
          title: "OOPS!",
          text: "Attention ! Il n'est pas possible de soumettre un test avec une valeur pour CYL sans ajouter une valeur pour AX et vice versa. Merci de vérifier votre test et de compléter les données manquantes.",
          icon: "warning",
        });
        return;
      }
      if (
        (blcy5 != "0.00" && blax5 == "0.00") ||
        (blcy5 == "0.00" && blax5 != "0.00")
      ) {
        swal({
          title: "OOPS!",
          text: "Attention ! Il n'est pas possible de soumettre un test avec une valeur pour CYL sans ajouter une valeur pour AX et vice versa. Merci de vérifier votre test et de compléter les données manquantes.",
          icon: "warning",
        });
        return;
      }
      if (
        (crcy6 != "0.00" && crax6 == "0.00") ||
        (crcy6 == "0.00" && crax6 != "0.00")
      ) {
        swal({
          title: "OOPS!",
          text: "Attention ! Il n'est pas possible de soumettre un test avec une valeur pour CYL sans ajouter une valeur pour AX et vice versa. Merci de vérifier votre test et de compléter les données manquantes.",
          icon: "warning",
        });
        return;
      }
      if (
        (clcy7 != "0.00" && clax7 == "0.00") ||
        (clcy7 == "0.00" && clax7 != "0.00")
      ) {
        swal({
          title: "OOPS!",
          text: "Attention ! Il n'est pas possible de soumettre un test avec une valeur pour CYL sans ajouter une valeur pour AX et vice versa. Merci de vérifier votre test et de compléter les données manquantes.",
          icon: "warning",
        });
        return;
      }
      let allValues = [
        getValueFromState(data.testDetails[4].brsh),
        getValueFromState(data.testDetails[4].brcy),
        getValueFromState(data.testDetails[4].brax),
        getValueFromState(data.testDetails[4].brad),
        getValueFromState(data.testDetails[4].brpd),
        getValueFromState(data.testDetails[5].blsh),
        getValueFromState(data.testDetails[5].blcy),
        getValueFromState(data.testDetails[5].blax),
        getValueFromState(data.testDetails[5].blad),
        getValueFromState(data.testDetails[5].blpd),
      ];
      console.log(allValues);
      let valuereturned =
        allValues.filter((val) => val != "0.00").length > 0
          ? ""
          : swal({
              title: "OOPS!",
              text: " Les valeurs de Réfraction objective (autoref), Réfraction subjective (manuelle) et recommandation opticien finale ne peuvent être égales à 0. Vous devez entrer une valeur pour soumettre le test de vue.",
              icon: "warning",
            });
      let allValuesr = [
        getValueFromState(data.testDetails[2].arsh),
        getValueFromState(data.testDetails[2].arcy),
        getValueFromState(data.testDetails[2].arax),
        getValueFromState(data.testDetails[2].arad),
        getValueFromState(data.testDetails[2].arpd),
        getValueFromState(data.testDetails[3].alsh),
        getValueFromState(data.testDetails[3].alcy),
        getValueFromState(data.testDetails[3].alax),
        getValueFromState(data.testDetails[3].alad),
        getValueFromState(data.testDetails[3].alpd),
      ];
      console.log(allValuesr);
      let valuereturnedr =
        allValuesr.filter((val) => val != "0.00").length > 0
          ? ""
          : swal({
              title: "OOPS!",
              text: " Les valeurs de Réfraction objective (autoref), Réfraction subjective (manuelle) et recommandation opticien finale ne peuvent être égales à 0. Vous devez entrer une valeur pour soumettre le test de vue.",
              icon: "warning",
            });
      let allValuesf = [
        getValueFromState(data.testDetails[6].crsh),
        getValueFromState(data.testDetails[6].crcy),
        getValueFromState(data.testDetails[6].crax),
        getValueFromState(data.testDetails[6].crad),
        getValueFromState(data.testDetails[6].crpd),
        getValueFromState(data.testDetails[7].clsh),
        getValueFromState(data.testDetails[7].clcy),
        getValueFromState(data.testDetails[7].clax),
        getValueFromState(data.testDetails[7].clad),
        getValueFromState(data.testDetails[7].clpd),
      ];
      let valuereturnedf =
        allValuesf.filter((val) => val != "0.00").length > 0
          ? ""
          : swal({
              title: "OOPS!",
              text: "Les valeurs de Réfraction objective (autoref), Réfraction subjective (manuelle) et recommandation opticien finale ne peuvent être égales à 0. Vous devez entrer une valeur pour soumettre le test de vue.",
              icon: "warning",
            });

      if (valuereturned != "" || valuereturnedf != "" || valuereturnedr != "")
        return;
      let manualUniqueCodeFormat = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
      if (data.avdprg == "") {
        swal({
          title: "OOPS!",
          text: "Les champs pour acuité vision de près et vision de loin ne peuvent pas être vides.",
          icon: "warning",
        });
        return;
      } else if (data.avdplg == "") {
        swal({
          title: "OOPS!",
          text: "Les champs pour acuité vision de près et vision de loin ne peuvent pas être vides.",
          icon: "warning",
        });
        return;
      } else if (data.avdlrg == "") {
        swal({
          title: "OOPS!",
          text: "Les champs pour acuité vision de près et vision de loin ne peuvent pas être vides.",
          icon: "warning",
        });
        return;
      } else if (data.avdllg == "") {
        swal({
          title: "OOPS!",
          text: "Les champs pour acuité vision de près et vision de loin ne peuvent pas être vides.",
          icon: "warning",
        });
        return;
      }
      if (data.ndp == "") {
        swal({
          title: "OOPS!",
          text: "Merci d'indiquer si une ordonnance est requise ou non pour ce test de vue.",
          icon: "warning",
        });
        return;
      }
    }
    if (
      $("#resultExamine").val() == "Juste ordonnance" ||
      $("#resultExamine").val() == "Vente" ||
      $("#resultExamine").val() == "Devis"
    ) {
      var arax2 = getValueFromState(data.testDetails[2].arax);
      var arcy2 = getValueFromState(data.testDetails[2].arcy);

      var alcy3 = getValueFromState(data.testDetails[3].alcy);
      var alxy3 = getValueFromState(data.testDetails[3].alax);

      var brcy4 = getValueFromState(data.testDetails[4].brcy);
      var brax4 = getValueFromState(data.testDetails[4].brax);

      var blcy5 = getValueFromState(data.testDetails[5].blcy);
      var blax5 = getValueFromState(data.testDetails[5].blax);

      var crcy6 = getValueFromState(data.testDetails[6].crcy);
      var crax6 = getValueFromState(data.testDetails[6].crax);

      var clcy7 = getValueFromState(data.testDetails[7].clcy);
      var clax7 = getValueFromState(data.testDetails[7].clax);

      if (
        (arcy2 != "0.00" && arax2 == "0.00") ||
        (arcy2 == "0.00" && arax2 != "0.00")
      ) {
        swal({
          title: "OOPS!",
          text: "Attention ! Il n'est pas possible de soumettre un test avec une valeur pour CYL sans ajouter une valeur pour AX et vice versa. Merci de vérifier votre test et de compléter les données manquantes.",
          icon: "warning",
        });
        return;
      }

      if (
        (alcy3 != "0.00" && alxy3 == "0.00") ||
        (alcy3 == "0.00" && alxy3 != "0.00")
      ) {
        swal({
          title: "OOPS!",
          text: "Attention ! Il n'est pas possible de soumettre un test avec une valeur pour CYL sans ajouter une valeur pour AX et vice versa. Merci de vérifier votre test et de compléter les données manquantes.",
          icon: "warning",
        });
        return;
      }
      if (
        (brcy4 != "0.00" && brax4 == "0.00") ||
        (brcy4 == "0.00" && brax4 != "0.00")
      ) {
        swal({
          title: "OOPS!",
          text: "Attention ! Il n'est pas possible de soumettre un test avec une valeur pour CYL sans ajouter une valeur pour AX et vice versa. Merci de vérifier votre test et de compléter les données manquantes.",
          icon: "warning",
        });
        return;
      }
      if (
        (blcy5 != "0.00" && blax5 == "0.00") ||
        (blcy5 == "0.00" && blax5 != "0.00")
      ) {
        swal({
          title: "OOPS!",
          text: "Attention ! Il n'est pas possible de soumettre un test avec une valeur pour CYL sans ajouter une valeur pour AX et vice versa. Merci de vérifier votre test et de compléter les données manquantes.",
          icon: "warning",
        });
        return;
      }
      if (
        (crcy6 != "0.00" && crax6 == "0.00") ||
        (crcy6 == "0.00" && crax6 != "0.00")
      ) {
        swal({
          title: "OOPS!",
          text: "Attention ! Il n'est pas possible de soumettre un test avec une valeur pour CYL sans ajouter une valeur pour AX et vice versa. Merci de vérifier votre test et de compléter les données manquantes.",
          icon: "warning",
        });
        return;
      }
      if (
        (clcy7 != "0.00" && clax7 == "0.00") ||
        (clcy7 == "0.00" && clax7 != "0.00")
      ) {
        swal({
          title: "OOPS!",
          text: "Attention ! Il n'est pas possible de soumettre un test avec une valeur pour CYL sans ajouter une valeur pour AX et vice versa. Merci de vérifier votre test et de compléter les données manquantes.",
          icon: "warning",
        });
        return;
      }
      let allValues = [
        getValueFromState(data.testDetails[4].brsh),
        getValueFromState(data.testDetails[4].brcy),
        getValueFromState(data.testDetails[4].brax),
        getValueFromState(data.testDetails[4].brad),
        getValueFromState(data.testDetails[4].brpd),
        getValueFromState(data.testDetails[5].blsh),
        getValueFromState(data.testDetails[5].blcy),
        getValueFromState(data.testDetails[5].blax),
        getValueFromState(data.testDetails[5].blad),
        getValueFromState(data.testDetails[5].blpd),
      ];
      console.log(allValues);
      let valuereturned =
        allValues.filter((val) => val != "0.00").length > 0
          ? ""
          : swal({
              title: "OOPS!",
              text: " Les valeurs de Réfraction objective (autoref), Réfraction subjective (manuelle) et recommandation opticien finale ne peuvent être égales à 0. Vous devez entrer une valeur pour soumettre le test de vue.",
              icon: "warning",
            });
      let allValuesr = [
        getValueFromState(data.testDetails[2].arsh),
        getValueFromState(data.testDetails[2].arcy),
        getValueFromState(data.testDetails[2].arax),
        getValueFromState(data.testDetails[2].arad),
        getValueFromState(data.testDetails[2].arpd),
        getValueFromState(data.testDetails[3].alsh),
        getValueFromState(data.testDetails[3].alcy),
        getValueFromState(data.testDetails[3].alax),
        getValueFromState(data.testDetails[3].alad),
        getValueFromState(data.testDetails[3].alpd),
      ];
      console.log(allValuesr);
      let valuereturnedr =
        allValuesr.filter((val) => val != "0.00").length > 0
          ? ""
          : swal({
              title: "OOPS!",
              text: " Les valeurs de Réfraction objective (autoref), Réfraction subjective (manuelle) et recommandation opticien finale ne peuvent être égales à 0. Vous devez entrer une valeur pour soumettre le test de vue.",
              icon: "warning",
            });
      let allValuesf = [
        getValueFromState(data.testDetails[6].crsh),
        getValueFromState(data.testDetails[6].crcy),
        getValueFromState(data.testDetails[6].crax),
        getValueFromState(data.testDetails[6].crad),
        getValueFromState(data.testDetails[6].crpd),
        getValueFromState(data.testDetails[7].clsh),
        getValueFromState(data.testDetails[7].clcy),
        getValueFromState(data.testDetails[7].clax),
        getValueFromState(data.testDetails[7].clad),
        getValueFromState(data.testDetails[7].clpd),
      ];
      let valuereturnedf =
        allValuesf.filter((val) => val != "0.00").length > 0
          ? ""
          : swal({
              title: "OOPS!",
              text: "Les valeurs de Réfraction objective (autoref), Réfraction subjective (manuelle) et recommandation opticien finale ne peuvent être égales à 0. Vous devez entrer une valeur pour soumettre le test de vue.",
              icon: "warning",
            });

      if (valuereturned != "" || valuereturnedf != "" || valuereturnedr != "")
        return;
      let manualUniqueCodeFormat = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
      if (data.avdprg == "") {
        swal({
          title: "OOPS!",
          text: "Les champs pour acuité vision de près et vision de loin ne peuvent pas être vides.",
          icon: "warning",
        });
        return;
      } else if (data.avdplg == "") {
        swal({
          title: "OOPS!",
          text: "Les champs pour acuité vision de près et vision de loin ne peuvent pas être vides.",
          icon: "warning",
        });
        return;
      } else if (data.avdlrg == "") {
        swal({
          title: "OOPS!",
          text: "Les champs pour acuité vision de près et vision de loin ne peuvent pas être vides.",
          icon: "warning",
        });
        return;
      } else if (data.avdllg == "") {
        swal({
          title: "OOPS!",
          text: "Les champs pour acuité vision de près et vision de loin ne peuvent pas être vides.",
          icon: "warning",
        });
        return;
      }
      if (data.ndp == "") {
        swal({
          title: "OOPS!",
          text: "Merci d'indiquer si une ordonnance est requise ou non pour ce test de vue.",
          icon: "warning",
        });
        return;
      }
      if ($("#imgret").val() == "No" && $("#specify").val() == "") {
        swal({
          title: "OOPS!",
          text: " Attention ! Merci de bien vouloir justifier l’absence de photos rétino. ",
          icon: "warning",
        });
        return;
      }
      if ($("#imgret").val() == "") {
        swal({
          title: "OOPS!",
          text: "Merci de bien vouloir préciser s’il y a eu prise d’image de fonds d’œil. ",
          icon: "warning",
        });
        return;
      }
    }
    if (!checked && data.lastTimeDoc == "") {
      swal({
        title: "OOPS!",
        text: "Merci de renseigner la date ou de cocher la case.",
        icon: "warning",
      });
      return;
    }
    if ($("#resultExamine").val() == "") {
      swal({
        title: "OOPS!",
        text: "Merci de préciser le résultat de le test de vue",
        icon: "warning",
      });
      return;
    }
    console.log("this is the data", data);
    let dataToSend = {};
    dataToSend.testno = isEdit ? state.eyetestid1 : "0";
    data.testDetails[0].TestType = "Test au fronto";
    data.testDetails[0].sph = getValueFromState(data.testDetails[0].rsh);
    delete data.testDetails[0].rsh;
    data.testDetails[0].cyl = getValueFromState(data.testDetails[0].rcy);
    delete data.testDetails[0].rcy;
    data.testDetails[0].axe = getValueFromState(data.testDetails[0].rax);
    delete data.testDetails[0].rax;
    data.testDetails[0].add = getValueFromState(data.testDetails[0].rad);
    delete data.testDetails[0].rad;
    data.testDetails[0].pd = getValueFromState(data.testDetails[0].rpd);
    delete data.testDetails[0].rpd;
    data.testDetails[1].TestType = "Test au fronto";
    data.testDetails[1].sph = getValueFromState(data.testDetails[1].lsh);
    delete data.testDetails[1].lsh;
    data.testDetails[1].cyl = getValueFromState(data.testDetails[1].lcy);
    delete data.testDetails[1].lcy;
    data.testDetails[1].axe = getValueFromState(data.testDetails[1].lax);
    delete data.testDetails[1].lax;
    data.testDetails[1].add = getValueFromState(data.testDetails[1].lad);
    delete data.testDetails[1].lad;
    data.testDetails[1].pd = getValueFromState(data.testDetails[1].lpd);
    delete data.testDetails[1].lpd;
    data.testDetails[2].TestType = "Auto ref";
    data.testDetails[2].sph = getValueFromState(data.testDetails[2].arsh);
    delete data.testDetails[2].arsh;
    data.testDetails[2].cyl = getValueFromState(data.testDetails[2].arcy);
    delete data.testDetails[2].arcy;
    data.testDetails[2].axe = getValueFromState(data.testDetails[2].arax);
    delete data.testDetails[2].arax;
    data.testDetails[2].add = getValueFromState(data.testDetails[2].arad);
    delete data.testDetails[2].arad;
    data.testDetails[2].pd = getValueFromState(data.testDetails[2].arpd);
    delete data.testDetails[2].arpd;
    data.testDetails[3].TestType = "Auto ref";
    data.testDetails[3].sph = getValueFromState(data.testDetails[3].alsh);
    delete data.testDetails[3].alsh;
    data.testDetails[3].cyl = getValueFromState(data.testDetails[3].alcy);
    delete data.testDetails[3].alcy;
    data.testDetails[3].axe = getValueFromState(data.testDetails[3].alax);
    delete data.testDetails[3].alax;
    data.testDetails[3].add = getValueFromState(data.testDetails[3].alad);
    delete data.testDetails[3].alad;
    data.testDetails[3].pd = getValueFromState(data.testDetails[3].alpd);
    delete data.testDetails[3].alpd;
    data.testDetails[4].TestType = "Réfraction subjective (manuelle)";
    data.testDetails[4].sph = getValueFromState(data.testDetails[4].brsh);
    delete data.testDetails[4].brsh;
    data.testDetails[4].cyl = getValueFromState(data.testDetails[4].brcy);
    delete data.testDetails[4].brcy;
    data.testDetails[4].axe = getValueFromState(data.testDetails[4].brax);
    delete data.testDetails[4].brax;
    data.testDetails[4].add = getValueFromState(data.testDetails[4].brad);
    delete data.testDetails[4].brad;
    data.testDetails[4].pd = getValueFromState(data.testDetails[4].brpd);
    delete data.testDetails[4].brpd;
    data.testDetails[5].TestType = "Réfraction subjective (manuelle)";
    data.testDetails[5].sph = getValueFromState(data.testDetails[5].blsh);
    delete data.testDetails[5].blsh;
    data.testDetails[5].cyl = getValueFromState(data.testDetails[5].blcy);
    delete data.testDetails[5].blcy;
    data.testDetails[5].axe = getValueFromState(data.testDetails[5].blax);
    delete data.testDetails[5].blax;
    data.testDetails[5].add = getValueFromState(data.testDetails[5].blad);
    delete data.testDetails[5].blad;
    data.testDetails[5].pd = getValueFromState(data.testDetails[5].blpd);
    delete data.testDetails[5].blpd;
    data.testDetails[6].TestType = "Reco opticien";
    data.testDetails[6].sph = getValueFromState(data.testDetails[6].crsh);
    delete data.testDetails[6].crsh;
    data.testDetails[6].cyl = getValueFromState(data.testDetails[6].crcy);
    delete data.testDetails[6].crcy;
    data.testDetails[6].axe = getValueFromState(data.testDetails[6].crax);
    delete data.testDetails[6].crax;
    data.testDetails[6].add = getValueFromState(data.testDetails[6].crad);
    delete data.testDetails[6].crad;
    data.testDetails[6].pd = getValueFromState(data.testDetails[6].crpd);
    delete data.testDetails[6].crpd;
    data.testDetails[7].TestType = "Reco opticien";
    data.testDetails[7].sph = getValueFromState(data.testDetails[7].clsh);
    delete data.testDetails[7].clsh;
    data.testDetails[7].cyl = getValueFromState(data.testDetails[7].clcy);
    delete data.testDetails[7].clcy;
    data.testDetails[7].axe = getValueFromState(data.testDetails[7].clax);
    delete data.testDetails[7].clax;
    data.testDetails[7].add = getValueFromState(data.testDetails[7].clad);
    delete data.testDetails[7].clad;
    data.testDetails[7].pd = getValueFromState(data.testDetails[7].clpd);
    delete data.testDetails[7].clpd;
    data.testDetails[0].whichEye = "right";
    data.testDetails[1].whichEye = "left";
    data.testDetails[2].whichEye = "right";
    data.testDetails[3].whichEye = "left";
    data.testDetails[4].whichEye = "right";
    data.testDetails[5].whichEye = "left";
    data.testDetails[6].whichEye = "right";
    data.testDetails[7].whichEye = "left";
    data.testDetails[0].orderNo = 1;
    data.testDetails[1].orderNo = 2;
    data.testDetails[2].orderNo = 3;
    data.testDetails[3].orderNo = 4;
    data.testDetails[4].orderNo = 5;
    data.testDetails[5].orderNo = 6;
    data.testDetails[6].orderNo = 7;
    data.testDetails[7].orderNo = 8;
    dataToSend.testDetails = data.testDetails;
    dataToSend.opticianEmail =
      (localStorage.getItem("opmail") && state.utype == "BACKOFFICE") ||
      state.utype == "ADMIN"
        ? opticianEmail
        : localStorage.getItem("opmail");
    dataToSend.patientEmail = isEdit ? state.patientemail : patientemail;
    dataToSend.posEmail =
      state.isExternalDoctor == "true"
        ? localStorage.getItem("opmail")
        : posEmail;
    dataToSend.needPrescription =
      data.ndp == "true" || data.ndp == true ? true : false;
    dataToSend.acteGratuit =
      data.Acte == true || data.Acte == "true" ? true : false;
    dataToSend.info_Acuity_Near_Vision_Right_Eye = data.avdprg;
    dataToSend.info_Acuity_Near_Vision_Left_Eye = data.avdplg;
    dataToSend.info_Acuity_Distance_Vision_Right_Eye = data.avdlrg;
    dataToSend.info_Acuity_Distance_Vision_Left_Eye = data.avdllg;
    dataToSend.info_Acuity_visual_sans_correction_Right_Eye = data.avscrg;
    dataToSend.info_Acuity_visual_sans_correction_Left_Eye = data.avsclg;
    dataToSend.info_Eye_Pressure_Right_Eye = data.aprg;
    dataToSend.info_Eye_Pressure_Left_Eye = data.aplg;
    dataToSend.IsMutualCompany = data.Nomdela;
    dataToSend.testNameId = 1;
    dataToSend.glasses = 0;
    dataToSend.status = "New";
    dataToSend.name = "";
    dataToSend.LastTimeWentToEyeDoc = data.lastTimeDoc;
    dataToSend.noteOpt = data.noteOpt;
    dataToSend.commentForLenses = isEditType == 1 ? state.comlen : null;
    dataToSend.commentForLenses == null
      ? delete dataToSend.commentForLenses
      : "";
    dataToSend.symptoms = isEdit
      ? state.symptoms
      : `${data.FrequentFlashes},${data.FrequentFloaters},${data.Eyerednesspain},${data.SevereHeadaches},${data.DoubleVision},${data.LossofVision},${data.Dizziness},${data.Photosensible},${data.NoneoftheAbove}`;
    dataToSend.Dignosedwith = isEdit
      ? state.dignosedwith
      : `${data.Amblyopya},${data.Strabismus},${data.Glaucoma},${data.Cataract},${data.Macular},${data.RetinalDetachment},${data.CornealDystrophy},${data.NoneDiagnosed} `;
    dataToSend.HealthConditions = isEdit
      ? state.healthConditions
      : `${data.Diabetes},${data.Hypertension},${data.MultipleSclerosis},${data.NoneHealthConditions}`;
    dataToSend.isPrescriptionDownload = false;
    dataToSend.isContactLensType = false;
    dataToSend.commentsForPrescription = data.CommPrescrip;
    dataToSend.commentaireInterne = data.commentaireinte;
    dataToSend.testUniqueCode = data.testUniqueCode;
    (dataToSend.isImageRetino = $("#imgret").val()),
      (dataToSend.imageRetinoSpecification = $("#specify").val()),
      (dataToSend.resultExamine = $("#resultExamine").val()),
      (dataToSend.InfoSubmitTrack = "Web");
    dataToSend.imagesVues = $('input[name="imagesVues"]:checked').val();
    dataToSend.infoPrescriptionToOptician =
      $('input[name="infoPrescriptionoptician"]:checked').val() === "true";
    console.log("dataToSend", dataToSend);
    console.log("data", data);

    if (
      $("#resultExamine").val() == "Juste ordonnance" ||
      $("#resultExamine").val() == "Vente" ||
      $("#resultExamine").val() == "Devis"
    ) {
      if (
        dataToSend.HealthConditions.split(",").every(
          (val) => val == "false" || val == ""
        )
      ) {
        swal({
          title: "OOPS!",
          text: "Il n'est pas possible de soumettre le test de vue sans sélectionner au moins un élément de la liste des symptômes.",
          icon: "warning",
        });
        return;
      } else if (
        dataToSend.symptoms
          .split(",")
          .every((val) => val == "false" || val == "")
      ) {
        swal({
          title: "OOPS!",
          text: "Il n'est pas possible de soumettre le test de vue sans sélectionner au moins un élément de la liste des symptômes.",
          icon: "warning",
        });
        return;
      } else if (
        dataToSend.Dignosedwith.split(",").every(
          (val) => val == "false" || val == ""
        )
      ) {
        swal({
          title: "OOPS!",
          text: "Il n'est pas possible de soumettre le test de vue sans sélectionner au moins un élément de la liste des symptômes.",
          icon: "warning",
        });
        return;
      }
    }
    if (state.isExternal == "true" || state.isExternalDoctor == "true") {
      if (
        dataToSend.HealthConditions.split(",").every(
          (val) => val == "false" || val == ""
        )
      ) {
        swal({
          title: "OOPS!",
          text: "Il n'est pas possible de soumettre le test de vue sans sélectionner au moins un élément de la liste des symptômes.",
          icon: "warning",
        });
        return;
      } else if (
        dataToSend.symptoms
          .split(",")
          .every((val) => val == "false" || val == "")
      ) {
        swal({
          title: "OOPS!",
          text: "Il n'est pas possible de soumettre le test de vue sans sélectionner au moins un élément de la liste des symptômes.",
          icon: "warning",
        });
        return;
      } else if (
        dataToSend.Dignosedwith.split(",").every(
          (val) => val == "false" || val == ""
        )
      ) {
        swal({
          title: "OOPS!",
          text: "Il n'est pas possible de soumettre le test de vue sans sélectionner au moins un élément de la liste des symptômes.",
          icon: "warning",
        });
        return;
      }
    }
    if (dataToSend.posEmail === "") {
      swal({
        title: "OOPS!",
        text: "Attention ! Veuillez sélectionner un point de vente.",
        icon: "warning",
      });
      return;
    }
    if (dataToSend.opticianEmail == "") {
      swal({
        title: "OOPS!",
        text: "Attention ! Veuillez sélectionner un opticien.",
        icon: "warning",
      });
      return;
    }
    if (
      dataToSend.infoPrescriptionToOptician == undefined ||
      dataToSend.infoPrescriptionToOptician == false
    ) {
      swal({
        title: "OOPS!",
        text: "Merci de cocher la case",
        icon: "warning",
      });
      return;
    }
    const endUrl = isEdit ? "/eyetests/modify" : "/eyetests/add";
    try {
      let logourl = API_URL + endUrl;
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.getItem("token"),
        },
        body: JSON.stringify(dataToSend),
      };
      const content = document.createElement("div");
      const input = document.createElement("img");
      input.src =
        "https://miro.medium.com/max/875/1*CsJ05WEGfunYMLGfsT2sXA.gif";
      input.width = "250";
      content.appendChild(input);
      // alert("before");
      swal({
        text: window.$language.PleaseWait,

        allowOutsideClick: false,
        buttons: false,
        content,
      });
      //   const AddInfo = getValues(["testDetails.2.arad","testDetails.3.alad","testDetails.4.brad","testDetails.5.blad","testDetails.6.crad","testDetails.7.clad"])
      //   if((AddInfo[0]==='0.00'||AddInfo[1]==='0.00'||AddInfo[2]==='0.00'||AddInfo[3]==='0.00'||AddInfo[4]==='0.00'||AddInfo[5]==='0.00')&&proceed){
      //   const process = await swal({
      //     title: "OOPS!",
      //     text: "You have not selected All ADD input field",
      //     icon: "warning",
      //     dangerMode: true,
      //   })
      //   if(process)
      //   return setProceed(false);
      // }
      const AddInfo = getValues([
        "testDetails.2.arad",
        "testDetails.3.alad",
        "testDetails.4.brad",
        "testDetails.5.blad",
        "testDetails.6.crad",
        "testDetails.7.clad",
      ]);
      if (
        ((AddInfo[0] !== "0.00" && AddInfo[1] === "0.00") ||
          (AddInfo[0] === "0.00" && AddInfo[1] !== "0.00") ||
          (AddInfo[2] !== "0.00" && AddInfo[3] === "0.00") ||
          (AddInfo[2] === "0.00" && AddInfo[3] !== "0.00") ||
          (AddInfo[4] !== "0.00" && AddInfo[5] === "0.00") ||
          (AddInfo[4] === "0.00" && AddInfo[5] !== "0.00")) &&
        proceed
      ) {
        const process = await swal({
          title: "OOPS!",
          text: "Attention ! Les valeurs ADD ne sont pas identiques pour les deux yeux. Merci de bien vouloir revérifier vos valeurs. Si c’est le cas et qu’il s’agit bien de deux valeurs différentes, vous pouvez cliquer à nouveau sur “soumettre” pour confirmer le test.",
          icon: "warning",
          dangerMode: true,
        });
        if (process) return setProceed(false);
      }
      const response = await fetch(logourl, requestOptions);
      let result = await response.text();

      result = result.toString();
      console.log(response.body, "response.body");

      if (result == "DUPLICATE") {
        swal({
          title: "OOPS!",
          text: "Manual Id must be unique",
          icon: "warning",
        });
        return;
      }

      if (!isEdit) {
        await fileUpload(result, state.imageValue, 1);
        await fileUpload(result, state.imageValue2, 2);
        await fileUpload(result, state.imageValue3, 3);
        await fileUpload(result, state.imageValue4, 4);
      }
      // alert("Hello")
      //   this.setState({posarr:json})
      swal({
        title: "Fait ! ",
        text: window.$language.AddedSuccessfully,
        icon: "success",
      });
      console.log("Before Component");
      componentMount();
      reset();

      console.log("After Component");
      document.getElementById("close123").click();
    } catch (e) {
      (e) => {
        swal({
          title: "OOPS!",
          text: window.$language.Somethingwentwrong,
          icon: "warning",
        });

        console.log(e);
      };
    }
  };
  console.log("This is State.acte --> ", state.Acte);
  const submitForm1Details = async (data) => {
    if (state.isExternal == "true" || state.isExternalDoctor == "true") {
      var arax2 = getValueFromState(data.testDetails[2].arax);
      var arcy2 = getValueFromState(data.testDetails[2].arcy);

      var alcy3 = getValueFromState(data.testDetails[3].alcy);
      var alxy3 = getValueFromState(data.testDetails[3].alax);

      var brcy4 = getValueFromState(data.testDetails[4].brcy);
      var brax4 = getValueFromState(data.testDetails[4].brax);

      var blcy5 = getValueFromState(data.testDetails[5].blcy);
      var blax5 = getValueFromState(data.testDetails[5].blax);

      var crcy6 = getValueFromState(data.testDetails[6].crcy);
      var crax6 = getValueFromState(data.testDetails[6].crax);

      var clcy7 = getValueFromState(data.testDetails[7].clcy);
      var clax7 = getValueFromState(data.testDetails[7].clax);

      if (
        (arcy2 != "0.00" && arax2 == "0.00") ||
        (arcy2 == "0.00" && arax2 != "0.00")
      ) {
        swal({
          title: "OOPS!",
          text: "Attention ! Il n'est pas possible de soumettre un test avec une valeur pour CYL sans ajouter une valeur pour AX et vice versa. Merci de vérifier votre test et de compléter les données manquantes.",
          icon: "warning",
        });
        return;
      }

      if (
        (alcy3 != "0.00" && alxy3 == "0.00") ||
        (alcy3 == "0.00" && alxy3 != "0.00")
      ) {
        swal({
          title: "OOPS!",
          text: "Attention ! Il n'est pas possible de soumettre un test avec une valeur pour CYL sans ajouter une valeur pour AX et vice versa. Merci de vérifier votre test et de compléter les données manquantes.",
          icon: "warning",
        });
        return;
      }
      if (
        (brcy4 != "0.00" && brax4 == "0.00") ||
        (brcy4 == "0.00" && brax4 != "0.00")
      ) {
        swal({
          title: "OOPS!",
          text: "Attention ! Il n'est pas possible de soumettre un test avec une valeur pour CYL sans ajouter une valeur pour AX et vice versa. Merci de vérifier votre test et de compléter les données manquantes.",
          icon: "warning",
        });
        return;
      }
      if (
        (blcy5 != "0.00" && blax5 == "0.00") ||
        (blcy5 == "0.00" && blax5 != "0.00")
      ) {
        swal({
          title: "OOPS!",
          text: "Attention ! Il n'est pas possible de soumettre un test avec une valeur pour CYL sans ajouter une valeur pour AX et vice versa. Merci de vérifier votre test et de compléter les données manquantes.",
          icon: "warning",
        });
        return;
      }
      if (
        (crcy6 != "0.00" && crax6 == "0.00") ||
        (crcy6 == "0.00" && crax6 != "0.00")
      ) {
        swal({
          title: "OOPS!",
          text: "Attention ! Il n'est pas possible de soumettre un test avec une valeur pour CYL sans ajouter une valeur pour AX et vice versa. Merci de vérifier votre test et de compléter les données manquantes.",
          icon: "warning",
        });
        return;
      }
      if (
        (clcy7 != "0.00" && clax7 == "0.00") ||
        (clcy7 == "0.00" && clax7 != "0.00")
      ) {
        swal({
          title: "OOPS!",
          text: "Attention ! Il n'est pas possible de soumettre un test avec une valeur pour CYL sans ajouter une valeur pour AX et vice versa. Merci de vérifier votre test et de compléter les données manquantes.",
          icon: "warning",
        });
        return;
      }
      let allValuesr = [
        getValueFromState(data.testDetails[2].arsh),
        getValueFromState(data.testDetails[2].arcy),
        getValueFromState(data.testDetails[2].arax),
        getValueFromState(data.testDetails[2].arad),
        getValueFromState(data.testDetails[3].alsh),
        getValueFromState(data.testDetails[3].alcy),
        getValueFromState(data.testDetails[3].alax),
        getValueFromState(data.testDetails[3].alad),
      ];
      let valuereturnedr =
        allValuesr.filter((val) => val != "0.00").length > 0
          ? ""
          : swal({
              title: "OOPS!",
              text: " Les valeurs de Réfraction objective (autoref), Réfraction subjective (manuelle) et recommandation opticien finale ne peuvent être égales à 0. Vous devez entrer une valeur pour soumettre le test de vue.",
              icon: "warning",
            });
      let allValues = [
        getValueFromState(data.testDetails[4].brsh),
        getValueFromState(data.testDetails[4].brcy),
        getValueFromState(data.testDetails[4].brax),
        getValueFromState(data.testDetails[4].brad),
        getValueFromState(data.testDetails[5].blsh),
        getValueFromState(data.testDetails[5].blcy),
        getValueFromState(data.testDetails[5].blax),
        getValueFromState(data.testDetails[5].blad),
      ];
      let valuereturned =
        allValues.filter((val) => val != "0.00").length > 0
          ? ""
          : swal({
              title: "OOPS!",
              text: " Les valeurs de Réfraction objective (autoref), Réfraction subjective (manuelle) et recommandation opticien finale ne peuvent être égales à 0. Vous devez entrer une valeur pour soumettre le test de vue.",
              icon: "warning",
            });

      let allValuesf = [
        getValueFromState(data.testDetails[6].crsh),
        getValueFromState(data.testDetails[6].crcy),
        getValueFromState(data.testDetails[6].crax),
        getValueFromState(data.testDetails[6].crad),
        getValueFromState(data.testDetails[7].clsh),
        getValueFromState(data.testDetails[7].clcy),
        getValueFromState(data.testDetails[7].clax),
        getValueFromState(data.testDetails[7].clad),
      ];
      let manualUniqueCodeFormat = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
      let valuereturnedf =
        allValuesf.filter((val) => val != "0.00").length > 0
          ? ""
          : swal({
              title: "OOPS!",
              text: " Les valeurs de Réfraction objective (autoref), Réfraction subjective (manuelle) et recommandation opticien finale ne peuvent être égales à 0. Vous devez entrer une valeur pour soumettre le test de vue.",
              icon: "warning",
            });

      if (state.imageValue === null) {
        swal({
          title: "OOPS!",
          text: "L'examen de la vue ne peut pas être soumis sans le téléchargement d'une ordonnance précédente.",
          icon: "warning",
        });
        return;
      } else if (data.comlen == "") {
        swal({
          title: "OOPS!",
          text: "L’examen de vue ne peut pas être soumis sans les specification lentilles.",
          icon: "warning",
        });
        return;
      } else if (data.avdprl == "") {
        swal({
          title: "OOPS!",
          text: "Les champs pour acuité vision de près et vision de loin ne peuvent pas être vides.",
          icon: "warning",
        });
        return;
      } else if (data.avdplgl == "") {
        swal({
          title: "OOPS!",
          text: "Les champs pour acuité vision de près et vision de loin ne peuvent pas être vides.",
          icon: "warning",
        });
        return;
      } else if (data.avdlrl == "") {
        swal({
          title: "OOPS!",
          text: "Les champs pour acuité vision de près et vision de loin ne peuvent pas être vides.",
          icon: "warning",
        });
        return;
      } else if (data.avdlll == "") {
        swal({
          title: "OOPS!",
          text: "Les champs pour acuité vision de près et vision de loin ne peuvent pas être vides.",
          icon: "warning",
        });
        return;
      }
      if (data.ndpl == "") {
        swal({
          title: "OOPS!",
          text: "Merci d'indiquer si une ordonnance est requise ou non pour ce test de vue.",
          icon: "warning",
        });
        return;
      }
      if (valuereturned != "" || valuereturnedf != "" || valuereturnedr != "")
        return;
    }
    if (
      $("#resultExamine1").val() == "Juste ordonnance" ||
      $("#resultExamine1").val() == "Vente" ||
      $("#resultExamine1").val() == "Devis"
    ) {
      var arax2 = getValueFromState(data.testDetails[2].arax);
      var arcy2 = getValueFromState(data.testDetails[2].arcy);

      var alcy3 = getValueFromState(data.testDetails[3].alcy);
      var alxy3 = getValueFromState(data.testDetails[3].alax);

      var brcy4 = getValueFromState(data.testDetails[4].brcy);
      var brax4 = getValueFromState(data.testDetails[4].brax);

      var blcy5 = getValueFromState(data.testDetails[5].blcy);
      var blax5 = getValueFromState(data.testDetails[5].blax);

      var crcy6 = getValueFromState(data.testDetails[6].crcy);
      var crax6 = getValueFromState(data.testDetails[6].crax);

      var clcy7 = getValueFromState(data.testDetails[7].clcy);
      var clax7 = getValueFromState(data.testDetails[7].clax);

      if (
        (arcy2 != "0.00" && arax2 == "0.00") ||
        (arcy2 == "0.00" && arax2 != "0.00")
      ) {
        swal({
          title: "OOPS!",
          text: "Attention ! Il n'est pas possible de soumettre un test avec une valeur pour CYL sans ajouter une valeur pour AX et vice versa. Merci de vérifier votre test et de compléter les données manquantes.",
          icon: "warning",
        });
        return;
      }

      if (
        (alcy3 != "0.00" && alxy3 == "0.00") ||
        (alcy3 == "0.00" && alxy3 != "0.00")
      ) {
        swal({
          title: "OOPS!",
          text: "Attention ! Il n'est pas possible de soumettre un test avec une valeur pour CYL sans ajouter une valeur pour AX et vice versa. Merci de vérifier votre test et de compléter les données manquantes.",
          icon: "warning",
        });
        return;
      }
      if (
        (brcy4 != "0.00" && brax4 == "0.00") ||
        (brcy4 == "0.00" && brax4 != "0.00")
      ) {
        swal({
          title: "OOPS!",
          text: "Attention ! Il n'est pas possible de soumettre un test avec une valeur pour CYL sans ajouter une valeur pour AX et vice versa. Merci de vérifier votre test et de compléter les données manquantes.",
          icon: "warning",
        });
        return;
      }
      if (
        (blcy5 != "0.00" && blax5 == "0.00") ||
        (blcy5 == "0.00" && blax5 != "0.00")
      ) {
        swal({
          title: "OOPS!",
          text: "Attention ! Il n'est pas possible de soumettre un test avec une valeur pour CYL sans ajouter une valeur pour AX et vice versa. Merci de vérifier votre test et de compléter les données manquantes.",
          icon: "warning",
        });
        return;
      }
      if (
        (crcy6 != "0.00" && crax6 == "0.00") ||
        (crcy6 == "0.00" && crax6 != "0.00")
      ) {
        swal({
          title: "OOPS!",
          text: "Attention ! Il n'est pas possible de soumettre un test avec une valeur pour CYL sans ajouter une valeur pour AX et vice versa. Merci de vérifier votre test et de compléter les données manquantes.",
          icon: "warning",
        });
        return;
      }
      if (
        (clcy7 != "0.00" && clax7 == "0.00") ||
        (clcy7 == "0.00" && clax7 != "0.00")
      ) {
        swal({
          title: "OOPS!",
          text: "Attention ! Il n'est pas possible de soumettre un test avec une valeur pour CYL sans ajouter une valeur pour AX et vice versa. Merci de vérifier votre test et de compléter les données manquantes.",
          icon: "warning",
        });
        return;
      }
      let allValuesr = [
        getValueFromState(data.testDetails[2].arsh),
        getValueFromState(data.testDetails[2].arcy),
        getValueFromState(data.testDetails[2].arax),
        getValueFromState(data.testDetails[2].arad),
        getValueFromState(data.testDetails[3].alsh),
        getValueFromState(data.testDetails[3].alcy),
        getValueFromState(data.testDetails[3].alax),
        getValueFromState(data.testDetails[3].alad),
      ];
      let valuereturnedr =
        allValuesr.filter((val) => val != "0.00").length > 0
          ? ""
          : swal({
              title: "OOPS!",
              text: " Les valeurs de Réfraction objective (autoref), Réfraction subjective (manuelle) et recommandation opticien finale ne peuvent être égales à 0. Vous devez entrer une valeur pour soumettre le test de vue.",
              icon: "warning",
            });
      let allValues = [
        getValueFromState(data.testDetails[4].brsh),
        getValueFromState(data.testDetails[4].brcy),
        getValueFromState(data.testDetails[4].brax),
        getValueFromState(data.testDetails[4].brad),
        getValueFromState(data.testDetails[5].blsh),
        getValueFromState(data.testDetails[5].blcy),
        getValueFromState(data.testDetails[5].blax),
        getValueFromState(data.testDetails[5].blad),
      ];
      let valuereturned =
        allValues.filter((val) => val != "0.00").length > 0
          ? ""
          : swal({
              title: "OOPS!",
              text: " Les valeurs de Réfraction objective (autoref), Réfraction subjective (manuelle) et recommandation opticien finale ne peuvent être égales à 0. Vous devez entrer une valeur pour soumettre le test de vue.",
              icon: "warning",
            });

      let allValuesf = [
        getValueFromState(data.testDetails[6].crsh),
        getValueFromState(data.testDetails[6].crcy),
        getValueFromState(data.testDetails[6].crax),
        getValueFromState(data.testDetails[6].crad),
        getValueFromState(data.testDetails[7].clsh),
        getValueFromState(data.testDetails[7].clcy),
        getValueFromState(data.testDetails[7].clax),
        getValueFromState(data.testDetails[7].clad),
      ];
      let manualUniqueCodeFormat = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
      let valuereturnedf =
        allValuesf.filter((val) => val != "0.00").length > 0
          ? ""
          : swal({
              title: "OOPS!",
              text: " Les valeurs de Réfraction objective (autoref), Réfraction subjective (manuelle) et recommandation opticien finale ne peuvent être égales à 0. Vous devez entrer une valeur pour soumettre le test de vue.",
              icon: "warning",
            });

      if (state.imageValue === null) {
        swal({
          title: "OOPS!",
          text: "L'examen de la vue ne peut pas être soumis sans le téléchargement d'une ordonnance précédente.",
          icon: "warning",
        });
        return;
      } else if (data.comlen == "") {
        swal({
          title: "OOPS!",
          text: "L’examen de vue ne peut pas être soumis sans les specification lentilles.",
          icon: "warning",
        });
        return;
      } else if (data.avdprl == "") {
        swal({
          title: "OOPS!",
          text: "Les champs pour acuité vision de près et vision de loin ne peuvent pas être vides.",
          icon: "warning",
        });
        return;
      } else if (data.avdplgl == "") {
        swal({
          title: "OOPS!",
          text: "Les champs pour acuité vision de près et vision de loin ne peuvent pas être vides.",
          icon: "warning",
        });
        return;
      } else if (data.avdlrl == "") {
        swal({
          title: "OOPS!",
          text: "Les champs pour acuité vision de près et vision de loin ne peuvent pas être vides.",
          icon: "warning",
        });
        return;
      } else if (data.avdlll == "") {
        swal({
          title: "OOPS!",
          text: "Les champs pour acuité vision de près et vision de loin ne peuvent pas être vides.",
          icon: "warning",
        });
        return;
      }
      if (data.ndpl == "") {
        swal({
          title: "OOPS!",
          text: "Merci d'indiquer si une ordonnance est requise ou non pour ce test de vue.",
          icon: "warning",
        });
        return;
      }
      if ($("#imgret1").val() == "No" && $("#specify1").val() == "") {
        swal({
          title: "OOPS!",
          text: "Attention ! Veuillez sélectionner Si non, veuillez préciser ",
          icon: "warning",
        });
        return;
      }
      if ($("#imgret1").val() == "") {
        swal({
          title: "OOPS!",
          text: "Merci de bien vouloir préciser s’il y a eu prise d’image de fonds d’œil. ",
          icon: "warning",
        });
        return;
      }
      if (valuereturned != "" || valuereturnedf != "" || valuereturnedr != "")
        return;
    }
    if ($("#resultExamine1").val() == "") {
      swal({
        title: "OOPS!",
        text: "Merci de préciser le résultat de le test de vue",
        icon: "warning",
      });
      return;
    }
    if (!checked && data.lastTimeDocl == "") {
      swal({
        title: "OOPS!",
        text: "Merci de renseigner la date ou de cocher la case.",
        icon: "warning",
      });
      return;
    }
    let dataToSend = {};
    dataToSend.testno = isEdit ? state.eyetestid1 : "0";
    data.testDetails[0].TestType = "Test au fronto";
    data.testDetails[0].sph = getValueFromState(data.testDetails[0].rsh);
    delete data.testDetails[0].rsh;
    data.testDetails[0].cyl = getValueFromState(data.testDetails[0].rcy);
    delete data.testDetails[0].rcy;
    data.testDetails[0].axe = getValueFromState(data.testDetails[0].rax);
    delete data.testDetails[0].rax;
    data.testDetails[0].add = getValueFromState(data.testDetails[0].rad);
    delete data.testDetails[0].rad;
    data.testDetails[1].TestType = "Test au fronto";
    data.testDetails[1].sph = getValueFromState(data.testDetails[1].lsh);
    delete data.testDetails[1].lsh;
    data.testDetails[1].cyl = getValueFromState(data.testDetails[1].lcy);
    delete data.testDetails[1].lcy;
    data.testDetails[1].axe = getValueFromState(data.testDetails[1].lax);
    delete data.testDetails[1].lax;
    data.testDetails[1].add = getValueFromState(data.testDetails[1].lad);
    delete data.testDetails[1].lad;
    data.testDetails[2].TestType = "Auto ref";
    data.testDetails[2].sph = getValueFromState(data.testDetails[2].arsh);
    delete data.testDetails[2].arsh;
    data.testDetails[2].cyl = getValueFromState(data.testDetails[2].arcy);
    delete data.testDetails[2].arcy;
    data.testDetails[2].axe = getValueFromState(data.testDetails[2].arax);
    delete data.testDetails[2].arax;
    data.testDetails[2].add = getValueFromState(data.testDetails[2].arad);
    delete data.testDetails[2].arad;
    data.testDetails[3].TestType = "Auto ref";
    data.testDetails[3].sph = getValueFromState(data.testDetails[3].alsh);
    delete data.testDetails[3].alsh;
    data.testDetails[3].cyl = getValueFromState(data.testDetails[3].alcy);
    delete data.testDetails[3].alcy;
    data.testDetails[3].axe = getValueFromState(data.testDetails[3].alax);
    delete data.testDetails[3].alax;
    data.testDetails[3].add = getValueFromState(data.testDetails[3].alad);
    delete data.testDetails[3].alad;
    data.testDetails[4].TestType = "Réfraction subjective (manuelle)";
    data.testDetails[4].sph = getValueFromState(data.testDetails[4].brsh);
    delete data.testDetails[4].brsh;
    data.testDetails[4].cyl = getValueFromState(data.testDetails[4].brcy);
    delete data.testDetails[4].brcy;
    data.testDetails[4].axe = getValueFromState(data.testDetails[4].brax);
    delete data.testDetails[4].brax;
    data.testDetails[4].add = getValueFromState(data.testDetails[4].brad);
    delete data.testDetails[4].brad;
    data.testDetails[5].TestType = "Réfraction subjective (manuelle)";
    data.testDetails[5].sph = getValueFromState(data.testDetails[5].blsh);
    delete data.testDetails[5].blsh;
    data.testDetails[5].cyl = getValueFromState(data.testDetails[5].blcy);
    delete data.testDetails[5].blcy;
    data.testDetails[5].axe = getValueFromState(data.testDetails[5].blax);
    delete data.testDetails[5].blax;
    data.testDetails[5].add = getValueFromState(data.testDetails[5].blad);
    delete data.testDetails[5].blad;
    data.testDetails[6].TestType = "Reco opticien";
    data.testDetails[6].sph = getValueFromState(data.testDetails[6].crsh);
    delete data.testDetails[6].crsh;
    data.testDetails[6].cyl = getValueFromState(data.testDetails[6].crcy);
    delete data.testDetails[6].crcy;
    data.testDetails[6].axe = getValueFromState(data.testDetails[6].crax);
    delete data.testDetails[6].crax;
    data.testDetails[6].add = getValueFromState(data.testDetails[6].crad);
    delete data.testDetails[6].crad;
    data.testDetails[7].TestType = "Reco opticien";
    data.testDetails[7].sph = getValueFromState(data.testDetails[7].clsh);
    delete data.testDetails[7].clsh;
    data.testDetails[7].cyl = getValueFromState(data.testDetails[7].clcy);
    delete data.testDetails[7].clcy;
    data.testDetails[7].axe = getValueFromState(data.testDetails[7].clax);
    delete data.testDetails[7].clax;
    data.testDetails[7].add = getValueFromState(data.testDetails[7].clad);
    delete data.testDetails[7].clad;
    data.testDetails[0].whichEye = "right";
    data.testDetails[1].whichEye = "left";
    data.testDetails[2].whichEye = "right";
    data.testDetails[3].whichEye = "left";
    data.testDetails[4].whichEye = "right";
    data.testDetails[5].whichEye = "left";
    data.testDetails[6].whichEye = "right";
    data.testDetails[7].whichEye = "left";
    data.testDetails[0].orderNo = 1;
    data.testDetails[1].orderNo = 2;
    data.testDetails[2].orderNo = 3;
    data.testDetails[3].orderNo = 4;
    data.testDetails[4].orderNo = 5;
    data.testDetails[5].orderNo = 6;
    data.testDetails[6].orderNo = 7;
    data.testDetails[7].orderNo = 8;
    dataToSend.testDetails = data.testDetails;
    dataToSend.opticianEmail =
      (data.opid && state.utype == "BACKOFFICE") || state.utype == "ADMIN"
        ? opticianEmail
        : localStorage.getItem("opmail");
    dataToSend.patientEmail = isEdit ? state.patientemail : patientemail;
    dataToSend.posEmail =
      state.isExternalDoctor == "true"
        ? localStorage.getItem("opmail")
        : posEmail;
    dataToSend.info_Acuity_Near_Vision_Right_Eye = data.avdprl;
    dataToSend.info_Acuity_Near_Vision_Left_Eye = data.avdplgl;
    dataToSend.info_Acuity_Distance_Vision_Right_Eye = data.avdlrl;
    dataToSend.info_Acuity_Distance_Vision_Left_Eye = data.avdlll;
    dataToSend.info_Acuity_visual_sans_correction_Right_Eye = data.avscrl;
    dataToSend.info_Acuity_visual_sans_correction_Left_Eye = data.avscll;
    dataToSend.info_Eye_Pressure_Right_Eye = data.aprl;
    dataToSend.info_Eye_Pressure_Left_Eye = data.apll;
    dataToSend.IsMutualCompany = data.Nomdela1;
    dataToSend.testNameId = 1;
    dataToSend.glasses = 0;
    dataToSend.status = "New";
    dataToSend.name = "";
    dataToSend.needPrescription =
      data.ndpl == "true" || data.ndpl == true ? true : false;
    dataToSend.acteGratuit =
      data.Actel == true || data.Actel == "true" ? true : false;
    dataToSend.noteOpt = data.noteoptl;
    dataToSend.commentForLenses = isEdit ? state.comlen : data.comlen;
    dataToSend.LastTimeWentToEyeDoc = data.lastTimeDocl;
    (dataToSend.resultExamine = $("#resultExamine1").val()),
      (dataToSend.symptoms = isEdit
        ? state.symptoms
        : `${data.FrequentFlashes},${data.FrequentFloaters},${data.Eyerednesspain},${data.SevereHeadaches},${data.DoubleVision},${data.LossofVision},${data.Dizziness},${data.Photosensible},${data.NoneoftheAbove}`);
    dataToSend.Dignosedwith = isEdit
      ? state.dignosedwith
      : `${data.Amblyopya},${data.Strabismus},${data.Glaucoma},${data.Cataract},${data.Macular},${data.RetinalDetachment},${data.CornealDystrophy},${data.NoneDiagnosed} `;
    dataToSend.HealthConditions = isEdit
      ? state.healthConditions
      : `${data.Diabetes},${data.Hypertension},${data.MultipleSclerosis},${data.NoneHealthConditions}`;
    dataToSend.isPrescriptionDownload = false;
    dataToSend.isContactLensType = true;
    dataToSend.commentsForPrescription = data.CommPrescripl;
    dataToSend.commentaireInterne = data.commentaireintel;
    dataToSend.testUniqueCode = data.testUniqueCode;
    dataToSend.InfoSubmitTrack = "Web";
    (dataToSend.isImageRetino = $("#imgret1").val()),
      (dataToSend.imageRetinoSpecification = $("#specify1").val()),
      (dataToSend.imagesVues = $('input[name="imagesVues1"]:checked').val());
    dataToSend.infoPrescriptionToOptician =
      $('input[name="infoPrescriptionoptician1"]:checked').val() === "true";
    console.log("data:" + data);
    console.log(dataToSend);
    //alert(data);
    //alert(isEdit)
    if (
      $("#resultExamine1").val() == "Juste ordonnance" ||
      $("#resultExamine1").val() == "Vente" ||
      $("#resultExamine1").val() == "Devis"
    ) {
      if (
        dataToSend.HealthConditions.split(",").every(
          (val) => val == "false" || val == ""
        )
      ) {
        swal({
          title: "OOPS!",
          text: "Il n'est pas possible de soumettre le test de vue sans sélectionner au moins un élément de la liste des symptômes.",
          icon: "warning",
        });
        return;
      } else if (
        dataToSend.symptoms
          .split(",")
          .every((val) => val == "false" || val == "")
      ) {
        swal({
          title: "OOPS!",
          text: "Il n'est pas possible de soumettre le test de vue sans sélectionner au moins un élément de la liste des symptômes.",
          icon: "warning",
        });
        return;
      } else if (
        dataToSend.Dignosedwith.split(",").every(
          (val) => val == "false" || val == ""
        )
      ) {
        swal({
          title: "OOPS!",
          text: "Il n'est pas possible de soumettre le test de vue sans sélectionner au moins un élément de la liste des symptômes.",
          icon: "warning",
        });
        return;
      }
    }
    if (state.isExternal == "true" || state.isExternalDoctor == "true") {
      if (
        dataToSend.HealthConditions.split(",").every(
          (val) => val == "false" || val == ""
        )
      ) {
        swal({
          title: "OOPS!",
          text: "Il n'est pas possible de soumettre le test de vue sans sélectionner au moins un élément de la liste des symptômes.",
          icon: "warning",
        });
        return;
      } else if (
        dataToSend.symptoms
          .split(",")
          .every((val) => val == "false" || val == "")
      ) {
        swal({
          title: "OOPS!",
          text: "Il n'est pas possible de soumettre le test de vue sans sélectionner au moins un élément de la liste des symptômes.",
          icon: "warning",
        });
        return;
      } else if (
        dataToSend.Dignosedwith.split(",").every(
          (val) => val == "false" || val == ""
        )
      ) {
        swal({
          title: "OOPS!",
          text: "Il n'est pas possible de soumettre le test de vue sans sélectionner au moins un élément de la liste des symptômes.",
          icon: "warning",
        });
        return;
      }
    }
    if (dataToSend.posEmail === "") {
      swal({
        title: "OOPS!",
        text: "Attention ! Veuillez sélectionner un point de vente.",
        icon: "warning",
      });
      return;
    }
    if (dataToSend.opticianEmail == "") {
      swal({
        title: "OOPS!",
        text: "Attention ! Veuillez sélectionner un opticien.",
        icon: "warning",
      });
      return;
    }
    if (
      dataToSend.infoPrescriptionToOptician == undefined ||
      dataToSend.infoPrescriptionToOptician == false
    ) {
      swal({
        title: "OOPS!",
        text: "Merci de cocher la case",
        icon: "warning",
      });
      return;
    }
    const endUrl = isEdit ? "/eyetests/modify" : "/eyetests/add";
    try {
      let logourl = API_URL + endUrl;
      // isEdit?data.testNo = "":""
      const requestOptions = {
        //credentials: 'include',
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.getItem("token"),
        },
        body: JSON.stringify(dataToSend),
      };
      const content = document.createElement("div");
      const input = document.createElement("img");
      input.src =
        "https://miro.medium.com/max/875/1*CsJ05WEGfunYMLGfsT2sXA.gif";
      input.width = "250";
      content.appendChild(input);
      //alert("before");
      swal({
        text: window.$language.PleaseWait,

        allowOutsideClick: false,
        buttons: false,
        content,
      });
      const AddInfo = getValues([
        "testDetails.2.arad",
        "testDetails.3.alad",
        "testDetails.4.brad",
        "testDetails.5.blad",
        "testDetails.6.crad",
        "testDetails.7.clad",
      ]);
      if (
        ((AddInfo[0] !== "0.00" && AddInfo[1] === "0.00") ||
          (AddInfo[0] === "0.00" && AddInfo[1] !== "0.00") ||
          (AddInfo[2] !== "0.00" && AddInfo[3] === "0.00") ||
          (AddInfo[2] === "0.00" && AddInfo[3] !== "0.00") ||
          (AddInfo[4] !== "0.00" && AddInfo[5] === "0.00") ||
          (AddInfo[4] === "0.00" && AddInfo[5] !== "0.00")) &&
        proceed
      ) {
        const process = await swal({
          title: "OOPS!",
          text: "Attention ! Les valeurs ADD ne sont pas identiques pour les deux yeux. Merci de bien vouloir revérifier vos valeurs. Si c’est le cas et qu’il s’agit bien de deux valeurs différentes, vous pouvez cliquer à nouveau sur “soumettre” pour confirmer le test.",
          icon: "warning",
          dangerMode: true,
        });
        if (process) return setProceed(false);
      }
      const response = await fetch(logourl, requestOptions);
      let result = await response.text();

      result = result.toString();
      //if (state.imageValue != null) {
      //alert(state.imageValue)
      //alert(id)
      console.log(response.body);

      // if (result == "DUPLICATE") {
      //   swal({
      //     title: "OOPS!",
      //     text: "Manual Id must be unique",
      //     icon: "warning",
      //   });
      //   return;
      // }

      if (!isEdit) {
        await fileUpload(result, state.imageValue, 1);
        await fileUpload(result, state.imageValue2, 2);
        await fileUpload(result, state.imageValue3, 3);
        await fileUpload(result, state.imageValue4, 4);
      }

      // alert("Hello")
      //   this.setState({posarr:json})
      swal({
        title: "Fait !",
        text: window.$language.AddedSuccessfully,
        icon: "success",
      });
      componentMount();
      reset();
      document.getElementById("close1234").click();
    } catch (e) {
      (e) => {
        swal({
          title: "OOPS!",
          text: window.$language.Somethingwentwrong,
          icon: "warning",
        });

        console.log(e);
      };
    }
  };
  const addressFunction1 = () => {
    //console.log(valObj)
    if (document.getElementById("same1").checked) {
      let valObj = getValues([
        "testDetails.2.arsh",
        "testDetails.2.arcy",
        "testDetails.2.arax",
        "testDetails.2.arad",
        "testDetails.2.arpd",
        "testDetails.3.alsh",
        "testDetails.3.alcy",
        "testDetails.3.alax",
        "testDetails.3.alad",
        "testDetails.3.alpd",
      ]);
      setValue("testDetails.4.brsh", valObj[0]);
      setValue("testDetails.4.brcy", valObj[1]);
      setValue("testDetails.4.brax", valObj[2]);
      setValue("testDetails.4.brad", valObj[3]);
      setValue("testDetails.4.brpd", valObj[4]);
      setValue("testDetails.5.blsh", valObj[5]);
      setValue("testDetails.5.blcy", valObj[6]);
      setValue("testDetails.5.blax", valObj[7]);
      setValue("testDetails.5.blad", valObj[8]);
      setValue("testDetails.5.blpd", valObj[9]);
    } else {
      setValue("testDetails.4.brsh", "0.00");
      setValue("testDetails.4.brcy", "0.00");
      setValue("testDetails.4.brax", "0.00");
      setValue("testDetails.4.brad", "0.00");
      setValue("testDetails.4.brpd", "0.00");
      setValue("testDetails.5.blsh", "0.00");
      setValue("testDetails.5.blcy", "0.00");
      setValue("testDetails.5.blax", "0.00");
      setValue("testDetails.5.blad", "0.00");
      setValue("testDetails.5.blpd", "0.00");
    }
  };
  const addressFunctionl1 = () => {
    //console.log(valObj)
    if (document.getElementById("samel1").checked) {
      let valObj = getValues([
        "testDetails.2.arsh",
        "testDetails.2.arcy",
        "testDetails.2.arax",
        "testDetails.2.arad",
        "testDetails.2.arpd",
        "testDetails.3.alsh",
        "testDetails.3.alcy",
        "testDetails.3.alax",
        "testDetails.3.alad",
        "testDetails.3.alpd",
      ]);
      setValue("testDetails.4.brsh", valObj[0]);
      setValue("testDetails.4.brcy", valObj[1]);
      setValue("testDetails.4.brax", valObj[2]);
      setValue("testDetails.4.brad", valObj[3]);
      setValue("testDetails.4.brpd", valObj[4]);
      setValue("testDetails.5.blsh", valObj[5]);
      setValue("testDetails.5.blcy", valObj[6]);
      setValue("testDetails.5.blax", valObj[7]);
      setValue("testDetails.5.blad", valObj[8]);
      setValue("testDetails.5.blpd", valObj[9]);
    } else {
      setValue("testDetails.4.brsh", "0.00");
      setValue("testDetails.4.brcy", "0.00");
      setValue("testDetails.4.brax", "0.00");
      setValue("testDetails.4.brad", "0.00");
      setValue("testDetails.4.brpd", "0.00");
      setValue("testDetails.5.blsh", "0.00");
      setValue("testDetails.5.blcy", "0.00");
      setValue("testDetails.5.blax", "0.00");
      setValue("testDetails.5.blad", "0.00");
      setValue("testDetails.5.blpd", "0.00");
    }
  };
  const addressFunction = () => {
    //console.log(valObj)
    if (document.getElementById("same").checked) {
      let valObj = getValues([
        "testDetails.4.brsh",
        "testDetails.4.brcy",
        "testDetails.4.brax",
        "testDetails.4.brad",
        "testDetails.4.brpd",
        "testDetails.5.blsh",
        "testDetails.5.blcy",
        "testDetails.5.blax",
        "testDetails.5.blad",
        "testDetails.5.blpd",
      ]);
      setValue("testDetails.6.crsh", valObj[0]);
      setValue("testDetails.6.crcy", valObj[1]);
      setValue("testDetails.6.crax", valObj[2]);
      setValue("testDetails.6.crad", valObj[3]);
      setValue("testDetails.6.crpd", valObj[4]);
      setValue("testDetails.7.clsh", valObj[5]);
      setValue("testDetails.7.clcy", valObj[6]);
      setValue("testDetails.7.clax", valObj[7]);
      setValue("testDetails.7.clad", valObj[8]);
      setValue("testDetails.7.clpd", valObj[9]);
    } else {
      setValue("testDetails.6.crsh", "0.00");
      setValue("testDetails.6.crcy", "0.00");
      setValue("testDetails.6.crax", "0.00");
      setValue("testDetails.6.crad", "0.00");
      setValue("testDetails.6.crpd", "0.00");
      setValue("testDetails.7.clsh", "0.00");
      setValue("testDetails.7.clcy", "0.00");
      setValue("testDetails.7.clax", "0.00");
      setValue("testDetails.7.clad", "0.00");
      setValue("testDetails.7.clpd", "0.00");
    }
  };
  const noneofthese = (type) => {
    if (type == "1") {
      if ($("#validationRadio10").is(":checked")) {
        $(".chk0").prop("checked", false);
        $(".chk0").attr("disabled", true);
        setValue("FrequentFlashes", false);
        setValue("FrequentFloaters", false);
        setValue("Eyerednesspain", false);
        setValue("SevereHeadaches", false);
        setValue("DoubleVision", false);
        setValue("LossofVision", false);
        setValue("Dizziness", false);
        setValue("Photosensible", false);
      } else {
        $(".chk0").attr("disabled", false);
      }
    }
    if (type == "2") {
      if ($("#validationRadio20").is(":checked")) {
        $(".chk1").prop("checked", false);
        $(".chk1").attr("disabled", true);
        setValue("Amblyopya", false);
        setValue("Strabismus", false);
        setValue("Glaucoma", false);
        setValue("Cataract", false);
        setValue("Macular", false);
        setValue("RetinalDetachment", false);
        setValue("CornealDystrophy", false);
      } else {
        $(".chk1").attr("disabled", false);
      }
    }
    if (type == "3") {
      if ($("#valid10").is(":checked")) {
        $(".chk3").prop("checked", false);
        $(".chk3").attr("disabled", true);
        setValue("Diabetes", false);
        setValue("Hypertension", false);
        setValue("MultipleSclerosis", false);
      } else {
        $(".chk3").attr("disabled", false);
      }
    }
    if (type == "4") {
      if ($("#validationRadio10e").is(":checked")) {
        $(".chk3").prop("checked", false);
        $(".chk3").attr("disabled", true);
        setValue("FrequentFlashes", false);
        setValue("FrequentFloaters", false);
        setValue("Eyerednesspain", false);
        setValue("SevereHeadaches", false);
        setValue("DoubleVision", false);
        setValue("LossofVision", false);
        setValue("Dizziness", false);
        setValue("Photosensible", false);
      } else {
        $(".chk3").attr("disabled", false);
      }
    }
    if (type == "5") {
      if ($("#validationRadio20e").is(":checked")) {
        $(".chk4").prop("checked", false);
        $(".chk4").attr("disabled", true);
        setValue("Amblyopya", false);
        setValue("Strabismus", false);
        setValue("Glaucoma", false);
        setValue("Cataract", false);
        setValue("Macular", false);
        setValue("RetinalDetachment", false);
        setValue("CornealDystrophy", false);
      } else {
        $(".chk4").attr("disabled", false);
      }
    }
    if (type == "6") {
      if ($("#valid10e").is(":checked")) {
        $(".chk5").prop("checked", false);
        $(".chk5").attr("disabled", true);
        setValue("Diabetes", false);
        setValue("Hypertension", false);
        setValue("MultipleSclerosis", false);
      } else {
        $(".chk5").attr("disabled", false);
      }
    }
  };
  const addressFunctionl = () => {
    //console.log(valObj)
    if (document.getElementById("samel").checked) {
      let valObj = getValues([
        "testDetails.4.brsh",
        "testDetails.4.brcy",
        "testDetails.4.brax",
        "testDetails.4.brad",
        "testDetails.4.brpd",
        "testDetails.5.blsh",
        "testDetails.5.blcy",
        "testDetails.5.blax",
        "testDetails.5.blad",
        "testDetails.5.blpd",
      ]);
      setValue("testDetails.6.crsh", valObj[0]);
      setValue("testDetails.6.crcy", valObj[1]);
      setValue("testDetails.6.crax", valObj[2]);
      setValue("testDetails.6.crad", valObj[3]);
      setValue("testDetails.6.crpd", valObj[4]);
      setValue("testDetails.7.clsh", valObj[5]);
      setValue("testDetails.7.clcy", valObj[6]);
      setValue("testDetails.7.clax", valObj[7]);
      setValue("testDetails.7.clad", valObj[8]);
      setValue("testDetails.7.clpd", valObj[9]);
    } else {
      setValue("testDetails.6.crsh", "0.00");
      setValue("testDetails.6.crcy", "0.00");
      setValue("testDetails.6.crax", "0.00");
      setValue("testDetails.6.crad", "0.00");
      setValue("testDetails.6.crpd", "0.00");
      setValue("testDetails.7.clsh", "0.00");
      setValue("testDetails.7.clcy", "0.00");
      setValue("testDetails.7.clax", "0.00");
      setValue("testDetails.7.clad", "0.00");
      setValue("testDetails.7.clpd", "0.00");
    }
  };
  const form1 = (
    <div class="modal modal-slide-in fade" id="modals-slide-in">
      <div class="modal-dialog sidebar-sm w-100">
        <form
          class="add-new-record modal-content pt-0"
          onSubmit={handleSubmit(submitFormDetails)}
        >
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            ×
          </button>
          <div class="modal-header mb-1">
            <h5 class="modal-title" id="exampleModalLabel">
              {window.$language.NewRecordeyetest}
            </h5>
          </div>
          <div class="modal-body flex-grow-1" ref={componentToPrint}>
            <div class="card-body">
              <form class="dt_adv_search frm1" method="POST">
                <div class="col">
                  <div class="form-row mb-1">
                    <div class="col-lg-3">
                      <label>{window.$language.TestName} :</label>
                      <select
                        class="form-control"
                        id="testNameId"
                        disabled={isEdit ? true : false}
                        value={state.testNameId}
                        {...register("testNameId")}
                      >
                        {state.testdet?.map((usr) => (
                          <option value={usr.testNameId}>{usr.testName}</option>
                        ))}
                      </select>
                    </div>
                    {state.isExternalDoctor == "false" ? (
                      <>
                        {state.utype == "OPTICIAN" ? (
                          <div class="col-lg-3">
                            <label>{window.$language.Optician} :*</label>
                            <select
                              class="form-control readonly"
                              id="opid"
                              disabled={true}
                              value={
                                isEdit
                                  ? state.opid
                                  : localStorage.getItem("opmail") ||
                                    state.utype == "BACKOFFICE" ||
                                    state.utype == "ADMIN"
                                  ? opticianEmail
                                  : localStorage.getItem("opmail")
                              }
                            >
                              <option>
                                {isEdit
                                  ? state.opid
                                  : localStorage.getItem("opmail")}
                              </option>
                            </select>
                          </div>
                        ) : null}
                        {state.utype == "BACKOFFICE" ||
                        state.utype == "ADMIN" ? (
                          <div class="col-lg-3">
                            <label>{window.$language.Optician} :*</label>
                            <div class="select-container">
                              <>
                                {isEdit ? (
                                  <Select
                                    class="form-control"
                                    id="opid1"
                                    value={{
                                      value: opticianEmail,
                                      label: opticianEmail,
                                    }}
                                    onChange={(selectedOption) =>
                                      setopticianEmail(selectedOption.value)
                                    }
                                    options={[
                                      {
                                        value: " ",
                                        label: "Saisir une option",
                                      },
                                      ...state.opcarr
                                        ?.sort((a, b) =>
                                          a.name.localeCompare(b.name)
                                        )
                                        .map((usr) => ({
                                          value: usr.email,
                                          label: usr.name + " " + usr.lastName,
                                        })),
                                    ]}
                                  />
                                ) : (
                                  <Select
                                    class="form-control"
                                    id="opid1"
                                    placeholder="Saisir une option"
                                    onChange={(selectedOption) =>
                                      setopticianEmail(selectedOption.value)
                                    }
                                    options={[
                                      { value: "", label: "Saisir une option" },
                                      ...state.opcarr
                                        ?.sort((a, b) =>
                                          a.name.localeCompare(b.name)
                                        )
                                        .map((usr) => ({
                                          value: usr.email,
                                          label: usr.name + " " + usr.lastName,
                                        })),
                                    ]}
                                  />
                                )}
                              </>
                            </div>
                          </div>
                        ) : null}
                      </>
                    ) : null}
                    {state.isExternalDoctor == "false" ? (
                      <div class="col-lg-3">
                        <label>{window.$language.POS} :*</label>
                        <div class="select-container">
                          <>
                            {isEdit ? (
                              <Select
                                class="form-control"
                                id="posid"
                                value={{ value: posEmail, label: posEmail }}
                                onChange={(selectedOption) =>
                                  setPosid(selectedOption.value)
                                }
                                options={[
                                  { value: "", label: "Saisir une option" }, // Add an empty option at the beginning
                                  ...(state.posarr || []) // Ensure state.posarr is not null or undefined
                                    .sort((a, b) =>
                                      a.name.localeCompare(b.name)
                                    )
                                    .map((usr) => ({
                                      value: usr.email,
                                      label: usr.name,
                                    })),
                                ]}
                              />
                            ) : state.isExternal == "true" ? (
                              <Select
                                class="form-control"
                                value={
                                  state.isExternal == "true"
                                    ? { value: posEmail, label: posEmail }
                                    : ""
                                }
                                id="posid"
                                onChange={(selectedOption) =>
                                  setPosid(selectedOption.value)
                                }
                                options={[
                                  ...(state.posarr || []) // Ensure state.posarr is not null or undefined
                                    .sort((a, b) =>
                                      a.name.localeCompare(b.name)
                                    )
                                    .map((usr) => ({
                                      value: usr.email,
                                      label: usr.name,
                                    })),
                                ]}
                              />
                            ) : state.isExternalDoctor == "true" ? (
                              <select
                                class="form-control readonly"
                                id="posid"
                                disabled={true}
                                value={
                                  isEdit
                                    ? state.opid
                                    : localStorage.getItem("opmail") ||
                                      state.utype == "BACKOFFICE" ||
                                      state.utype == "ADMIN"
                                    ? opticianEmail
                                    : localStorage.getItem("opmail")
                                }
                              >
                                <option>
                                  {localStorage.getItem("opmail")}
                                </option>
                              </select>
                            ) : (
                              <Select
                                class="form-control"
                                id="posid"
                                onChange={(selectedOption) =>
                                  setPosid(selectedOption.value)
                                }
                                placeholder="Saisir une option"
                                options={[
                                  { value: "", label: "Saisir une option" }, // Add an empty option at the beginning
                                  ...(state.posarr || []) // Ensure state.posarr is not null or undefined
                                    .sort((a, b) =>
                                      a.name.localeCompare(b.name)
                                    )
                                    .map((usr) => ({
                                      value: usr.email,
                                      label: usr.name,
                                    })),
                                ]}
                              />
                            )}
                          </>
                        </div>
                      </div>
                    ) : null}
                    <div class="col-lg-3">
                      <div className="form-group align-baseline flex-row">
                        <label className="form-label" for="login-email">
                          {window.$language.PatientEmail}:
                        </label>
                        <div class="select-container">
                          <Select
                            class="form-control"
                            id="patientemail"
                            isDisabled={isEdit == true ? true : false}
                            onChange={(selectedOption) =>
                              setPatientEmail(selectedOption.value)
                            }
                            value={{ value: patientemail, label: patientemail }}
                            options={state.patientarr?.map((usr) => ({
                              value: usr.email,
                              label: usr.email,
                            }))}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="col-lg-3  ">
                      <label>Dernière visite chez l'ophtalmologue :*</label>
                      {/* <input
                        type="date"
                        class="form-control pickadate"
                        id="lastTimeWentToEyeDoc"
                        disabled={isEdit ? true : false}
                        defaultValue={state.lastTimeDoc}
                        {...register("lastTimeDoc")}
                        placeholder="01 Jan 2000"
                      /> */}
                      <input
                        type="date"
                        class="form-control pickadate"
                        id="lastTimeWentToEyeDoc"
                        disabled={checked ? true : false}
                        defaultValue={state.lastTimeDoc}
                        {...register("lastTimeDoc")}
                        placeholder="01 Jan 2000"
                      />
                      <input
                        type="checkbox"
                        class="datecheckbox pr-1"
                        defaultChecked={checked}
                        onClick={() => setChecked(!checked)}
                      />
                      &nbsp;Cochez la case si le sujet n'a jamais vu
                      d'ophtalmologue
                    </div>

                    <div class="col-lg-3">
                      <div class="form-group">
                        <label class="d-block pr-1 ml-1">
                          {" "}
                          {
                            window.$language
                              .Areyoucurrentlyexperiencinganyofthefollowing
                          }{" "}
                          :*
                        </label>
                        <div class=" custom-control custom-checkbox ml-1">
                          <input
                            type="checkbox"
                            id="validationRadio0"
                            {...register("FrequentFlashes")}
                            class=" custom-control-input chk0"
                            value="FrequentFlashes"
                            name="FrequentFlashes"
                            required
                            disabled={state.eyetestid1 != null}
                          />
                          <label
                            class="custom-control-label"
                            for="validationRadio0"
                          >
                            {window.$language.FrequentFlashes}
                          </label>
                        </div>
                        <div class="custom-control custom-checkbox ml-1">
                          <input
                            type="checkbox"
                            id="validationRadio4"
                            name="FrequentFloaters"
                            value="FrequentFloaters"
                            class="custom-control-input chk0"
                            required
                            {...register("FrequentFloaters")}
                            disabled={state.eyetestid1 != null}
                          />
                          <label
                            class="custom-control-label"
                            for="validationRadio4"
                          >
                            {window.$language.FrequentFloaters}
                          </label>
                        </div>
                        <div class="custom-control custom-checkbox ml-1">
                          <input
                            type="checkbox"
                            id="validationRadio5"
                            name="Eye redness / pain"
                            value="Eye redness / pain"
                            class="custom-control-input chk0"
                            required
                            {...register("Eyerednesspain")}
                            disabled={state.eyetestid1 != null}
                          />
                          <label
                            class="custom-control-label"
                            for="validationRadio5"
                          >
                            {window.$language.EyerednessPain}
                          </label>
                        </div>
                        <div class="custom-control custom-checkbox ml-1">
                          <input
                            type="checkbox"
                            id="validationRadio6"
                            name="Severe Headaches"
                            value="Severe Headhaches"
                            class="custom-control-input chk0"
                            required
                            {...register("SevereHeadaches")}
                            disabled={state.eyetestid1 != null}
                          />
                          <label
                            class="custom-control-label"
                            for="validationRadio6"
                          >
                            {window.$language.SevereHeadhaches}
                          </label>
                        </div>
                        <div class="custom-control custom-checkbox ml-1">
                          <input
                            type="checkbox"
                            id="validationRadio7"
                            name="Double Vision"
                            value="Double Vision"
                            class="custom-control-input chk0"
                            required
                            {...register("DoubleVision")}
                            disabled={state.eyetestid1 != null}
                          />
                          <label
                            class="custom-control-label"
                            for="validationRadio7"
                          >
                            {window.$language.DoubleVision}{" "}
                          </label>
                        </div>
                        <div class="custom-control custom-checkbox ml-1">
                          <input
                            type="checkbox"
                            id="validationRadio8"
                            name="Loss of Vision"
                            value="Loss of Vision"
                            class="custom-control-input chk0"
                            required
                            {...register("LossofVision")}
                            disabled={state.eyetestid1 != null}
                          />
                          <label
                            class="custom-control-label"
                            for="validationRadio8"
                          >
                            {window.$language.LossofVision}
                          </label>
                        </div>
                        <div class="custom-control custom-checkbox ml-1">
                          <input
                            type="checkbox"
                            id="validationRadio9"
                            name="Dizziness"
                            value="Dizziness"
                            class="custom-control-input chk0"
                            required
                            {...register("Dizziness")}
                            disabled={state.eyetestid1 != null}
                          />
                          <label
                            class="custom-control-label"
                            for="validationRadio9"
                          >
                            {window.$language.Dizziness}
                          </label>
                        </div>
                        <div class="custom-control custom-checkbox ml-1">
                          <input
                            type="checkbox"
                            id="validationRadio909"
                            name="Photosensible"
                            value="Photosensible"
                            class="custom-control-input chk0"
                            required
                            {...register("Photosensible")}
                            disabled={state.eyetestid1 != null}
                          />
                          <label
                            class="custom-control-label"
                            for="validationRadio909"
                          >
                            Photosensible
                          </label>
                        </div>
                        <div class="custom-control custom-checkbox ml-1">
                          <input
                            type="checkbox"
                            id="validationRadio10"
                            name="None of the Above"
                            value="None of the Above"
                            class="custom-control-input"
                            onClick={() => noneofthese("1")}
                            required
                            {...register("NoneoftheAbove")}
                            // disabled={state.eyetestid1 != null}
                          />
                          <label
                            class="custom-control-label"
                            for="validationRadio10"
                          >
                            <b>{window.$language.NoneoftheAbove}</b>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3">
                      <div class="form-group">
                        <label class="d-block ml-1">
                          {
                            window.$language
                              .HaveYouEverBeenDiagnosedWithAnyOfTheFollowingEyeConditions
                          }{" "}
                          :*
                        </label>
                        <div class="custom-control custom-checkbox ml-1">
                          <input
                            type="checkbox"
                            id="validationRadio13"
                            name="Amblyopya"
                            value="Amblyopya or Lazy eye"
                            class="custom-control-input chk1"
                            required
                            {...register("Amblyopya")}
                            disabled={state.eyetestid1 != null}
                          />
                          <label
                            class="custom-control-label"
                            for="validationRadio13"
                          >
                            {window.$language.AmblyopyaOrLazyEye}
                          </label>
                        </div>
                        <div class="custom-control custom-checkbox ml-1">
                          <input
                            type="checkbox"
                            id="validationRadio14"
                            name="diagnosed"
                            value="Strabismus"
                            class="custom-control-input chk1"
                            required
                            {...register("Strabismus")}
                            disabled={state.eyetestid1 != null}
                          />
                          <label
                            class="custom-control-label"
                            for="validationRadio14"
                          >
                            {window.$language.Strabismus}
                          </label>
                        </div>
                        <div class="custom-control custom-checkbox ml-1">
                          <input
                            type="checkbox"
                            id="validationRadio15"
                            name="diagnosed"
                            value="Glaucoma"
                            class="custom-control-input chk1"
                            required
                            {...register("Glaucoma")}
                            disabled={state.eyetestid1 != null}
                          />
                          <label
                            class="custom-control-label"
                            for="validationRadio15"
                          >
                            {window.$language.Glaucoma}
                          </label>
                        </div>

                        <div class="custom-control custom-checkbox ml-1">
                          <input
                            type="checkbox"
                            id="validationRadio16"
                            name="diagnosed"
                            value="Cataract"
                            class="custom-control-input chk1"
                            required
                            {...register("Cataract")}
                            disabled={state.eyetestid1 != null}
                          />
                          <label
                            class="custom-control-label"
                            for="validationRadio16"
                          >
                            Cataracte
                          </label>
                        </div>
                        <div class="custom-control custom-checkbox ml-1">
                          <input
                            type="checkbox"
                            id="validationRadio17"
                            name="diagnosed"
                            value="Macular or Retinal Degeneration"
                            class="custom-control-input chk1"
                            required
                            {...register("Macular")}
                            disabled={state.eyetestid1 != null}
                          />
                          <label
                            class="custom-control-label"
                            for="validationRadio17"
                          >
                            {window.$language.MacularOrRetinalDegeneration}
                          </label>
                        </div>
                        <div class="custom-control custom-checkbox ml-1">
                          <input
                            type="checkbox"
                            id="validationRadio18"
                            name="diagnosed"
                            value="Retinal Detachment"
                            class="custom-control-input chk1"
                            required
                            {...register("RetinalDetachment")}
                            disabled={state.eyetestid1 != null}
                          />
                          <label
                            class="custom-control-label"
                            for="validationRadio18"
                          >
                            {window.$language.RetinalDetachement}
                          </label>
                        </div>
                        <div class="custom-control custom-checkbox ml-1">
                          <input
                            type="checkbox"
                            id="validationRadio19"
                            name="diagnosed"
                            value="Corneal Dystrophy"
                            class="custom-control-input chk1"
                            required
                            {...register("CornealDystrophy")}
                            disabled={state.eyetestid1 != null}
                          />
                          <label
                            class="custom-control-label"
                            for="validationRadio19"
                          >
                            {window.$language.CornealDystrophy}
                          </label>
                        </div>
                        <div class="custom-control custom-checkbox ml-1">
                          <input
                            type="checkbox"
                            id="validationRadio20"
                            name="diagnosed"
                            value="None of the above"
                            class="custom-control-input"
                            onClick={() => noneofthese("2")}
                            required
                            {...register("NoneDiagnosed")}
                            // disabled={state.eyetestid1 != null}
                          />
                          <label
                            class="custom-control-label"
                            for="validationRadio20"
                          >
                            <b>{window.$language.NoneoftheAbove}</b>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3">
                      <div class="form-group">
                        <label class="d-block ml-1">
                          {
                            window.$language
                              .DoYouSufferFromAnyOfTheFollowingConditions
                          }{" "}
                          :*
                        </label>
                        <div class="custom-control custom-checkbox ml-1 ">
                          <input
                            type="checkbox"
                            id="validationRadio23"
                            name="healthconditions"
                            value="Diabetes"
                            class="custom-control-input chk3"
                            required
                            {...register("Diabetes")}
                            disabled={state.eyetestid1 != null}
                          />
                          <label
                            class="custom-control-label"
                            for="validationRadio23"
                          >
                            {window.$language.Diabetes}
                          </label>
                        </div>
                        <div class="custom-control custom-checkbox ml-1">
                          <input
                            type="checkbox"
                            id="validationRadio24"
                            name="healthconditions"
                            value="Hypertension"
                            class="custom-control-input chk3"
                            required
                            {...register("Hypertension")}
                            disabled={state.eyetestid1 != null}
                          />
                          <label
                            class="custom-control-label"
                            for="validationRadio24"
                          >
                            {window.$language.Hypertension}
                          </label>
                        </div>
                        <div class="custom-control custom-checkbox ml-1">
                          <input
                            type="checkbox"
                            id="valid5"
                            name="MultipleSclerosis"
                            value="Multiple Sclerosis"
                            class="custom-control-input chk3"
                            required
                            {...register("MultipleSclerosis")}
                            disabled={state.eyetestid1 != null}
                          />
                          <label class="custom-control-label" for="valid5">
                            {window.$language.MultipleSclerosis}
                          </label>
                        </div>
                        <div class="custom-control custom-checkbox ml-1">
                          <input
                            type="checkbox"
                            id="valid10"
                            name="healthconditions"
                            value="None of the above"
                            class="custom-control-input"
                            onClick={() => noneofthese("3")}
                            required
                            {...register("NoneHealthConditions")}
                            // disabled={state.eyetestid1 != null}
                          />
                          <label class="custom-control-label" for="valid10">
                            <b> {window.$language.NoneoftheAbove}</b>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <hr class="my-2 btnhide" />
            <div class="frm2">
              <div class="card-datatable frm6">
                <table class="table table-striped w-100">
                  <thead>
                    {!state.isContactLens ? (
                      <tr>
                        <th class="border border-primary"></th>
                        <th
                          class="border border-primary align-middle"
                          colSpan="5"
                        >
                          {window.$language.RIGHTEYE}
                        </th>
                        <th
                          class="border border-primary align-middle"
                          colSpan="5"
                        >
                          {window.$language.LEFTEYE}
                        </th>
                      </tr>
                    ) : (
                      ""
                    )}
                    {state.isContactLens ? (
                      <tr>
                        <th class="border border-primary"></th>
                        <th
                          class="border border-primary align-middle"
                          colSpan="4"
                        >
                          {window.$language.RIGHTEYE}
                        </th>
                        <th
                          class="border border-primary align-middle"
                          colSpan="4"
                        >
                          {window.$language.LEFTEYE}
                        </th>
                      </tr>
                    ) : (
                      ""
                    )}
                    <tr>
                      <th></th>
                      <th>SPH</th>
                      <th>CYL</th>
                      <th>AXE</th>
                      <th>ADD</th>
                      {!state.isContactLens ? <th>PD</th> : ""}
                      {/* <th>PHIGH</th> */}

                      <th>SPH</th>
                      <th>CYL</th>
                      <th>AXE</th>
                      <th>ADD</th>
                      {!state.isContactLens ? <th>PD</th> : ""}
                      {/* <th>PHIGH</th> */}
                    </tr>
                    <tr class="p-0">
                      <td class="p-1">Correction portée</td>
                      <td class="px-9 py-1">
                        <Controller
                          control={control}
                          name="testDetails.0.rsh"
                          defaultValue={
                            isEdit == true ? state.rsh : options[80]
                          }
                          render={({ field }) => (
                            <Select
                              id="rsh"
                              onMenuOpen={onMenuOpen}
                              className={"eyetestdetails"}
                              classNamePrefix={"eyetestdetails"}
                              options={options}
                              {...field}
                              placeholder="0.00"
                            />
                          )}
                        />
                      </td>
                      <td class="px-9 py-1">
                        <Controller
                          control={control}
                          name="testDetails.0.rcy"
                          defaultValue={
                            isEdit == true ? state.rcy : options2[40]
                          }
                          render={({ field }) => (
                            <Select
                              onMenuOpen={onMenuOpen}
                              className={"eyetestdetails"}
                              classNamePrefix={"eyetestdetails"}
                              id="rcy"
                              options={options2}
                              {...field}
                              placeholder="0.00"
                            />
                          )}
                        />
                      </td>
                      <td class="px-9 py-1">
                        <Controller
                          control={control}
                          name="testDetails.0.rax"
                          defaultValue={
                            isEdit == true ? state.rax : options3[0]
                          }
                          render={({ field }) => (
                            <Select
                              id="rax"
                              onMenuOpen={onMenuOpen}
                              className={"eyetestdetails"}
                              classNamePrefix={"eyetestdetails"}
                              options={options3}
                              {...field}
                              placeholder="0"
                            />
                          )}
                        />
                      </td>
                      <td class="px-9 py-1">
                        <Controller
                          control={control}
                          name="testDetails.0.rad"
                          defaultValue={
                            isEdit == true ? state.rad : options5[0]
                          }
                          render={({ field }) => (
                            <Select
                              onMenuOpen={onMenuOpen}
                              className={"eyetestdetails"}
                              classNamePrefix={"eyetestdetails"}
                              id="rad"
                              options={options5}
                              {...field}
                              placeholder="0.00"
                            />
                          )}
                        />
                      </td>
                      {!state.isContactLens ? (
                        <td class="px-9 py-1">
                          <Controller
                            control={control}
                            name="testDetails.0.rpd"
                            defaultValue={
                              isEdit == true ? state.rpd : options4[0]
                            }
                            render={({ field }) => (
                              <Select
                                onMenuOpen={onMenuOpen}
                                className={"eyetestdetails"}
                                classNamePrefix={"eyetestdetails"}
                                id="rpd"
                                options={options4}
                                {...field}
                                placeholder="0.00"
                              />
                            )}
                          />
                        </td>
                      ) : (
                        ""
                      )}
                      {/* <td class="pad"><Select id='rph' class="form-control " placeholder="0.00" /></td> */}

                      <td class="px-9 py-1">
                        <Controller
                          control={control}
                          name="testDetails.1.lsh"
                          defaultValue={
                            isEdit == true ? state.lsh : options[80]
                          }
                          render={({ field }) => (
                            <Select
                              onMenuOpen={onMenuOpen}
                              className={"eyetestdetails"}
                              classNamePrefix={"eyetestdetails"}
                              id="lsh"
                              options={options}
                              {...field}
                              placeholder="0.00"
                            />
                          )}
                        />
                      </td>
                      <td class="px-9 py-1">
                        <Controller
                          control={control}
                          name="testDetails.1.lcy"
                          defaultValue={
                            isEdit == true ? state.lcy : options2[40]
                          }
                          render={({ field }) => (
                            <Select
                              onMenuOpen={onMenuOpen}
                              className={"eyetestdetails"}
                              classNamePrefix={"eyetestdetails"}
                              id="lcy"
                              options={options2}
                              {...field}
                              placeholder="0.00"
                            />
                          )}
                        />
                      </td>
                      <td class="px-9 py-1">
                        <Controller
                          control={control}
                          name="testDetails.1.lax"
                          defaultValue={
                            isEdit == true ? state.lax : options3[0]
                          }
                          render={({ field }) => (
                            <Select
                              onMenuOpen={onMenuOpen}
                              className={"eyetestdetails"}
                              classNamePrefix={"eyetestdetails"}
                              id="lax"
                              options={options3}
                              {...field}
                              placeholder="0"
                            />
                          )}
                        />
                      </td>
                      <td class="px-9 py-1">
                        <Controller
                          control={control}
                          name="testDetails.1.lad"
                          defaultValue={
                            isEdit == true ? state.lad : options5[0]
                          }
                          render={({ field }) => (
                            <Select
                              onMenuOpen={onMenuOpen}
                              className={"eyetestdetails"}
                              classNamePrefix={"eyetestdetails"}
                              id="lad"
                              options={options5}
                              {...field}
                              placeholder="0.00"
                            />
                          )}
                        />
                      </td>
                      {!state.isContactLens ? (
                        <td class="px-9 py-1">
                          <Controller
                            control={control}
                            name="testDetails.1.lpd"
                            defaultValue={
                              isEdit == true ? state.lpd : options4[0]
                            }
                            render={({ field }) => (
                              <Select
                                onMenuOpen={onMenuOpen}
                                className={"eyetestdetails"}
                                classNamePrefix={"eyetestdetails"}
                                id="lpd"
                                options={options4}
                                {...field}
                                placeholder="0.00"
                              />
                            )}
                          />
                        </td>
                      ) : (
                        ""
                      )}
                      {/* <td class="p-1"><input id='lph' class="form-control " placeholder="0.00" /></td> */}
                    </tr>
                    <tr>
                      <td class="p-1">Réfraction objective (autoref)*</td>
                      <td class="px-9 py-1">
                        <Controller
                          control={control}
                          name="testDetails.2.arsh"
                          defaultValue={
                            isEdit == true ? state.arsh : options[80]
                          }
                          render={({ field }) => (
                            <Select
                              onMenuOpen={onMenuOpen}
                              className={"eyetestdetails"}
                              classNamePrefix={"eyetestdetails"}
                              id="lax"
                              options={options}
                              {...field}
                              placeholder="0.00"
                            />
                          )}
                        />
                      </td>
                      <td class="px-9 py-1">
                        <Controller
                          control={control}
                          name="testDetails.2.arcy"
                          defaultValue={
                            isEdit == true ? state.arcy : options2[40]
                          }
                          render={({ field }) => (
                            <Select
                              onMenuOpen={onMenuOpen}
                              className={"eyetestdetails"}
                              classNamePrefix={"eyetestdetails"}
                              id="arcy"
                              options={options2}
                              {...field}
                              placeholder="0.00"
                            />
                          )}
                        />
                      </td>
                      <td class="px-9 py-1">
                        <Controller
                          control={control}
                          name="testDetails.2.arax"
                          defaultValue={state.arax}
                          render={({ field }) => (
                            <Select
                              onMenuOpen={onMenuOpen}
                              className={"eyetestdetails"}
                              classNamePrefix={"eyetestdetails"}
                              id="arax"
                              options={options3}
                              {...field}
                              placeholder="0"
                            />
                          )}
                        />
                      </td>
                      <td class="px-9 py-1">
                        <Controller
                          control={control}
                          name="testDetails.2.arad"
                          defaultValue={state.arad}
                          render={({ field }) => (
                            <Select
                              onMenuOpen={onMenuOpen}
                              className={"eyetestdetails"}
                              classNamePrefix={"eyetestdetails"}
                              id="arad"
                              options={options5}
                              {...field}
                              placeholder="0.00"
                            />
                          )}
                        />
                      </td>
                      {!state.isContactLens ? (
                        <td class="px-9 py-1">
                          <Controller
                            control={control}
                            name="testDetails.2.arpd"
                            defaultValue={state.arpd}
                            render={({ field }) => (
                              <Select
                                onMenuOpen={onMenuOpen}
                                className={"eyetestdetails"}
                                classNamePrefix={"eyetestdetails"}
                                id="arpd"
                                options={options4}
                                {...field}
                                placeholder="0.00"
                              />
                            )}
                          />
                        </td>
                      ) : (
                        ""
                      )}
                      {/* <td class="p-1"><Select id='arph' class="form-control " placeholder="0.00" /></td> */}
                      <td class="px-9 py-1">
                        <Controller
                          control={control}
                          name="testDetails.3.alsh"
                          defaultValue={
                            isEdit == true ? state.alsh : options[80]
                          }
                          render={({ field }) => (
                            <Select
                              onMenuOpen={onMenuOpen}
                              className={"eyetestdetails"}
                              classNamePrefix={"eyetestdetails"}
                              id="alsh"
                              options={options}
                              {...field}
                              placeholder="0.00"
                            />
                          )}
                        />
                      </td>
                      <td class="px-9 py-1">
                        <Controller
                          control={control}
                          name="testDetails.3.alcy"
                          defaultValue={
                            isEdit == true ? state.alcy : options2[40]
                          }
                          render={({ field }) => (
                            <Select
                              onMenuOpen={onMenuOpen}
                              className={"eyetestdetails"}
                              classNamePrefix={"eyetestdetails"}
                              id="alcy"
                              options={options2}
                              {...field}
                              placeholder="0.00"
                            />
                          )}
                        />
                      </td>
                      <td class="px-9 py-1">
                        <Controller
                          control={control}
                          name="testDetails.3.alax"
                          defaultValue={state.alax}
                          render={({ field }) => (
                            <Select
                              onMenuOpen={onMenuOpen}
                              className={"eyetestdetails"}
                              classNamePrefix={"eyetestdetails"}
                              id="alax"
                              options={options3}
                              {...field}
                              placeholder="0"
                            />
                          )}
                        />
                      </td>
                      <td class="px-9 py-1">
                        <Controller
                          control={control}
                          name="testDetails.3.alad"
                          defaultValue={state.alad}
                          render={({ field }) => (
                            <Select
                              onMenuOpen={onMenuOpen}
                              className={"eyetestdetails"}
                              classNamePrefix={"eyetestdetails"}
                              id="alad"
                              options={options5}
                              {...field}
                              placeholder="0.00"
                            />
                          )}
                        />
                      </td>
                      {!state.isContactLens ? (
                        <td class="px-9 py-1">
                          <Controller
                            control={control}
                            name="testDetails.3.alpd"
                            defaultValue={state.alpd}
                            render={({ field }) => (
                              <Select
                                onMenuOpen={onMenuOpen}
                                className={"eyetestdetails"}
                                classNamePrefix={"eyetestdetails"}
                                id="alpd"
                                options={options4}
                                {...field}
                                placeholder="0.00"
                              />
                            )}
                          />
                        </td>
                      ) : (
                        ""
                      )}
                      {/* <td class="p-1"><input id='alph' class="form-control " placeholder="0.00" /></td> */}{" "}
                    </tr>
                    <i>Cochez la case pour copier les valeurs&nbsp;</i>
                    <input
                      class="m-0"
                      type="checkbox"
                      id="same1"
                      name="same1"
                      onClick={() => addressFunction1()}
                    />
                    <tr>
                      <td class="p-1">Réfraction subjective (manuelle)*</td>
                      <td class="px-9 py-1">
                        <Controller
                          control={control}
                          name="testDetails.4.brsh"
                          defaultValue={
                            isEdit == true ? state.brsh : options[80]
                          }
                          render={({ field }) => (
                            <Select
                              onMenuOpen={onMenuOpen}
                              className={"eyetestdetails"}
                              classNamePrefix={"eyetestdetails"}
                              id="brsh"
                              options={options}
                              {...field}
                              placeholder="0.00"
                            />
                          )}
                        />
                      </td>
                      <td class="px-9 py-1">
                        <Controller
                          control={control}
                          name="testDetails.4.brcy"
                          defaultValue={
                            isEdit == true ? state.brcy : options2[40]
                          }
                          render={({ field }) => (
                            <Select
                              onMenuOpen={onMenuOpen}
                              className={"eyetestdetails"}
                              classNamePrefix={"eyetestdetails"}
                              id="brcy"
                              options={options2}
                              {...field}
                              placeholder="0.00"
                            />
                          )}
                        />
                      </td>
                      <td class="px-9 py-1">
                        <Controller
                          control={control}
                          name="testDetails.4.brax"
                          defaultValue={state.brax}
                          render={({ field }) => (
                            <Select
                              onMenuOpen={onMenuOpen}
                              className={"eyetestdetails"}
                              classNamePrefix={"eyetestdetails"}
                              id="brax"
                              options={options3}
                              {...field}
                              placeholder="0"
                            />
                          )}
                        />
                      </td>
                      <td class="px-9 py-1">
                        <Controller
                          control={control}
                          name="testDetails.4.brad"
                          defaultValue={state.brad}
                          render={({ field }) => (
                            <Select
                              onMenuOpen={onMenuOpen}
                              className={"eyetestdetails"}
                              classNamePrefix={"eyetestdetails"}
                              id="brad"
                              options={options5}
                              {...field}
                              placeholder="0.00"
                            />
                          )}
                        />
                      </td>
                      {!state.isContactLens ? (
                        <td class="px-9 py-1">
                          <Controller
                            control={control}
                            name="testDetails.4.brpd"
                            defaultValue={state.brpd}
                            render={({ field }) => (
                              <Select
                                onMenuOpen={onMenuOpen}
                                className={"eyetestdetails"}
                                classNamePrefix={"eyetestdetails"}
                                id="brpd"
                                options={options4}
                                {...field}
                                placeholder="0.00"
                              />
                            )}
                          />
                        </td>
                      ) : (
                        ""
                      )}
                      {/* <td class="px-9 py-1"><select id='brph' class="form-control " placeholder="0.00" /></td> */}

                      <td class="px-9 py-1">
                        <Controller
                          control={control}
                          name="testDetails.5.blsh"
                          defaultValue={
                            isEdit == true ? state.blsh : options[80]
                          }
                          render={({ field }) => (
                            <Select
                              onMenuOpen={onMenuOpen}
                              className={"eyetestdetails"}
                              classNamePrefix={"eyetestdetails"}
                              id="blsh"
                              options={options}
                              {...field}
                              placeholder="0.00"
                            />
                          )}
                        />
                      </td>
                      <td class="px-9 py-1">
                        <Controller
                          control={control}
                          name="testDetails.5.blcy"
                          defaultValue={
                            isEdit == true ? state.blcy : options2[40]
                          }
                          render={({ field }) => (
                            <Select
                              onMenuOpen={onMenuOpen}
                              className={"eyetestdetails"}
                              classNamePrefix={"eyetestdetails"}
                              id="blcy"
                              options={options2}
                              {...field}
                              placeholder="0.00"
                            />
                          )}
                        />
                      </td>
                      <td class="px-9 py-1">
                        <Controller
                          control={control}
                          name="testDetails.5.blax"
                          defaultValue={state.blax}
                          render={({ field }) => (
                            <Select
                              onMenuOpen={onMenuOpen}
                              className={"eyetestdetails"}
                              classNamePrefix={"eyetestdetails"}
                              id="blax"
                              options={options3}
                              {...field}
                              placeholder="0"
                            />
                          )}
                        />
                      </td>
                      <td class="px-9 py-1">
                        <Controller
                          control={control}
                          name="testDetails.5.blad"
                          defaultValue={state.blad}
                          render={({ field }) => (
                            <Select
                              onMenuOpen={onMenuOpen}
                              className={"eyetestdetails"}
                              classNamePrefix={"eyetestdetails"}
                              id="blad"
                              options={options5}
                              {...field}
                              placeholder="0.00"
                            />
                          )}
                        />
                      </td>
                      {!state.isContactLens ? (
                        <td class="px-9 py-1">
                          <Controller
                            control={control}
                            name="testDetails.5.blpd"
                            defaultValue={state.blpd}
                            render={({ field }) => (
                              <Select
                                onMenuOpen={onMenuOpen}
                                className={"eyetestdetails"}
                                classNamePrefix={"eyetestdetails"}
                                id="blpd"
                                options={options4}
                                {...field}
                                placeholder="0.00"
                              />
                            )}
                          />
                        </td>
                      ) : (
                        ""
                      )}
                      {/* <td class="p-1"><input id='blph' class="form-control form-control-sm p-1" placeholder="0.00" /></td> */}
                    </tr>
                    <i>Cochez la case pour copier les valeurs&nbsp;</i>
                    <input
                      class="m-0"
                      type="checkbox"
                      id="same"
                      name="same"
                      onClick={() => addressFunction()}
                    />
                    <tr>
                      <td class="p-1">Recommandation opticien finale*</td>
                      <td class="px-9 py-1">
                        <Controller
                          control={control}
                          name="testDetails.6.crsh"
                          defaultValue={
                            isEdit == true ? state.crsh : options[80]
                          }
                          render={({ field }) => (
                            <Select
                              onMenuOpen={onMenuOpen}
                              className={"eyetestdetails"}
                              classNamePrefix={"eyetestdetails"}
                              id="crsh"
                              options={options}
                              {...field}
                              placeholder="0.00"
                            />
                          )}
                        />
                      </td>
                      <td class="px-9 py-1">
                        <Controller
                          control={control}
                          name="testDetails.6.crcy"
                          defaultValue={
                            isEdit == true ? state.crcy : options2[40]
                          }
                          render={({ field }) => (
                            <Select
                              onMenuOpen={onMenuOpen}
                              className={"eyetestdetails"}
                              classNamePrefix={"eyetestdetails"}
                              id="crcy"
                              options={options2}
                              {...field}
                              placeholder="0.00"
                            />
                          )}
                        />
                      </td>
                      <td class="px-9 py-1">
                        <Controller
                          control={control}
                          name="testDetails.6.crax"
                          defaultValue={state.crax}
                          render={({ field }) => (
                            <Select
                              onMenuOpen={onMenuOpen}
                              className={"eyetestdetails"}
                              classNamePrefix={"eyetestdetails"}
                              id="crax"
                              options={options3}
                              {...field}
                              placeholder="0"
                            />
                          )}
                        />
                      </td>
                      <td class="px-9 py-1">
                        <Controller
                          control={control}
                          name="testDetails.6.crad"
                          defaultValue={state.crad}
                          render={({ field }) => (
                            <Select
                              onMenuOpen={onMenuOpen}
                              className={"eyetestdetails"}
                              classNamePrefix={"eyetestdetails"}
                              id="crad"
                              options={options5}
                              {...field}
                              placeholder="0.00"
                            />
                          )}
                        />
                      </td>
                      {!state.isContactLens ? (
                        <td class="px-9 py-1">
                          <Controller
                            control={control}
                            name="testDetails.6.crpd"
                            defaultValue={state.crpd}
                            render={({ field }) => (
                              <Select
                                onMenuOpen={onMenuOpen}
                                className={"eyetestdetails"}
                                classNamePrefix={"eyetestdetails"}
                                id="crpd"
                                options={options4}
                                {...field}
                                placeholder="0.00"
                              />
                            )}
                          />
                        </td>
                      ) : (
                        ""
                      )}
                      {/* <td class="px-9 py-1"><Select
                                            onMenuOpen={onMenuOpen}
                          className ={"eyetestdetails"}
                          classNamePrefix ={"eyetestdetails"} id='crph' class="form-control form-control-sm px-9 py-1" placeholder="0.00" /></td> */}

                      <td class="px-9 py-1">
                        <Controller
                          control={control}
                          name="testDetails.7.clsh"
                          defaultValue={
                            isEdit == true ? state.clsh : options[80]
                          }
                          render={({ field }) => (
                            <Select
                              onMenuOpen={onMenuOpen}
                              className={"eyetestdetails"}
                              classNamePrefix={"eyetestdetails"}
                              id="clsh"
                              options={options}
                              {...field}
                              placeholder="0.00"
                            />
                          )}
                        />
                      </td>
                      <td class="px-9 py-1">
                        <Controller
                          control={control}
                          name="testDetails.7.clcy"
                          defaultValue={
                            isEdit == true ? state.clcy : options2[40]
                          }
                          render={({ field }) => (
                            <Select
                              onMenuOpen={onMenuOpen}
                              className={"eyetestdetails"}
                              classNamePrefix={"eyetestdetails"}
                              id="clcy"
                              options={options2}
                              {...field}
                              placeholder="0.00"
                            />
                          )}
                        />
                      </td>
                      <td class="px-9 py-1">
                        <Controller
                          control={control}
                          name="testDetails.7.clax"
                          defaultValue={state.clax}
                          render={({ field }) => (
                            <Select
                              onMenuOpen={onMenuOpen}
                              className={"eyetestdetails"}
                              classNamePrefix={"eyetestdetails"}
                              id="clax"
                              options={options3}
                              {...field}
                              placeholder="0"
                            />
                          )}
                        />
                      </td>
                      <td class="px-9 py-1">
                        <Controller
                          control={control}
                          name="testDetails.7.clad"
                          defaultValue={state.clad}
                          render={({ field }) => (
                            <Select
                              onMenuOpen={onMenuOpen}
                              className={"eyetestdetails"}
                              classNamePrefix={"eyetestdetails"}
                              id="clad"
                              options={options5}
                              {...field}
                              placeholder="0.00"
                            />
                          )}
                        />
                      </td>
                      {!state.isContactLens ? (
                        <td class="px-9 py-1">
                          <Controller
                            control={control}
                            name="testDetails.7.clpd"
                            defaultValue={state.clpd}
                            render={({ field }) => (
                              <Select
                                onMenuOpen={onMenuOpen}
                                className={"eyetestdetails"}
                                classNamePrefix={"eyetestdetails"}
                                id="clpd"
                                options={options4}
                                {...field}
                                placeholder="0.00"
                              />
                            )}
                          />
                        </td>
                      ) : (
                        ""
                      )}
                      {/* <td class="p-1"><select id='clph' class="form-control " placeholder="0.00" /></td> */}
                    </tr>
                    <tr>
                      <td class="p-1">Acuité visuelle de près*</td>
                      <td class="p-1" colSpan="5">
                        <input
                          id="avdprg"
                          // disabled={isEdit ? true : false}
                          defaultValue={state.avdprg}
                          class="form-control form-control-sm p-1"
                          {...register("avdprg")}
                        />
                      </td>

                      <td class="p-1" colSpan="5">
                        <input
                          // disabled={isEdit ? true : false}
                          defaultValue={state.avdplg}
                          class="form-control form-control-sm p-1"
                          {...register("avdplg")}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td class="p-1">Acuité visuelle de loin*</td>
                      <td class="p-1" colSpan="5">
                        <input
                          // disabled={isEdit ? true : false}
                          defaultValue={state.avdlrg}
                          class="form-control form-control-sm p-1"
                          {...register("avdlrg")}
                        />
                      </td>

                      <td class="p-1" colSpan="5">
                        <input
                          // disabled={isEdit ? true : false}
                          defaultValue={state.avdllg}
                          class="form-control form-control-sm p-1"
                          {...register("avdllg")}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td class="p-1">
                        Acuité visuelle sans correction de loin
                      </td>
                      <td class="p-1" colSpan="5">
                        <input
                          // disabled={isEdit ? true : false}
                          defaultValue={state.avscrg}
                          class="form-control form-control-sm p-1"
                          {...register("avscrg")}
                        />
                      </td>

                      <td class="p-1" colSpan="5">
                        <input
                          // disabled={isEdit ? true : false}
                          defaultValue={state.avsclg}
                          class="form-control form-control-sm p-1"
                          {...register("avsclg")}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td class="p-1">Pression intra oculaire (PIO) mmHg</td>
                      <td class="p-1" colSpan="5">
                        <input
                          // disabled={isEdit ? true : false}
                          defaultValue={state.aprg}
                          class="form-control form-control-sm p-1"
                          {...register("aprg")}
                        />
                      </td>

                      <td class="p-1" colSpan="5">
                        <input
                          // disabled={isEdit ? true : false}
                          defaultValue={state.aplg}
                          class="form-control form-control-sm p-1"
                          {...register("aplg")}
                        />
                      </td>
                    </tr>
                  </thead>
                </table>
              </div>
              <hr class="mb-2 btnhide" />
              <div class="col-lg-8">
                <div
                  class="form-group d-flex flex-wrap align-items-center"
                  style={{ width: "100vw" }}
                >
                  {/* <label class="d-block pr-1 ml-1"></label> */}
                  <div style={{ width: "280px" }}>
                    <h7 class="d-block pr-1">Test de Lang / # images vues</h7>
                  </div>
                  <div class="custom-control custom-radio ml-1 mr-3 select-container56">
                    <input
                      type="radio"
                      id="Radio2399"
                      name="imagesVues"
                      value="0"
                      class="custom-control-input"
                    />
                    <label class="custom-control-label" for="Radio2399">
                      0
                    </label>
                  </div>
                  <div class="custom-control custom-radio ml-1 mr-3 select-container56">
                    <input
                      type="radio"
                      id="Radio2499"
                      name="imagesVues"
                      value="1"
                      class="custom-control-input"
                    />
                    <label class="custom-control-label" for="Radio2499">
                      1
                    </label>
                  </div>
                  <div class="custom-control custom-radio ml-1 mr-3 select-container56">
                    <input
                      type="radio"
                      id="Radio2599"
                      name="imagesVues"
                      value="2"
                      class="custom-control-input"
                    />
                    <label class="custom-control-label" for="Radio2599">
                      2
                    </label>
                  </div>
                  <div class="custom-control custom-radio ml-1 mr-3 select-container56">
                    <input
                      type="radio"
                      id="Radio2699"
                      name="imagesVues"
                      value="3"
                      class="custom-control-input"
                    />
                    <label class="custom-control-label" for="Radio2699">
                      3
                    </label>
                  </div>
                  <div class="custom-control custom-radio ml-1 mr-3 select-container56">
                    <input
                      type="radio"
                      id="Radio2799"
                      name="imagesVues"
                      value="N/A"
                      class="custom-control-input"
                      defaultChecked
                    />
                    <label class="custom-control-label" for="Radio2799">
                      N/A
                    </label>
                  </div>
                </div>
              </div>
              {state.utype == "BACKOFFICE" ||
              state.utype == "ADMIN" ||
              (state.utype == "OPTICIAN" &&
                state.isExternal !== null &&
                state.isExternal == "false") ? (
                <div className="col-lg-4">
                  <div className="form-group align-baseline flex-row">
                    <label
                      className="form-label required"
                      htmlFor="login-email"
                    >
                      Résultat de l'examen :*
                    </label>
                    <select
                      className="form-control required"
                      id="resultExamine"
                    >
                      <option value=""></option>
                      <option value="Devis">Devis</option>
                      <option value="Juste ordonnance">Juste ordonnance</option>
                      <option value="Vente">Vente</option>
                      <option value="Retour ophtalmologue">
                        Retour ophtalmologue
                      </option>
                      <option value="Sans ordonnance">Sans ordonnance</option>
                    </select>
                  </div>
                </div>
              ) : null}
              <hr class="mb-2 btnhide" />
              <div class="form-row frm3">
                {state.utype == "BACKOFFICE" ||
                state.utype == "ADMIN" ||
                (state.utype == "OPTICIAN" &&
                  state.isExternal !== null &&
                  state.isExternal == "false") ? (
                  <div class="col-lg-4">
                    <label>Commentaire interne :</label>
                    {errors.noteOpt && errors.noteOpt?.type === "maxLength" ? (
                      <span className="text-danger py-2 inline-block">
                        (250 caractères maximum)
                      </span>
                    ) : null}
                    <textarea
                      type="text"
                      id="commentaireinte"
                      class="form-control flatpickr-basic"
                      placeholder="250 caractères maximum"
                      defaultValuevalue={state.commentaireinte}
                      {...register("commentaireinte", { maxLength: 250 })}
                    />
                  </div>
                ) : null}
                {frametype && (
                  <div class="col-lg-4">
                    <label>
                      Spécification lentilles (modèle / diamètre / rayon) :*
                    </label>
                    {errors.noteOpt && errors.noteOpt?.type === "maxLength" ? (
                      <span className="text-danger py-2 inline-block">
                        (250 caractères maximum)
                      </span>
                    ) : null}
                    <textarea
                      type="text"
                      // id="noteOpt"
                      class="form-control flatpickr-basic"
                      placeholder="250 caractères maximum"
                      onChange={() => console.log("info", state)}
                      // {...register("noteOpt", { maxLength: 250 })}
                      value={frametype}
                    />
                  </div>
                )}
                <div class="col-lg-4">
                  <label>Commentaire (à porter sur l'ordonnance) :</label>
                  {errors.noteOpt && errors.noteOpt?.type === "maxLength" ? (
                    <span className="text-danger py-2 inline-block">
                      (250 caractères maximum)
                    </span>
                  ) : null}
                  <textarea
                    type="text"
                    id="noteOpt"
                    class="form-control flatpickr-basic"
                    placeholder="250 caractères maximum"
                    defaultValue={state.commentsForPrescription}
                    {...register("CommPrescrip", { maxLength: 250 })}
                  />
                </div>
                <div class="col-lg-4">
                  <label>Commentaire additionel :</label>
                  {errors.noteOpt && errors.noteOpt?.type === "maxLength" ? (
                    <span className="text-danger py-2 inline-block">
                      (250 caractères maximum)
                    </span>
                  ) : null}
                  <textarea
                    type="text"
                    id="noteOpt"
                    class="form-control flatpickr-basic"
                    placeholder="250 caractères maximum"
                    defaultValue={state.noteOpt}
                    {...register("noteOpt", { maxLength: 250 })}
                  />
                </div>
                <div class="col-lg-4">
                  <div className="form-group align-baseline flex-row">
                    <label className="form-label required" for="login-email">
                      {window.$language.NeedsAPrescription} ?*
                    </label>
                    <select
                      class="form-control required"
                      {...register("ndp")}
                      id="ndp"
                      defaultValuevalue={state.ndp}
                    >
                      {state.isExternalDoctor == "false" ? (
                        <option value=""></option>
                      ) : null}
                      <option value="true">{window.$language.Yes}</option>
                      <option value="false">{window.$language.No}</option>
                    </select>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div className="form-group align-baseline flex-row">
                    <label className="form-label required" for="login-email">
                      Acte gratuit
                    </label>
                    <select
                      class="form-control required"
                      //disabled={isEdit ? true : false}
                      {...register("Acte")}
                      id="Acte"
                    >
                      {" "}
                      <option value="true">{window.$language.Yes}</option>
                      <option value="false">{window.$language.No}</option>
                    </select>
                  </div>
                </div>
                {state.utype == "OPTICIAN" ? (
                  <div class="col-lg-4">
                    <label>Nom de la mutuelle (si connue)</label>
                    <input
                      type="text"
                      id="Nomdela"
                      class="form-control flatpickr-basic"
                      defaultValue={state.Nomdela}
                      {...register("Nomdela")}
                    />
                  </div>
                ) : null}
                {state.utype == "BACKOFFICE" ||
                state.utype == "ADMIN" ||
                (state.utype == "OPTICIAN" &&
                  state.isExternal !== null &&
                  state.isExternal == "false") ? (
                  <>
                    <div className="col-lg-4">
                      <div className="form-group align-baseline flex-row">
                        <label
                          className="form-label required"
                          htmlFor="login-email"
                        >
                          Images rétino :*
                        </label>
                        <select
                          class="form-control required"
                          onChange={handleChange}
                          id="imgret"
                        >
                          <option value=""></option>
                          <option value="Yes">{window.$language.Yes}</option>
                          <option value="No">{window.$language.No}</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group align-baseline flex-row">
                        <label
                          className="form-label required"
                          htmlFor="login-email"
                          id="labelval"
                        >
                          Si non, veuillez spécifier
                        </label>
                        <select className="form-control required" id="specify">
                          <option value=""></option>
                          <option value="Problème technique">
                            Problème technique
                          </option>
                          <option value="Pupille trop grosse">
                            Pupille trop grosse
                          </option>
                          <option value="Dimensions de la tête du patient">
                            Dimensions de la tête du patient
                          </option>
                          <option value="Positionnement incorrect du patient">
                            Positionnement incorrect du patient
                          </option>
                          <option value="Autre">Autre</option>
                        </select>
                      </div>
                    </div>
                  </>
                ) : null}
              </div>
              <div class="col-lg-12">
    <div class="form-group d-flex flex-wrap align-items-center w-100">
        <div class="w-100">
            <p class="text-wrap">
                Je confirme avoir informé mon client / patient que les données relatives au test de vue ainsi 
                que les ordonnances susceptibles d'être émises par les ophtalmologues partenaires sur la base 
                de ces données, seront automatiquement partagées avec moi, à moins que ce partage automatique 
                ne soit désactivé sur son compte client, ce qui peut être fait directement en ligne ou en 
                contactant le service client de Sym Care Lab.
            </p>
        </div>
        <div class="custom-control custom-checkbox ml-2">
            <input type="checkbox" id="check0987" name="infoPrescriptionoptician" value="true" class="custom-control-input"/>
            <label class="custom-control-label" for="check0987"></label>
        </div>
    </div>
</div>
            </div>

            <hr class="mb-2 btnhide" />
            {isEdit ? (
              ""
            ) : (
              <div class="form-row mb-1 frm4">
                <div class="media mb-2 ">
                  <h6 class="p-1">Télécharger image fond d’oeil</h6>
                  <div class="media-body mt-50">
                    <input
                      type="file"
                      name="myImage"
                      id="Image2"
                      onChange={onImageChange1}
                    />
                  </div>
                </div>
                <div class="media mb-2 ">
                  <h6 class="p-1">Télécharger image fond d’oeil</h6>
                  <div class="media-body mt-50">
                    <input
                      type="file"
                      name="myImage"
                      id="Image3"
                      onChange={onImageChange2}
                    />
                  </div>
                </div>
                <div class="media mb-2 d-none">
                  <h6 class="p-1">Télécharger image fond d’oeil</h6>
                  <div class="media-body mt-50">
                    <input
                      type="file"
                      name="myImage"
                      id="Image4"
                      onChange={onImageChange3}
                    />
                  </div>
                </div>
              </div>
            )}
            <div class="paddingscreenshot">
              <button
                type="button"
                class="btnhide btn btn-outline-secondary"
                onClick={handlePrint}
              >
                Capture d'écran
              </button>
            </div>
            <div>
              <button
                type="submit"
                class="btnhide btn btn-primary data-submit mr-1 m-1"
              >
                {/* {window.$language.Submit} */}Envoyer
              </button>
              <button
                type="reset"
                class="btnhide btn btn-outline-danger m-1"
                id="close123"
                data-dismiss="modal"
              >
                {window.$language.Cancel}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
  const form2 = (
    <div class="modal modal-slide-in fade" id="modals-slide-in-2">
      <div class="modal-dialog sidebar-sm w-100">
        <form
          class="add-new-record modal-content pt-0"
          onSubmit={handleSubmit(submitForm1Details)}
        >
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            ×
          </button>
          <div class="modal-header mb-1">
            <h5 class="modal-title" id="exampleModalLabel">
              {window.$language.NewRecordeyetest}
            </h5>
          </div>
          <div class="modal-body flex-grow-1">
            <div class="card-body">
              <form class="dt_adv_search" method="POST">
                <div class="col">
                  <div class="form-row mb-1">
                    <div class="col-lg-3">
                      <label>{window.$language.TestName} :</label>
                      <select
                        class="form-control"
                        id="testNameId"
                        value={state.testNId}
                        {...register("testNId")}
                      >
                        {state.testdet?.map((usr) => (
                          <option value={usr.testNameId}>{usr.testName}</option>
                        ))}
                      </select>
                    </div>
                    {state.utype == "OPTICIAN" ? (
                      <div class="col-lg-3">
                        <label>{window.$language.Optician}:*</label>
                        <select
                          class="form-control readonly"
                          id="opid"
                          disabled={true}
                          value={
                            isEdit
                              ? state.opid
                              : localStorage.getItem("opmail") ||
                                state.utype == "BACKOFFICE" ||
                                state.utype == "ADMIN"
                              ? opticianEmail
                              : localStorage.getItem("opmail")
                          }
                          //onChange={(e) => setopticianEmail(e.target.value)}
                        >
                          <option>
                            {isEdit
                              ? state.opid
                              : localStorage.getItem("opmail")}
                          </option>
                        </select>
                      </div>
                    ) : null}
                    {state.utype == "BACKOFFICE" || state.utype == "ADMIN" ? (
                      <div class="col-lg-3">
                        <label>{window.$language.Optician} :*</label>
                        <div class="select-container">
                          <>
                            {isEdit ? (
                              <Select
                                class="form-control"
                                id="opid1"
                                value={{
                                  value: opticianEmail,
                                  label: opticianEmail,
                                }}
                                placeholder="Saisir une option"
                                onChange={(selectedOption) =>
                                  setopticianEmail(selectedOption.value)
                                }
                                options={[
                                  { value: "", label: "Saisir une option" },
                                  ...state.opcarr
                                    ?.sort((a, b) =>
                                      a.name.localeCompare(b.name)
                                    )
                                    .map((usr) => ({
                                      value: usr.email,
                                      label: usr.name + " " + usr.lastName,
                                    })),
                                ]}
                              />
                            ) : (
                              <Select
                                class="form-control"
                                id="opid1"
                                placeholder="Saisir une option"
                                onChange={(selectedOption) =>
                                  setopticianEmail(selectedOption.value)
                                }
                                options={[
                                  { value: "", label: "Saisir une option" },
                                  ...state.opcarr
                                    ?.sort((a, b) =>
                                      a.name.localeCompare(b.name)
                                    )
                                    .map((usr) => ({
                                      value: usr.email,
                                      label: usr.name + " " + usr.lastName,
                                    })),
                                ]}
                              />
                            )}
                          </>
                        </div>
                      </div>
                    ) : null}

                    <div class="col-lg-3">
                      <label>{window.$language.POS} :*</label>
                      <div class="select-container">
                        <>
                          {isEdit ? (
                            <Select
                              class="form-control"
                              id="posid"
                              value={{ value: posEmail, label: posEmail }}
                              onChange={(selectedOption) =>
                                setPosid(selectedOption.value)
                              }
                              options={[
                                { value: "", label: "Saisir une option" }, // Add an empty option at the beginning
                                ...(state.posarr || []) // Ensure state.posarr is not null or undefined
                                  .sort((a, b) => a.name.localeCompare(b.name))
                                  .map((usr) => ({
                                    value: usr.email,
                                    label: usr.name,
                                  })),
                              ]}
                            />
                          ) : state.isExternal == "true" ? (
                            <Select
                              class="form-control"
                              value={
                                state.isExternal == "true"
                                  ? { value: posEmail, label: posEmail }
                                  : null
                              }
                              id="posid"
                              onChange={(selectedOption) =>
                                setPosid(selectedOption.value)
                              }
                              options={[
                                ...(state.posarr || []) // Ensure state.posarr is not null or undefined
                                  .sort((a, b) => a.name.localeCompare(b.name))
                                  .map((usr) => ({
                                    value: usr.email,
                                    label: usr.name,
                                  })),
                              ]}
                            />
                          ) : (
                            <Select
                              class="form-control"
                              id="posid"
                              onChange={(selectedOption) =>
                                setPosid(selectedOption.value)
                              }
                              placeholder="Saisir une option"
                              options={[
                                { value: "", label: "Saisir une option" }, // Add an empty option at the beginning
                                ...(state.posarr || []) // Ensure state.posarr is not null or undefined
                                  .sort((a, b) => a.name.localeCompare(b.name))
                                  .map((usr) => ({
                                    value: usr.email,
                                    label: usr.name,
                                  })),
                              ]}
                            />
                          )}
                        </>
                      </div>
                    </div>

                    <div class="col-lg-3">
                      <div className="form-group align-baseline flex-row">
                        <label className="form-label" for="login-email">
                          {window.$language.PatientEmail}:
                        </label>
                        <div class="select-container">
                          <Select
                            class="form-control"
                            id="patientemail"
                            isDisabled={isEdit == true ? true : false}
                            onChange={(selectedOption) =>
                              setPatientEmail(selectedOption.value)
                            }
                            value={{ value: patientemail, label: patientemail }}
                            options={state.patientarr
                              ?.sort((a, b) => a.email.localeCompare(b.email))
                              .map((usr) => ({
                                value: usr.email,
                                label: isEdit ? patientemail : usr.email,
                              }))}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="form-row">
                    <div class="col-lg-3  ">
                      <label>Dernière visite chez l'ophtalmologu :*</label>
                      {/* <input
                        type="date"
                        class="form-control pickadate"
                        id="lastTimeDocl"
                        disabled={isEdit ? true : false}
                        {...register("lastTimeDocl")}
                        placeholder="01 Jan 2000"
                      /> */}
                      {/* {checked ? (
                        <input
                          type="date"
                          class="form-control pickadate"
                          disabled={true}
                          placeholder="01 Jan 2000"
                        />
                      ) : ( */}
                      <input
                        type="date"
                        class="form-control pickadate"
                        id="lastTimeDocl"
                        disabled={checked ? true : false}
                        defaultValue={state.lastTimeDocl}
                        {...register("lastTimeDocl")}
                        placeholder="01 Jan 2000"
                      />
                      {/* // )} */}
                      {/* {errors.lastTimeDocl && (
                        <h3 style={{ color: "red" }}>Date is Required!</h3>
                      )} */}
                      <input
                        type="checkbox"
                        class="datecheckbox p-1"
                        onClick={() => setChecked(!checked)}
                      />
                      &nbsp;Cochez la case si le sujet n'a jamais vu
                      d'ophtalmologue
                    </div>

                    <div class="col-lg-3">
                      <div class="form-group">
                        <label class="d-block pr-1 ml-1">
                          {" "}
                          {
                            window.$language
                              .Areyoucurrentlyexperiencinganyofthefollowing
                          }{" "}
                          :*
                        </label>
                        <div class=" custom-control custom-checkbox ml-1">
                          <input
                            type="checkbox"
                            id="frequentflashesee"
                            name="FrequentFlashes"
                            value="FrequentFlashes"
                            {...register("FrequentFlashes")}
                            class=" custom-control-input chk3"
                            required
                            disabled={state.eyetestid1 != null}
                          />
                          <label
                            class="custom-control-label"
                            for="frequentflashesee"
                          >
                            {window.$language.FrequentFlashes}
                          </label>
                        </div>
                        <div class="custom-control custom-checkbox ml-1">
                          <input
                            type="checkbox"
                            id="validationRadio4e"
                            name="FrequentFloaters"
                            value="FrequentFloaters"
                            class="custom-control-input chk3"
                            required
                            {...register("FrequentFloaters")}
                            disabled={state.eyetestid1 != null}
                          />
                          <label
                            class="custom-control-label"
                            for="validationRadio4e"
                          >
                            {window.$language.FrequentFloaters}
                          </label>
                        </div>
                        <div class="custom-control custom-checkbox ml-1">
                          <input
                            type="checkbox"
                            id="validationRadio5e"
                            name="Eye redness / pain"
                            value="Eye redness / pain"
                            class="custom-control-input chk3"
                            required
                            {...register("Eyerednesspain")}
                            disabled={state.eyetestid1 != null}
                          />
                          <label
                            class="custom-control-label"
                            for="validationRadio5e"
                          >
                            {window.$language.EyerednessPain}
                          </label>
                        </div>
                        <div class="custom-control custom-checkbox ml-1">
                          <input
                            type="checkbox"
                            id="validationRadio6e"
                            name="Severe Headaches"
                            value="Severe Headhaches"
                            class="custom-control-input chk3"
                            required
                            {...register("SevereHeadaches")}
                            disabled={state.eyetestid1 != null}
                          />
                          <label
                            class="custom-control-label"
                            for="validationRadio6e"
                          >
                            {window.$language.SevereHeadhaches}
                          </label>
                        </div>
                        <div class="custom-control custom-checkbox ml-1">
                          <input
                            type="checkbox"
                            id="validationRadio7e"
                            name="Double Vision"
                            value="Double Vision"
                            class="custom-control-input chk3"
                            required
                            {...register("DoubleVision")}
                            disabled={state.eyetestid1 != null}
                          />
                          <label
                            class="custom-control-label"
                            for="validationRadio7e"
                          >
                            {window.$language.DoubleVision}{" "}
                          </label>
                        </div>
                        <div class="custom-control custom-checkbox ml-1">
                          <input
                            type="checkbox"
                            id="validationRadio8e"
                            name="Loss of Vision"
                            value="Loss of Vision"
                            class="custom-control-input chk3"
                            required
                            {...register("LossofVision")}
                            disabled={state.eyetestid1 != null}
                          />
                          <label
                            class="custom-control-label"
                            for="validationRadio8e"
                          >
                            {window.$language.LossofVision}
                          </label>
                        </div>
                        <div class="custom-control custom-checkbox ml-1">
                          <input
                            type="checkbox"
                            id="validationRadio9e"
                            name="Dizziness"
                            value="Dizziness"
                            class="custom-control-input chk3"
                            required
                            {...register("Dizziness")}
                            disabled={state.eyetestid1 != null}
                          />
                          <label
                            class="custom-control-label"
                            for="validationRadio9e"
                          >
                            {window.$language.Dizziness}
                          </label>
                        </div>
                        <div class="custom-control custom-checkbox ml-1">
                          <input
                            type="checkbox"
                            id="validationRadio90990"
                            name="Photosensible"
                            value="Photosensible"
                            class="custom-control-input chk3"
                            required
                            {...register("Photosensible")}
                            disabled={state.eyetestid1 != null}
                          />
                          <label
                            class="custom-control-label"
                            for="validationRadio90990"
                          >
                            Photosensible
                          </label>
                        </div>
                        <div class="custom-control custom-checkbox ml-1">
                          <input
                            type="checkbox"
                            id="validationRadio10e"
                            name="None of the Above"
                            value="None of the Above"
                            class="custom-control-input"
                            onClick={() => noneofthese("4")}
                            required
                            {...register("NoneoftheAbove")}
                            // disabled={state.eyetestid1 != null}
                          />
                          <label
                            class="custom-control-label"
                            for="validationRadio10e"
                          >
                            <b>{window.$language.NoneoftheAbove}</b>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3">
                      <div class="form-group">
                        <label class="d-block ml-1">
                          {
                            window.$language
                              .HaveYouEverBeenDiagnosedWithAnyOfTheFollowingEyeConditions
                          }{" "}
                          :*
                        </label>
                        <div class="custom-control custom-checkbox ml-1">
                          <input
                            type="checkbox"
                            id="validationRadio13e"
                            name="diagnosed"
                            value="Amblyopya or Lazy eye"
                            class="custom-control-input chk4"
                            required
                            {...register("Amblyopya")}
                            disabled={state.eyetestid1 != null}
                          />
                          <label
                            class="custom-control-label"
                            for="validationRadio13e"
                          >
                            {window.$language.AmblyopyaOrLazyEye}
                          </label>
                        </div>
                        <div class="custom-control custom-checkbox ml-1">
                          <input
                            type="checkbox"
                            id="validationRadio14e"
                            name="diagnosed"
                            value="Strabismus"
                            class="custom-control-input chk4"
                            required
                            {...register("Strabismus")}
                            disabled={state.eyetestid1 != null}
                          />
                          <label
                            class="custom-control-label"
                            for="validationRadio14e"
                          >
                            {window.$language.Strabismus}
                          </label>
                        </div>
                        <div class="custom-control custom-checkbox ml-1">
                          <input
                            type="checkbox"
                            id="validationRadio15e"
                            name="diagnosed"
                            value="Glaucoma"
                            class="custom-control-input chk4"
                            required
                            {...register("Glaucoma")}
                            disabled={state.eyetestid1 != null}
                          />
                          <label
                            class="custom-control-label"
                            for="validationRadio15e"
                          >
                            {window.$language.Glaucoma}
                          </label>
                        </div>
                        <div class="custom-control custom-checkbox ml-1">
                          <input
                            type="checkbox"
                            id="validationRadio16e"
                            name="diagnosed"
                            value="Cataract"
                            class="custom-control-input chk4"
                            required
                            {...register("Cataract")}
                            disabled={state.eyetestid1 != null}
                          />
                          <label
                            class="custom-control-label"
                            for="validationRadio16e"
                          >
                            Cataracte
                          </label>
                        </div>
                        <div class="custom-control custom-checkbox ml-1">
                          <input
                            type="checkbox"
                            id="validationRadio17e"
                            name="diagnosed"
                            value="Macular or Retinal Degeneration"
                            class="custom-control-input chk4"
                            required
                            {...register("Macular")}
                            disabled={state.eyetestid1 != null}
                          />
                          <label
                            class="custom-control-label"
                            for="validationRadio17e"
                          >
                            {window.$language.MacularOrRetinalDegeneration}
                          </label>
                        </div>
                        <div class="custom-control custom-checkbox ml-1">
                          <input
                            type="checkbox"
                            id="validationRadio18e"
                            name="diagnosed"
                            value="Retinal Detachment"
                            class="custom-control-input chk4"
                            required
                            {...register("RetinalDetachment")}
                            disabled={state.eyetestid1 != null}
                          />
                          <label
                            class="custom-control-label"
                            for="validationRadio18e"
                          >
                            {window.$language.RetinalDetachement}
                          </label>
                        </div>
                        <div class="custom-control custom-checkbox ml-1">
                          <input
                            type="checkbox"
                            id="validationRadio19e"
                            name="diagnosed"
                            value="Corneal Dystrophy"
                            class="custom-control-input chk4"
                            required
                            {...register("CornealDystrophy")}
                            disabled={state.eyetestid1 != null}
                          />
                          <label
                            class="custom-control-label"
                            for="validationRadio19e"
                          >
                            {window.$language.CornealDystrophy}
                          </label>
                        </div>
                        <div class="custom-control custom-checkbox ml-1">
                          <input
                            type="checkbox"
                            id="validationRadio20e"
                            name="diagnosed"
                            value="None of the above"
                            class="custom-control-input"
                            onClick={() => noneofthese("5")}
                            required
                            {...register("NoneDiagnosed")}
                            // disabled={state.eyetestid1 != null}
                          />
                          <label
                            class="custom-control-label"
                            for="validationRadio20e"
                          >
                            <b>{window.$language.NoneoftheAbove}</b>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3">
                      <div class="form-group">
                        <label class="d-block ml-1">
                          {
                            window.$language
                              .DoYouSufferFromAnyOfTheFollowingConditions
                          }{" "}
                          :*
                        </label>
                        <div class="custom-control custom-checkbox ml-1 ">
                          <input
                            type="checkbox"
                            id="validationRadio23e"
                            name="healthconditions"
                            value="Diabetes"
                            class="custom-control-input chk5"
                            required
                            {...register("Diabetes")}
                            disabled={state.eyetestid1 != null}
                          />
                          <label
                            class="custom-control-label"
                            for="validationRadio23e"
                          >
                            {window.$language.Diabetes}
                          </label>
                        </div>
                        <div class="custom-control custom-checkbox ml-1">
                          <input
                            type="checkbox"
                            id="validationRadio24e"
                            name="healthconditions"
                            value="Hypertension"
                            class="custom-control-input chk5"
                            required
                            {...register("Hypertension")}
                            disabled={state.eyetestid1 != null}
                          />
                          <label
                            class="custom-control-label"
                            for="validationRadio24e"
                          >
                            {window.$language.Hypertension}
                          </label>
                        </div>
                        <div class="custom-control custom-checkbox ml-1">
                          <input
                            type="checkbox"
                            id="valid5e"
                            name="MultipleSclerosis"
                            value="Multiple Sclerosis"
                            class="custom-control-input chk5"
                            required
                            {...register("MultipleSclerosis")}
                            disabled={state.eyetestid1 != null}
                          />
                          <label class="custom-control-label" for="valid5e">
                            {window.$language.MultipleSclerosis}
                          </label>
                        </div>
                        <div class="custom-control custom-checkbox ml-1">
                          <input
                            type="checkbox"
                            id="valid10e"
                            name="healthconditions"
                            value="None of the above"
                            class="custom-control-input"
                            onClick={() => noneofthese("6")}
                            required
                            {...register("NoneHealthConditions")}
                            // disabled={state.eyetestid1 != null}
                          />
                          <label class="custom-control-label" for="valid10e">
                            <b> {window.$language.NoneoftheAbove}</b>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 d-none">
                      <label>Manual Id :</label>
                      <input
                        disabled={isEdit ? true : false}
                        defaultValue={state.testUniqueCode1}
                        class="form-control form-control-sm p-1"
                        {...register("testUniqueCode1")}
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
            {/* <hr class="my-0" /> */}
            <div class="form-row mb-1 ">
              <div class="media mb-2 pl-2">
                <h6 class="p-1">
                  Télécharger l'ancienne ordonnance lentilles*
                </h6>
                {/* <img
                  src={
                    state.Imagel1 == undefined || state.Imagel1 == ""
                      ? "../../../app-assets//images/portrait/small/avatar-s-10.png"
                      : state.Imagel1
                  }
                  alt="users avatar"
                  class="user-avatar users-avatar-shadow rounded mr-2 my-25 cursor-pointer"
                  height="90"
                  width="90"
                /> */}
                <div class="media-body mt-50">
                  <input
                    type="file"
                    name="myImage"
                    id="imagel1"
                    onChange={onImageChangel}
                  />
                </div>
              </div>
            </div>
            <hr class="my-2 btnhide" />
            <div class="card-datatable">
              <table class="table table-striped w-100">
                <thead>
                  <tr>
                    <th class="border border-primary"></th>
                    <th class="border border-primary align-middle" colSpan="4">
                      {window.$language.RIGHTEYE}
                    </th>
                    <th class="border border-primary align-middle" colSpan="4">
                      {window.$language.LEFTEYE}
                    </th>
                  </tr>
                  <tr>
                    <th></th>
                    <th>SPH</th>
                    <th>CYL</th>
                    <th>AXE</th>
                    <th>ADD</th>
                    {/*  <th>PD</th>
                    <th>PHIGH</th> */}

                    <th>SPH</th>
                    <th>CYL</th>
                    <th>AXE</th>
                    <th>ADD</th>
                    {/* <th>PD</th>
                     <th>PHIGH</th> */}
                  </tr>
                  <tr class="p-0">
                    <td class="p-1">Correction portée</td>
                    <td class="px-9 py-1">
                      <Controller
                        control={control}
                        name="testDetails.0.rsh"
                        defaultValue={isEdit == true ? state.rsh : options[80]}
                        render={({ field }) => (
                          <Select
                            onMenuOpen={onMenuOpen}
                            className={"eyetestdetails"}
                            classNamePrefix={"eyetestdetails"}
                            id="rsh"
                            options={options}
                            {...field}
                            placeholder="0.00"
                          />
                        )}
                      />
                    </td>
                    <td class="px-9 py-1">
                      <Controller
                        control={control}
                        name="testDetails.0.rcy"
                        defaultValue={isEdit == true ? state.rcy : options2[40]}
                        render={({ field }) => (
                          <Select
                            onMenuOpen={onMenuOpen}
                            className={"eyetestdetails"}
                            classNamePrefix={"eyetestdetails"}
                            id="rcy"
                            options={options2}
                            {...field}
                            placeholder="0.00"
                          />
                        )}
                      />
                    </td>
                    <td class="px-9 py-1">
                      <Controller
                        control={control}
                        name="testDetails.0.rax"
                        defaultValue={state.rax}
                        render={({ field }) => (
                          <Select
                            onMenuOpen={onMenuOpen}
                            className={"eyetestdetails"}
                            classNamePrefix={"eyetestdetails"}
                            id="rax"
                            options={options3}
                            {...field}
                            placeholder="0"
                          />
                        )}
                      />
                    </td>
                    <td class="px-9 py-1">
                      <Controller
                        control={control}
                        name="testDetails.0.rad"
                        defaultValue={state.rad}
                        render={({ field }) => (
                          <Select
                            onMenuOpen={onMenuOpen}
                            className={"eyetestdetails"}
                            classNamePrefix={"eyetestdetails"}
                            id="rad"
                            options={options5}
                            {...field}
                            placeholder="0.00"
                          />
                        )}
                      />
                    </td>
                    {/*  <td class="px-9 py-1">
                      <Controller
                        control={control}
                        name="testDetails.0.rpd"
                        defaultValue={state.rpd}
                        render={({ field }) => (
                          <Select
                                                  onMenuOpen={onMenuOpen}
                          className ={"eyetestdetails"}
                          classNamePrefix ={"eyetestdetails"}
                            id="rpd"
                            options={options4}
                            {...field}
                            placeholder="0.00"
                          />
                        )}
                      />
                    </td>
                    <td class="pad"><Select
                                            onMenuOpen={onMenuOpen}
                          className ={"eyetestdetails"}
                          classNamePrefix ={"eyetestdetails"} id='rph' class="form-control " placeholder="0.00" /></td> */}

                    <td class="px-9 py-1">
                      <Controller
                        control={control}
                        name="testDetails.1.lsh"
                        defaultValue={isEdit == true ? state.lsh : options[80]}
                        render={({ field }) => (
                          <Select
                            onMenuOpen={onMenuOpen}
                            className={"eyetestdetails"}
                            classNamePrefix={"eyetestdetails"}
                            id="lsh"
                            options={options}
                            {...field}
                            placeholder="0.00"
                          />
                        )}
                      />
                    </td>
                    <td class="px-9 py-1">
                      <Controller
                        control={control}
                        name="testDetails.1.lcy"
                        defaultValue={isEdit == true ? state.lcy : options2[40]}
                        render={({ field }) => (
                          <Select
                            onMenuOpen={onMenuOpen}
                            className={"eyetestdetails"}
                            classNamePrefix={"eyetestdetails"}
                            id="lcy"
                            options={options2}
                            {...field}
                            placeholder="0.00"
                          />
                        )}
                      />
                    </td>
                    <td class="px-9 py-1">
                      <Controller
                        control={control}
                        name="testDetails.1.lax"
                        defaultValue={state.lax}
                        render={({ field }) => (
                          <Select
                            onMenuOpen={onMenuOpen}
                            className={"eyetestdetails"}
                            classNamePrefix={"eyetestdetails"}
                            id="lax"
                            options={options3}
                            {...field}
                            placeholder="0"
                          />
                        )}
                      />
                    </td>
                    <td class="px-9 py-1">
                      <Controller
                        control={control}
                        name="testDetails.1.lad"
                        defaultValue={state.lad}
                        render={({ field }) => (
                          <Select
                            onMenuOpen={onMenuOpen}
                            className={"eyetestdetails"}
                            classNamePrefix={"eyetestdetails"}
                            id="lad"
                            options={options5}
                            {...field}
                            placeholder="0.00"
                          />
                        )}
                      />
                    </td>
                    {/* <td class="px-9 py-1">
                      <Controller
                        control={control}
                        name="testDetails.1.lpd"
                        defaultValue={state.lpd}
                        render={({ field }) => (
                          <Select
                          onMenuOpen={onMenuOpen}
                          className ={"eyetestdetails"}
                          classNamePrefix ={"eyetestdetails"}
                            id="lpd"
                            options={options4}
                            {...field}
                            placeholder="0.00"
                          />
                        )}
                      />
                    </td>
                     <td class="p-1"><input id='lph' class="form-control " placeholder="0.00" /></td> */}
                  </tr>
                  <tr>
                    <td class="p-1">Réfraction objective (autoref)*</td>
                    <td class="px-9 py-1">
                      <Controller
                        control={control}
                        name="testDetails.2.arsh"
                        defaultValue={isEdit == true ? state.arsh : options[80]}
                        render={({ field }) => (
                          <Select
                            onMenuOpen={onMenuOpen}
                            className={"eyetestdetails"}
                            classNamePrefix={"eyetestdetails"}
                            id="lax"
                            options={options}
                            {...field}
                            placeholder="0.00"
                          />
                        )}
                      />
                    </td>
                    <td class="px-9 py-1">
                      <Controller
                        control={control}
                        name="testDetails.2.arcy"
                        defaultValue={
                          isEdit == true ? state.arcy : options2[40]
                        }
                        render={({ field }) => (
                          <Select
                            onMenuOpen={onMenuOpen}
                            className={"eyetestdetails"}
                            classNamePrefix={"eyetestdetails"}
                            id="arcy"
                            options={options2}
                            {...field}
                            placeholder="0.00"
                          />
                        )}
                      />
                    </td>
                    <td class="px-9 py-1">
                      <Controller
                        control={control}
                        name="testDetails.2.arax"
                        defaultValue={state.arax}
                        render={({ field }) => (
                          <Select
                            onMenuOpen={onMenuOpen}
                            className={"eyetestdetails"}
                            classNamePrefix={"eyetestdetails"}
                            id="arax"
                            options={options3}
                            {...field}
                            placeholder="0"
                          />
                        )}
                      />
                    </td>
                    <td class="px-9 py-1">
                      <Controller
                        control={control}
                        name="testDetails.2.arad"
                        defaultValue={state.arad}
                        render={({ field }) => (
                          <Select
                            onMenuOpen={onMenuOpen}
                            className={"eyetestdetails"}
                            classNamePrefix={"eyetestdetails"}
                            id="arad"
                            options={options5}
                            {...field}
                            placeholder="0.00"
                          />
                        )}
                      />
                    </td>
                    {/*  <td class="px-9 py-1">
                      <Controller
                        control={control}
                        name="testDetails.2.arpd"
                        defaultValue={state.arpd}
                        render={({ field }) => (
                          <Select
                                                  onMenuOpen={onMenuOpen}
                          className ={"eyetestdetails"}
                          classNamePrefix ={"eyetestdetails"}
                            id="arpd"
                            options={options4}
                            {...field}
                            placeholder="0.00"
                          />
                        )}
                      />
                    </td>
                    {/* <td class="p-1"><Select
                                            onMenuOpen={onMenuOpen}
                          className ={"eyetestdetails"}
                          classNamePrefix ={"eyetestdetails"} id='arph' class="form-control " placeholder="0.00" /></td> */}
                    <td class="px-9 py-1">
                      <Controller
                        control={control}
                        name="testDetails.3.alsh"
                        defaultValue={isEdit == true ? state.alsh : options[80]}
                        render={({ field }) => (
                          <Select
                            onMenuOpen={onMenuOpen}
                            className={"eyetestdetails"}
                            classNamePrefix={"eyetestdetails"}
                            id="alsh"
                            options={options}
                            {...field}
                            placeholder="0.00"
                          />
                        )}
                      />
                    </td>
                    <td class="px-9 py-1">
                      <Controller
                        control={control}
                        name="testDetails.3.alcy"
                        defaultValue={
                          isEdit == true ? state.alcy : options2[40]
                        }
                        render={({ field }) => (
                          <Select
                            onMenuOpen={onMenuOpen}
                            className={"eyetestdetails"}
                            classNamePrefix={"eyetestdetails"}
                            id="alcy"
                            options={options2}
                            {...field}
                            placeholder="0.00"
                          />
                        )}
                      />
                    </td>
                    <td class="px-9 py-1">
                      <Controller
                        control={control}
                        name="testDetails.3.alax"
                        defaultValue={state.alax}
                        render={({ field }) => (
                          <Select
                            onMenuOpen={onMenuOpen}
                            className={"eyetestdetails"}
                            classNamePrefix={"eyetestdetails"}
                            id="alax"
                            options={options3}
                            {...field}
                            placeholder="0"
                          />
                        )}
                      />
                    </td>
                    <td class="px-9 py-1">
                      <Controller
                        control={control}
                        name="testDetails.3.alad"
                        defaultValue={state.alad}
                        render={({ field }) => (
                          <Select
                            onMenuOpen={onMenuOpen}
                            className={"eyetestdetails"}
                            classNamePrefix={"eyetestdetails"}
                            id="alad"
                            options={options5}
                            {...field}
                            placeholder="0.00"
                          />
                        )}
                      />
                    </td>
                    {/* <td class="px-9 py-1">
                      <Controller
                        control={control}
                        name="testDetails.3.alpd"
                        defaultValue={state.alpd}
                        render={({ field }) => (
                          <Select
                                                  onMenuOpen={onMenuOpen}
                          className ={"eyetestdetails"}
                          classNamePrefix ={"eyetestdetails"}
                            id="alpd"
                            options={options4}
                            {...field}
                            placeholder="0.00"
                          />
                        )}
                      />
                    </td>
                    {/* <td class="p-1"><input id='alph' class="form-control " placeholder="0.00" /></td> */}{" "}
                  </tr>
                  <i>Cochez la case pour copier les valeurs&nbsp;</i>
                  <input
                    class="m-0"
                    type="checkbox"
                    id="samel1"
                    name="samel1"
                    onClick={() => addressFunctionl1()}
                  />
                  <tr>
                    <td class="p-1">Réfraction subjective (manuelle)*</td>
                    <td class="px-9 py-1">
                      <Controller
                        control={control}
                        name="testDetails.4.brsh"
                        defaultValue={isEdit == true ? state.brsh : options[80]}
                        render={({ field }) => (
                          <Select
                            onMenuOpen={onMenuOpen}
                            className={"eyetestdetails"}
                            classNamePrefix={"eyetestdetails"}
                            id="brsh"
                            options={options}
                            {...field}
                            placeholder="0.00"
                          />
                        )}
                      />
                    </td>
                    <td class="px-9 py-1">
                      <Controller
                        control={control}
                        name="testDetails.4.brcy"
                        defaultValue={
                          isEdit == true ? state.brcy : options2[40]
                        }
                        render={({ field }) => (
                          <Select
                            onMenuOpen={onMenuOpen}
                            className={"eyetestdetails"}
                            classNamePrefix={"eyetestdetails"}
                            id="brcy"
                            options={options2}
                            {...field}
                            placeholder="0.00"
                          />
                        )}
                      />
                    </td>
                    <td class="px-9 py-1">
                      <Controller
                        control={control}
                        name="testDetails.4.brax"
                        defaultValue={state.brax}
                        render={({ field }) => (
                          <Select
                            onMenuOpen={onMenuOpen}
                            className={"eyetestdetails"}
                            classNamePrefix={"eyetestdetails"}
                            id="brax"
                            options={options3}
                            {...field}
                            placeholder="0"
                          />
                        )}
                      />
                    </td>
                    <td class="px-9 py-1">
                      <Controller
                        control={control}
                        name="testDetails.4.brad"
                        defaultValue={state.brad}
                        render={({ field }) => (
                          <Select
                            onMenuOpen={onMenuOpen}
                            className={"eyetestdetails"}
                            classNamePrefix={"eyetestdetails"}
                            id="brad"
                            options={options5}
                            {...field}
                            placeholder="0.00"
                          />
                        )}
                      />
                    </td>
                    {/*   <td class="px-9 py-1">
                      <Controller
                        control={control}
                        name="testDetails.4.brpd"
                        defaultValue={state.brpd}
                        render={({ field }) => (
                          <Select
                                                  onMenuOpen={onMenuOpen}
                          className ={"eyetestdetails"}
                          classNamePrefix ={"eyetestdetails"}
                            id="brpd"
                            options={options4}
                            {...field}
                            placeholder="0.00"
                          />
                        )}
                      />
                    </td>
                    <td class="px-9 py-1"><select id='brph' class="form-control " placeholder="0.00" /></td> */}

                    <td class="px-9 py-1">
                      <Controller
                        control={control}
                        name="testDetails.5.blsh"
                        defaultValue={isEdit == true ? state.blsh : options[80]}
                        render={({ field }) => (
                          <Select
                            onMenuOpen={onMenuOpen}
                            className={"eyetestdetails"}
                            classNamePrefix={"eyetestdetails"}
                            id="blsh"
                            options={options}
                            {...field}
                            placeholder="0.00"
                          />
                        )}
                      />
                    </td>
                    <td class="px-9 py-1">
                      <Controller
                        control={control}
                        name="testDetails.5.blcy"
                        defaultValue={
                          isEdit == true ? state.blcy : options2[40]
                        }
                        render={({ field }) => (
                          <Select
                            onMenuOpen={onMenuOpen}
                            className={"eyetestdetails"}
                            classNamePrefix={"eyetestdetails"}
                            id="blcy"
                            options={options2}
                            {...field}
                            placeholder="0.00"
                          />
                        )}
                      />
                    </td>
                    <td class="px-9 py-1">
                      <Controller
                        control={control}
                        name="testDetails.5.blax"
                        defaultValue={state.blax}
                        render={({ field }) => (
                          <Select
                            onMenuOpen={onMenuOpen}
                            className={"eyetestdetails"}
                            classNamePrefix={"eyetestdetails"}
                            id="blax"
                            options={options3}
                            {...field}
                            placeholder="0"
                          />
                        )}
                      />
                    </td>
                    <td class="px-9 py-1">
                      <Controller
                        control={control}
                        name="testDetails.5.blad"
                        defaultValue={state.blad}
                        render={({ field }) => (
                          <Select
                            onMenuOpen={onMenuOpen}
                            className={"eyetestdetails"}
                            classNamePrefix={"eyetestdetails"}
                            id="blad"
                            options={options5}
                            {...field}
                            placeholder="0.00"
                          />
                        )}
                      />
                    </td>
                    {/*  <td class="px-9 py-1">
                      <Controller
                        control={control}
                        name="testDetails.5.blpd"
                        defaultValue={state.blpd}
                        render={({ field }) => (
                          <Select
                                                  onMenuOpen={onMenuOpen}
                          className ={"eyetestdetails"}
                          classNamePrefix ={"eyetestdetails"}
                            id="blpd"
                            options={options4}
                            {...field}
                            placeholder="0.00"
                          />
                        )}
                      />
                    </td>
                     <td class="p-1"><input id='blph' class="form-control form-control-sm p-1" placeholder="0.00" /></td> */}
                  </tr>
                  <i>Cochez la case pour copier les valeurs&nbsp;</i>
                  <input
                    class="m-0"
                    type="checkbox"
                    id="samel"
                    name="samel"
                    onClick={() => addressFunctionl()}
                  />
                  <tr>
                    <td class="p-1">Recommandation opticien finale*</td>
                    <td class="px-9 py-1">
                      <Controller
                        control={control}
                        name="testDetails.6.crsh"
                        defaultValue={isEdit == true ? state.crsh : options[80]}
                        render={({ field }) => (
                          <Select
                            onMenuOpen={onMenuOpen}
                            className={"eyetestdetails"}
                            classNamePrefix={"eyetestdetails"}
                            id="crsh"
                            options={options}
                            {...field}
                            placeholder="0.00"
                          />
                        )}
                      />
                    </td>
                    <td class="px-9 py-1">
                      <Controller
                        control={control}
                        name="testDetails.6.crcy"
                        defaultValue={
                          isEdit == true ? state.crcy : options2[40]
                        }
                        render={({ field }) => (
                          <Select
                            onMenuOpen={onMenuOpen}
                            className={"eyetestdetails"}
                            classNamePrefix={"eyetestdetails"}
                            id="crcy"
                            options={options2}
                            {...field}
                            placeholder="0.00"
                          />
                        )}
                      />
                    </td>
                    <td class="px-9 py-1">
                      <Controller
                        control={control}
                        name="testDetails.6.crax"
                        defaultValue={state.crax}
                        render={({ field }) => (
                          <Select
                            onMenuOpen={onMenuOpen}
                            className={"eyetestdetails"}
                            classNamePrefix={"eyetestdetails"}
                            id="crax"
                            options={options3}
                            {...field}
                            placeholder="0"
                          />
                        )}
                      />
                    </td>
                    <td class="px-9 py-1">
                      <Controller
                        control={control}
                        name="testDetails.6.crad"
                        defaultValue={state.crad}
                        render={({ field }) => (
                          <Select
                            onMenuOpen={onMenuOpen}
                            className={"eyetestdetails"}
                            classNamePrefix={"eyetestdetails"}
                            id="crad"
                            options={options5}
                            {...field}
                            placeholder="0.00"
                          />
                        )}
                      />
                    </td>
                    {/* <td class="px-9 py-1">
                      <Controller
                        control={control}
                        name="testDetails.6.crpd"
                        defaultValue={state.crpd}
                        render={({ field }) => (
                          <Select
                                                  onMenuOpen={onMenuOpen}
                          className ={"eyetestdetails"}
                          classNamePrefix ={"eyetestdetails"}
                            id="crpd"
                            options={options4}
                            {...field}
                            placeholder="0.00"
                          />
                        )}
                      />
                    </td>
                     <td class="px-9 py-1"><Select
                                             onMenuOpen={onMenuOpen}
                          className ={"eyetestdetails"}
                          classNamePrefix ={"eyetestdetails"} id='crph' class="form-control form-control-sm px-9 py-1" placeholder="0.00" /></td> */}

                    <td class="px-9 py-1">
                      <Controller
                        control={control}
                        name="testDetails.7.clsh"
                        defaultValue={isEdit == true ? state.clsh : options[80]}
                        render={({ field }) => (
                          <Select
                            onMenuOpen={onMenuOpen}
                            className={"eyetestdetails"}
                            classNamePrefix={"eyetestdetails"}
                            id="clsh"
                            options={options}
                            {...field}
                            placeholder="0.00"
                          />
                        )}
                      />
                    </td>
                    <td class="px-9 py-1">
                      <Controller
                        control={control}
                        name="testDetails.7.clcy"
                        defaultValue={
                          isEdit == true ? state.clcy : options2[40]
                        }
                        render={({ field }) => (
                          <Select
                            onMenuOpen={onMenuOpen}
                            className={"eyetestdetails"}
                            classNamePrefix={"eyetestdetails"}
                            id="clcy"
                            options={options2}
                            {...field}
                            placeholder="0.00"
                          />
                        )}
                      />
                    </td>
                    <td class="px-9 py-1">
                      <Controller
                        control={control}
                        name="testDetails.7.clax"
                        defaultValue={state.clax}
                        render={({ field }) => (
                          <Select
                            onMenuOpen={onMenuOpen}
                            className={"eyetestdetails"}
                            classNamePrefix={"eyetestdetails"}
                            id="clax"
                            options={options3}
                            {...field}
                            placeholder="0"
                          />
                        )}
                      />
                    </td>
                    <td class="px-9 py-1">
                      <Controller
                        control={control}
                        name="testDetails.7.clad"
                        defaultValue={state.clad}
                        render={({ field }) => (
                          <Select
                            onMenuOpen={onMenuOpen}
                            className={"eyetestdetails"}
                            classNamePrefix={"eyetestdetails"}
                            id="clad"
                            options={options5}
                            {...field}
                            placeholder="0.00"
                          />
                        )}
                      />
                    </td>
                    {/*  <td class="px-9 py-1">
                      <Controller
                        control={control}
                        name="testDetails.7.clpd"
                        defaultValue={state.clpd}
                        render={({ field }) => (
                          <Select
                                                  onMenuOpen={onMenuOpen}
                          className ={"eyetestdetails"}
                          classNamePrefix ={"eyetestdetails"}
                            id="clpd"
                            options={options4}
                            {...field}
                            placeholder="0.00"
                          />
                        )}
                      />
                    </td>
                    <td class="p-1"><select id='clph' class="form-control " placeholder="0.00" /></td> */}
                  </tr>
                  <tr>
                    <td class="p-1">Acuité visuelle de près*</td>
                    <td class="p-1" colSpan="4">
                      <input
                        id="avdprl"
                        defaultValue={state.avdprl}
                        class="form-control form-control-sm p-1"
                        {...register("avdprl")}
                      />
                    </td>

                    <td class="p-1" colSpan="4">
                      <input
                        id="avdplgl"
                        class="form-control form-control-sm p-1"
                        {...register("avdplgl")}
                        defaultValue={state.avdplgl}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td class="p-1">Acuité visuelle de loin*</td>
                    <td class="p-1" colSpan="4">
                      <input
                        id="avdlrl"
                        class="form-control form-control-sm p-1"
                        {...register("avdlrl")}
                        defaultValue={state.avdlrl}
                      />
                    </td>

                    <td class="p-1" colSpan="4">
                      <input
                        id="avdlll"
                        defaultValue={state.avdlll}
                        class="form-control form-control-sm p-1"
                        {...register("avdlll")}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td class="p-1">Acuité visuelle sans correction de loin</td>
                    <td class="p-1" colSpan="4">
                      <input
                        // disabled={isEdit ? true : false}
                        defaultValue={state.avscrl}
                        class="form-control form-control-sm p-1"
                        {...register("avscrl")}
                      />
                    </td>

                    <td class="p-1" colSpan="4">
                      <input
                        // disabled={isEdit ? true : false}
                        defaultValue={state.avscll}
                        class="form-control form-control-sm p-1"
                        {...register("avscll")}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td class="p-1">Pression intra oculaire (PIO) mmHg</td>
                    <td class="p-1" colSpan="4">
                      <input
                        // disabled={isEdit ? true : false}
                        defaultValue={state.aprl}
                        class="form-control form-control-sm p-1"
                        {...register("aprl")}
                      />
                    </td>

                    <td class="p-1" colSpan="4">
                      <input
                        // disabled={isEdit ? true : false}
                        defaultValue={state.apll}
                        class="form-control form-control-sm p-1"
                        {...register("apll")}
                      />
                    </td>
                  </tr>
                </thead>
              </table>
            </div>
            <hr class="mb-2 btnhide" />
            <div class="col-lg-8">
              <div class="form-group d-flex flex-wrap align-items-center">
                {/* <label class="d-block pr-1 ml-1"></label> */}
                <h7 class="d-block pr-1">Test de Lang / # images vues :</h7>
                <div class="custom-control custom-radio ml-1 mr-3">
                  <input
                    type="radio"
                    id="Radio2396"
                    name="imagesVues1"
                    value="0"
                    class="custom-control-input"
                  />
                  <label class="custom-control-label" for="Radio2396">
                    0
                  </label>
                </div>
                <div class="custom-control custom-radio ml-1 mr-3">
                  <input
                    type="radio"
                    id="Radio2496"
                    name="imagesVues1"
                    value="1"
                    class="custom-control-input"
                  />
                  <label class="custom-control-label" for="Radio2496">
                    1
                  </label>
                </div>
                <div class="custom-control custom-radio ml-1 mr-3">
                  <input
                    type="radio"
                    id="Radio2596"
                    name="imagesVues1"
                    value="2"
                    class="custom-control-input"
                  />
                  <label class="custom-control-label" for="Radio2596">
                    2
                  </label>
                </div>
                <div class="custom-control custom-radio ml-1 mr-3">
                  <input
                    type="radio"
                    id="Radio2696"
                    name="imagesVues1"
                    value="3"
                    class="custom-control-input"
                  />
                  <label class="custom-control-label" for="Radio2696">
                    3
                  </label>
                </div>
                <div class="custom-control custom-radio ml-1 mr-3">
                  <input
                    type="radio"
                    id="Radio2796"
                    name="imagesVues1"
                    value="N/A"
                    class="custom-control-input"
                    defaultChecked
                  />
                  <label class="custom-control-label" for="Radio2796">
                    N/A
                  </label>
                </div>
              </div>
            </div>
            {state.utype == "BACKOFFICE" ||
            state.utype == "ADMIN" ||
            (state.utype == "OPTICIAN" &&
              state.isExternal !== null &&
              state.isExternal == "false") ? (
              <div className="col-lg-4">
                <div className="form-group align-baseline flex-row">
                  <label className="form-label required" htmlFor="login-email">
                    Résultat de l'examen :*
                  </label>
                  <select className="form-control required" id="resultExamine1">
                    <option value=""></option>
                    <option value="Devis">Devis</option>
                    <option value="Juste ordonnance">Juste ordonnance</option>
                    <option value="Vente">Vente</option>
                    <option value="Retour ophtalmologue">
                      Retour ophtalmologue
                    </option>
                    <option value="Sans ordonnance">Sans ordonnance</option>
                  </select>
                </div>
              </div>
            ) : null}
            <hr class="mb-2 btnhide" />
            <div class="form-row">
              {state.utype == "BACKOFFICE" ||
              state.utype == "ADMIN" ||
              (state.utype == "OPTICIAN" &&
                state.isExternal !== null &&
                state.isExternal == "false") ? (
                <div class="col-lg-4">
                  <label>Commentaire interne :</label>
                  {errors.noteOpt && errors.noteOpt?.type === "maxLength" ? (
                    <span className="text-danger py-2 inline-block">
                      (250 caractères maximum)
                    </span>
                  ) : null}
                  <textarea
                    type="text"
                    id="commentaireintel"
                    class="form-control flatpickr-basic"
                    placeholder="250 caractères maximum"
                    defaultValue={state.commentaireintel}
                    {...register("commentaireintel", { maxLength: 250 })}
                  />
                </div>
              ) : null}
              <div class="col-lg-4">
                <label>
                  Spécification lentilles (modèle / diamètre / rayon) :*
                </label>
                {errors.comlen && errors.comlen?.type === "maxLength" ? (
                  <span className="text-danger py-2 inline-block">
                    (250 caractères maximum)
                  </span>
                ) : null}
                <textarea
                  type="text"
                  id="comlen"
                  class="form-control flatpickr-basic"
                  {...register("comlen", { maxLength: 250 })}
                  defaultValue={state.comlen}
                  placeholder="250 caractères maximum"
                  disabled={isEdit ? true : false}
                />
              </div>
              <div class="col-lg-4">
                <label>Commentaire (à porter sur l'ordonnance) :</label>
                {errors.noteOpt && errors.noteOpt?.type === "maxLength" ? (
                  <span className="text-danger py-2 inline-block">
                    (250 caractères maximum)
                  </span>
                ) : null}
                <textarea
                  type="text"
                  id="noteOpt"
                  class="form-control flatpickr-basic"
                  placeholder="250 caractères maximum"
                  defaultValue={state.commentsForPrescription}
                  {...register("CommPrescripl", { maxLength: 250 })}
                />
              </div>
              <div class="col-lg-4">
                <label>Commentaire additionel :</label>
                {errors.noteoptl && errors.noteoptl?.type === "maxLength" ? (
                  <span className="text-danger py-2 inline-block">
                    (250 caractères maximum)
                  </span>
                ) : null}
                <textarea
                  type="text"
                  id="noteoptl"
                  class="form-control flatpickr-basic"
                  placeholder="250 caractères maximum"
                  {...register("noteoptl", { maxLength: 250 })}
                  defaultValue={state.noteoptl}
                />
              </div>
              <div class="col-lg-4">
                <div className="form-group align-baseline flex-row">
                  <label className="form-label required" for="login-email">
                    {window.$language.NeedsAPrescription} ?*
                  </label>
                  <select
                    class="form-control required"
                    {...register("ndpl")}
                    id="ndpl"
                  >
                    {state.isExternalDoctor == "false" ? (
                      <option value=""></option>
                    ) : null}
                    <option value="true">{window.$language.Yes}</option>
                    <option value="false">{window.$language.No}</option>
                  </select>
                </div>
              </div>
              <div class="col-lg-4">
                <div className="form-group align-baseline flex-row">
                  <label className="form-label required" for="login-email">
                    Acte gratuit
                  </label>
                  <select
                    class="form-control required"
                    defaultValue={
                      state.Actel == true
                        ? window.$language.Yes
                        : window.$language.No
                    }
                    {...register("Actel")}
                    id="Actel"
                  >
                    {" "}
                    <option value="true">{window.$language.Yes}</option>
                    <option value="false">{window.$language.No}</option>
                  </select>
                </div>
              </div>
              {state.utype == "OPTICIAN" ? (
                <div class="col-lg-4">
                  <label>Nom de la mutuelle (si connue)</label>
                  <input
                    type="text"
                    id="Nomdela1"
                    class="form-control flatpickr-basic"
                    defaultValue={state.Nomdela}
                    {...register("Nomdela1")}
                  />
                </div>
              ) : null}
              {state.utype == "ADMIN" ||
              (state.utype == "OPTICIAN" &&
                state.isExternal !== null &&
                state.isExternal == "false") ? (
                <>
                  <div className="col-lg-4">
                    <div className="form-group align-baseline flex-row">
                      <label
                        className="form-label required"
                        htmlFor="login-email"
                      >
                        Images rétino :*
                      </label>
                      <select
                        className="form-control required"
                        onChange={handleChange1}
                        id="imgret1"
                      >
                        <option value=""></option>
                        <option value="Yes">{window.$language.Yes}</option>
                        <option value="No">{window.$language.No}</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="form-group align-baseline flex-row">
                      <label
                        className="form-label required"
                        htmlFor="login-email"
                        id="labelval1"
                      >
                        Si non, veuillez spécifier
                      </label>
                      <select className="form-control required" id="specify1">
                        <option value=""></option>
                        <option value="Problème technique">
                          Problème technique
                        </option>
                        <option value="Pupille trop grosse">
                          Pupille trop grosse
                        </option>
                        <option value="Dimensions de la tête du patient">
                          Dimensions de la tête du patient
                        </option>
                        <option value="Positionnement incorrect du patient">
                          Positionnement incorrect du patient
                        </option>
                        <option value="Autre">Autre</option>
                      </select>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
            <div class="form-group d-flex flex-wrap align-items-center w-100">
        <div class="w-100">
            <p class="text-wrap">
                    Je confirme avoir informé mon client / patient que les
                    données relatives au test de vue ainsi les ordonnances
                    susceptibles d'être émises par les ophtalmologues
                    partenaires sur la base de ces données, seront
                    automatiquement partagées avec moi, à moins que ce partage
                    automatique ne soit désactivé sur son compte client, ce qui
                    peut-être fait directement en ligne ou en contactant le
                    service client de Sym Care Lab. 
                  </p>
                </div>
                <div class="custom-control custom-checkbox ml-1 mr-3 select-container56">
                  <input
                    type="checkbox"
                    id="check098700"
                    name="infoPrescriptionoptician1"
                    value="true"
                    class="custom-control-input"
                  />
                  <label class="custom-control-label" for="check098700"></label>
                </div>
            </div>
            <hr class="mb-2 btnhide" />
            <div class="form-row mb-1 ">
              <div class="media mb-2">
                <h6 class="p-1">Télécharger image fond d’oeil</h6>
                <div class="media-body mt-50">
                  <input
                    type="file"
                    name="myImage"
                    id="imagel1"
                    onChange={onImageChangel1}
                  />
                </div>
              </div>
              <div class="media mb-2 ">
                <h6 class="p-1">Télécharger image fond d’oeil</h6>
                <div class="media-body mt-50">
                  <input
                    type="file"
                    name="myImage"
                    id="imagel2"
                    onChange={onImageChangel2}
                  />
                </div>
              </div>
              <div class="media mb-2 d-none">
                <h6 class="p-1">Télécharger image fond d’oeil</h6>
                <div class="media-body mt-50">
                  <input
                    type="file"
                    name="myImage"
                    id="imagel3"
                    onChange={onImageChangel3}
                  />
                </div>
              </div>
            </div>
            {/* <hr class="my-2" /> */}
            {/* <button type="button" class="btn btn-outline-success" onClick={() => window.print()}>Capture d'écran</button> */}

            {/* <div class="paddingscreenshot">
              <button type="button" class="btnhide btn btn-outline-warning" onClick={handlePrint}>Capture d'écran</button>
            </div> */}

            <div>
              <button
                type="submit"
                class="btn btn-primary data-submit mr-1 m-1"
                // onClick={() => submitForm()}
              >
                {/* {window.$language.Submit} */}Envoyer
              </button>
              <button
                type="reset"
                onClick={reset}
                class="btn btn-outline-danger m-1"
                id="close1234"
                data-dismiss="modal"
              >
                {window.$language.Cancel}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
  const makeSelectOption = (k, i, valueName) => {
    return {
      value: k.testDetails[i][`${valueName}`],
      label: k.testDetails[i][`${valueName}`],
    };
  };
  const finalDate = (date) => {
    if (!date) {
      return;
    }
    date = new Date(date); // M-D-YYYY
    let d = date.getDate();
    let m = date.getMonth() + 1;
    let y = date.getFullYear();
    let dateString =
      y + "-" + (m <= 9 ? "0" + m : m) + "-" + (d <= 9 ? "0" + d : d);
    return dateString.toString();
  };
  const modify = (k) => {
    setIsEdit(true);
    $("#imgret").val(k.isImageRetino);
    if (k.isImageRetino == "No") {
      $("#specify").val(k.isImageRetinoSpecification);
      $("#specify").show();
      $("#labelval").show();
    } else {
      $("#specify").hide();
      $("#labelval").hide();
    }
    $("#imgret1").val(k.isImageRetino);
    if (k.isImageRetino == "No") {
      $("#specify1").show();
      $("#specify1").val(k.isImageRetinoSpecification);
      $("#labelval1").show();
    } else {
      $("#specify1").hide();
      $("#labelval1").hide();
    }
    $("#resultExamine").val(k.infoResultExamine),
      $("#resultExamine1").val(k.infoResultExamine),
      k.isContactLensType ? setIsEditType(1) : setIsEditType(0);
    console.log("This is k", k);
    if (k.symptoms != null) {
      let symp = k.symptoms.split(",");
      checkUncheck("Symptoms", symp);
    }
    if (k.dignosedwith != null) {
      let diag = k.dignosedwith.split(",");
      checkUncheck("diagnosed", diag);
    }
    if (k.healthConditions) {
      let health = k.healthConditions.split(",");
      checkUncheck("healthconditions", health);
    }
    if (k.infoImagesVues == "0") {
      $("#Radio2399").prop("checked", true);
      $("#Radio2396").prop("checked", true);
    } else if (k.infoImagesVues == "1") {
      $("#Radio2499").prop("checked", true);
      $("#Radio2496").prop("checked", true);
    } else if (k.infoImagesVues == "2") {
      $("#Radio2599").prop("checked", true);
      $("#Radio2596").prop("checked", true);
    } else if (k.infoImagesVues == "3") {
      $("#Radio2699").prop("checked", true);
      $("#Radio2696").prop("checked", true);
    } else if (k.infoImagesVues == "N/A") {
      $("#Radio2799").prop("checked", true);
      $("#Radio2796").prop("checked", true);
    } else {
      $("#Radio2399").prop("checked", false);
      $("#Radio2396").prop("checked", false);
      $("#Radio2499").prop("checked", false);
      $("#Radio2496").prop("checked", false);
      $("#Radio2599").prop("checked", false);
      $("#Radio2596").prop("checked", false);
      $("#Radio2699").prop("checked", false);
      $("#Radio2696").prop("checked", false);
      $("#Radio2799").prop("checked", false);
      $("#Radio2796").prop("checked", false);
    }
    $(
      "#validationRadio10,#validationRadio20,#valid10,#validationRadio10e,#validationRadio20e,#valid10e,.chk0,.chk1,.chk2,.chk3,.chk4,.chk5"
    ).attr("disabled", true);
    setPatientEmail(k.patientEmail);
    setPosid(k.posEmail);
    setopticianEmail(k.opticianEmail);
    setState((prevState) => {
      return {
        ...prevState,
        count: 2,
        opid: k.opticianEmail,
        symptoms: k.symptoms,
        testUniqueCode: k.testUniqueCode,
        testUniqueCode1: k.testUniqueCode,
        healthConditions: k.healthConditions,
        dignosedwith: k.dignosedwith,
        avdprg: k.info_Acuity_Near_Vision_Right_Eye,
        avdplg: k.info_Acuity_Near_Vision_Left_Eye,
        avdlrg: k.info_Acuity_Distance_Vision_Right_Eye,
        avdllg: k.info_Acuity_Distance_Vision_Left_Eye,
        avdprl: k.info_Acuity_Near_Vision_Right_Eye,
        avdplgl: k.info_Acuity_Near_Vision_Left_Eye,
        avdlrl: k.info_Acuity_Distance_Vision_Right_Eye,
        avdlll: k.info_Acuity_Distance_Vision_Left_Eye,
        avscrl: k.info_Acuity_visual_sans_correction_Right_Eye,
        avscll: k.info_Acuity_visual_sans_correction_Left_Eye,
        aprl: k.info_Eye_Pressure_Right_Eye,
        apll: k.info_Eye_Pressure_Left_Eye,
        avscrg: k.info_Acuity_visual_sans_correction_Right_Eye,
        avsclg: k.info_Acuity_visual_sans_correction_Left_Eye,
        aprg: k.info_Eye_Pressure_Right_Eye,
        aplg: k.info_Eye_Pressure_Left_Eye,
        noteOpt: k.noteOpt,
        noteoptl: k.noteOpt,
        posEmail: k.posEmail,
        patientemail: k.patientEmail,
        comlen: k.commentForLenses,
        isContactLens: k.isContactLensType,
        lastTimeDocl: k.lastTimeWentToEyeDoc,
        lastTimeDoc: k.lastTimeWentToEyeDoc,
        CommPrescripl: k.commentsForPrescription,
        CommPrescrip: k.commentsForPrescription,
        commentaireintel: k.infoCommentaireInterne,
        commentaireinte: k.infoCommentaireInterne,
        ndp: k.needPrescription,
        ndpl: k.needPrescription,
        Actel: k.acteGratuit,
        Acte: k.acteGratuit,
        imagesVues1: k.infoImagesVues,
        imagesVues: k.infoImagesVues,
        rcy: makeSelectOption(k, 0, "cyl"),
        rax: makeSelectOption(k, 0, "axe"),
        rad: makeSelectOption(k, 0, "add"),
        rpd: makeSelectOption(k, 0, "pd"),
        lsh: makeSelectOption(k, 1, "sph"),
        lcy: makeSelectOption(k, 1, "cyl"),
        lax: makeSelectOption(k, 1, "axe"),
        lad: makeSelectOption(k, 1, "add"),
        lpd: makeSelectOption(k, 1, "pd"),
        arsh: makeSelectOption(k, 2, "sph"),
        arcy: makeSelectOption(k, 2, "cyl"),
        arax: makeSelectOption(k, 2, "axe"),
        arad: makeSelectOption(k, 2, "add"),
        arpd: makeSelectOption(k, 2, "pd"),
        alsh: makeSelectOption(k, 3, "sph"),
        alcy: makeSelectOption(k, 3, "cyl"),
        alax: makeSelectOption(k, 3, "axe"),
        alad: makeSelectOption(k, 3, "add"),
        alpd: makeSelectOption(k, 3, "pd"),
        brsh: makeSelectOption(k, 4, "sph"),
        brcy: makeSelectOption(k, 4, "cyl"),
        brax: makeSelectOption(k, 4, "axe"),
        brad: makeSelectOption(k, 4, "add"),
        brpd: makeSelectOption(k, 4, "pd"),
        blsh: makeSelectOption(k, 5, "sph"),
        blcy: makeSelectOption(k, 5, "cyl"),
        blax: makeSelectOption(k, 5, "axe"),
        blad: makeSelectOption(k, 5, "add"),
        blpd: makeSelectOption(k, 5, "pd"),
        crsh: makeSelectOption(k, 6, "sph"),
        crcy: makeSelectOption(k, 6, "cyl"),
        crax: makeSelectOption(k, 6, "axe"),
        crad: makeSelectOption(k, 6, "add"),
        crpd: makeSelectOption(k, 6, "pd"),
        clsh: makeSelectOption(k, 7, "sph"),
        clcy: makeSelectOption(k, 7, "cyl"),
        clax: makeSelectOption(k, 7, "axe"),
        clad: makeSelectOption(k, 7, "add"),
        clpd: makeSelectOption(k, 7, "pd"),
        eyetestid1: k.testid,
      };
    });
    k.lastTimeWentToEyeDoc == "" ? setChecked(true) : setChecked(false);
    console.log("Lasttime doc", k.lastTimeWentToEyeDoc);
    setValue("patientemail", k.patientEmail);
    setValue("Actel", k.acteGratuit);
    setValue("Acte", k.acteGratuit);
    setValue("noteOpt", k.noteOpt);
    setValue("noteoptl", k.noteOpt);
    setValue("lastTimeDoc", finalDate(k.lastTimeWentToEyeDoc));
    setValue("avdprg", k.info_Acuity_Near_Vision_Right_Eye);
    setValue("avdplg", k.info_Acuity_Near_Vision_Left_Eye);
    setValue("avdlrg", k.info_Acuity_Distance_Vision_Right_Eye);
    setValue("avdllg", k.info_Acuity_Distance_Vision_Left_Eye);
    setValue("avdprl", k.info_Acuity_Near_Vision_Right_Eye);
    setValue("avdplgl", k.info_Acuity_Near_Vision_Left_Eye);
    setValue("avdlrl", k.info_Acuity_Distance_Vision_Right_Eye);
    setValue("avdlll", k.info_Acuity_Distance_Vision_Left_Eye);
    setValue("avscrl", k.info_Acuity_visual_sans_correction_Right_Eye);
    setValue("avscll", k.info_Acuity_visual_sans_correction_Left_Eye);
    setValue("aprl", k.info_Eye_Pressure_Right_Eye);
    setValue("apll", k.info_Eye_Pressure_Left_Eye);
    setValue("avscrg", k.info_Acuity_visual_sans_correction_Right_Eye);
    setValue("avsclg", k.info_Acuity_visual_sans_correction_Left_Eye);
    setValue("aprg", k.info_Eye_Pressure_Right_Eye);
    setValue("aplg", k.info_Eye_Pressure_Left_Eye);
    setValue("CommPrescripl", k.commentsForPrescription);
    setValue("CommPrescrip", k.commentsForPrescription);
    setValue("commentaireintel", k.infoCommentaireInterne);
    setValue("commentaireinte", k.infoCommentaireInterne);
    setValue("ndp", k.needPrescription);
    setValue("ndpl", k.needPrescription);
    setValue("testDetails.0.rsh", makeSelectOption(k, 0, "sph"));
    setValue("testDetails.0.rcy", makeSelectOption(k, 0, "cyl"));
    setValue("testDetails.0.rax", makeSelectOption(k, 0, "axe"));
    setValue("testDetails.0.rad", makeSelectOption(k, 0, "add"));
    setValue("testDetails.0.rpd", makeSelectOption(k, 0, "pd"));
    setValue("testDetails.1.lsh", makeSelectOption(k, 1, "sph"));
    setValue("testDetails.1.lcy", makeSelectOption(k, 1, "cyl"));
    setValue("testDetails.1.lax", makeSelectOption(k, 1, "axe"));
    setValue("testDetails.1.lad", makeSelectOption(k, 1, "add"));
    setValue("testDetails.1.lpd", makeSelectOption(k, 1, "pd"));
    setValue("testDetails.2.arsh", makeSelectOption(k, 2, "sph"));
    setValue("testDetails.2.arcy", makeSelectOption(k, 2, "cyl"));
    setValue("testDetails.2.arax", makeSelectOption(k, 2, "axe"));
    setValue("testDetails.2.arad", makeSelectOption(k, 2, "add"));
    setValue("testDetails.2.arpd", makeSelectOption(k, 2, "pd"));
    setValue("testDetails.3.alsh", makeSelectOption(k, 3, "sph"));
    setValue("testDetails.3.alcy", makeSelectOption(k, 3, "cyl"));
    setValue("testDetails.3.alax", makeSelectOption(k, 3, "axe"));
    setValue("testDetails.3.alad", makeSelectOption(k, 3, "add"));
    setValue("testDetails.3.alpd", makeSelectOption(k, 3, "pd"));
    setValue("testDetails.4.brsh", makeSelectOption(k, 4, "sph"));
    setValue("testDetails.4.brcy", makeSelectOption(k, 4, "cyl"));
    setValue("testDetails.4.brax", makeSelectOption(k, 4, "axe"));
    setValue("testDetails.4.brad", makeSelectOption(k, 4, "add"));
    setValue("testDetails.4.brpd", makeSelectOption(k, 4, "pd"));
    setValue("testDetails.5.blsh", makeSelectOption(k, 5, "sph"));
    setValue("testDetails.5.blcy", makeSelectOption(k, 5, "cyl"));
    setValue("testDetails.5.blax", makeSelectOption(k, 5, "axe"));
    setValue("testDetails.5.blad", makeSelectOption(k, 5, "add"));
    setValue("testDetails.5.blpd", makeSelectOption(k, 5, "pd"));
    setValue("testDetails.6.crsh", makeSelectOption(k, 6, "sph"));
    setValue("testDetails.6.crcy", makeSelectOption(k, 6, "cyl"));
    setValue("testDetails.6.crax", makeSelectOption(k, 6, "axe"));
    setValue("testDetails.6.crad", makeSelectOption(k, 6, "add"));
    setValue("testDetails.6.crpd", makeSelectOption(k, 6, "pd"));
    setValue("testDetails.7.clsh", makeSelectOption(k, 7, "sph"));
    setValue("testDetails.7.clcy", makeSelectOption(k, 7, "cyl"));
    setValue("testDetails.7.clax", makeSelectOption(k, 7, "axe"));
    setValue("testDetails.7.clad", makeSelectOption(k, 7, "add"));
    setValue("testDetails.7.clpd", makeSelectOption(k, 7, "pd"));
  };

  const add = (glasstype) => {
    glasstype == "lu"
      ? (state.isContactLens = false)
      : (state.isContactLens = true);
    setIsEdit(false);
    setState((prevState) => {
      return { ...prevState, count: 1, testno: "0" };
    });
    $("input,textarea,date").val("");
    $("input[type=checkbox],input[type=date]").attr("disabled", false);
    $("input[type=checkbox]").prop("checked", false);
    $("#imgret,#resultExamine,resultExamine1").val("");
    if ($("#imgret").val() === "Yes" || $("#imgret").val() === "") {
      $("#specify").hide();
      $("#labelval").hide();
    } else {
      $("#specify").show();
    }
    $("#imgret1").val("");
    if ($("#imgret1").val() === "Yes" || $("#imgret1").val() === "") {
      $("#specify1").hide();
      $("#labelval1").hide();
    } else {
      $("#specify1").show();
    }
    // setPosid("")
    reset();
  };
  const prereset = () => {};
  // const reset = () => {
  //   setValue("posid", "Saisir une option");
  // };
  const assign = () => {
    let leng = document.getElementsByName("deletecheck").length;
    //alert(leng)
    let arr1 = [];
    for (let i = 0; i < leng; i++) {
      if (document.getElementsByName("deletecheck")[i].checked) {
        arr1.push(document.getElementsByName("deletecheck")[i].value);
      }
    }
    console.log(arr1, "assigndata");
    swal({
      title: window.$language.Areyousure,
      text: window.$language.Areyousure,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        let logourl = API_URL + "/eyetests/assign";
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            authorization: localStorage.getItem("token"),
          },
          body: JSON.stringify({
            doctorEmail: document.getElementById("emails").value,
            tests: arr1,
          }),
        };
        fetch(logourl, requestOptions)
          .then((res) => res.json())
          .then((json) => {
            console.log(json);

            if (json == true) {
              swal({
                title: "Fait ! ",
                text: window.$language.AssignedSuccessfully,
                icon: "success",
              });
              document.getElementById("www4").click();
              for (let i = 0; i < leng; i++) {
                document.getElementsByName("deletecheck")[i].checked = false;
              }
              componentMount();
              geteyet();
              // this.componentDidMount();
            }
            //else {
            //   swal({
            //     title: "OOPS!",
            //     text: window.$language.Somethingwentwrong,
            //     icon: "warning",
            //   });
            // }
          });
        // .catch((e) => {
        //   swal({
        //     title: "OOPS!",
        //     text: window.$language.Somethingwentwrong,
        //     icon: "warning",
        //   });

        //   console.log(e);
        // });
      }
    });
  };

  const pemail = (email) => {
    let logourl = API_URL + "/eyetests/notify";
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("token"),
      },
      body: JSON.stringify({
        email: email,
      }),
    };
    fetch(logourl, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        //  document.getElementById("www4").click()

        console.log("email notified  ", email);

        //this.setState({opcarr:json})
      });
  };
  const back = () => {
    ReactDOM.render(
      <Patientreg patientemail={patientemail} />,
      document.getElementById("c11")
    );
  };
  const notify = () => {
    let leng = document.getElementsByName("deletecheck").length;
    //alert(leng)
    let arr1 = [];
    for (let i = 0; i < leng; i++) {
      if (document.getElementsByName("deletecheck")[i].checked) {
        arr1.push(document.getElementsByName("deletecheck")[i].value);
      }
    }
    console.log(arr1, "notyfydata");
    swal({
      title: window.$language.Areyousure,
      text: window.$language.AreyousuretoNotify,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        let logourl = API_URL + "/eyetests/notify";
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            authorization: localStorage.getItem("token"),
          },
          body: JSON.stringify({
            testNo: arr1,
          }),
        };
        fetch(logourl, requestOptions)
          .then((res) => res.json())
          .then((json) => {
            if (json == true) {
              swal({
                title: "Fait ! ",
                text: window.$language.NotifiedSuccessfully,
                icon: "success",
              });

              for (let i = 0; i < leng; i++) {
                document.getElementsByName("deletecheck")[i].checked = false;
              }
              geteyet();
              componentMount();
              // this.componentDidMount();
              //  document.getElementById("www4").click()
              // for (let i = 0; i < leng; i++) {
              //   document.getElementsByName("deletecheck")[i].checked = false;
            }
            //   } else {
            //     swal({
            //       title: "OOPS!",
            //       text: window.$language.Somethingwentwrong,
            //       icon: "warning",
            //     });
            //   }
            //   //this.setState({opcarr:json})
          });
        // .catch((e) => {
        //   swal({
        //     title: "OOPS!",
        //     text: window.$language.Somethingwentwrong,
        //     icon: "warning",
        //   });

        //   console.log(e);
        // });
      }
    });
  };
  function formatDate(dateString) {
    // Create a Date object from the dateString
    const date = new Date(dateString);

    // Get day, month, and year
    const day = date.getDate();
    const monthIndex = date.getMonth() + 1; // Add 1 since getMonth() returns zero-based index
    const year = date.getFullYear();

    // Convert month index to month abbreviation
    const months = [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ];
    const monthAbbreviation = months[monthIndex - 1]; // Subtract 1 to match array index

    // Format day and month to have leading zeros if needed
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = monthIndex < 10 ? `0${monthIndex}` : monthIndex;

    // Return the formatted date string
    return `${formattedDay}/${formattedMonth}/${year}`;
  }
  return viewEyeTest != "" ? (
    (console.log({ det } + "data"),
    (
      <Vieweyetest
        det={viewEyeTest}
        refresh={componentMount}
        showEyetest={setViewEyeTest}
      />
    ))
  ) : (
    <>
      {isLoading ? <SymLoader /> : ""}
      <div class="app-content content ">
        <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
        <div class="content-wrapper">
          <div class="content-header row"></div>
          <div class="content-body px-2 mt-3">
            <section id="accordion-with-margin">
              <div class="row">
                <div class="col-sm-12">
                  <div class="card collapse-icon">
                    <div class="card-body">
                      <div class="card-header border-bottom p-1 mb-1">
                        <div class="head-label d-flex align-items-center">
                          {props.heading != undefined ? null : (
                            <div onClick={back} className="px-2">
                              <i
                                class="fa fa-arrow-left arrow_icon"
                                aria-hidden="true"
                              ></i>
                            </div>
                          )}
                          <h4 id="name1" class="mb-0">
                            {props.heading != undefined
                              ? props.heading
                              : window.$language.ViewAllEyeTests}
                            <b id="patientnametest"></b>
                          </h4>
                        </div>
                        <div class="dt-action-buttons">
                          <div class="dt-buttons d-inline-flex">
                            {state.utype == "ADMIN" ? (
                              <>
                                {/* {props.status == "New" ||
                                props.status == "new" ||
                                props.status == "Not Approved" ? ( */}

                                {props.status == "" ||
                                props.status == "Approved" ? (
                                  <>
                                    <div class="col-lg-2">
                                      <div className="align-baseline flex-row">
                                        <div class="select-container">
                                          <Select
                                            placeholder="Mois"
                                            id="monthid"
                                            onChange={(selectedOption) =>
                                              setmonthexport(
                                                selectedOption.value
                                              )
                                            }
                                            options={[
                                              ...options6?.map((month) => ({
                                                value: month.value,
                                                label: month.label,
                                              })),
                                            ]}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-lg-2">
                                      <div className="form-group align-baseline flex-row">
                                        <div class="select-container">
                                          <Select
                                            placeholder="Année"
                                            id="yearhid"
                                            options={[
                                              ...(state.yeararr || [])?.map(
                                                (year) => ({
                                                  value: year,
                                                  label: year,
                                                })
                                              ),
                                            ]}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                ) : null}

                                <button
                                  onClick={() =>
                                    exportToExcel(
                                      props.status,
                                      props.unass == 1 ? "0" : "1"
                                    )
                                  }
                                  className="btn btn-primary"
                                  //ref={csvLink}
                                >
                                  Exporter des données (Excel)
                                </button>

                                {props.status == "" ? (
                                  <>
                                    <button
                                      onClick={() => exportToAllExcel()}
                                      className="btn btn-primary mx-1"
                                      //ref={csvLink}
                                    >
                                      Exporter toutes les données (Excel)
                                    </button>
                                  </>
                                ) : null}

                                {/* </> */}
                                {/* ) : null} */}
                              </>
                            ) : null}
                            {state.utype == "OPTICIAN" ||
                            state.utype == "BACKOFFICE" ||
                            state.utype == "ADMIN" ||
                            (state.utype == "EYEDOCTOR" &&
                              state.isExternalDoctor !== null &&
                              state.isExternalDoctor !== "false") ? (
                              <>
                                <li
                                  class="btn btn-outline-primary px-1 mx-1"
                                  data-menu="dropdown"
                                >
                                  <a
                                    class="dropdown-toggle nav-link d-flex align-items-center"
                                    href="index.html"
                                    data-toggle="dropdown"
                                  >
                                    <span>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        class="feather feather-plus mr-50  font-small-4"
                                      >
                                        <line
                                          x1="12"
                                          y1="5"
                                          x2="12"
                                          y2="19"
                                        ></line>
                                        <line
                                          x1="5"
                                          y1="12"
                                          x2="19"
                                          y2="12"
                                        ></line>
                                      </svg>
                                      Ajouter une nouvelle fiche
                                    </span>
                                  </a>
                                  <ul class="dropdown-menu">
                                    <li data-menu="">
                                      <a
                                        class="dropdown-item d-flex align-items-center"
                                        data-toggle="modal"
                                        data-target="#modals-slide-in"
                                        onClick={() => add("lu")}
                                      >
                                        <i class="far fa-plus-square mr-50"></i>
                                        <span> Lunettes</span>
                                      </a>
                                    </li>
                                    <li data-menu="">
                                      <a
                                        class="dropdown-item d-flex align-items-center"
                                        data-toggle="modal"
                                        data-target="#modals-slide-in-2"
                                        onClick={() => add("li")}
                                      >
                                        <i class="far fa-plus-square mr-50"></i>
                                        <span> Lentilles</span>
                                      </a>
                                    </li>
                                  </ul>
                                </li>
                              </>
                            ) : null}
                            {(state.utype == "OPTICIAN" &&
                              isExternal !== null &&
                              isExternal == "false") ||
                            state.utype == "BACKOFFICE" ||
                            state.utype == "ADMIN" ? (
                              <>
                                {(props.status === "New" ||
                                  props.status === "new") && (
                                  <button
                                    className="btn btn-outline-success"
                                    type="button" // Changed "reset" to "button" assuming it triggers some action
                                    id="assign"
                                    data-dismiss="modal"
                                    data-toggle="modal"
                                    data-target="#modals-slide-in-1"
                                  >
                                    {/* <i class="fas fa-angle-double-left mr-50"></i> */}
                                    <span>{window.$language.Assign}</span>
                                  </button>
                                )}
                              </>
                            ) : null}
                            {state.utype == "BACKOFFICE" ||
                            state.utype == "ADMIN" ? (
                              <>
                                {props.status == "Approved" ||
                                props.status == "approved" ? (
                                  <button
                                    type="reset"
                                    class="btn btn-outline-success ml-2 float-right"
                                    onClick={() => notify()}
                                    id="notify"
                                  >
                                    {window.$language.NotifyPatients}
                                  </button>
                                ) : null}
                              </>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div class="d-flex justify-content-between align-items-center mt-0 mx-0 row">
                        <div class="col-sm-12 col-md-2">
                          <div
                            class="dataTables_length"
                            id="DataTables_Table_0_length"
                          >
                            <label>
                              Afficher
                              <select
                                name="DataTables_Table_0_length"
                                onChange={(e) => {
                                  setItemsPerPage(Number(e.target.value));
                                  setIsLoading(true);
                                }}
                                aria-controls="DataTables_Table_0"
                                class="custom-select form-control"
                              >
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                                <option value={200}>200</option>
                                <option value={500}>500</option>
                                <option value={50000}>Afficher tout</option>
                              </select>
                            </label>
                          </div>
                        </div>
                        {state.utype !== "PAITENTS" ? (
                          <div class="col-sm-12 col-md-4 mr-0 pr-0">
                            <div class="d-flex justify-content-between align-items-center mt-0 mx-0 row">
                              <div className="col pt-1">
                                <input
                                  type="date"
                                  className="form-control rounded-lg shadow-sm"
                                  onChange={(event) =>
                                    setStartDate(event.target.value)
                                  }
                                  value={startDate}
                                />
                              </div>
                              <div className="col pt-1">
                                <input
                                  type="date"
                                  className="form-control rounded-lg shadow-sm"
                                  onChange={(event) =>
                                    setEndDate(event.target.value)
                                  }
                                  value={endDate}
                                />
                              </div>
                            </div>
                          </div>
                        ) : null}
                        <div class="col-sm-12 col-md-3  pt-1">
                          <div
                            id="DataTables_Table_0_filter"
                            class="dataTables_filter"
                          >
                            <label class="w-100">
                              <input
                                onChange={(data) =>
                                  getLocalEyeTest(data.target.value)
                                }
                                type="search"
                                class="form-control rounded-lg shadow-sm"
                                placeholder="Rechercher une information"
                                aria-controls="DataTables_Table_0"
                              />
                            </label>
                          </div>
                        </div>
                        <div class="col-sm-12 col-md-3 mr-0 pr-0  pt-1">
                          <div class="d-flex justify-content-between align-items-center mt-0 mx-0 row">
                            <div className="pr-1">
                              <button
                                className="btn btn-primary"
                                onClick={() => setIsLoading(true)}
                                disabled={isLoading}
                              >
                                {isLoading ? "Rechercher..." : "Rechercher"}
                              </button>
                            </div>
                            <div className="col">
                              <button
                                className="btn btn-outline-secondary"
                                onClick={handleReset}
                              >
                                Réinitialiser
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="d-flex justify-content-between align-items-center mt-0 mx-0 row">
                        <div class="col-sm-12 col-md-5"></div>
                      </div>
                      <div id="ff" class="my-2 mx-1 border p-0">
                        <div class="container-fluid p-0">
                          <div class="col-md-12 px-0">
                            <div class="panel panel-default">
                              <div class="panel-body">
                                <div class="table-responsive">
                                  <table class="table table-condensed table-striped">
                                    {console.log(
                                      state.eyearr,
                                      "this is eyearr"
                                    )}
                                    {/* {loadItems()} */}
                                    <tbody class="table table-condensed table-striped">
                                      {/* <tr>                                             
                                          <input type="text" hidden name="pmail" value={k.key.patientEmail} />  </tr> */}
                                      <tr>
                                        <td colspan="12" class="hiddenRow">
                                          <div class="" id={"demo"}>
                                            <table class="table table-striped">
                                              <thead>
                                                <tr class="info">
                                                  {state.utype ==
                                                    "BACKOFFICE" ||
                                                  state.utype == "ADMIN" ||
                                                  state.utype == "OPTICIAN" ? (
                                                    <th>
                                                      <input
                                                        type="checkbox"
                                                        id="selectAll"
                                                        class="selectall"
                                                      />
                                                      {/* <a class="p-1">
                                                        {
                                                          window.$language
                                                            .SELECT
                                                        }
                                                      </a> */}
                                                    </th>
                                                  ) : null}
                                                  {state.utype !== "PATIENT" ? (
                                                    <th>N° test</th>
                                                  ) : null}
                                                  {state.utype == "PATIENT" ? (
                                                    <th>
                                                      {window.$language.Actions}
                                                    </th>
                                                  ) : null}
                                                  {state.utype !== "PATIENT" ? (
                                                    <>
                                                      {state.utype !==
                                                      "EYEDOCTOR" ? (
                                                        <>
                                                          <th>
                                                            {
                                                              window.$language
                                                                .Optician
                                                            }
                                                          </th>
                                                          <th>PDV</th>
                                                        </>
                                                      ) : null}
                                                      <th>Patient</th>
                                                    </>
                                                  ) : null}
                                                  <th>
                                                    {window.$language.Created}
                                                  </th>
                                                  <th>Type</th>
                                                  <th>
                                                    {window.$language.Status}
                                                  </th>

                                                  {state.utype ==
                                                    "BACKOFFICE" ||
                                                  state.utype == "ADMIN" ? (
                                                    <th>Docteur</th>
                                                  ) : null}
                                                  {state.utype ==
                                                    "BACKOFFICE" ||
                                                  state.utype == "ADMIN" ||
                                                  (state.utype == "OPTICIAN" &&
                                                    state.isExternal !== null &&
                                                    state.isExternal ==
                                                      "false") ? (
                                                    <>
                                                      {props.unass == 1 &&
                                                      props.status == "New" ? (
                                                        <th>Retino</th>
                                                      ) : null}
                                                    </>
                                                  ) : null}
                                                  {state.utype !== "PATIENT" ? (
                                                    <th>
                                                      {window.$language.Actions}
                                                    </th>
                                                  ) : null}
                                                </tr>
                                              </thead>

                                              <tbody>
                                                {currentItems?.map((k, i) => (
                                                  <>
                                                    <tr class="trhidden">
                                                      {state.utype ==
                                                        "BACKOFFICE" ||
                                                      state.utype == "ADMIN" ||
                                                      state.utype ==
                                                        "OPTICIAN" ? (
                                                        <>
                                                          <td>
                                                            <input
                                                              type="checkbox"
                                                              class="checkbox"
                                                              name="deletecheck"
                                                              value={k.testId}
                                                            />
                                                          </td>
                                                        </>
                                                      ) : null}
                                                      {state.utype !==
                                                      "PATIENT" ? (
                                                        <td>
                                                          {k.testUniqueCode}
                                                        </td>
                                                      ) : null}
                                                      {state.utype ==
                                                      "PATIENT" ? (
                                                        <td>
                                                          <div
                                                            class="btn-group"
                                                            role="group"
                                                            aria-label="Basic example"
                                                          >
                                                            {localStorage.getItem(
                                                              "type"
                                                            ) == "PATIENT" &&
                                                            (k.status ==
                                                              "New" ||
                                                              k.status ==
                                                                "Not Approved") ? (
                                                              <p className="mr-1">
                                                                En cours
                                                                <br />
                                                                d'analyse
                                                              </p>
                                                            ) : (
                                                              <>
                                                                {state.utype ==
                                                                  "PATIENT" &&
                                                                k.needPrescription !==
                                                                  false &&
                                                                k.needPrescription !==
                                                                  "No" ? (
                                                                  <>
                                                                    {k.testType ==
                                                                    "EyeGlasses" ? (
                                                                      <>
                                                                        <button
                                                                          type="button"
                                                                          class="btn btn-outline-secondary"
                                                                          onClick={() =>
                                                                            Presglas(
                                                                              k.testId
                                                                            )
                                                                          }
                                                                          href="javascript:void(0);"
                                                                          data-toggle="tooltip"
                                                                          data-placement="top"
                                                                          title="Prescription"
                                                                        >
                                                                          <i class="fas fa-file-medical"></i>
                                                                        </button>
                                                                      </>
                                                                    ) : null}
                                                                    {k.testType ==
                                                                    "Lenses" ? (
                                                                      <>
                                                                        <button
                                                                          type="button"
                                                                          class="btn btn-outline-secondary"
                                                                          onClick={() =>
                                                                            Preslen(
                                                                              k.testId
                                                                            )
                                                                          }
                                                                          href="javascript:void(0);"
                                                                          data-toggle="tooltip"
                                                                          data-placement="top"
                                                                          title="Prescription"
                                                                        >
                                                                          <i class="fas fa-file-medical"></i>
                                                                        </button>
                                                                      </>
                                                                    ) : null}
                                                                  </>
                                                                ) : null}
                                                              </>
                                                            )}
                                                            {state.utype ==
                                                              "OPTICIAN" &&
                                                            k.showPrescriptiontoOptician ==
                                                              true ? (
                                                              <>
                                                                {k.testType ==
                                                                "EyeGlasses" ? (
                                                                  <>
                                                                    <button
                                                                      type="button"
                                                                      class="btn btn-outline-secondary"
                                                                      onClick={() =>
                                                                        Presglas(
                                                                          k.testId
                                                                        )
                                                                      }
                                                                      href="javascript:void(0);"
                                                                      data-toggle="tooltip"
                                                                      data-placement="top"
                                                                      title="Prescription"
                                                                    >
                                                                      <i class="fas fa-file-medical"></i>
                                                                    </button>
                                                                  </>
                                                                ) : null}
                                                                {k.testType ==
                                                                "Lenses" ? (
                                                                  <>
                                                                    <button
                                                                      type="button"
                                                                      class="btn btn-outline-secondary"
                                                                      onClick={() =>
                                                                        Preslen(
                                                                          k.testId
                                                                        )
                                                                      }
                                                                      href="javascript:void(0);"
                                                                      data-toggle="tooltip"
                                                                      data-placement="top"
                                                                      title="Prescription"
                                                                    >
                                                                      <i class="fas fa-file-medical"></i>
                                                                    </button>
                                                                  </>
                                                                ) : null}
                                                              </>
                                                            ) : null}

                                                            {localStorage.getItem(
                                                              "type"
                                                            ) == "PATIENT" ? (
                                                              <>
                                                                <button
                                                                  type="button"
                                                                  class="btn btn-outline-secondary"
                                                                  onClick={() =>
                                                                    geteyetid(
                                                                      k.testId
                                                                    )
                                                                  }
                                                                  href="javascript:void(0);"
                                                                  data-toggle="tooltip"
                                                                  data-placement="top"
                                                                  title="View Details"
                                                                >
                                                                  <i class="far fa-eye fa-fw"></i>
                                                                </button>
                                                              </>
                                                            ) : null}
                                                            {state.utype ==
                                                              "BACKOFFICE" ||
                                                            state.utype ==
                                                              "ADMIN" ? (
                                                              <>
                                                                <button
                                                                  type="button"
                                                                  class="btn btn-outline-secondary "
                                                                  data-toggle="modal"
                                                                  data-target="#modals-slide-in"
                                                                  onClick={() =>
                                                                    geteyeteditid(
                                                                      k.testId
                                                                    )
                                                                  }
                                                                  href="javascript:void(0);"
                                                                  data-placement="top"
                                                                  title="Edit"
                                                                >
                                                                  <i class="far fa-edit fa-fw"></i>
                                                                </button>
                                                              </>
                                                            ) : null}
                                                            {state.utype ==
                                                            "ADMIN" ? (
                                                              <>
                                                                <button
                                                                  type="button"
                                                                  class="btn btn-outline-secondary "
                                                                  onClick={() =>
                                                                    deleteEytest(
                                                                      k.testId
                                                                    )
                                                                  }
                                                                  href="javascript:void(0);"
                                                                  data-toggle="tooltip"
                                                                  data-placement="top"
                                                                  title="Delete"
                                                                >
                                                                  <i class="far fa-trash-alt fa-fw"></i>
                                                                </button>
                                                              </>
                                                            ) : null}
                                                          </div>
                                                        </td>
                                                      ) : null}
                                                      {state.utype !==
                                                      "PATIENT" ? (
                                                        <>
                                                          {state.utype !==
                                                          "EYEDOCTOR" ? (
                                                            <>
                                                              <td>
                                                                {k.opticianName}
                                                              </td>
                                                              <td>
                                                                {k.posName}
                                                              </td>
                                                            </>
                                                          ) : null}
                                                          <td>
                                                            {k.patientName}
                                                          </td>
                                                        </>
                                                      ) : null}
                                                      <td>
                                                        {formatDate(
                                                          k.createDate
                                                        )}
                                                      </td>
                                                      <td>
                                                        {k.testType ==
                                                        "EyeGlasses"
                                                          ? "Lunettes"
                                                          : "Lentilles"}
                                                      </td>
                                                      <td>
                                                        <span class="badge badge-pill badge-light-primary mr-1 status-badge">
                                                          {k.status ==
                                                          "Approved"
                                                            ? window.$language
                                                                .Approved
                                                            : k.status ==
                                                              "Not Approved"
                                                            ? window.$language
                                                                .NotApproved
                                                            : "Nouveau"}
                                                        </span>
                                                      </td>

                                                      {state.utype ==
                                                        "BACKOFFICE" ||
                                                      state.utype == "ADMIN" ? (
                                                        <>
                                                          {props.doctorName ==
                                                            "" ||
                                                          k.needPrescription ==
                                                            "No" ? (
                                                            <td>N/A</td>
                                                          ) : (
                                                            <td>
                                                              {k.doctorName}
                                                            </td>
                                                          )}
                                                        </>
                                                      ) : null}
                                                      {state.utype ==
                                                        "BACKOFFICE" ||
                                                      state.utype == "ADMIN" ||
                                                      (state.utype ==
                                                        "OPTICIAN" &&
                                                        state.isExternal !==
                                                          null &&
                                                        state.isExternal ==
                                                          "false") ? (
                                                        <>
                                                          {k.assignedToDoc ==
                                                            "No" &&
                                                          k.status == "New" &&
                                                          props.unass == 1 ? (
                                                            <td>
                                                              {k.imageRetino ==
                                                                "" ||
                                                              k.imageRetino ==
                                                                null
                                                                ? ""
                                                                : k.imageRetino ==
                                                                  "Yes"
                                                                ? "Oui"
                                                                : "Non"}
                                                            </td>
                                                          ) : null}
                                                        </>
                                                      ) : (
                                                        ""
                                                      )}
                                                      {state.utype !==
                                                      "PATIENT" ? (
                                                        <td>
                                                          <div
                                                            class="btn-group"
                                                            role="group"
                                                            aria-label="Basic example"
                                                          >
                                                            {localStorage.getItem(
                                                              "type"
                                                            ) == "PATIENT" &&
                                                            (k.status ==
                                                              "New" ||
                                                              k.status ==
                                                                "Not Approved") ? (
                                                              <p>
                                                                En cours
                                                                d'analyse
                                                              </p>
                                                            ) : (
                                                              <>
                                                                {state.utype ==
                                                                "PATIENT" ? (
                                                                  <>
                                                                    {k.needPrescription ==
                                                                      "Yes" &&
                                                                    k.showPrescriptiontoOptician ==
                                                                      "Yes" &&
                                                                    k.status ==
                                                                      "Approved" ? (
                                                                      <>
                                                                        {k.testType ==
                                                                        "EyeGlasses" ? (
                                                                          <>
                                                                            <button
                                                                              type="button"
                                                                              class="btn btn-outline-secondary"
                                                                              onClick={() =>
                                                                                Presglas(
                                                                                  k.testId
                                                                                )
                                                                              }
                                                                              href="javascript:void(0);"
                                                                              data-toggle="tooltip"
                                                                              data-placement="top"
                                                                              title="Prescription"
                                                                            >
                                                                              <i class="fas fa-file-medical"></i>
                                                                            </button>
                                                                          </>
                                                                        ) : null}
                                                                        {k.testType ==
                                                                        "Lenses" ? (
                                                                          <>
                                                                            <button
                                                                              type="button"
                                                                              class="btn btn-outline-secondary"
                                                                              onClick={() =>
                                                                                Preslen(
                                                                                  k.testId
                                                                                )
                                                                              }
                                                                              href="javascript:void(0);"
                                                                              data-toggle="tooltip"
                                                                              data-placement="top"
                                                                              title="Prescription"
                                                                            >
                                                                              <i class="fas fa-file-medical"></i>
                                                                            </button>
                                                                          </>
                                                                        ) : null}
                                                                      </>
                                                                    ) : null}
                                                                  </>
                                                                ) : null}
                                                              </>
                                                            )}

                                                            {state.utype ==
                                                              "OPTICIAN" &&
                                                            k.status ==
                                                              "Approved" &&
                                                            k.showPrescriptiontoOptician ==
                                                              "Yes" &&
                                                            k.needPrescription ==
                                                              "Yes" ? (
                                                              <>
                                                                {k.testType ==
                                                                "EyeGlasses" ? (
                                                                  <>
                                                                    <button
                                                                      type="button"
                                                                      class="btn btn-outline-secondary"
                                                                      onClick={() =>
                                                                        Presglas(
                                                                          k.testId
                                                                        )
                                                                      }
                                                                      href="javascript:void(0);"
                                                                      data-toggle="tooltip"
                                                                      data-placement="top"
                                                                      title="Prescription"
                                                                    >
                                                                      <i class="fas fa-file-medical"></i>
                                                                    </button>
                                                                  </>
                                                                ) : null}
                                                                {k.testType ==
                                                                "Lenses" ? (
                                                                  <>
                                                                    <button
                                                                      type="button"
                                                                      class="btn btn-outline-secondary"
                                                                      onClick={() =>
                                                                        Preslen(
                                                                          k.testId
                                                                        )
                                                                      }
                                                                      href="javascript:void(0);"
                                                                      data-toggle="tooltip"
                                                                      data-placement="top"
                                                                      title="Prescription"
                                                                    >
                                                                      <i class="fas fa-file-medical"></i>
                                                                    </button>
                                                                  </>
                                                                ) : null}
                                                              </>
                                                            ) : null}
                                                            {state.utype ==
                                                              "BACKOFFICE" &&
                                                            k.status ==
                                                              "Approved" ? (
                                                              <>
                                                                {k.testType ==
                                                                "EyeGlasses" ? (
                                                                  <>
                                                                    <button
                                                                      type="button"
                                                                      class="btn btn-outline-secondary"
                                                                      onClick={() =>
                                                                        Presglas(
                                                                          k.testId
                                                                        )
                                                                      }
                                                                      href="javascript:void(0);"
                                                                      data-toggle="tooltip"
                                                                      data-placement="top"
                                                                      title="Prescription"
                                                                    >
                                                                      <i class="fas fa-file-medical"></i>
                                                                    </button>
                                                                  </>
                                                                ) : null}
                                                                {k.testType ==
                                                                "Lenses" ? (
                                                                  <>
                                                                    <button
                                                                      type="button"
                                                                      class="btn btn-outline-secondary"
                                                                      onClick={() =>
                                                                        Preslen(
                                                                          k.testId
                                                                        )
                                                                      }
                                                                      href="javascript:void(0);"
                                                                      data-toggle="tooltip"
                                                                      data-placement="top"
                                                                      title="Prescription"
                                                                    >
                                                                      <i class="fas fa-file-medical"></i>
                                                                    </button>
                                                                  </>
                                                                ) : null}
                                                              </>
                                                            ) : null}
                                                            {localStorage.getItem(
                                                              "type"
                                                            ) == "PATIENT" &&
                                                            (k.status ==
                                                              "New" ||
                                                              k.status ==
                                                                "Not Approved") ? null : (
                                                              <>
                                                                <button
                                                                  type="button"
                                                                  class="btn btn-outline-secondary d-flex"
                                                                  onClick={() =>
                                                                    geteyetid(
                                                                      k.testId,
                                                                      i
                                                                    )
                                                                  }
                                                                  href="javascript:void(0);"
                                                                  data-toggle="tooltip"
                                                                  data-placement="top"
                                                                  title="View Details"
                                                                >
                                                                  {loaderIdx ===
                                                                  i ? (
                                                                    "..."
                                                                  ) : (
                                                                    <i class="far fa-eye fa-fw"></i>
                                                                  )}
                                                                  {/* {isViewLoading && (i===index)?'...':<i class="far fa-eye fa-fw"></i>} */}
                                                                </button>
                                                              </>
                                                            )}
                                                            {state.utype ==
                                                              "BACKOFFICE" ||
                                                            state.utype ==
                                                              "ADMIN" ||
                                                            state.utype ==
                                                              "OPTICIAN" ? (
                                                              <>
                                                                {k.status ==
                                                                  "New" ||
                                                                k.status ==
                                                                  "new" ? (
                                                                  <>
                                                                    {k.assignedToDoc !==
                                                                    "Yes" ? (
                                                                      <button
                                                                        type="button"
                                                                        class="btn btn-outline-secondary "
                                                                        data-toggle="modal"
                                                                        data-target="#modals-slide-in"
                                                                        onClick={() => {
                                                                          geteyeteditid(
                                                                            k.testId
                                                                          );
                                                                        }}
                                                                        href="javascript:void(0);"
                                                                        data-placement="top"
                                                                        title="Edit"
                                                                      >
                                                                        <i class="far fa-edit fa-fw"></i>
                                                                      </button>
                                                                    ) : null}
                                                                  </>
                                                                ) : null}
                                                              </>
                                                            ) : null}
                                                            {state.utype ==
                                                            "ADMIN" ? (
                                                              <>
                                                                <button
                                                                  type="button"
                                                                  class="btn btn-outline-secondary "
                                                                  onClick={() =>
                                                                    deleteEytest(
                                                                      k.testId
                                                                    )
                                                                  }
                                                                  href="javascript:void(0);"
                                                                  data-toggle="tooltip"
                                                                  data-placement="top"
                                                                  title="Delete"
                                                                >
                                                                  <i class="far fa-trash-alt fa-fw"></i>
                                                                </button>
                                                              </>
                                                            ) : null}
                                                          </div>
                                                        </td>
                                                      ) : null}
                                                    </tr>
                                                    {/* </>
                                                                                                            ))} */}
                                                  </>
                                                ))}
                                              </tbody>
                                            </table>
                                            <ReactPaginate
                                              nextLabel="suivant >"
                                              onPageChange={handlePageClick}
                                              pageRangeDisplayed={3}
                                              marginPagesDisplayed={2}
                                              pageCount={pageCount}
                                              previousLabel="< retour"
                                              pageClassName="page-item"
                                              pageLinkClassName="page-link"
                                              previousClassName="page-item"
                                              previousLinkClassName="page-link"
                                              nextClassName="page-item"
                                              nextLinkClassName="page-link"
                                              breakLabel="..."
                                              breakClassName="page-item"
                                              breakLinkClassName="page-link"
                                              containerClassName="pagination"
                                              activeClassName="active"
                                              renderOnZeroPageCount={null}
                                            />
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {form1}

                      {form2}

                      <div
                        class="modal modal-slide-in fade"
                        id="modals-slide-in-1"
                      >
                        <div class="modal-dialog sidebar-sm">
                          <form class="add-new-record modal-content pt-0">
                            <button
                              type="button"
                              class="close"
                              id="www4"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              ×
                            </button>
                            <div class="modal-header mb-1">
                              <h5 class="modal-title" id="exampleModalLabel">
                                {window.$language.Doctor}
                              </h5>
                            </div>
                            <div class="modal-body flex-grow-1">
                              <div class="">
                                <form class="dt_adv_search" method="POST">
                                  <div class="col-lg-12">
                                    <div class="form-group">
                                      <label for="languages">
                                        {window.$language.AssignDoctor}:
                                      </label>
                                      <select id="emails" class="form-control">
                                        <span class="select2-search select2-search--dropdown">
                                          <input
                                            class="select2-search__field"
                                            type="search"
                                            tabindex="0"
                                            autocomplete="off"
                                            autocorrect="off"
                                            autocapitalize="none"
                                            spellcheck="false"
                                            role="searchbox"
                                            aria-autocomplete="list"
                                            aria-controls="select2-y9ao-results"
                                            aria-activedescendant="select2-y9ao-result-pbn3-IN"
                                          />
                                        </span>
                                        {state.docarr?.map((usr) => (
                                          <option>{usr.email}</option>
                                        ))}
                                      </select>
                                    </div>
                                  </div>
                                </form>
                              </div>
                              <button
                                type="button"
                                class="btn btn-primary data-submit mr-1 m-1"
                                onClick={() => assign()}
                              >
                                {window.$language.Assign}
                              </button>
                              <button
                                type="reset"
                                class="btn btn-outline-secondary m-1"
                                id="close"
                                data-dismiss="modal"
                              >
                                {window.$language.Cancel}
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            {/* Advanced Search*/}
          </div>
        </div>
      </div>
    </>
  );
}

export default Eyetest;
