import React, { useState, useEffect, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import ReactPaginate from "react-paginate";
import ReactDOM from "react-dom";
import swal from "sweetalert";
import { API_URL } from "../../../config";
import Paideta from "./viewpatientdetails";
import SymLoader1 from "../../SymLoader/SymLoader1";
import SymLoader2 from "../../SymLoader/Symloader2";
import $, { data, event } from "jquery";
//import { CSVLink } from "react-csv";
import * as XLSX from "xlsx";
import "./Patientreg.css";
//import { ExcelFile, ExcelSheet } from "react-data-export";
function Pacientreg(props) {
  const [state, setState] = useState({
    emailID: "",
    pwd: "",
    loginIP: "",
    loginBrowser: "",
    deviceType: "",
    userLocation: "",
    logIsp: "",
    ii: 0,
    actions: [],
    pLogo: "",
    patientarr: [],
    patientarrall: [],
    count: 1,
    utype: localStorage.getItem("type"),
    isExternalDoctor: localStorage.getItem("isExternalDoctor"),
    isFirstname: localStorage.getItem("FirstName"),
    isLastname: localStorage.getItem("LastName"),
    //dob1:0
  });
  const [pDraftState, setPDraftState] = useState();
  const [selectedFile, setSelectedFile] = useState(null);
  const [checkedData, setCheckedData] = useState([]);
  const [userId, setUserId] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoading2, setIsLoading2] = useState(false);
  const [isViewLoading, setIsViewLoading] = useState(false);
  const [isAddPDraft, setIsAddPDraft] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [pageNumber, setPageNumber] = useState(1);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const {
    register,
    handleSubmit,
    reset,
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({ mode: "onChange" });
  const [currentItems, setCurrentItems] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [index, setIndex] = useState(0);
  const [fileName, setFileName] = useState("");
  const [fileErrMsg, setFileErrMsg] = useState(false);
  const [tab, setTab] = useState(1);
  const fileRef = useRef();
  const csvLink = useRef();
  const handlePageClick = (event) => {
    setPageNumber(event.selected + 1);
    setIsLoading(true);
  };
  const getLocalEyeTest = (search) => {
    setSearchText(search);
    localStorage.setItem("searchtext", search);
  };
  const handleReset = () => {
    setStartDate(""); // Reset startDate to an empty string or default value
    setEndDate(""); // Reset endDate to an empty string or default value
    setSearchText("");
    setIsLoading(true);
    localStorage.setItem("searchtext", "");
  };
  const componentMount = () => {
    setState((prevState) => ({
      ...prevState,
      patientarr: JSON.parse(localStorage.getItem("PATIENT")),
    }));
    console.log(localStorage.getItem("OPTICIAN"));
    //getpinfo("", "");
    if (tab == 1) getpinfo("", "");
    else PDraftInfo();
  };
  useEffect(() => {
    if (isLoading) {
      prereset();
      componentMount();
    }
  }, [isLoading]);
  const getpinfo = (email, mob, search = "") => {
    ///var email = props.patientemail == undefined ? email :props.patientemail
    var searchtext =
      searchText != "" ? searchText : localStorage.getItem("searchtext");
    var token = localStorage.getItem("token");
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({
        email: email,
        mobile: mob,
        search: searchtext,
        pageNumber: pageNumber,
        pageSize: itemsPerPage,
        startDate: startDate,
        endDate: endDate,
      }),
    };
    let logourl = API_URL + "/patient/NewsearchPatients";
    fetch(logourl, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        console.log("getinfo", json);
        console.log(props);
        console.log(
          Math.ceil(json.totalItems / itemsPerPage),
          "jkdsajkdsajsdajk"
        );
        setPageCount(Math.ceil(json.totalItems / itemsPerPage));
        if (json.userProfileData != null) {
          let currentDetails = json.userProfileData.filter(
            (val) => val.email === state.emailID
          );
          console.log("currentADetails", currentDetails);

          {
            setState((prevState) => {
              return { ...prevState, patientarr: json.userProfileData };
            });
            setCurrentItems(json.userProfileData);
          }
        }

        setIsLoading(false);
      });
  };

  const PDraftInfo = () => {
    if (
      localStorage.getItem("type") != "ADMIN" &&
      localStorage.getItem("type") != "BACKOFFICE"
    ) {
      $(".importfileclass").hide();
    }
    // setIsLoading2(true);
    var token = localStorage.getItem("token");
    const purl = API_URL + "/patient/NewsearchPatientsDraft";
    const payload = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({
        email: "",
        mobile: "",
        name: "",
        search: searchText,
        latestRecords: 0,
        pageNumber: pageNumber,
        pageSize: 50,
        startDate: "",
        endDate: "",
      }),
    };
    fetch(purl, payload)
      .then((res) => res.json())
      .then((json) => {
        console.log("pdraftInfo1210:", json);
        setPageCount(Math.ceil(json.totalItems / 50));
        setPDraftState(json.userProfileData);
        $("#email,#confirmEmail").prop("disabled", false);
        // setIsLoading2(false);
      })
      .catch((err) => console.log("error", err));
    setIsLoading(false);
  };

  const handleChange_age = (e) => {
    //   console.log("DOB:", event.target.value);

    //setState({ dob1: event.target.value });
    //   console.log(state.dob1);
    var age_latest = { age_latest: calculate_age(e.target.value) };
    //   console.log(age_latest);
    //   state.utype;
    // alert(age_latest)
  };
  const calculate_age = (dob1) => {
    console.log("Age1:", dob1);
    var today = new Date();
    var birthDate = new Date(dob1);
    var age_now = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age_now--;
    }
    console.log(age_now);
    document.getElementById("Age1").value = age_now;
    //alert("Calculated Age"+" "+ age_now)
    return age_now;
  };
  const exportToExcel = async () => {
    const logourl = `${API_URL}/patient/export`;
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("token"),
      },
    };

    try {
      const response = await fetch(logourl, requestOptions);
      const json = await response.json();

      const ws = XLSX.utils.json_to_sheet(json);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Patients");

      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const url = URL.createObjectURL(data);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "patients_Data.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error(error);
    }
  };
  const removerd = (email, id) => {
    if (tab == 1) {
      swal({
        title: "Are you sure?",
        text: "Do you want delete?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          let logourl = API_URL + "/user/delete";
          const requestOptions = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              authorization: localStorage.getItem("token"),
            },
            body: JSON.stringify({
              email: email,
            }),
          };
          fetch(logourl, requestOptions)
            .then((res) => res.json())
            .then((json) => {
              if (json == true) {
                swal({
                  title: "Done!",
                  text: window.$language.DeletedSuccessfully,
                  icon: "success",
                });
                componentMount();
              } else {
                swal({
                  title: "OOPS!",
                  text: window.$language.Somethingwentwrong,
                  icon: "warning",
                });
              }
              //this.setState({opcarr:json})
            })
            .catch((e) => {
              swal({
                title: "OOPS!",
                text: window.$language.Somethingwentwrong,
                icon: "warning",
              });

              console.log(e);
            });
        }
      });
    } else {
      swal({
        title: "Are you sure?",
        text: "Do you want delete?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          let delUrl = API_URL + "/patient/PatientDraftDelete";
          const requestOptions = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              authorization: localStorage.getItem("token"),
            },
            body: JSON.stringify({
              userId: id,
            }),
          };
          fetch(delUrl, requestOptions)
            .then((res) => res.json())
            .then((json) => {
              if (json == true) {
                swal({
                  title: "Done!",
                  text: window.$language.DeletedSuccessfully,
                  icon: "success",
                });
                PDraftInfo();
              } else {
                swal({
                  title: "OOPS!",
                  text: window.$language.Somethingwentwrong,
                  icon: "warning",
                });
              }
            })
            .catch((err) => {
              console.log("error", err);
              swal({
                title: "OOPS!",
                text: window.$language.Somethingwentwrong,
                icon: "warning",
              });
            });
        }
      });
    }
  };
  const submit = () => {
    if (tab == 2) {
      return PDraftSubmit("");
    }
    if (Age1.value < 16 || Age1.value > 110) {
      swal({
        title: "OOPS!",
        text: "Ce n'est pas possible de créer un compte pour les patients qui ont moins de 16 ans",
        icon: "warning",
      });
      return false;
    }
    var email = document.getElementById("email").value;
    var confirmEmail = document.getElementById("confirmEmail").value;
    if (email === confirmEmail) {
      swal({
        title: "",
        text: "Email match",
        icon: "warning",
      });
    } else {
      swal({
        title: "OOPS!",
        text: "Veuillez vérifier que les emails soient identiques.",
        icon: "warning",
      });
      return;
    }
    var ret = false;
    $("#inputform")
      .find(".form-group, .form-row")
      .find(".form-control.required")
      .each(function () {
        if ($(this).val() == "") {
          swal({
            title: "OOPS!",
            text:
              $(this).parent().find("label").html() +
              window.$language.cannotbeblank,
            icon: "warning",
          });
          ret = true;
          return false;
        }
      });

    if (ret) return false;
    /* Validations */

    let wgval = true;
    if (
      document.getElementById("wg").value == "false" ||
      document.getElementById("wg").value == ""
    ) {
      wgval = false;
    }
    let iopcre = isEdit
      ? document.getElementById("isprescrip").value !== "false" &&
        document.getElementById("isprescrip").value !== ""
      : true;
    let data1 = {
      email: document.getElementById("email").value,
      password: "SymInfo@#2024",
      mobile: document.getElementById("PH").value,
      userType: "PATIENT",
      languageId: 1,
      name: document.getElementById("fname").value,
      lastName: document.getElementById("lname").value,
      dateOfBirth: document.getElementById("DOB").value,
      doYouWearGlasses: wgval,
      whenIsTheLastTimeYouWentToTheEyeDoctor: "",
      patientNameOfYourUsualEyeDoctor: document.getElementById("Namedoc").value,
      patientRppsNumberOfYourUsualEyeDoctor: 0,
      phoneNumber: 0,
      typeOfVision: document.getElementById("visi").value,
      patientHappy: "",
      patientSymptoms: "",
      patientDiagnosedWith: "",
      patientHealthConditions: "",
      additionalComments: document.getElementById("Addcom").value,
      doctorRppsNumber: "",
      doctorIdOfRegistration: "",
      doctorAddressOfTheHealthCenter: document.getElementById("ADDDOC").value,
      city: "",
      gender: document.getElementById("gen").value,
      age: document.getElementById("Age1").value,
      SubmitTrack: "Web",
      infoPrescriptionToOptician: iopcre,
      infoSSN: document.getElementById("inssn").value,
    };
    swal({
      title: "Confirmation adresse email",
      text: "Êtes vous sûr(e) d'avoir écrit l'adresse mail correctement ?",
      icon: "warning",
      buttons: true,
      showCancelButton: true,
      cancelButtonColor: "#d33",
    }).then((willDelete) => {
      if (willDelete) {
        let logourl = isEdit
          ? API_URL + "/user/edit"
          : API_URL + "/patient/add";
        let msg = isEdit
          ? window.$language.PatientDetailseditedsuccessfully
          : window.$language.PatientDetailsAddedsuccessfully;
        // if (state.count == 1 && isEdit=="false") {
        //   logourl = API_URL + "/patient/add";
        //   msg = window.$language.PatientDetailsAddedsuccessfully;
        // } else {
        //   logourl = API_URL + "/user/edit";
        //   msg = window.$language.PatientDetailseditedsuccessfully;
        // }
        //////////////////////////////////////////////
        //let logourl = API_URL + "/patient/add";
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            authorization: localStorage.getItem("token"),
          },
          body: JSON.stringify(data1),
        };
        const content = document.createElement("div");
        const input = document.createElement("img");
        input.src =
          "https://miro.medium.com/max/875/1*CsJ05WEGfunYMLGfsT2sXA.gif";
        input.width = "250";
        content.appendChild(input);

        swal({
          text: window.$language.PleaseWait,

          allowOutsideClick: false,
          buttons: false,
          content,
        });
        fetch(logourl, requestOptions)
          .then((res) => res.json())
          .then((json) => {
            if (json == 0) {
              swal({
                title: "Oops!",
                text: window.$language.Thisemailalreadyexists,
                icon: "warning",
              });
              document.getElementById("close").click();
              componentMount();
              return;
            }

            swal({
              title: "Done!",
              text: msg,
              icon: "success",
            });
            document.getElementById("close").click();
            componentMount();
            callView(document.getElementById("email").value);
          })
          .catch((e) => {
            swal({
              title: "OOPS!",
              text: window.$language.TryAgain,
              icon: "warning",
            });

            console.log(e);
          });
      }
    }); //////
  };

  const PDraftSubmit = (userid) => {
    setIsLoading2(true);
    let data1 = {
      userId: userid == "" ? "" : userid,
      email: document.getElementById("email").value,
      firstName: document.getElementById("fname").value,
      lastName: document.getElementById("lname").value,
      dateOfBirth: document.getElementById("DOB").value,
      phoneNumber: document.getElementById("PH").value,
      address: document.getElementById("ADDDOC").value,
      password: "SymInfo2024",
      mobile: document.getElementById("PH").value,
      userType: "PATIENT",
      languageId: 1,
      doYouWearGlasses:
        document.getElementById("wg").value == ""
          ? null
          : document.getElementById("wg").value == "false"
          ? false
          : true,
      whenIsTheLastTimeYouWentToTheEyeDoctor: "",
      patientNameOfYourUsualEyeDoctor: document.getElementById("Namedoc").value,
      patientRppsNumberOfYourUsualEyeDoctor: 0,
      phoneNumber: document.getElementById("PH").value,
      typeOfVision: document.getElementById("visi").value,
      additionalComments: document.getElementById("Addcom").value,
      doctorAddressOfTheHealthCenter: document.getElementById("ADDDOC").value,
      gender: document.getElementById("gen").value,
      age: document.getElementById("Age1").value,
    };
    if (userid == "") {
      document.getElementById("close").click();
    }
    const url = API_URL + "/patient/PatientDraftUpdate";
    const payloadData = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("token"),
      },
      body: JSON.stringify(data1),
    };
    fetch(url, payloadData)
      .then((res) => res.json())
      .then((json) => {
        if (json) {
          if (isAddPDraft) {
            swal({
              title: "Notification",
              text: "Patient Draft Added Successfully",
              icon: "success",
            });
          }
          PDraftInfo();

          if (userid != "") {
            handleApprove();
          }
        }
      })
      .catch((err) => console.log("error", err));
    setIsLoading2(false);
    setIsAddPDraft(false);
  };

  const Paidetails = (email) => {
    var token = localStorage.getItem("token");
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({
        email: email,
        pageNumber: 1,
        pageSize: itemsPerPage,
      }),
    };
    let logourl = API_URL + "/patient/NewsearchPatients";
    fetch(logourl, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        setState((prevState) => {
          return { ...prevState, patientarr: json.userProfileData };
        });

        paideta(json.userProfileData[0]);
      })
      .catch((e) => {
        swal({
          title: "OOPS!",
          text: window.$language.Somethingwentwrong,
          icon: "warning",
        });
        console.log(e);
      });
  };
  const paideta = (usr) => {
    console.log("hi");
    ReactDOM.render(<Paideta usr={usr} />, document.getElementById("c11"));
    //ReactDOM.render(<Prel row={det} />, document.getElementById("c11"));
  };
  const edit = (usr) => {
    console.log("user data", usr);
    setCheckedData([usr]);
    const currentYear = new Date().getFullYear();
    const dobArr = usr.dateOfBirth.split(" ");
    let age = currentYear - dobArr[dobArr.length - 1];
    setIsEdit(true);
    setState((prevState) => {
      return { ...prevState, count: 2 };
    });
    // prereset();
    document.getElementById("email").value = usr.email;
    document.getElementById("confirmEmail").value = usr.email;
    document.getElementById("ADDDOC").value = usr.address;
    document.getElementById("PH").value = usr.phoneNumber;
    document.getElementById("Age1").value = age;
    if (tab == 1) document.getElementById("fname").value = usr.name;
    else document.getElementById("fname").value = usr.firstName;
    document.getElementById("lname").value = usr.lastName;
    document.getElementById("Addcom").value = usr.additionalComments;
    document.getElementById("Namedoc").value =
      usr.patientNameOfYourUsualEyeDoctor;
    document.getElementById("gen").value = usr.gender;
    document.getElementById("confirmEmail").value = usr.email;
    document.getElementById("wg").value = usr.doYouWearGlasses;
    var date = usr.dateOfBirth.split(" ");
    var months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    for (var j = 0; j < months.length; j++) {
      if (date[1] == months[j]) {
        date[1] = months.indexOf(months[j]) + 1;
      }
    }
    if (date[1] < 10) {
      date[1] = "0" + date[1];
    }
    var formattedDate = date[2] + "-" + date[1] + "-" + date[0];
    console.log(formattedDate);
    document.getElementById("DOB").value = formattedDate;
    var date = usr.whenIsTheLastTimeYouWentToTheEyeDoctor.split(" ");
    var months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    for (var j = 0; j < months.length; j++) {
      if (date[1] == months[j]) {
        date[1] = months.indexOf(months[j]) + 1;
      }
    }
    if (date[1] < 10) {
      date[1] = "0" + date[1];
    }
    var formattedDate = date[2] + "-" + date[1] + "-" + date[0];
    console.log(formattedDate);
    //document.getElementById("lasttime").value = formattedDate;
    document.getElementById("Namedoc").value =
      usr.patientNameOfYourUsualEyeDoctor;
    document.getElementById("PH").value = usr.mobile;
    document.getElementById("visi").value = usr.typeOfVision;
    document.getElementById("Addcom").value = usr.additionalComments;
    document.getElementById("ADDDOC").value =
      usr.doctorAddressOfTheHealthCenter;
    document.getElementById("gen").value = usr.gender;
    document.getElementById("inssn").value = usr.infoSSN;
    document.getElementById("isprescrip").value =
      usr.infoPrescriptionToOptician;
    $("#email").prop("disabled", true);
    $("#confirmEmail").prop("disabled", true);
  };
  const add = () => {
    setIsEdit(false);
    setState((prevState) => {
      return { ...prevState, count: 1 };
    });
    prereset();
  };
  const prereset = () => {
    document.getElementById("email").value = "";
    //document.getElementById("PPP").value=""
    document.getElementById("PH").value = "";
    document.getElementById("Age1").value = "";
    document.getElementById("fname").value = "";
    document.getElementById("lname").value = "";
    document.getElementById("DOB").value = "";
    document.getElementById("confirmEmail").value = "";
    document.getElementById("Namedoc").value = "";

    document.getElementById("PH").value = "";
    document.getElementById("visi").value = "";
    document.getElementById("Addcom").value = "";

    document.getElementById("ADDDOC").value = "";
    document.getElementById("gen").value = "";
    document.getElementById("wg").value = "";
    document.getElementById("inssn").value = "";
    $("#email").prop("disabled", false);
    $("#confirmEmail").prop("disabled", false);
  };

  const handleFile = (e) => {
    setSelectedFile(e.target.files[0]);
    const getFileArr = e.target.files[0].name.split(".");
    const getfileType = getFileArr[getFileArr.length - 1];
    setFileName(e.target.files[0].name);
    if (getfileType != "xlsx") {
      setFileErrMsg(true);
      swal({
        title: "Erreur de type de fichier",
        text: "Veuillez sélectionner un type de fichier valide, c'est-à-dire .xlsx",
        icon: "warning",
      });
    } else {
      setFileErrMsg(false);
    }
  };

  const handleExportFile = () => {
    if (!fileErrMsg && fileName !== "") {
      setIsLoading2(true);
      const formData = new FormData();
      formData.append("fileExcel", selectedFile);
      const url = API_URL + "/patient/PatientExcelFile";
      fetch(url, {
        method: "POST",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        body: formData,
      })
        .then((res) => res.text())
        .then((text) => {
          if (text == "success") {
            swal({
              title: "Notification",
              text: "Le brouillon Excel du patient a été téléchargé avec succès",
              icon: "success",
            });
            PDraftInfo();
          } else {
            swal({
              title: "Erreur de sélection de fichier",
              text: text,
              icon: "warning",
            });
          }
          setIsLoading2(false);
        })
        .catch((err) => console.log("error", err));
    } else {
      swal({
        title: "Erreur de sélection de fichier",
        text: "Veuillez sélectionner un type de fichier valide, c'est-à-dire .xlsx",
        icon: "warning",
      });
    }
  };
  const handleApprove = () => {
    if (checkedData.length !== 0) {
      const requiredData = checkedData.map((item) => {
        return {
          userId: userId,
          email: document.getElementById("email").value,
          password: "SymInfo2024",
          mobile: document.getElementById("PH").value,
          userType: "PATIENT",
          languageId: 1,
          name: document.getElementById("fname").value,
          firstName: document.getElementById("fname").value,
          lastName: document.getElementById("lname").value,
          dateOfBirth: document.getElementById("DOB").value,
          doYouWearGlasses: Boolean(document.getElementById("wg").value),
          whenIsTheLastTimeYouWentToTheEyeDoctor: "",
          patientNameOfYourUsualEyeDoctor:
            document.getElementById("Namedoc").value,
          patientRppsNumberOfYourUsualEyeDoctor: 0,
          phoneNumber: document.getElementById("PH").value,
          typeOfVision: document.getElementById("visi").value,
          patientHappy: "",
          patientSymptoms: "",
          patientDiagnosedWith: "",
          patientHealthConditions: "",
          additionalComments: document.getElementById("Addcom").value,
          doctorRppsNumber: "",
          doctorIdOfRegistration: "",
          doctorAddressOfTheHealthCenter:
            document.getElementById("ADDDOC").value,
          posid: "",
          city: "",
          gender: document.getElementById("gen").value,
          age: document.getElementById("Age1").value,
          drDesignation: "",
          patientUniqueCode: "",
          SubmitTrack: "Web",
        };
      });

      if (Age1.value < 16 || Age1.value > 110) {
        swal({
          title: "OOPS!",
          text: "Ce n'est pas possible de créer un compte pour les patients qui ont moins de 16 ans",
          icon: "warning",
        });
        return;
      }
      var email = document.getElementById("email").value;
      var confirmEmail = document.getElementById("confirmEmail").value;
      if (email !== confirmEmail) {
        // swal({
        //   title: "",
        //   text: "Email match",
        //   icon: "warning",
        // });
        swal({
          title: "OOPS!",
          text: "Veuillez vérifier que les emails soient identiques.",
          icon: "warning",
        });
        return;
      }
      // else
      // {
      //   swal({
      //     title: "OOPS!",
      //     text: "Veuillez vérifier que les emails soient identiques.",
      //     icon: "warning",
      //   });
      //   return;
      // }

      if (
        requiredData[0].firstName == "" ||
        requiredData.lastName == "" ||
        requiredData[0].email == "" ||
        requiredData[0].dateOfBirth == "" ||
        requiredData[0].phoneNumber == "" ||
        requiredData[0].gender == "" ||
        requiredData[0].doYouWearGlasses == ""
      ) {
        return swal({
          title: "OOPS!",
          text: "Veuillez remplir tous les champs obligatoires pour pouvoir créer le compte patient.",
          icon: "warning",
        });
      }
      $("#close").trigger("click");
      setIsLoading2(true);
      const url = API_URL + "/patient/PatientDraftApprove";
      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
        body: JSON.stringify(requiredData),
      })
        .then((res) => res.text())
        .then((text) => {
          if (text == "success") {
            swal({
              title: "Notification",
              text: "Le brouillon du patient a été approuvé avec succès.",
              icon: "success",
            });
            setCheckedData([]);
            // PDraftInfo();
            // getpinfo();
            callView(document.getElementById("email").value);
          } else {
            swal({
              title: "Erreur de sélection de fichier",
              text: text,
              icon: "warning",
            });
          }
          setIsLoading2(false);
        })
        .catch((err) => console.log("error", err));
    }
  };
  const handleAddPDraft = () => {
    prereset();
    setIsAddPDraft(true);
  };

  const callView = (usr, idx) => {
    setIndex(idx);
    setIsViewLoading(true);
    setTimeout(() => {
      Paidetails(usr);
    }, 1000);
  };
  function formatDate(dateString) {
    // Create a Date object from the dateString
    const date = new Date(dateString);

    // Get day, month, and year
    const day = date.getDate();
    const monthIndex = date.getMonth() + 1; // Add 1 since getMonth() returns zero-based index
    const year = date.getFullYear();

    // Convert month index to month abbreviation
    const months = [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ];
    const monthAbbreviation = months[monthIndex - 1]; // Subtract 1 to match array index

    // Format day and month to have leading zeros if needed
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = monthIndex < 10 ? `0${monthIndex}` : monthIndex;

    // Return the formatted date string
    return `${formattedDay}/${formattedMonth}/${year}`;
  }
  return (
    <>
      {isLoading || isLoading2 ? <SymLoader1 /> : null}
      <div class="app-content content ">
        <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
        <div class="content-wrapper">
          <div class="content-header row"></div>
          <div class="content-body px-2 mt-3">
            <section id="accordion-with-margin">
              <div class="row">
                <div class="col-sm-12">
                  <div class="card collapse-icon">
                    <div class="card-body p-0">
                      <div class="card-header border-bottom p-1 mb-1">
                        <div class="head-label d-flex">
                          <h4
                            class={`mb-0 p-1 px-2 rounded border ${
                              tab == 1 ? "selected-tab" : ""
                            }`}
                            onClick={() => {
                              setTab(1);
                              getpinfo();
                              setPageNumber(1);
                            }}
                            id="cursor"
                          >
                            {window.$language.ViewPatients}
                          </h4>
                          {state.isExternalDoctor == "false" ? (
                            <h4
                              class={`mb-0 p-1 pe-auto px-2 rounded border ${
                                tab == 2 ? "selected-tab" : ""
                              }`}
                              onClick={() => {
                                setTab(2);
                                PDraftInfo();
                                setPageNumber(1);
                              }}
                              id="cursor1"
                            >
                              Brouillons
                            </h4>
                          ) : null}
                        </div>
                        <div
                          class={`dt-action-buttons text-right ${
                            tab == 1 ? "" : "d-none"
                          }`}
                        >
                          <div class="dt-buttons d-inline-flex">
                            {state.utype == "ADMIN" ? (
                              <>
                                <button
                                  onClick={exportToExcel}
                                  className="btn btn-primary m-1"
                                  //ref={csvLink}
                                >
                                  Exporter des données (Excel)
                                </button>
                              </>
                            ) : null}
                            <button
                              onClick={() => add()}
                              class="btn btn-primary m-1"
                              tabindex="0"
                              aria-controls="DataTables_Table_0"
                              type="button"
                              data-toggle="modal"
                              data-target="#modals-slide-in"
                            >
                              <span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  class="feather feather-plus mr-50 font-small-4"
                                >
                                  <line x1="12" y1="5" x2="12" y2="19"></line>
                                  <line x1="5" y1="12" x2="19" y2="12"></line>
                                </svg>
                                {window.$language.AddNewRecord}
                              </span>
                            </button>
                          </div>
                        </div>
                        <div
                          class={`dt-action-buttons text-right ${
                            tab == 2 ? "" : "d-none"
                          }`}
                        >
                          <div class="dt-buttons d-inline-flex position-relative">
                            {/* {state.utype !=="OPTICIAN" &&<> */}
                            <button
                              onClick={() => fileRef.current.click()}
                              className="btn border m-1 bg-light importfileclass"
                            >
                              Sélectionner un ficher
                            </button>
                            <input
                              type="file"
                              ref={fileRef}
                              onChange={handleFile}
                              className="d-none"
                            />
                            {fileName && (
                              <div
                                className="position-absolute"
                                style={{ left: "20px", bottom: "-9px" }}
                              >
                                {fileName}
                              </div>
                            )}
                            <button
                              class="btn bg-info text-white m-1 importfileclass"
                              tabindex="0"
                              aria-controls="DataTables_Table_0"
                              type="button"
                              onClick={handleExportFile}
                            >
                              Importer un fichier Excel
                            </button>
                            {/* </>} */}
                            <button
                              type="button"
                              class="btn btn-primary m-1 "
                              data-toggle="modal"
                              data-target="#modals-slide-in"
                              onClick={handleAddPDraft}
                            >
                              Ajouter une nouvelle fiche
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="d-flex justify-content-between align-items-center mt-0 mx-0 row">
                        <div class="col-sm-2 col-md-2 pt-1">
                          <select
                            name="DataTables_Table_0_length"
                            onChange={(e) => {
                              setItemsPerPage(Number(e.target.value));
                              setIsLoading(true);
                            }}
                            aria-controls="DataTables_Table_0"
                            class="custom-select form-control"
                          >
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="200">200</option>
                            <option value="20000">Afficher tout</option>
                          </select>
                        </div>
                        {state.utype !== "EYEDOCTOR" &&
                        state.utype !== "PAITENT" ? (
                          <div class="col-sm-12 col-md-4 mr-0 pr-0">
                            <div class="d-flex justify-content-between align-items-center mt-0 mx-0 row">
                              <div className="col pt-1">
                                <input
                                  type="date"
                                  className="form-control rounded-lg shadow-sm"
                                  onChange={(event) =>
                                    setStartDate(event.target.value)
                                  }
                                  value={startDate}
                                />
                              </div>
                              <div className="col pt-1">
                                <input
                                  type="date"
                                  className="form-control rounded-lg shadow-sm"
                                  onChange={(event) =>
                                    setEndDate(event.target.value)
                                  }
                                  value={endDate}
                                />
                              </div>
                            </div>
                          </div>
                        ) : null}
                        <div class="col-sm-12 col-md-3  pt-1">
                          <div
                            id="DataTables_Table_0_filter"
                            class="dataTables_filter"
                          >
                            <label class="w-100">
                              <input
                                onChange={(data) =>
                                  getLocalEyeTest(data.target.value)
                                }
                                type="search"
                                class="form-control rounded-lg shadow-sm"
                                placeholder="Rechercher une information"
                                aria-controls="DataTables_Table_0"
                              />
                            </label>
                          </div>
                        </div>
                        {state.utype !== "EYEDOCTOR" &&
                        state.utype !== "PAITENT" ? (
                          <div class="col-sm-12 col-md-3 mr-0 pr-0  pt-1">
                            <div class="d-flex justify-content-between align-items-center mt-0 mx-0 row">
                              <div className="col">
                                <button
                                  className="btn btn-primary"
                                  onClick={() => setIsLoading(true)}
                                  disabled={isLoading}
                                >
                                  {isLoading ? "Rechercher..." : "Rechercher"}
                                </button>
                              </div>
                              <div className="col">
                                <button
                                  className="btn btn-outline-secondary"
                                  onClick={handleReset}
                                >
                                  Réinitialiser
                                </button>
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </div>
                      <div id="ff" class="my-2 mx-1 border p-0">
                        <div class="container-fluid p-0">
                          <div class="col-md-12 px-0">
                            <div class="panel panel-default">
                              <div class="panel-body">
                                <div class="table-responsive">
                                  {tab == 1 && (
                                    <table class="table table-condensed table-striped">
                                      <tbody class="table table-condensed table-striped">
                                        <tr>
                                          <td colspan="12" class="hiddenRow">
                                            <table class="table table-striped">
                                              <thead>
                                                <tr>
                                                  <th></th>
                                                  {/* <th>ID</th> */}
                                                  <th>N° patient</th>
                                                  <th>
                                                    {window.$language.Name}
                                                  </th>
                                                  <th>
                                                    {window.$language.Email}
                                                  </th>
                                                  <th>
                                                    {window.$language.Phone}
                                                  </th>
                                                  {state.utype ==
                                                    "BACKOFFICE" ||
                                                  state.utype == "ADMIN" ? (
                                                    <>
                                                      <th>PDV</th>
                                                      <th>
                                                        {
                                                          window.$language
                                                            .Created
                                                        }
                                                      </th>
                                                    </>
                                                  ) : null}
                                                  {/* <th>
                                                    {window.$language.STATUS}
                                                  </th> */}
                                                  <th>
                                                    {window.$language.ACTIONS}
                                                  </th>
                                                </tr>
                                              </thead>

                                              <tbody class="table-wrapper-scroll-y scrollbar-table my-custom-scrollbar">
                                                {currentItems?.map(
                                                  (usr, idx) => (
                                                    <tr>
                                                      <td class="tablerowimage">
                                                        {/* <img
                                                      class="round img-thumbnail"
                                                      src={
                                                        usr.profileImagePath ==
                                                          undefined ||
                                                        usr.profileImagePath ==
                                                          ""
                                                          ? "../../../app-assets//images/portrait/small/avatar-s-11.jpg"
                                                          : API_URL +
                                                            "/" +
                                                            usr.profileImagePath
                                                      }
                                                      alt="avatar"
                                                      height="40"
                                                      width="40"
                                                    /> */}
                                                        <input type="checkbox" />
                                                      </td>
                                                      {/* <td>{usr.patientNumber}</td> */}
                                                      <td>
                                                        {usr.patientUniqueCode}
                                                      </td>
                                                      <td>
                                                        {usr.name +
                                                          " " +
                                                          usr.lastName}
                                                      </td>
                                                      <td>{usr.email}</td>
                                                      <td>{usr.mobile}</td>

                                                      {state.utype ==
                                                        "BACKOFFICE" ||
                                                      state.utype == "ADMIN" ? (
                                                        <>
                                                          <td>
                                                            {usr.thirdPartyOptPOSName ==
                                                            ""
                                                              ? "Sym"
                                                              : usr.thirdPartyOptPOSName}
                                                          </td>
                                                          <td>
                                                            {formatDate(
                                                              usr.createdDate
                                                            )}
                                                          </td>
                                                        </>
                                                      ) : null}

                                                      {/* <td>
                                                      <span class="badge badge-pill badge-light-primary mr-1 status-badge">
                                                      Actif.
                                                      </span>
                                                    </td> */}
                                                      <td>
                                                        <div
                                                          class="btn-group"
                                                          role="group"
                                                          aria-label="Basic example"
                                                        >
                                                          <button
                                                            type="button"
                                                            class="btn btn-outline-secondary"
                                                            onClick={() =>
                                                              callView(
                                                                usr.email,
                                                                idx
                                                              )
                                                            }
                                                            href="javascript:void(0);"
                                                            data-toggle="tooltip"
                                                            data-placement="top"
                                                            title="View Details"
                                                            disabled={
                                                              isViewLoading &&
                                                              idx === index
                                                            }
                                                          >
                                                            {isViewLoading &&
                                                            idx === index ? (
                                                              "..."
                                                            ) : (
                                                              <i class="far fa-eye fa-fw"></i>
                                                            )}
                                                          </button>

                                                          {state.utype ==
                                                            "BACKOFFICE" ||
                                                          state.utype ==
                                                            "ADMIN" ||
                                                          state.utype ==
                                                            "OPTICIAN" ? (
                                                            <button
                                                              type="button"
                                                              class="btn btn-outline-secondary"
                                                              data-toggle="modal"
                                                              data-target="#modals-slide-in"
                                                              onClick={() =>
                                                                edit(usr)
                                                              }
                                                              href="javascript:void(0);"
                                                              data-placement="top"
                                                              title="Edit"
                                                            >
                                                              <i class="far fa-edit fa-fw"></i>
                                                            </button>
                                                          ) : null}
                                                          {state.utype ==
                                                            "BACKOFFICE" ||
                                                          state.utype ==
                                                            "ADMIN" ? (
                                                            <button
                                                              type="button"
                                                              class="btn btn-outline-secondary"
                                                              onClick={() =>
                                                                removerd(
                                                                  usr.email
                                                                )
                                                              }
                                                              href="javascript:void(0);"
                                                              data-toggle="tooltip"
                                                              data-placement="top"
                                                              title="Delete"
                                                            >
                                                              <i class="far fa-trash-alt fa-fw"></i>
                                                            </button>
                                                          ) : null}
                                                        </div>
                                                      </td>
                                                    </tr>
                                                  )
                                                )}
                                              </tbody>
                                            </table>
                                            <ReactPaginate
                                              nextLabel="suivant >"
                                              onPageChange={handlePageClick}
                                              pageRangeDisplayed={3}
                                              marginPagesDisplayed={2}
                                              pageCount={pageCount}
                                              previousLabel="< retour"
                                              pageClassName="page-item"
                                              pageLinkClassName="page-link"
                                              previousClassName="page-item"
                                              previousLinkClassName="page-link"
                                              nextClassName="page-item"
                                              nextLinkClassName="page-link"
                                              breakLabel="..."
                                              breakClassName="page-item"
                                              breakLinkClassName="page-link"
                                              containerClassName="pagination"
                                              activeClassName="active"
                                              renderOnZeroPageCount={null}
                                            />
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  )}
                                  {tab == 2 && (
                                    <table class="table table-condensed table-striped">
                                      <tbody class="table table-condensed table-striped">
                                        <tr>
                                          <td colspan="12" class="hiddenRow">
                                            <table class="table table-striped">
                                              <thead>
                                                <tr>
                                                  <th></th>
                                                  <th>Prénom</th>
                                                  <th>Nom de famille</th>
                                                  <th>E-mail</th>
                                                  <th>Numéro de téléphone</th>

                                                  {/* <th>Address</th> */}
                                                  {/* <th>Birthdate</th> */}
                                                  {/* <th>Status</th> */}
                                                  <th>Actions</th>
                                                </tr>
                                              </thead>

                                              <tbody class="table-wrapper-scroll-y scrollbar-table my-custom-scrollbar">
                                                {pDraftState?.map((usr) => (
                                                  <tr>
                                                    <td class="tablerowimage">
                                                      {/* <input
                                                        onClick={() =>
                                                          handleCheckedInfo(usr)
                                                        }
                                                        type="checkbox"
                                                        checked={checkedData.includes(usr)}
                                                      /> */}
                                                    </td>
                                                    <td>{usr.firstName}</td>
                                                    <td>{usr.lastName}</td>
                                                    <td>{usr.email}</td>
                                                    <td>{usr.phoneNumber}</td>
                                                    {/* <td>{usr.address}</td> */}
                                                    {/* <td>{usr.dateOfBirth}</td> */}
                                                    {/* <td>
                                                      <span class="badge badge-pill badge-light-primary mr-1 status-badge">
                                                        Active
                                                      </span>
                                                    </td> */}
                                                    <td>
                                                      <div
                                                        class="btn-group"
                                                        role="group"
                                                        aria-label="Basic example"
                                                      >
                                                        {/* <button
                                                        type="button"
                                                        class="btn btn-outline-secondary"
                                                        onClick={() =>
                                                          Paidetails(usr.email)
                                                        }
                                                        href="javascript:void(0);"
                                                        data-toggle="tooltip"
                                                        data-placement="top"
                                                        title="View Details"
                                                      >
                                                        <i class="far fa-eye fa-fw"></i>
                                                      </button> */}

                                                        {state.utype ==
                                                          "BACKOFFICE" ||
                                                        state.utype ==
                                                          "ADMIN" ||
                                                        state.utype ==
                                                          "OPTICIAN" ? (
                                                          <button
                                                            type="button"
                                                            class="btn btn-outline-secondary"
                                                            data-toggle="modal"
                                                            data-target="#modals-slide-in"
                                                            onClick={() => {
                                                              setUserId(
                                                                usr.userId
                                                              );
                                                              edit(usr);
                                                            }}
                                                            href="javascript:void(0);"
                                                            data-placement="top"
                                                            title="Edit"
                                                          >
                                                            <i class="far fa-edit fa-fw"></i>
                                                          </button>
                                                        ) : null}
                                                        {state.utype ==
                                                          "BACKOFFICE" ||
                                                        state.utype ==
                                                          "ADMIN" ||
                                                        state.utype ==
                                                          "OPTICIAN" ? (
                                                          <button
                                                            type="button"
                                                            class="btn btn-outline-secondary"
                                                            onClick={() =>
                                                              removerd(
                                                                usr.email,
                                                                usr.userId
                                                              )
                                                            }
                                                            href="javascript:void(0);"
                                                            data-toggle="tooltip"
                                                            data-placement="top"
                                                            title="Delete"
                                                          >
                                                            <i class="far fa-trash-alt fa-fw"></i>
                                                          </button>
                                                        ) : null}
                                                      </div>
                                                    </td>
                                                  </tr>
                                                ))}
                                              </tbody>
                                            </table>
                                            <ReactPaginate
                                              nextLabel="suivant >"
                                              onPageChange={handlePageClick}
                                              pageRangeDisplayed={3}
                                              marginPagesDisplayed={2}
                                              pageCount={pageCount}
                                              previousLabel="< retour"
                                              pageClassName="page-item"
                                              pageLinkClassName="page-link"
                                              previousClassName="page-item"
                                              previousLinkClassName="page-link"
                                              nextClassName="page-item"
                                              nextLinkClassName="page-link"
                                              breakLabel="..."
                                              breakClassName="page-item"
                                              breakLinkClassName="page-link"
                                              containerClassName="pagination"
                                              activeClassName="active"
                                              renderOnZeroPageCount={null}
                                            />
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="modal modal-slide-in fade"
                        id="modals-slide-in"
                      >
                        <div class="modal-dialog sidebar-sm w-50">
                          <form class="add-new-record modal-content pt-0">
                            <button
                              type="button"
                              class="close"
                              data-dismiss="modal"
                              aria-label="Close"
                              onClick={() => setIsAddPDraft(false)}
                            >
                              ×
                            </button>
                            <div class="modal-header mb-1">
                              <h5 class="modal-title" id="exampleModalLabel">
                                Nouvelle fiche
                              </h5>
                            </div>
                            <div class="modal-body flex-grow-1" id="inputform">
                              <div class="form-row mb-1">
                                <div class="col-lg-4">
                                  <label
                                    class="form-label"
                                    for="basic-icon-default-fullname"
                                  >
                                    {window.$language.FirstName}
                                    {isAddPDraft ? null : "*"}
                                  </label>
                                  <input
                                    type="text"
                                    class="form-control required dt-full-name"
                                    id="fname"
                                    placeholder=""
                                    aria-label=""
                                  />
                                </div>
                                <div class="col-lg-4">
                                  <label
                                    class="form-label"
                                    for="basic-icon-default-fullname"
                                  >
                                    {window.$language.LastName}
                                    {isAddPDraft ? null : "*"}
                                  </label>
                                  <input
                                    type="text"
                                    class="form-control required dt-full-name"
                                    id="lname"
                                    placeholder=""
                                    aria-label=""
                                  />
                                </div>
                                <div class="col-lg-3">
                                  <label for="DOB">
                                    {window.$language.DateofBirth}
                                    {isAddPDraft ? null : "*"}
                                  </label>

                                  <input
                                    type="date"
                                    class="form-control required pickadate"
                                    required
                                    placeholder="YYYY-MM-DD"
                                    id="DOB"
                                    onChange={handleChange_age}
                                  />
                                </div>
                                <div class="col-lg-1">
                                  <label for="Age1">Age</label>
                                  <input
                                    type="number"
                                    id="Age1"
                                    class="form-control "
                                    readOnly
                                  />
                                </div>
                              </div>
                              <div class="form-row mb-1 mt-0">
                                <div class="col-lg-4">
                                  <label class="form-label" for="email">
                                    {window.$language.Email}
                                    {isAddPDraft ? null : "*"}
                                  </label>
                                  <input
                                    type="text"
                                    class="form-control required dt-full-name"
                                    id="email"
                                    placeholder=""
                                    aria-label=""
                                  />
                                </div>
                                <div class={`col-lg-4`}>
                                  <label class="form-label" for="confirmEmail">
                                    Confirmer l'email{isAddPDraft ? null : "*"}
                                  </label>
                                  <input
                                    type="text"
                                    class="form-control required dt-full-name"
                                    id="confirmEmail"
                                    placeholder=""
                                    aria-label=""
                                  />
                                </div>
                                <div class="col-lg-4">
                                  <label
                                    class="form-label"
                                    for="basic-icon-default-fullname"
                                  >
                                    {window.$language.Phone}
                                    {isAddPDraft ? null : "*"}
                                  </label>
                                  <input
                                    type="number"
                                    class="form-control required dt-full-name"
                                    id="PH"
                                    placeholder=""
                                    aria-label=""
                                  />
                                </div>
                              </div>
                              <div class={`form-row mb-1`}>
                                <div class="col-lg-4">
                                  <div className="form-group align-baseline flex-row">
                                    <label
                                      className="form-label"
                                      for="login-email"
                                    >
                                      Genre{isAddPDraft ? null : "*"}
                                    </label>
                                    <select
                                      class="form-control required"
                                      id="gen"
                                    >
                                      <option value=""></option>
                                      <option value="Male">
                                        {window.$language.Male}
                                      </option>

                                      <option value="Female">
                                        {window.$language.Female}
                                      </option>

                                      <option value="Others">
                                        {window.$language.Others}
                                      </option>
                                    </select>
                                  </div>
                                </div>
                                {state.isExternalDoctor == "false" ? (
                                  <div class="col-lg-4">
                                    <label class="form-label" for="email">
                                      Nom de l'ophtalmologue
                                    </label>
                                    <input
                                      type="text"
                                      class="form-control dt-full-name"
                                      id="Namedoc"
                                      placeholder=""
                                      aria-label=""
                                    />
                                  </div>
                                ) : (
                                  <div class="col-lg-4">
                                    <label class="form-label" for="email">
                                      Nom de l'ophtalmologue
                                    </label>
                                    <input
                                      type="text"
                                      value={
                                        state.isFirstname +
                                        " " +
                                        state.isLastname
                                      }
                                      class="form-control dt-full-name"
                                      id="Namedoc"
                                      placeholder=""
                                      aria-label=""
                                    />
                                  </div>
                                )}
                                <div class="col-lg-4">
                                  <div className="form-group align-baseline flex-row">
                                    <label
                                      className="form-label required"
                                      for="login-email"
                                    >
                                      {" "}
                                      Le sujet porte des lunettes ?
                                      {isAddPDraft ? null : "*"}
                                    </label>
                                    <select
                                      class="form-control required"
                                      id="wg"
                                    >
                                      <option value=""></option>
                                      <option value="true">
                                        {window.$language.Yes}
                                      </option>
                                      <option value="false">
                                        {window.$language.No}
                                      </option>
                                    </select>
                                  </div>
                                </div>
                                <div class="col-lg-3 d-none">
                                  <div className="form-group align-baseline flex-row">
                                    <label
                                      className="form-label"
                                      for="login-email"
                                    >
                                      {window.$language.Languages}
                                    </label>
                                    <select class="form-control" id="1">
                                      <option value="1">English</option>

                                      <option value="1" selected>
                                        French
                                      </option>

                                      <option value="1">German</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <div class="form-row mb-1">
                                <div class={`col-lg-4`}>
                                  <div className="form-group align-baseline flex-row">
                                    <label
                                      className="form-label"
                                      for="login-email"
                                    >
                                      {" "}
                                      {window.$language.TypeofVision}
                                    </label>
                                    <select
                                      class="form-control required"
                                      id="visi"
                                    >
                                      <option>
                                        {window.$language.FarVision}
                                      </option>
                                      <option>
                                        {window.$language.CloseVision}
                                      </option>
                                      <option>
                                        {window.$language.ProgressiveVision}
                                      </option>
                                    </select>
                                  </div>
                                </div>
                                <div class={`col-lg-4`}>
                                  <label
                                    class="form-label"
                                    for="basic-icon-default-pass"
                                  >
                                    {window.$language.AdditionalComments}
                                  </label>
                                  <input
                                    class="form-control"
                                    id="Addcom"
                                    rows="3"
                                    placeholder=""
                                  />
                                </div>
                                <div class="col-lg-4">
                                  <label
                                    class="form-label"
                                    for="basic-icon-default-pass"
                                  >
                                    {window.$language.Address}
                                  </label>
                                  <input
                                    class="form-control"
                                    id="ADDDOC"
                                    rows="3"
                                    placeholder=""
                                  />
                                </div>
                                <div class="col-lg-4">
                                  <label
                                    class="form-label"
                                    for="basic-icon-default-pass"
                                  >
                                    Numéro de sécurité sociale
                                  </label>
                                  <input
                                    class="form-control"
                                    id="inssn"
                                    placeholder=""
                                  />
                                </div>
                              </div>
                              {isEdit ? (
                                <div className="form-row mb-1">
                                  {state.utype === "ADMIN" ? (
                                    <div className="col-lg-4 col-md-6">
                                      <div className="form-group">
                                        <label htmlFor="isprescrip">
                                          Prescription shared automatically
                                        </label>
                                        <select
                                          id="isprescrip"
                                          // value={
                                          //   state.infoPrescriptionToOptician
                                          //     ? "true"
                                          //     : "false"
                                          // } // Ensure boolean converts to string
                                          className="form-control"
                                          onChange={(e) =>
                                            setState((prevState) => ({
                                              ...prevState,
                                              infoPrescriptionToOptician:
                                                e.target.value === "true", // Convert string back to boolean
                                            }))
                                          }
                                        >
                                          <option value="true">
                                            {window.$language?.Yes || "Yes"}
                                          </option>
                                          <option value="false">
                                            {window.$language?.No || "No"}
                                          </option>
                                        </select>
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                              ) : null}
                              {tab == 1 || isAddPDraft ? (
                                <button
                                  type="button"
                                  class="btn btn-primary data-submit mr-1"
                                  // data-dismiss="modal"
                                  onClick={() => submit()}
                                >
                                  {window.$language.Submit}
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  class="btn btn-primary bg-success mr-1"
                                  onClick={() => PDraftSubmit(userId)}
                                  // data-dismiss="modal"
                                  //onClick={handleApprove}
                                >
                                  Approuver
                                </button>
                              )}
                              <button
                                type="reset"
                                class="btn btn-outline-secondary"
                                id="close"
                                data-dismiss="modal"
                                onClick={() => setIsAddPDraft(false)}
                              >
                                {window.$language.Cancel}
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
}

export default Pacientreg;
