import React, { Component } from "react";
import ReactDOM from "react-dom";
import $ from "jquery";
import Docreg from "../User/doctorregistration/doctorreg";
import Patientreg from "../User/patientregistration/pacientreg";
import Backofficereg from "../User/Backofficeregistration/Backoffice";
import Posreg from "../User/POS/Posreg";
import Opticianreg from "../User/opticianregistration/opticianreg";
import Eyetest from "../EyetestComponent/Eyetest";
import Eyeresult from "../EyetestComponent/Eyetestresult";
import Product from "../ProductComponent/Product";
import Order from "../Order/Orderprocessing";
import { API_URL } from "../../config";
import "./dashboard.css";
import { ProductFruits } from 'react-product-fruits';
class Dashboard2 extends Component {
  constructor() { 
    super();
    this.state = {
      emailID: "",
      pwd: "",
      loginIP: "",
      loginBrowser: "",
      deviceType: "",
      userLocation: "",
      logIsp: "",
      ii: 0,
      actions: [],
      eyetest: [],
      pLogo: "",
      email: localStorage.getItem("email"),
      utype: localStorage.getItem("type"),
      isExternal: localStorage.getItem("isExternal"),
      isExternalDoctor:localStorage.getItem("isExternalDoctor"),
      ufirstname:localStorage.getItem("FirstName"),
      ulastname:localStorage.getItem("LastName"),
      mergedCount: {},
      unass: [],
      apuny: [],
      assnew: [],
      userInfo: {
        username:localStorage.getItem("opmail"), // REQUIRED - any unique user identifier
        email: localStorage.getItem("opmail"),
        firstname: localStorage.getItem("FirstName"),
        lastname: localStorage.getItem("LastName"),
        signUpAt: '',
        role: localStorage.getItem("type"),
        props: { customProp1: localStorage.getItem("isExternal"),},
      },
    };
    this.docreg = this.docreg.bind(this);
    this.patientreg = this.patientreg.bind(this);
    this.posreg = this.posreg.bind(this);
    this.opticianreg = this.opticianreg.bind(this);
    this.backofficereg = this.backofficereg.bind(this);
    localStorage.setItem("hdnsearch","")
  }
  submit() {}
  docreg() {
    console.log("hi");
    ReactDOM.render(<Docreg />, document.getElementById("c11"));
  }
  backofficereg() {
    console.log("hi");
    ReactDOM.render(<Backofficereg />, document.getElementById("c11"));
  }
  patientreg() {
    console.log("hi");
    ReactDOM.render(<Patientreg />, document.getElementById("c11"));
  }
  opticianreg() {
    console.log("hi");
    ReactDOM.render(<Opticianreg />, document.getElementById("c11"));
  }
  posreg() {
    console.log("hi");
    localStorage.setItem("Orderdashboard","")
    ReactDOM.render(<Posreg />, document.getElementById("c11"));
  }

  eyetest() {
    console.log("hi");
    localStorage.setItem("Orderdashboard","")
    ReactDOM.render(<Eyetest />, document.getElementById("c11"));
  }
  eyeresult(head, status) {
    localStorage.setItem("Orderdashboard","")
    console.log(status, "onclick status");
    let bb = head.target.id;
    //alert(bb)
    console.log("hi");
    if (status == "unass") {
      ReactDOM.render(
        <Eyeresult
          status="New"
          //data={this.state.eyetest}
          unass={1}
          doctorName={false}
          heading={bb}
        />,
        document.getElementById("c11")
      );
    } else if (status == "assnew") {
      ReactDOM.render(
        <Eyeresult
          status="New"
          assnew={1}
          // data={this.state.eyetest}
          heading={bb}
        />,
        document.getElementById("c11")
      );
    } else if (status == "apuny") {
      ReactDOM.render(
        <Eyeresult
          status="Approved"
          apuny={1}
          //data={this.state.eyetest}
          heading={bb}
        />,
        document.getElementById("c11")
      );
    } else
      ReactDOM.render(
        <Eyeresult status={status} heading={bb} />,
        document.getElementById("c11")
      );
  }

  Product() {
    console.log("hi");
    ReactDOM.render(<Product />, document.getElementById("c11"));
  }
  Order(head, val) {
  
    localStorage.setItem("Orderdashboard","order")
    let bb = head.target.id;
    console.log("hi");
    ReactDOM.render(
      <Order heading={bb} det={[]} val={val} vv={1} />,
      document.getElementById("c11")
    );
  }

  unasscount() {
    let data1 = {
      patientEmail: "",
      status: "New",
      search: "",
      pageNumber: 1,
      pageSize: 1000,
      assigUnassigned:"0",
    };
    let logourl = API_URL + "/eyetests/exportEyeTest";
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("token"),
      },
      body: JSON.stringify(data1),
    };
    fetch(logourl, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        console.log(json);
        let arr1 = [];
        arr1 = json.eyeTestData;
        this.setState({ unass: arr1 });
      })
      .catch((e) => {

        console.log(e);
      });
  }
  assnewcount() {
    let data1 = {
      patientEmail: "",
      status: "New",
      search: "",
      pageNumber: 1,
      pageSize: 1000,
      assigUnassigned:"1",
    };
    let logourl = API_URL + "/eyetests/exportEyeTest";
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("token"),
      },
      body: JSON.stringify(data1),
    };
    fetch(logourl, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        console.log("eyetestdataassnew",json);
        let arr1 = [];
        arr1 = json.eyeTestData;
        this.setState({ assnew: arr1 });
      })
      .catch((e) => {

        console.log(e);
      });
  }
  appunnoty() {
    let data1 = {
      patientEmail: "",
      status: "Approved",
      search: "",
      pageNumber: 0,
      pageSize: 0,
    };
    let logourl = API_URL + "/eyetests/exportEyeTest";
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("token"),
      },
      body: JSON.stringify(data1),
    };
    fetch(logourl, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        console.log(json);
        let arr1 = [];

        for (let i = 0; i < json.length; i++) {
          if (!json[i].assignedToDoc) {
            arr1.push(json);
          }
        }
        arr1 = json.eyeTestData.filter((item) => item.notifiedPatient == "No");
        console.log("unnotified" + arr1);
        this.setState({ apuny: arr1 });
      })
      .catch((e) => {

        console.log(e);
      });
  }

  componentDidMount() {
    console.log(this.state.userInfo,"userinfo")
    let url1 = window.location.href;
    if (url1 == "https://app.symvisionlab.com/dashboard") {
      window.location.href = "https://app.symcarelab.com/dashboard";
    }
    let cname = "loggedin";
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        console.log(c.substring(name.length, c.length));
      }
    }
    localStorage.setItem("searchtext","")
    let logourl = API_URL + "/users/profile";
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
    };
    fetch(logourl, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        console.log(json);
        localStorage.setItem("isExternal", json.isExternal);
        localStorage.setItem("isExternalDoctor", json.isExternalDoctor);
        localStorage.setItem("FirstName", json.name);
        localStorage.setItem("LastName", json.lastName);
        this.setState({
          email: localStorage.getItem("email"),
          utype: localStorage.getItem("type"),
          isExternal: json.isExternal,
          isExternalDoctor: json.isExternalDoctor,
        });
      })
      .catch((e) => {}); //
    //////
    var token = localStorage.getItem("token");

    var fetArr = [];

    var userlist= "OPTICIAN,EYEDOCTOR,POINTOFSALES,PATIENT"
    var eyetestlist="New,Approved,Not Approved,All,Unassigned,Assigned"
    var orderlist="All,ToBeOrdered,Pending,Commandé,Annulé,SAV"

    Promise.all([
      fetch(API_URL + "/user/count", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({
          Type: userlist,
        }),
      }).then((res) => res.json()),
      fetch(API_URL + "/eyetests/count", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({
          status: eyetestlist,
          PatientEmail: "",
          search: "",
        }),
      }).then((res) => res.json()),
      fetch(API_URL + "/Products/count", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({
          ProductCode: "",
        }),
      }).then((res) => res.json()),
      fetch(API_URL + "/Orders/count", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({
          patientEmail: "",
          status: orderlist,
          search: "",
          pageNumber: 1,
          pageSize: 10000,
        }),
      }).then((res) => res.json()),
    ]).then(([d1, d2, d3, d4]) => 
      {
      this.setState({
        mergedCount: {
          O: d1[0],
          E: d1[1],
          POS: d1[2],
          P: d1[3],
          N: d2[0],
          A: d2[1],
          NA: d2[2],
          All: d2[3],
          unass: d2[4],
          assnew: d2[5],
          Prod: d3,
          Ord: d4[0],
          Tord: d4[1],
          Pord: d4[2],
          Ordered: d4[3],
          Canord: d4[4],
          sav:d4[5]
        },
      });

      console.log(this.state.mergedCount);
    });
  }

  render() {
    const { userInfo } = this.state;
    return (
      <div class="app-content content" id="c11">
        <div class="content-wrapper">
          <div class="content-header row"></div>
          <div class="content-body px-1 ">
            {/*Body */}
            <section id="dashboard-analytics">
            <ProductFruits workspaceCode="prtz9Zb2NKbVbUBc" language="en" user={userInfo} />
              <div class="row mx-0 p-0">
                {this.state.utype !== "PATIENT" &&
                this.state.utype !== "EYEDOCTOR" ? (
                  <div class=" float-left">
                    <div class="card shadow m-0 rounded h-100">
                      <div class="card-body p-1">
                        <div class="mt-1">
                          {" "}
                          <img
                            className="img-fluid4 img-thumbnail"
                            src="../../../app-assets/images/slider/10.jpg"
                            alt="Logo"
                          />
                        </div>
                        <div class="px-1 row d-flex flex-column m-0 justify-content-between">
                          {this.state.utype == "ADMIN" ? (
                            <div class="dashboardlinkcover">
                              <div>
                                <i class="fas fa-user-plus fa-fw text-primary"></i>
                              </div>
                              <div class="pl-1">
                                <a
                                  class="dashboardlink text-wrap"
                                  onClick={this.backofficereg}
                                >
                                  L'equipe Backoffice
                                </a>
                              </div>
                            </div>
                          ) : null}
                          {this.state.utype == "BACKOFFICE" ||
                          this.state.utype == "ADMIN" ? (
                            <div class="dashboardlinkcover">
                              <div>
                                <i class="fas fa-user-plus fa-fw text-primary"></i>
                              </div>
                              <div class="pl-1">
                                <a
                                  class="dashboardlink text-wrap"
                                  onClick={this.opticianreg}
                                >
                                  {/* {window.$language.ViewOpticians} */}
                                  Opticiens
                                </a>
                              </div>
                              <div class="badge badge-light-primary">
                                {" "}
                                {this.state.mergedCount.O}{" "}
                              </div>
                            </div>
                          ) : null}
                          {this.state.utype == "BACKOFFICE" ||
                          this.state.utype == "ADMIN" ? (
                            <div class="dashboardlinkcover">
                              <div>
                                <i class="fas fa-user-plus fa-fw text-primary"></i>
                              </div>

                              <div class="pl-1">
                                <a
                                  class="dashboardlink text-wrap"
                                  onClick={this.docreg}
                                >
                                  {/* {window.$language.ViewEyeDoctors} */}
                                  Docteurs
                                </a>
                              </div>
                              <div class="badge badge-light-primary">
                                {" "}
                                {this.state.mergedCount.E}{" "}
                              </div>
                            </div>
                          ) : null}
                          {this.state.utype == "BACKOFFICE" ||
                          this.state.utype == "ADMIN" ? (
                            <div class="dashboardlinkcover">
                              <div>
                                <i class="fas fa-user-plus fa-fw text-primary"></i>
                              </div>
                              <div class="pl-1">
                                <a
                                  class="dashboardlink text-wrap"
                                  onClick={this.posreg}
                                >
                                  {window.$language.ViewPointOfSales}
                                </a>
                              </div>
                              <div class="badge badge-light-primary">
                                {" "}
                                {this.state.mergedCount.POS}{" "}
                              </div>
                            </div>
                          ) : null}
                          {this.state.utype !== "PATIENT" ? (
                            <div class="dashboardlinkcover">
                              <div>
                                <i class="fas fa-user-plus fa-fw text-primary"></i>
                              </div>
                              <div class="pl-1">
                                <a
                                  class="dashboardlink text-wrap"
                                  onClick={this.patientreg}
                                >
                                  {window.$language.ViewPatients}
                                </a>
                              </div>
                              <div class="badge badge-light-primary">
                                {" "}
                                {this.state.mergedCount.P}{" "}
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
                {this.state.utype == "EYEDOCTOR" &&
                this.state.isExternalDoctor !== null &&
                this.state.isExternalDoctor !== false ? (
                    <div class=" float-left">
                    <div class="card shadow m-0 rounded h-100">
                      <div class="card-body p-1">
                        <div class="mt-1">
                          {" "}
                          <img
                            className="img-fluid4 img-thumbnail"
                            src="../../../app-assets/images/slider/10.jpg"
                            alt="Logo"
                          />
                        </div>
                        <div class="px-1 row d-flex flex-column m-0 justify-content-between">
                            <div class="dashboardlinkcover">
                              <div>
                                <i class="fas fa-user-plus fa-fw text-primary"></i>
                              </div>
                              <div class="pl-1">
                                <a
                                  class="dashboardlink text-wrap"
                                  onClick={this.patientreg}
                                >
                                  {window.$language.ViewPatients}
                                </a>
                              </div>
                              <div class="badge badge-light-primary">
                                {" "}
                                {this.state.mergedCount.P}{" "}
                              </div>
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>) : null}
                <div class=" float-left">
                  <div class="card shadow m-0 rounded h-100">
                    <div class="card-body p-1">
                      <div class="mt-1">
                        {" "}
                        <img
                          className="img-fluid4 img-thumbnail"
                          src="../../../app-assets/images/slider/07.jpg"
                          alt="Logo"
                        />
                      </div>
                      <div class="px-1 row d-flex flex-column m-0 justify-content-start">
                        {this.state.utype == "EYEDOCTOR" && this.state.isExternalDoctor == false ? (
                          <div class="dashboardlinkcover">
                            <div>
                              <i class="far fa-eye fa-fw text-primary"></i>
                            </div>
                            <div class="pl-1">
                              <a
                                class="dashboardlink text-wrap"
                                id="Voir les nouveaux tests de vue"
                                onClick={(data) => this.eyeresult(data, "New")}
                                data-toggle="tooltip"
                                data-placement="top"
                                // title={window.$language.ViewNewEyeTests}
                              >
                                Voir les nouveaux tests de vue
                              </a>
                            </div>
                            <div class="badge badge-light-primary">
                              {" "}
                              {this.state.mergedCount.N}{" "}
                            </div>
                          </div>
                        ) : null}
                         {this.state.mergedCount.P !== 0 ? (
                          <>
                        {this.state.utype == "BACKOFFICE" ||
                        this.state.utype == "ADMIN" ||this.state.utype == "OPTICIAN" ? (
                          <>
                            <div class="dashboardlinkcover ">
                              <div>
                                <i class="far fa-eye fa-fw text-primary"></i>
                              </div>
                              <div class="pl-1">
                                <a
                                  class="dashboardlink text-wrap"
                                  onClick={(data) =>
                                    this.eyeresult(data, "unass")
                                  }
                                  id={window.$language.ViewUnassignedEyeTests}
                                  data-toggle="tooltip"
                                  data-placement="top"
                                >
                                  {window.$language.ViewUnassignedEyeTests}
                                </a>
                              </div>
                              <div class="badge badge-light-primary">
                                {" "}
                                {this.state.mergedCount.unass}{" "}
                                {/* {this.state.unass.length}{" "} */}
                              </div>
                            </div>
                            <div class="dashboardlinkcover ">
                              <div>
                                <i class="far fa-eye fa-fw text-primary"></i>
                              </div>
                              <div class="pl-1">
                                <a
                                  class="dashboardlink text-wrap"
                                  onClick={(data) =>
                                    this.eyeresult(data, "assnew")
                                  }
                                  id="Tests de vue attribués, en attente de validation"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title=""
                                >
                                  Tests de vue attribués, en attente de validation
                                </a>
                              </div>
                              <div class="badge badge-light-primary">
                                {" "}
                                {this.state.mergedCount.assnew}{" "}
                                {/* {this.state.assnew.length}{" "} */}
                              </div>
                            </div>
                          </>
                        ) : null}
                        {this.state.utype == "BACKOFFICE" ||
                        this.state.utype == "ADMIN" ? (
                          <div class="dashboardlinkcover d-none">
                            <div>
                              <i class="far fa-eye fa-fw text-primary"></i>
                            </div>
                            <div class="px-1">
                              <a
                                class="dashboardlink text-wrap"
                                onClick={(data) =>
                                  this.eyeresult(data, "apuny")
                                }
                                id={
                                  window.$language
                                    .ViewApprovednonnotifiedEyeTests
                                }
                                data-toggle="tooltip"
                                data-placement="top"
                              >
                                {
                                  window.$language
                                    .ViewApprovednonnotifiedEyeTests
                                }
                              </a>
                            </div>
                            <div class="badge badge-light-primary">
                              {" "}
                              {this.state.apuny.length}{" "}
                            </div>
                          </div>
                        ) : null}

                        {this.state.utype == "BACKOFFICE" ||
                        this.state.utype == "ADMIN" ||this.state.utype == "OPTICIAN" ? (
                          <div class="dashboardlinkcover ">
                            <div>
                              <i class="far fa-eye fa-fw text-primary"></i>
                            </div>
                            <div class="px-1">
                              <a
                                class="dashboardlink text-wrap"
                                onClick={(data) =>
                                  this.eyeresult(data, "Approved")
                                }
                                id={window.$language.ViewApprovedEyeTests}
                                data-toggle="tooltip"
                                data-placement="top"
                                // title={window.$language.ViewApprovedEyeTests}
                              >
                                {window.$language.ViewApprovedEyeTests}
                              </a>
                            </div>
                            <div class="badge badge-light-primary">
                              {" "}
                              {this.state.mergedCount.A}{" "}
                            </div>
                          </div>
                        ) : null}

                        {this.state.utype == "BACKOFFICE" ||
                        this.state.utype == "ADMIN" ? (
                          <div class="dashboardlinkcover ">
                            <div>
                              <i class="far fa-eye fa-fw text-primary"></i>
                            </div>
                            <div class="px-1">
                              <a
                                class="dashboardlink text-wrap"
                                onClick={(data) =>
                                  this.eyeresult(data, "Not Approved")
                                }
                                id={window.$language.ViewNotApprovedEyeTests}
                                data-toggle="tooltip"
                                data-placement="top"
                                // title={window.$language.ViewNotApprovedEyeTests}
                              >
                                {window.$language.ViewNotApprovedEyeTests}
                              </a>
                            </div>
                            <div class="badge badge-light-primary">
                              {" "}
                              {this.state.mergedCount.NA}{" "}
                            </div>
                          </div>
                        ) : null}
                       
                          <div class="dashboardlinkcover">
                            <div>
                              <i class="far fa-eye fa-fw text-primary"></i>
                            </div>
                            <div class="pl-1">
                              <a
                                class="dashboardlink text-wrap"
                                onClick={(data) => this.eyeresult(data, "")}
                                id="Voir tous les tests de vue"
                                data-toggle="tooltip"
                                data-placement="top"
                                // title={window.$language.ViewAllEyeTests}
                              >
                               Voir tous les tests de vue
                              </a>
                            </div>
                            <div class="badge badge-light-primary">
                              {" "}
                              {this.state.mergedCount.All}{" "}
                            </div>
                          </div>
                          </>
                        ) : null}
                        {this.state.mergedCount.P == 0 ? (
                          <div class="dashboardlinkcover">
                            <h6>
                              Cette fonction sera accessible après avoir créé
                              votre premier patient
                            </h6>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>

                {this.state.utype == "BACKOFFICE" ||
                this.state.utype == "ADMIN" ? (
                  <div class=" float-left">
                    <div class="card shadow m-0 rounded h-100">
                      <div class="card-body p-1">
                        <div class="mt-1">
                          {" "}
                          <img
                            className="img-fluid4 img-thumbnail"
                            src="../../../app-assets/images/slider/06.jpg"
                            alt="Logo"
                          />
                        </div>
                        <div class="px-1 row d-flex flex-column m-0 justify-content-between">
                          <div class="dashboardlinkcover">
                            <div>
                              <i class="fas fa-shopping-cart fa-fw text-primary"></i>
                            </div>
                            <div class="px-1">
                              <a
                                class="dashboardlink text-wrap"
                                onClick={() => this.Product("")}
                                data-toggle="tooltip"
                                data-placement="top"
                                // title={window.$language.ViewProducts}
                              >
                                {window.$language.ViewProducts}
                              </a>
                            </div>
                            <div class="badge badge-light-primary">
                              {" "}
                              {this.state.mergedCount.Prod}{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}

             {this.state.utype == "BACKOFFICE" ||
              this.state.utype == "ADMIN" ||
              (this.state.utype == "OPTICIAN" &&
                this.state.isExternal !== null &&
                this.state.isExternal == false) ? (
                  <div class="float-left">
                    <div class="card shadow m-0 rounded h-100">
                      <div class="card-body p-1">
                        <div class="mt-1">
                          {" "}
                          <img
                            className="img-fluid4 img-thumbnail"
                            src="../../../app-assets/images/slider/09.jpg"
                            alt="Logo"
                          />
                        </div>
                        <div class="px-1 row d-flex flex-column m-0 justify-content-start">
                          <div class="dashboardlinkcover">
                            <div>
                              <i class="fas fa-shopping-cart fa-fw text-primary"></i>
                            </div>
                            <div class="px-1">
                              <a
                                class="dashboardlink text-wrap"
                                onClick={(data) => this.Order(data, "")}
                                id={window.$language.ViewAllOrders}
                                data-toggle="tooltip"
                                data-placement="top"
                               
                              >
                                {window.$language.ViewAllOrders}
                              </a>
                            </div>
                            <div class="badge badge-light-primary">
                              {" "}
                              {this.state.mergedCount.Ord}{" "}
                            </div>
                          </div>
                          <div class="dashboardlinkcover">
                            <div>
                              <i class="fas fa-shopping-cart fa-fw text-primary"></i>
                            </div>
                            <div class="px-1">
                              <a
                                class="dashboardlink text-wrap"
                                onClick={(data) => this.Order(data, "Pending")}
                                id={window.$language.PendingOrders}
                                data-toggle="tooltip"
                                data-placement="top"
                                // title={window.$language.PendingOrders}
                              >
                                En attente
                              </a>
                            </div>
                            <div class="badge badge-light-primary">
                              {" "}
                              {this.state.mergedCount.Pord}{" "}
                            </div>
                          </div>
                          <div class="dashboardlinkcover">
                            <div>
                              <i class="fas fa-shopping-cart fa-fw text-primary"></i>
                            </div>
                            <div class="px-1">
                              <a
                                class="dashboardlink text-wrap"
                                onClick={(data) => this.Order(data, "SAV")}
                                id="Commandes SAV"
                                data-toggle="tooltip"
                                data-placement="top"
                                // title={window.$language.CancelledOrders}
                              >
                                SAV
                              </a>
                            </div>
                            <div class="badge badge-light-primary">
                              {" "}
                              {this.state.mergedCount.sav}{" "}
                            </div>
                          </div>
                          <div class="dashboardlinkcover">
                            <div>
                              <i class="fas fa-shopping-cart fa-fw text-primary"></i>
                            </div>
                            <div class="px-1">
                              <a
                                class="dashboardlink text-wrap"
                                onClick={(data) =>
                                  this.Order(data, "ToBeOrdered")
                                }
                                id={window.$language.TobeOrdered}
                                data-toggle="tooltip"
                                data-placement="top"
                                // title={window.$language.TobeOrdered}
                              >
                                {window.$language.TobeOrdered}
                              </a>
                            </div>
                            <div class="badge badge-light-primary">
                              {" "}
                              {this.state.mergedCount.Tord}{" "}
                            </div>
                          </div>
                          <div class="dashboardlinkcover">
                            <div>
                              <i class="fas fa-shopping-cart fa-fw text-primary"></i>
                            </div>
                            <div class="px-1">
                              <a
                                class="dashboardlink text-wrap"
                                onClick={(data) => this.Order(data, "Commandé")}
                                data-toggle="tooltip"
                                data-placement="top"
                              >
                                Commandées
                              </a>
                            </div>
                            <div class="badge badge-light-primary">
                              {" "}
                              {this.state.mergedCount.Ordered}{" "}
                            </div>
                          </div>
                          <div class="dashboardlinkcover">
                            <div>
                              <i class="fas fa-shopping-cart fa-fw text-primary"></i>
                            </div>
                            <div class="px-1">
                              <a
                                class="dashboardlink text-wrap"
                                onClick={(data) => this.Order(data, "Annulé")}
                                id={window.$language.CancelledOrders}
                                data-toggle="tooltip"
                                data-placement="top"
                                // title={window.$language.CancelledOrders}
                              >
                                Annulées
                              </a>
                            </div>
                            <div class="badge badge-light-primary">
                              {" "}
                              {this.state.mergedCount.Canord}{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </section>
            {/* <ProductFruits workspaceCode="prtz9Zb2NKbVbUBc" language="en" user={this.state.email}  /> */}
            <footer class="footer footer-static footer-light">
              {this.state.utype !== "BACKOFFICE" &&
              this.state.utype !== "ADMIN" ? (
                <p class="clearfix mb-0">
                  <span class="float-md-left d-block d-md-inline-block mt-25">
                    <a
                      class="ml-25 instagram"
                      href="../../../home/assets/220609MentionsLegales.pdf"
                      target="_blank"
                    >
                      Mentions Légales
                    </a>{" "}
                    &amp;
                    <a
                      class="ml-25 instagram"
                      href="../../../home/assets/220906Politiquedeconfidentialité.pdf"
                    >
                      Politique de Confidentialité
                    </a>
                  </span>
                </p>
              ) : null}
              {this.state.utype == "PATIENT" ? (
                <p class="clearfix mb-0">
                  <span class="float-md-left d-block d-md-inline-block mt-25">
                    <a
                      class="ml-25 instagram"
                      href="../../../home/assets/FAQs_patient.pdf"
                      target="_blank"
                    >
                      Foire aux questions
                    </a>{" "}
                    &amp;
                    <a
                      class="ml-25 instagram"
                      href="../../../home/assets/How_To_patients.pdf"
                    >
                      Guide d'utilisation de la plateforme
                    </a>
                  </span>
                </p>
              ) : null}
              {this.state.isExternal == "true" ? (
                <p class="clearfix1 mb-0">
                  <span class="float-md-left d-block d-md-inline-block mt-25">
                    <a
                      class="ml-25 instagram"
                      href="../../../home/assets/FAQs_opticians.pdf"
                      target="_blank"
                    >
                      Foire aux questions
                    </a>{" "}
                    &amp;
                    <a
                      class="ml-25 instagram"
                      href="../../../home/assets/How_To_Opticians.pdf"
                    >
                      Guide d'utilisation de la plateforme
                    </a>
                  </span>
                </p>
              ) : null}
              {this.state.utype == "OPTICIAN" &&
              this.state.isExternal !== "true" ? (
                <p class="mb-0">
                  <span class="float-md-left d-block d-md-inline-block mt-25">
                    <a
                      class="ml-25 instagram"
                      href="../../../home/assets/FAQs_opticians.pdf"
                      target="_blank"
                    >
                      Foire aux questions
                    </a>{" "}
                    &amp;
                    <a
                      class="ml-25 instagram"
                      href="../../../home/assets/How_To_Opticians.pdf"
                    >
                      Guide d'utilisation de la plateforme
                    </a>
                  </span>
                </p>
              ) : null}
            </footer>
          </div>
        </div>
      </div>
    );
  }
}
export default Dashboard2;
