import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Login from "./Component/loginComponent/login";
import Setpwd from "./Component/loginComponent/setpassword";
import Landing from "./Component/Landing/landing";
// import Docreg from './Component/User/doctorreg'
import UserProfile from "./Component/User/userprofile/UserProfile";
import Forgot from "./Component/loginComponent/forgotpassword";
import Paidetails from "./Component/User/patientregistration/viewpatientdetails";
import Header from "./Component/header/header";
import Menu from "./Component/menu/menu";
import Dash2 from "./Component/dashboardComponent/dashboard2";
import Settings from "./Component/dashboardComponent/Settings";
import Eyetest from "./Component/EyetestComponent/Eyetest";
//import Register from "./Component/RegistrationComponent/Registration";
import Prescription from "./Component/Prescription/Prescription";
import Vieweyetest from "./Component/EyetestComponent/Vieweyetest";
import Test from "./test";
export default function App() {
  return (
    <Router>
      {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
      <Switch>
        <Route path="/test">
          <Test />
        </Route>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/setpassword">
          <Setpwd />
        </Route>
        <Route path="/Prescription">
          <Prescription />
        </Route>
        <Route path="/paidetails">
          <Paidetails />
        </Route>
        <Route path="/UserProfile">
          <UserProfile />
        </Route>
        <Route path="/Vieweyetest">
          <Vieweyetest />
        </Route>
        <Route path="/forgotpassword">
          <Forgot />
        </Route>
        {/* <Route path="/dashboard">
            <Dash />
          </Route> */}
        <Route path="/dashboard">
          <Header />
          <Menu />
          <Dash2 />
        </Route>
        <Route path="/Settings">
          <Settings />
        </Route>
        <Route path="/Eyetest">
          <Eyetest />
        </Route>
        {/* <Route path="/Registration">
          <Register />
        </Route> */}
        <Route path="/">
          <Landing />
        </Route>
      </Switch>
    </Router>
  );
}

function About() {
  return <h2>About</h2>;
}

function Users() {
  return <h2>Users</h2>;
}
